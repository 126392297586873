#testimoni-subscription {
  margin-top: 36px !important;
}
.container-subscription {
  margin: auto;
  max-width: 1170px;
  width: 100%;
}
.head-testimoni-subs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.wrapper-testimoni-subs {
  display: flex;
  align-items: center;
  margin-top: 22px;
  overflow-x: hidden;
  flex-wrap: nowrap;
  -webkit-overflow-scrolling: touch;
}
.wrapper-testimoni-subs::-webkit-scrollbar {
  display: none;
}
.text-title-testimoni-subs {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  margin: unset !important;
  color: rgba(0, 0, 0, 0.87);
}
.parent-button-testimoni-subs {
  display: flex;
  align-items: center;
}
.btn-arrow-testimoni {
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  height: 34px;
  width: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
  border-radius: 50%;
}
.parent-card-testimoni-subs {
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 12px;
  display: flex;
  width: 374px;
  height: 157px;
  flex: 0 0 auto;
  margin-right: 24px;
}
.img-testimoni-subs {
  object-fit: cover;
  width: 121px;
  border-radius: 12px 0px 0px 12px;
}
.wraps-about-tes {
  display: block;
}
.wraps-about-tes-mob {
  display: none;
}
.section-testimoni-subs {
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.text-name-testimoni-subs {
  margin: unset !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.87);
}
.text-about-testimoni-subs {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin: unset !important;
  color: #999999;
  margin-top: 4px !important;
}
.text-content-testimoni-subs {
  margin: unset !important;
  font-family: Lato;
  font-style: italic;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.87);
}
.dummy-box-tes {
  display: none;
}
.wrapper-content-testimoni-subs {
  margin-top: 12px;
}
.link-testimoni-subs {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  /* margin-top: 8px; */
  color: #7d61d6;
  cursor: pointer;
}
.link-testimoni-subs:focus {
  color: #7d61d6;
}
.link-testimoni-subs:hover {
  color: #7d61d6;
}
.text-tes-subs-desktop {
  display: block;
}
.text-tes-subs-mobile {
  display: none;
}
/* Video Section */
.parent-video-container {
  margin-top: 48px;
  background: #f5f0ff;
  padding: 48px 0px;
}
.parent-content-video {
  display: flex;
  align-items: center;
  width: 100%;
}
.section-one-video {
  width: 35%;
}
.section-separator-video {
  width: 15%;
}
.section-two-video {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.text-title-video-subs {
  margin-bottom: 12px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.87);
  width: 75%;
  text-align: left;
  white-space: nowrap;
}
.text-description-video-subs {
  margin: unset !important;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.87);
  margin-top: 12px !important;
}
.video-subs {
  border-radius: 12px;
}

@media (max-width: 768px) {
  .container-subscription {
    margin: unset;
    max-width: 100%;
  }
  .text-title-testimoni-subs {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.87);
  }
  .dummy-box-tes {
    flex: 0 0 auto;
    width: 5px;
    height: 16px;
    display: block;
  }
  .head-testimoni-subs {
    padding: 16px;
  }
  .parent-button-testimoni-subs {
    display: none;
  }
  #testimoni-subscription {
    margin-top: 24px !important;
  }
  .wrapper-testimoni-subs {
    padding: 16px;
    padding-right: 16px;
    margin-top: 0px;
    padding-top: 0px;
    overflow-x: auto;
  }
  .parent-card-testimoni-subs {
    width: 304px;
    margin-right: 16px;
  }
  .wraps-about-tes {
    display: none;
  }
  .wraps-about-tes-mob {
    display: block;
  }
  .text-tes-subs-mobile {
    display: block;
  }
  .text-tes-subs-desktop {
    display: none;
  }
  /* Video Section */
  .parent-video-container {
    margin-top: 36px;
    padding: 24px 16px;
  }
  .parent-content-video {
    display: block;
    margin-bottom: 0px !important;
  }
  .section-one-video {
    width: 100%;
  }
  .section-separator-video {
    display: none;
  }
  .section-two-video {
    width: 100%;
    margin-top: 16px;
    justify-content: center;
  }
  .text-title-video-subs {
    width: 100%;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.87);
  }
  .text-description-video-subs {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    margin-top: 10px !important;
    color: rgba(0, 0, 0, 0.87);
  }
  .video-subs {
    height: 185px;
  }
}
