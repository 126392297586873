#footer-container #footer .companydetail p {
  margin-bottom: 13px;
  margin-top:22px;
}

#footer-container .footerlink ul li {
	line-height: 0px;
}

#footer-container .footerlink ul li {
	margin-bottom: 29px;
}
#footer-container .footerlink ul.footersocial li {
	margin-bottom: 22px;
}
#footer-container .footerlink ul li:last-child {
	margin-bottom: 0px;
}

#footer-container h4 {
	margin-bottom: 0px;
	margin-top:0px;
}
#footer-container ul.footerhubungi li {
	margin-bottom: 24px;
}
#footer-container ul.download li {
	margin-bottom: 14px;
}
#footer-container .footerlink h4 {
	margin-bottom: 24px;
}

#footer-container  .footerlink .footersocial h4 {
	margin-bottom: 20px;
}

@media only screen and (max-width : 430px) {
	.footerlink ul {
		margin-top:20px;
	}

	.download {
		margin-bottom: 70px
	}
}