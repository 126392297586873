#profile_menu_list {
    background: #FFFFFF;
}

#profile_menu_list h4 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.87);
    margin: 0;
    margin-bottom: 2px;
}

#profile_menu_list p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: rgba(0, 0, 0, 0.87);
    margin: 0;
}


#profile_menu_list .pm_box {
    border-radius: 6px;
    position: absolute;
    z-index: 99;
    top: 65px;
    right: 0;
}

#profile_menu_list .pm_head,
.pm_list,
.pm_logout {
    padding: 16px;
}


#profile_menu_list .pm_head,
.pm_list {
    border-bottom: 1px solid #EAEAEA;
}

#profile_menu_list .pm_list,
.pm_logout {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
}


#profile_menu_list .pm_menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 16px;
    cursor: pointer;
}


.backdrop {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 64px;
    left: 0;
}