.otb-login {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}

.otb-login button {
  border-radius: 12px;
  padding: 8px 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
}

.otb-login button img {
  margin-right: 25px;
}

.otb-login .login-pijar {
  background: #6C60E0;
  color: #fff;
  margin-bottom: 12px;
}

.otb-login .register {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.87);
  border-radius: 12px;
  color: rgba(0, 0, 0, 0.87);
}

.otb-login .divider {
  width: 100%;
  background-color: #999;
  height: 1px;
  margin: 24px 0px;
}