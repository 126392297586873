.wrapper-or-social {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.divide-or {
  height: 1px;
  background-color: #dedede;
  width: 103px;
}
.text-or {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin: unset !important;
  color: #999999 !important;
}

.section-social-login {
  margin-top: 24px;
}
.btn-socials {
  background-color: #fff !important;
  width: 48%;
  height: 41px !important;
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.87) !important;
  box-sizing: border-box;
  border-radius: 12px;
  justify-content: center;
  margin: 0 !important;
}
.text-socials {
  font-family: Rubik !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  margin: unset !important;
  color: rgba(0, 0, 0, 0.87) !important;
  margin-left: 10px !important;
}
.daftar-in-btn:hover {
  color: #7d61d6 !important;
}
.daftar-page p span a {
  color: #7d61d6;
}
.btn-daftar:hover {
  opacity: 1 !important;
}
.btn-socials:hover {
  opacity: 1 !important;
}
.already-reg {
  display: none;
}
#bar-register #new-header {
  display: none;
}
.kebijakan-section {
  margin-bottom: 24px;
  margin-top: 36px;
}
.kebijakan-section span {
  cursor: pointer;
  text-decoration: underline;
  font-weight: 500;
  color: #7d61d6;
}
.kebijakan-section p {
  font-family: Lato !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.87);
}
.span-commit {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.87);
}
@media (max-width: 768px) {
  .daftar-page {
    border: unset !important;
    margin-top: 0px !important;
    width: 100% !important;
  }
  #signup-page {
    background-color: #fff !important;
  }
  #bar-login #menu-header {
    box-shadow: unset !important;
  }
  #bar-register #menu-header {
    display: none !important;
  }
  #bar-register #navigator-utama {
    position: absolute !important;
  }
  #bar-register #new-header {
    display: flex;
    padding: 16px;
    align-items: center;
  }
  .signup-table {
    padding: 0px !important;
  }
  .text-or {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    margin: unset !important;
    color: #999999;
  }
  .btn-socials {
    width: 48%;
    height: 41px;
  }
  #formlogin button {
    height: 41px !important;
  }
  .text-socials {
    font-family: Rubik !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 17px !important;
    color: rgba(0, 0, 0, 0.87) !important;
    margin: unset !important;
    margin-left: 10px !important;
  }
  .already-reg {
    display: block;
  }
  .text-ready {
    font-family: Rubik !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    margin: unset !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  #formlogin button.btn-masuk {
    border: 1px solid #7d61d6 !important;
    box-sizing: border-box;
    border-radius: 12px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    width: 100%;
    height: 41px;
    margin: unset !important;
    margin-top: 10px !important;
    background-color: #fff !important;
    color: #7d61d6 !important;
  }
  #fammi-logo-header,
  .menuutama .row > div > a > img,
  #mobile-button-content > img {
    display: block;
  }
  #signup-page #headerlogin {
    display: none;
  }
  #arrow-back-header {
    position: absolute;
    top: 16px;
    left: calc(50% - 54px);
  }
  .kebijakan-section p {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.87);
  }
  .row.loginheader > div > a > img {
    display: none !important;
  }
  #formlogin {
    padding: 24px 16px !important;
  }
}
