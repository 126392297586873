@import url(https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*Delip Code*/
.left-menu-materi {
  background: red;
  height: 100%;
}

html,
body,
#root {
  height: 100%;
}

body {
  /*overflow-x: hidden;*/
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}

button:focus {
  outline: none !important;
}

#menu-search .search-container:before {
  left: auto !important;
}

.box-cta-pertanyaan .left-cta {
  width: 35% !important;
}
.box-cta-pertanyaan .right-cta {
  width: 65% !important;
}

.box-cta-pertanyaan .left-cta button,
.box-cta-pertanyaan .right-cta button {
  width: 100%;
}

#login {
  margin-top: 64px !important;
}

.rel-pos {
  position: relative;
}

.well,
.wrap-sub-materi {
  display: table;
  background: rgb(255, 255, 255);
  border-radius: 12px;
  min-height: 423px;
  font-family: Rubik;
  overflow: auto;
  width: 100%;
}

.wrap-sub-materi {
  display: table;
  max-width: 320px;
  margin: 0 auto;
  padding: 5px 15px;
}

.button-new-question {
  width: 48px;
  height: 48px;
  background: #7d61d6;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 50px;
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 4;
}

.button-new-question img {
  width: 20px;
  display: block;
  margin: 0 auto;
  padding-top: 10px;
}

.well .empty-state,
.wrap-sub-materi .empty-state {
  display: table-cell;
  vertical-align: middle;
  margin: 0 auto !important;
  width: 100%;
}

.wrap-sub-materi .empty-state {
  background: white;
  border: solid 1px #eaeaea;
  border-radius: 12px;
  padding: 35px;
}

.wrap-sub-materi .empty-state img {
  max-width: 150px;
  display: block;
  margin: 0 auto;
}

.wrap-sub-materi .empty-state h2 {
  font-family: Rubik;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
}

.wrap-sub-materi .empty-state p {
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  /*line-height: 17px;*/
  letter-spacing: 0em;
  text-align: center;
  color: #999999;
}

.empty-state h1 {
  width: 100%;
  font-weight: 500;
  font-size: 24px;
  /*line-height: 28px;*/
  color: #7d61d6;
  text-align: center;
}

.empty-state p {
  margin-top: 14px;
  font-family: Lato;
  font-size: 18px;
  /*line-height:22px;*/
  text-align: center;
}

.img-support {
  margin-top: 24px;
}

.img-support img {
  width: 100%;
}

.img-support .col-sm-5 {
  padding: 7.5px;
}

.wrap-nav-top {
  position: fixed;
  top: 0px;
  width: 100%;
  text-align: center;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  z-index: 2;
  background: #fff;
  padding: 0px;
}

.wrap-bottom-ques {
  width: 100%;
  height: 66px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  z-index: 2;
  background-color: #fff;
  padding: 0px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.wrap-btn {
  margin-top: 24px;
  text-align: center;
}

.wrap-btn .btn-secondary {
  max-width: 198px;
  width: 100%;
  float: none;
}

.masuk-kelas-header {
  padding: 0;
}

.pad-0 {
  padding: 0;
}

.logo-mb {
  padding: 16px 0;
  text-align: left;
}

.logo-mb i {
  margin-right: 20px;
  margin-left: 40px;
}

.logo-mb img {
  width: 108px;
  height: 32px;
}

.jadwal,
.questions,
.discussions,
.certificate,
.info {
  padding: 20px 0;
  cursor: pointer;
}

.box-contact-data a,
.box-contact-data a:hover,
.box-contact-data a:focus {
  color: black;
}

#wrap-materi {
  /* overflow: hidden; */
  margin-top: 94px;
  padding-top: 0;
}
.wrap-sub-materi {
  background: none;
}

.sub-pertanyaan {
  position: relative;
  width: 100%;
  padding: 0 15px;
  margin: 0px auto;
  float: left;
  width: 100%;
}

.sub-pertanyaan .modal.in {
  display: table !important;
  width: 100%;
  height: 100%;
  max-width: 375px;
  margin: 0 auto;
}

.sub-pertanyaan .modal-dialog {
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}

.sub-pertanyaan .modal-dialog .modal-body img {
  width: 190px;
  display: block;
  margin: 0 auto;
  padding: 20px;
}

.sub-pertanyaan .modal-dialog .modal-body h2 {
  font-family: Rubik;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 0;
}

.sub-pertanyaan .modal-dialog .modal-body p {
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: #999999;
}

.sub-pertanyaan .modal-dialog .modal-content {
  border: solid 1px white;
}

.sub-pertanyaan .modal-dialog .modal-footer {
  margin: 0 auto;
  text-align: center;
  border-top: transparent;
  padding: 24px;
  padding-top: 0;
}

.sub-pertanyaan .modal-dialog .modal-footer button,
.sub-pertanyaan .modal-dialog .modal-footer a {
  float: none;
}

.sub-pertanyaan h1 {
  margin-top: 0;
  font-family: Rubik;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  float: left;
  width: 100%;
}

.box-pertanyaan {
  margin-top: 16px;
  background: white;
  border: solid 1px #eaeaea;
  border-radius: 12px;
  float: left;
  width: 100%;
}

.box-pertanyaan.no-border {
  border: transparent;
}

.box-pertanyaan h3 {
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  padding: 0 24px;
}

.tulis-jawaban {
  margin-top: 0;
}

.tulis-jawaban h3,
.tulis-jawaban textarea {
  padding: 0 24px !important;
}

.tulis-jawaban textarea {
  height: 90px !important;
  color: #999999;
}

.jawaban-text {
  padding-bottom: 24px;
}

.jawaban-text p {
  padding: 0 24px;
}

.box-pertanyaan textarea {
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  border: none;
  width: 100%;
  padding: 0 24px 24px;
  height: 130px;
}

.box-pertanyaan textarea:hover,
.box-pertanyaan textarea:focus {
  border: transparent;
  outline: transparent;
}

.box-cta-tanya {
  margin-top: 24px;
  border: solid 1px #eaeaea;
  border-radius: 12px;
  padding: 15px;
  display: block;
  width: 100%;
  float: left;
}

.left-cta {
  display: inline-block;
  width: 95%;
  vertical-align: top;
  padding-right: 5px;
  /*padding-top: 6px;*/
}

.left-cta-full span:first-child {
  display: inline-block;
  width: 5%;
  vertical-align: top;
  margin-bottom: 20px;
}

.left-cta-full span:last-child {
  display: inline-block;
  width: 90%;
  padding-left: 10px;
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

.right-cta {
  display: inline-block;
  width: 49%;
  /*padding-top: 1px;*/
  padding-left: 5px;
}

.sertifikat-wrap {
  padding: 0;
}

.sertifikat-content img {
  width: 100%;
  margin-top: 24px;
  margin-bottom: 8px;
}

.btn-purple {
  background: #7d61d6;
  width: 100%;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  padding: 12px;
  color: white;
  border-radius: 12px;
}

.btn-purple:hover {
  color: white;
}

.wrap-profil {
  margin: 0 auto;
  display: block;
  max-width: 475px;
  padding: 15px;
  padding-top: 0;
}

.wrap-profil h1 {
  font-family: Rubik;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 0;
}

.wrap-banner {
  background: #fff;
  border: 1px solid #eaeaea;
  border-radius: 12px;
  width: 100%;
  height: auto;
  /*height: 256px;*/
}

.box-top-banner {
  height: 154px;
  background: #7d61d6;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
}

.box-bottom-avatar {
  padding: 20px;
}

.ava-profile {
  max-width: 54px;
  width: 54px;
}

.box-bottom-avatar h5 {
  font-weight: bold;
  text-align: left;
  padding-left: 10px;
  line-height: 40px;
}

.box-contact-data {
  margin-top: 20px;
  background: #fff;
  border: 1px solid #eaeaea;
  border-radius: 12px;
  width: 100%;
}

.notelp > img,
.tanggallahir > img,
.kotasal > img {
  display: block;
  margin: 0 auto !important;
  padding-bottom: 20px;
  padding-top: 20px;
}

.signup-button-nav .col-xs-5 {
  padding-right: 7.5px;
  padding-left: 0;
}

.signup-button-nav .col-xs-7 {
  padding-left: 7.5px;
  padding-right: 0;
}

.signup-button-nav button {
  padding: 10px 15px !important;
}

.arrow-select {
  position: relative;
}

.arrow-select select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  position: relative;
  padding: 0 10px !important;
}
.arrow-select:after {
  content: "";
  width: 20px;
  height: 20px;
  background: url("http://beta.fammi.ly/images-delip/arrow-top.png") no-repeat
    center center;
  background-size: 12px;
  position: absolute;
  top: 15px;
  right: 10px;
}
.arrow-select.justflag:after {
  content: "";
  width: 20px;
  height: 20px;
  background: url("http://beta.fammi.ly/images-delip/arrow-top.png") no-repeat
    center center;
  background-size: 12px;
  position: absolute;
  top: 16px;
  left: 78px;
  z-index: 1;
}

.no-country.hide-codearea input {
  width: 100% !important;
  border-radius: 12px !important;
}

/*http://beta.fammi.ly/images-delip/arrow-top.png*/

.box-top-mitra {
  padding: 24px;
  background: #ffffff;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  margin-bottom: 24px;
}

.box-top-mitra.active {
  border: solid 1px #7d61d6;
  position: relative;
}

.box-top-mitra .img-active {
  position: absolute;
  top: -5px;
  right: 15px;
  visibility: hidden;
}

.box-top-mitra.active .img-active {
  position: absolute;
  top: -5px;
  right: 15px;
  visibility: visible;
}

.syarat h3 {
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #7d61d6;
}

.syarat p.small-info {
  font-size: 14px;
}

.syarat ul {
  font-size: 14px;
  padding-left: 20px;
}
.syarat li {
  margin-bottom: 15px;
}

/*.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}*/

#headerlogin p span {
  font-size: 14px;
}

/* Hide the browser's default checkbox */
.checkit {
  position: relative;
}
.checkit input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.text-checkit {
  padding-left: 35px;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.checkit:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkit input:checked ~ .checkmark {
  background-color: #7d61d6;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkit input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkit .checkmark:after {
  left: 7px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.img-mitra,
.text-mitra {
  display: inline-block;
}

.img-mitra img {
  width: 100%;
  margin: 0 auto !important;
  vertical-align: bottom;
}

.img-mitra {
  width: 30%;
}
.text-mitra {
  width: 70%;
  padding-left: 15px;
}

.text-mitra h2 {
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0 auto;
  margin-bottom: 8px;
}

.text-mitra p {
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0 auto;
}

@media (min-width: 1300px) {
  .well {
    max-width: 765px;
  }
}

/*@media(min-width: 1400px){
	.well {
  	max-width: 900px;
	}
}*/

@media (min-width: 768px) {
  .embed-container {
    border-radius: 12px;
  }
  .well {
    position: fixed;
    width: 58%;
  }
  .max-box-wrap,
  .wrap-sub-materi {
    max-width: 475px;
    margin: 0 auto;
  }
  .wrap-sub-materi.min-h {
    min-height: 633px;
  }
  .filter-tanya {
    font-size: 12px !important;
    padding: 12px 21px !important;
  }
}

#formlogin .step:first-child {
  z-index: 4;
}

#formlogin .step:nth-child(2) {
  z-index: 3;
}

#formlogin .step:nth-child(3) {
  z-index: 2;
}

#formlogin .step:last-child {
  z-index: 1;
}

#signup-stepnav > .active ~ .step {
  background: white;
  color: #999999;
}

#signup-stepnav > .active ~ .step.active {
  background: red;
}

#formlogin {
  position: relative;
  margin: 0 auto;
}

.row-remix {
  margin-left: -7.5px;
  margin-right: -7.5px;
}

@media (max-width: 768px) {
  body {
    margin-top: 55px !important;
  }

  .box-question {
    padding: 0 15px;
  }

  .mar-on-mb {
    margin-left: 0px;
    margin-right: 0px;
  }

  .ql-snow .ql-picker.ql-size {
    width: 100%;
  }

  /*.box-border-question {
		width: auto !important;
	}*/

  /*.box-question-remix .box-border-question {
		width: auto !important;
	}*/

  .max-box-wrap {
    padding: 0 30px;
  }
  .sub-pertanyaan .max-box-wrap {
    padding: 0 15px;
  }

  .box-cta-pertanyaan .left-cta .btn-secondary {
    min-width: inherit !important;
  }

  .left-cta-full {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .size-mobile-fammi {
  }

  .table-sign {
    width: 100%;
  }

  #wrap-materi.container {
    padding-left: 15px;
  }

  .no-wa select.codearea {
    padding-left: 30px !important;
  }

  #formlogin input {
    padding: 15px 10px;
  }

  .daftar-page #form-utama {
    padding: 0;
  }

  #signup-stepnav > .active ~ .step {
    background: #eaeaea;
  }

  .signup-table {
    background: transparent !important;
  }

  #formlogin .step {
    margin: 34px 10px !important;
  }

  .daftar-page {
    padding: 0 !important;
  }
  .loginheader img {
    left: 0 !important;
  }
  .wanlang > .col-xs-12 {
    margin: 0;
    padding: 0 15px;
  }
  .wrap-profil {
    padding: 0;
  }
  .empty-state h1 {
    font-size: 18px;
    margin-top: 0;
  }
  .empty-state p {
    font-size: 12px;
  }
  .sub-pertanyaan .modal-dialog .modal-footer button {
    width: 100%;
  }
  .sub-pertanyaan .modal-dialog {
    vertical-align: bottom;
  }
  .sub-pertanyaan .modal-dialog .modal-content {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .well {
    border-radius: 0 !important;
    min-height: 220px !important;
  }
  #wrap-materi [class*="col-"] {
    padding-left: 0;
    padding-right: 0;
  }
  #wrap-materi li {
    border-radius: 0 !important;
  }
  .jadwal-active,
  .jadwal-active .col-sm-1,
  .questions-active,
  .questions-active .questions .col-sm-2,
  .discussions-active,
  .discussions-active .discussions .col-sm-2,
  .certificate-active,
  .certificate-active .certificate .col-sm-2,
  .info-active,
  .info-active .info .col-sm-1 {
    float: left;
  }
  .masuk-kelas-header {
    min-width: 570px;
  }
  .wrap-nav-top {
    overflow-x: scroll;
  }
  .wrap-nav-top ::-webkit-scrollbar {
    display: none;
  }
  .wrap-nav-top {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .wrap-nav-top .col-sm-2,
  .wrap-nav-top .col-sm-1 {
    padding: 20px 25px !important;
    float: left;
  }

  .wrap-nav-top .col-sm-1.one {
    padding: 20px 0px 14px 33px !important;
  }

  .wrap-nav-top .logo-mb {
    padding: 17px 10px !important;
  }
  .jadwal {
    margin-left: 30px;
  }
  .jadwal-active .jadwal:after,
  .questions-active .questions:after,
  .discussions-active .discussions:after,
  .certificate-active .certificate:after,
  .info-active .info:after {
    bottom: 0px !important;
  }
  #wrap-materi {
    margin-top: 60px !important;
  }
  .sub-pertanyaan,
  .min-h {
    margin-top: 24px !important;
  }
}

.faq.jadwalkelas > li {
  /*padding: 24px;*/
  /*background: red;*/
  border: solid 1px rgb(204, 204, 204);
  cursor: pointer;
  border-bottom: transparent;
}

.faq.jadwalkelas > li:first-child {
  border-radius: 12px 12px 0 0;
}

.faq.jadwalkelas > li:last-child {
  border-radius: 0 0 12px 12px;
  border-bottom: solid 1px rgb(204, 204, 204);
}

.wrap-materi {
  padding: 24px;
}

.box-materi {
  vertical-align: top;
}

.box-materi,
.title-materi {
  display: inline-block;
}

.box-materi {
  border-radius: 5px;
  border: solid 1px #000;
  min-width: 30px;
  min-height: 36px;
}

.box-materi.icon-active {
  margin: 0;
  padding: 6px 5px;
  max-width: 30%;
}

.box-materi.icon-active img {
  width: 18px;
  vertical-align: top;
  padding-top: 2px;
  display: block;
  margin: 0 auto;
}

.box-materi.icon-active img.custom {
  padding-top: 3px;
}

.box-materi.date-active {
  margin: 0;
  padding: 3px 3px;
  max-width: 30%;
  min-width: 30px;
  min-height: 36px;
}

.box-materi.date-active p,
.box-materi.date-active h3 {
  margin: 0;
  padding: 0;
}

.box-materi.date-active p {
  font-size: 8px;
  line-height: 9px;
  text-align: center;
  font-family: "Rubik";
}

.box-materi.date-active h3 {
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  font-family: "Rubik";
  font-weight: normal;
}

.title-materi {
  padding-left: 16px;
  max-width: 70%;
}

.title-materi.text-icon h3 {
  line-height: 35px;
}

.title-materi.text-icon.l-text h3 {
  line-height: normal;
}

.title-materi h3,
.title-materi p {
  margin: 0;
  padding: 0;
  font-size: 12px;
}

.title-materi span {
  background: red;
  padding: 0px 5px;
  text-transform: uppercase;
  color: white;
  font-weight: bold;
  border-radius: 3px;
}

.img-arrow {
  float: right;
  padding-top: 8px;
}

.img-arrow img {
  width: 15px;
}

.title-materi h3 {
  font-family: Rubik;
  font-weight: 500;
  line-height: 19px;
}

.title-materi p {
  line-height: 16px;
}

.materi-open {
  /*margin-top: 15px;*/
  padding: 0 24px 0px 24px;
  margin-top: 0;
  padding-bottom: 1px;
}

.list-materi {
  padding-left: 20px;
}

.list-materi li {
  list-style: decimal;
  font-size: 12px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 15px;
  padding-left: 26px;
}
.list-materi li:active {
  color: blue;
}

a {
  color: #7d61d6;
}

.jadwal,
.questions,
.discussions,
.certificate,
.info {
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  color: grey;
  position: relative;
}

.jadwal-active .jadwal,
.questions-active .questions,
.discussions-active .discussions,
.certificate-active .certificate,
.info-active .info {
  color: #7d61d6;
  border-bottom: transparent !important;
}

.jadwal-active .jadwal:after,
.questions-active .questions:after,
.discussions-active .discussions:after,
.certificate-active .certificate:after,
.info-active .info:after {
  content: "";
  width: 100%;
  height: 5px;
  position: absolute;
  background: #7d61d6;
  bottom: -4px;
  left: 0;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.btn-secondary {
  float: right;
  min-width: 103px;
  border-radius: 12px;
  color: #fff;
}

.btn-secondary.active-btn {
  background: #7d61d6;
  color: white;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
}

.btn-secondary.no-active-btn {
  background: white;
  color: #7d61d6;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  border: solid 1px #7d61d6;
}

.box-question {
  position: relative;
  /*max-width: 425px; */
  /*max-width: 320px;*/
  width: 100%;
  margin: 0px auto;
  border-radius: 12px;
  margin-top: 16px;
  float: left;
}

.box-question hr {
  margin: 0;
}

.box-content-question {
  padding: 24px;
}

.name-question {
  font-family: Rubik;
  font-size: 14px;
}

.box-question .name-question span {
  font-size: 14px;
  font-weight: 600;
  margin-left: 14px;
}

.box-question .question-list {
  margin-top: 15px;
  font-size: 14px;
}

.box-border-question {
  border: solid 1px #eaeaea;
  /*margin:5px 15px;*/
  border-radius: 12px;
  background: white;
  float: left;
  width: 100%;
}

.box-answer {
  width: 100%;
  float: left;
}

.box-answer span {
  font-size: 14px;
}

.box-answer div.belum-dijawab {
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

.box-answer div.belum-dijawab img {
  width: 7px;
  float: right;
  padding-top: 3px;
}

.box-answer a.belum-dijawab {
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  padding: 24px;
  float: left;
  width: 100%;
  color: black;
}

.box-answer a.belum-dijawab img {
  width: 7px;
  float: right;
  padding-top: 3px;
}

.box-link-question {
  padding: 0 !important;
  margin: 0 !important;
  float: left;
  width: 100%;
}

.filter-tanya {
  padding: 12px 7px;
  background: white;
  display: inline-block;
  border-radius: 20px;
  color: #999999;
  font-family: Rubik;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: center;
  border: solid 1px #999999;
  margin-top: 15px;
  margin-left: 10px;
}

.filter-tanya:hover,
.filter-tanya.active {
  background: #7d61d6;
  color: white;
  border: solid 1px #7d61d6;
  cursor: pointer;
}

.filter-tanya:first-child {
  margin-left: 0px;
}

.jawaban-list {
  padding: 50px 0;
}

.jawaban-list h3 {
  font-family: Rubik;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
}
.jawaban-list p {
  font-family: Lato;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: #999999;
}

.custom-box {
  padding: 16px 24px;
}

.wrap-load {
  display: table;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  margin: 0 auto;
  text-align: center;
  z-index: 100;
  background: white;
  left: 0;
}
.content-load {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 100%;
}

.content-load p {
  margin-top: 15px;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: #999999;
}

.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #7d61d6;
  width: 54px;
  height: 54px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  margin: 0 auto;
}

.box-form {
  padding: 20px;
}

.box-form h5 {
  font-weight: bold;
  text-align: left;
}

.arrow-r-icon {
  width: 7px;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.info-wrap .box-border-question {
  margin: 0 auto;
  margin-top: 16px;
}

.info-wrap p {
  margin: 0;
  padding: 0;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}

.info-wrap h2 {
  font-family: Rubik;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.img-center {
  display: block;
  margin: 0 auto;
}

.box-testi-done {
  padding: 40px;
}

.box-testi-done p {
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: #999999;
}

.text-center {
  text-align: center !important;
}

.mar-top-24 {
  margin-top: 24px !important;
}

.mar-btm-24 {
  margin-bottom: 24px !important;
}

.mar-btm-15 {
  margin-bottom: 15px !important;
}

.mar-top-36 {
  margin-top: 36px !important;
}

.mar-top-30 {
  margin-top: 30px !important;
}

.mar-top-48 {
  margin-top: 48px !important;
}

.list-info {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  margin-bottom: 0;
}

.list-info li {
  margin-bottom: 15px;
}

.list-info li:last-child {
  margin-bottom: 0px;
}

.img-info {
  display: inline-block;
  vertical-align: top;
  width: 3%;
}

.text-info-custom {
  display: inline-block;
  padding-left: 15px;
  width: 95%;
  padding-top: 2px;
  font-size: 14px;
}

.testimoni-wrap .box-border-question {
  margin: 0;
}

.testimoni-wrap h2 {
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
}

.testimoni-wrap textarea {
  border: solid 1px #eaeaea;
  padding: 15px;
  width: 100%;
  min-height: 100px;
  margin-top: 15px;
  border-radius: 12px;
}

.state-testi {
  font-family: Lato;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: center;
  color: #999999;
}

.star-wrap {
  margin: 0 auto;
  display: block;
  text-align: center;
}

.star-wrap img {
  padding-right: 30px;
}

.star-wrap img:last-child {
  padding-right: 0;
}

.daftar-page h4 {
  font-family: Rubik;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 24px;
}

.daftar-page label {
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 8px;
}

.daftar-page input {
  text-align: center;
}

.daftar-page p {
  word-break: break-word;
  font-family: Rubik !important;
}

.counting {
  margin-top: 24px;
  font-size: 14px !important;
}

.caption-label {
  color: #999999;
  margin: 16px 0 24px 0;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}

.wrap-login button {
  background: #7d61d6;
  color: #fff;
  border-radius: "12px";
  margin-left: 0px;
  margin-top: "24px";
}

.wrap-login > img {
  display: block;
  margin: 0 auto !important;
  padding-bottom: 20px;
  padding-top: 13px;
}

.wrap-login h5 {
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: black;
}

.otp-style {
  font-family: Lato;
  font-size: 14px;
  font-style: normal !important;
  font-weight: normal;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: #999999;
}

#formlogin {
  margin-top: -30px;
}

#signup-page {
  height: 100% !important;
  /*position:absolute; */
  width: 100%;
  top: 0px;
}

.signup-table {
  display: table;
  width: 100%;
  height: 100%;
}

.signup-middle {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 100%;
}

#formlogin .step {
  margin: 34px 24px;
}

#signup-stepnav > .step:after {
  height: 4px !important;
  width: 50px;
}

/*#formlogin .step.active {
	z-index: 2;
}*/

.info-layanan p {
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #585858;
  margin: 15px 0 !important;
}

.info-layanan span a {
  font-weight: 700 !important;
  font-family: Lato;
  color: #585858;
}

.form-groups {
  margin-bottom: 15px;
}

.form-groups:last-child {
  margin-bottom: 0;
}

.form-groups span {
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 8px;
  display: block;
}

.form-groups input {
  margin-bottom: 0 !important;
  text-align: left;
}

.eye-password {
  position: absolute;
  top: 20px;
  right: 10px;
}

.daftar-page {
  border-radius: 12px;
  margin: 45px 0;
}

#legal h1 {
  font-family: Rubik;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
  color: #7d61d6;
  text-transform: capitalize;
}

#legal h2 {
  font-family: Rubik;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}

#legal h3 {
  font-family: Rubik;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}
#legal p {
  margin-bottom: 20px;
}

.list-legal {
  /*list-style: lower-latin;*/
}

.box-terms {
  max-height: 290px;
  overflow: scroll;
  margin-top: 8px;
}

.bg-modal-fammi {
  background: rgb(0 0 0 / 50%);
}

.bg-modal-fammi .modal-dialog {
  max-width: 345px;
  min-width: 345px;
  width: 100% !important;
}

.table-sign {
  display: table;
  height: 100%;
  margin: 0 auto;
}

.justflag {
  display: none;
}

.justflag input {
  border-radius: 12px !important;
  width: 100% !important;
}

.search-empty-table {
  display: table;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  margin-bottom: 24px;
}

.search-empty-center {
  display: table-cell;
  vertical-align: middle;
  margin: 0 auto;
}

.search-empty-content {
  max-width: 100%;
  background: white;
  border: solid 1px #eaeaea;
  margin: 0 auto;
  padding: 20px;
  border-radius: 12px;
  text-align: center;
  padding: 149px 15px;
}

.search-empty-content img {
  max-width: 200px;
  display: block;
  margin: 0 auto;
}

.search-empty-content a {
  max-width: 200px;
  margin: 0 auto;
  text-align: center;
  margin-top: 24px;
}

.search-empty-content h2 {
  text-align: center;
  font-family: Rubik;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
}

.search-empty-content p {
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: #999999;
}

@media (max-width: 992px) {
  .faq.jadwalkelas {
    margin-bottom: 66px;
  }
}

@media (max-width: 768px) {
  .signup-table {
    height: 100%;
    max-height: 0px !important;
  }
  #signup-page {
    background-color: #fff !important;
  }
  .text-or {
    white-space: nowrap !important;
  }
  .daftar-page h4 {
    font-size: 16px !important;
    font-weight: 500 !important;
  }
}

.search-container input {
  margin-bottom: 0 !important;
}

.goto-register {
  font-weight: 500 !important;
}

.multiitem-container {
  /*padding-top: 0;*/
  padding: 0 15px;
}

.search-container:before {
  left: 20px !important;
}

/*.scroll {
  white-space: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.scroll {
  background: red;
}

.logo {
  text-align: center;
  font-weight: 700;
  color: white;
  border-right: 1px solid rgba(#727c87, 0.4);
  padding: 12px 24px 13px;
}

.nav-item {
  padding: 12px 16px 13px;
}

.logo,
.nav-item,
.vertical-align-middle {
  display: inline-block;
  vertical-align: middle;
}

.title {
  margin: 24px 0 6px;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  color: #999;
}

.scroll::-webkit-scrollbar {
  display: none;
}

<div class="wrap-load hide">
          <div class="content-load">
            <div class="loader">&nbsp;</div>
            <p>Harap Tunggu</p>
          </div>
        </div>



*/

.parent-header-e {
  display: flex;
  justify-content: center;
  width: 100%;
}

.parent-header-e .menu-mob {
  display: block;
}

.materials-mobile {
  display: block;
}

.materials-desktop {
  display: none;
}

.parent-header-e .menu-mobiles {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  z-index: 10000;
  background: #fdfbff;
  height: 100vh;
  width: 100%;
}

.parent-header-e .menu-mobiles .headers {
  padding: 19px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #eaeaea;
}

.parent-header-e .menu-mobiles .logouts {
  padding: 19px 16px;
  display: flex;
  align-items: center;
  width: 100%;
  border-top: 1px solid #eaeaea;
  cursor: pointer;
  margin-left: 10px;
}

.parent-header-e .menu-mobiles .mained {
  display: block;
  padding: 24px 16px;
}

.parent-header-e .menu-mobiles .mained .menu-ava {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.parent-header-e .menu-mobiles .mained .menu-ava p {
  margin: 0px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}

.parent-header-e .menu-mobiles .mained .menu-ava div {
  background: #6c60e0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  width: 42px;
  border-radius: 12px;
  margin-right: 12px;
}

.parent-header-e .menu-mobiles .mained .menu-ava h5 {
  margin: 0px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.87);
}

.parent-header-e .menu-mobiles .mained .menu-status {
  display: flex;
  align-items: center;
}

.parent-header-e .menu-mobiles .mained .menu-status div {
  margin-left: 12px;
}

.parent-header-e .menu-mobiles .mained .menu-status p {
  margin: 0px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.87);
}

.parent-header-e .menu-mobiles .mained .menu-status span {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin: 0px !important;
  margin-top: 8px !important;
  color: rgba(0, 0, 0, 0.87);
}

.parent-header-e .menu-mobiles .logouts p {
  margin: 0px !important;
  margin-left: 20px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.87);
}

.parent-header-e .menu-mobiles .headers p {
  margin: 0px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #262626;
}

.event-header div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
  padding-left: 16px;
}

.event-header {
  width: 100%;
  max-width: 448px;
  padding: 12px 0;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0px;
  z-index: 1000;
  background-color: #fff;
}

.parent-event {
  display: flex;
  justify-content: center;
  width: 100%;
  background: #f2f2f2;
  margin-top: -90px;
  padding-top: 54px;
  height: auto;
  min-height: 100%;
  overflow: visible;
  overflow: initial;
}

.event-container {
  max-width: 448px;
  width: 100%;
  margin-top: -24px;
  padding-top: 0px;
  background: #fcfcfc;
}

.event-logo {
  width: 123px;
  height: 41px;
}

.event-cover {
  width: 100%;
  min-height: 100%;
  height: auto;
  max-height: 244px;
  overflow: hidden;
  border-radius: 10px;
}

.zoom-schedule.soon {
  background: #999;
  color: #fff;
  border: none;
}

.zoom-schedule.opened {
  background: #7d61d6;
  color: #fff;
  border: none;
}

.zoom-schedule.ended {
  background: #fff;
  color: #999999;
  border: solid 1px #999;
}

.zoom-schedule.unavailable {
  background: #999;
  color: #fff;
  border: none;
}

.zoom-schedule.ended.unavailable {
  background: #fff;
  color: #999;
  border: solid 1px #999;
}

.recorded-materials,
.certificate {
  background: #7d61d6;
  color: #fff;
  border: none;
}

.recorded-materials.unavailable,
.certificate.unavailable {
  background: #999;
  color: #fff;
  border: solid 1px #999;
}

.parent-notif-success.aud {
  position: fixed;
  bottom: 24px;
  width: 100%;
  display: flex;
  justify-content: center;
  left: 0px;
  z-index: 1;
}

.box-notif-success {
  display: flex;
  background: #fd8c25;
  border-radius: 12px;
  align-items: center;
  width: 327px;
  height: 86px;
  padding: 26px;
}

.section-text-notif {
  margin-left: 26px;
}

.text-title-notif {
  margin: unset !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}

.text-description-notif {
  margin: unset !important;
  margin-top: 4px !important;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}

.event-container .menu {
  margin: 32px 0px;
  display: flex;
  width: 100%;
  padding: 0px 16px;
}

.event-container .menu div {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  cursor: pointer;
}

.event-container .menu div p {
  margin: 0px !important;
  margin-top: 6px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #262626;
}

.event-container .last-event {
  margin-bottom: 32px;
  padding: 0px 16px;
}

.event-container .last-event>h1 {
  margin: 0px !important;
  margin-bottom: 10px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.87);
}

.h1-prog {
  margin: 0px !important;
  margin-bottom: 16px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.87);
}

.event-container .last-event .head-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
}

.event-container .last-event .head-section div {
  background: #f9a33f;
  border-radius: 10px;
  padding: 4px 13px;
}

.event-container .last-event .head-section div p {
  margin: 0px !important;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
}

.event-container .last-event .head-section h1 {
  margin: 0px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.87);
}

.card-events {
  width: 100%;
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 12px;
}

.card-events .quiz-carded {
  display: flex;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #eaeaea;
}

.card-events .quiz-carded .imag {
  width: 88px;
  height: 88px;
  border-radius: 12px;
}

.card-events .quiz-carded div {
  margin-left: 16px;
}

.card-events .quiz-carded h5 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.87);
  margin: 0px !important;
}

.card-events .quiz-carded p {
  margin: 0px !important;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #6c60e0;
}

.card-events>img {
  width: 100%;
  height: 179px;
  border-radius: 12px 12px 0px 0px;
}

.card-events .detailes {
  padding: 8px;
  background: #f3f3f3;
}

.card-events .detailes p {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  margin: 0px !important;
  color: #999999;
  text-align: center;
}

.card-events .schedule {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 12px;
  border-bottom: 1px solid #eaeaea;
}

.card-events .schedule div {
  display: flex;
  align-items: center;
  width: 142px;
}

.card-events .schedule div p {
  margin: 0px !important;
  margin-left: 8px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.87);
}

.card-events .status {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 12px;
}

.card-events .status div {
  display: flex;
  align-items: center;
  width: 142px;
}

.card-events .status div p {
  margin: 0px !important;
  margin-left: 8px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.87);
}

.card-events .status .cta {
  width: 142px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
}

.card-events .status .cta.done {
  background: #ffffff;
  border: 1px solid #999999;
  color: #999999;
}

.card-events .status .cta.off {
  background: #999;
  border: unset;
  color: #fff;
}

.card-events .status .cta.off.offline {
  background: #6c60e0;
  border: unset;
  color: #fff;
}

.card-events .status .cta.on {
  background: #6c60e0;
  border: unset;
  color: #fff;
}
#detail-schedule .look-all {
  width: 100%;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  background: #f4f1ff;
  border: 1px solid rgba(125, 97, 214, 0.2);
  box-sizing: border-box;
  border-radius: 8px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #6c60e0;
}

.event-container .last-event .look-all {
  border: none;
  width: auto;
  background: #f5f5f5;
  border-radius: 4px;
  padding: 4px 12px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.87);
}

.event-container .want-ask {
  padding: 32px 16px;
  background: #f5f0ff;
}

.event-container .want-ask div {
  display: flex;
}

.event-container .want-ask div h4 {
  margin: 0px !important;
  margin-left: 10px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.87);
}

.event-container .want-ask button,
.button-submit-question {
  margin-top: 16px;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #6c60e0;
  border-radius: 12px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  border: unset;
}

.event-container .qna {
  padding: 32px 16px;
}

.event-container .qna>h1 {
  margin: 0px !important;
  margin-bottom: 10px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.87);
}

.event-container .qna .filter-qna {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.event-container .qna .filter-qna button {
  height: 30px;
  border-radius: 8px;
  background: #ffffff;
  border: 1px solid #eaeaea;
  padding: 8px 12px;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.87);
  margin-right: 8px;
  white-space: nowrap;
}

.event-container .qna .filter-qna button.active {
  background: #e0d7ff;
  border: unset;
  color: #6c60e0;
  font-weight: 500;
}

.last-event .filter-qna {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.last-event .filter-qna button {
  height: 30px;
  border-radius: 8px;
  background: #ffffff;
  border: 1px solid #eaeaea;
  padding: 8px 12px;
  font-family: Rubik;
  white-space: nowrap;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.87);
  margin-right: 8px;
}

.last-event .filter-qna button.active {
  background: #e0d7ff;
  border: unset;
  color: #6c60e0;
  font-weight: 500;
}

.event-container .card-qna {
  margin-bottom: 10px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 12px;
}

.event-container .card-qna .up {
  padding: 16px 12px;
  border-bottom: 1px solid #eaeaea;
}

.event-container .card-qna .up h1 {
  margin: unset !important;
  margin-bottom: 0px !important;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.87);
}

.event-container .card-qna .up h5 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #999999;
  margin: unset !important;
  margin-top: 12px !important;
}

.event-container .card-qna .down {
  padding: 16px 12px;
}

.event-container .card-qna .down .speakers {
  display: flex;
  align-items: center;
}

.event-container .card-qna .down .speakers img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-right: 12px;
}

.event-container .card-qna .down p {
  margin: 0px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 4px !important;
  color: rgba(0, 0, 0, 0.87);
}

.event-container .card-qna .down h4 {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin: unset !important;
  margin-bottom: 4px !important;
  color: rgba(0, 0, 0, 0.87);
}

.event-container .card-qna .down h5 {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin: unset !important;
  color: #999999;
}

.event-container .card-qna .down .cta-sections {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 16px;
}

.event-container .card-qna .down .cta-sections .actions {
  display: flex;
  align-items: center;
  margin-right: 12px;
  cursor: pointer;
}

.event-container .card-qna .down .cta-sections .actions img {
  margin: 0px;
}

.event-container .card-qna .down .cta-sections .actions p {
  margin: 0px !important;
  margin-left: 6px !important;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

.event-container .card-qna .down .cta-sections button {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: unset;
  width: 26px;
  padding: 4px;
  height: 26px;
}

.event-container .card-qna .down div button img {
  height: 16px;
  margin-right: 4px;
}

.event-container .card-qna .down div button p {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin: unset !important;
}

.event-container .card-qna .down div button.clock {
  background: #f5f0ff;
}

.event-container .card-qna .down div button.clock p {
  color: #7d61d6;
}

.event-container .card-qna .down div button.play {
  background: #7d61d6;
}

.event-container .card-qna .down div button.play img {
  margin-right: 2px;
}

.event-container .card-qna .down div button.play p {
  color: #fff;
}

.event-container .narsum {
  background: #fff3c6;
  padding: 32px 16px;
}

.event-container .narsum .top {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}

.event-container .narsum .top h1 {
  margin: unset !important;
  margin-left: 10px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.87);
}

.event-container .narsum .bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.event-container .narsum .bottom div {
  display: flex;
  align-items: center;
  width: 48.5%;
  height: 40px;
  background: #fffae7;
  border-radius: 12px;
  padding: 8px;
  margin-top: 10px;
}

.event-container .narsum .bottom div p {
  margin: unset !important;
  margin-left: 4px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #262626;
}

.event-container .feature {
  padding: 32px 16px;
}

.event-container .feature>h1 {
  margin: 0px !important;
  margin-bottom: 10px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.87);
}

.event-container .feature>h1~div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.event-container .feature>h1~div div {
  padding: 14px 12px;
  background: #e0d7ff;
  border-radius: 12px;
  margin-bottom: 10px;
  width: 31%;
  height: 86px;
}

.event-container .feature>h1~div div p {
  margin: unset !important;
  margin-top: 8px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #262626;
}

#material-webs {
  padding: 0px 16px;
  margin-bottom: 32px;
}

#material-webs h1 {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin: 0px !important;
  color: rgba(0, 0, 0, 0.87);
}

#material-webs .contents {
  display: flex;
  align-items: center;
  width: 100%;
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 12px;
  cursor: pointer;
  margin-top: 10px;
  justify-content: space-between;
}

#material-webs .parents-mate {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

#material-webs .contents {
  width: 48.5%;
  cursor: pointer;
}

#material-webs .contents .dv {
  display: flex;
  align-items: center;
}

#material-webs .contents .dv div {
  margin-left: 16px;
}

#material-webs .contents .dv div h4 {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin: 0px !important;
  color: rgba(0, 0, 0, 0.87);
}

#material-webs .contents .dv div p {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin: 0px !important;
  margin-top: 5px !important;
  color: #999999;
}

.lists-mobile {
  display: none;
}

.ask-question-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  top: 0px;
  position: fixed;
  z-index: 1000;
}

.ask-question-container .pop_boxes {
  width: 360px;
  display: flex;
  max-height: 620px;
  border-radius: 12px;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.05);
}

.pop_tanya .pop_boxes .fq_parent {
  margin-bottom: 0px;
}

.pop_tanya .pop_boxes .fq_select {
  margin-top: 10px;
}

.pop_tanya .pop_boxes .tx_fq_title {
  margin-top: 0px;
}

.ask-question-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
}

.ask-question-header h1 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #262626;
  margin: 0px;
  margin-left: 12px;
  width: 80%;
}

.ask-question-footer {
  padding: 16px;
  background: white;
  box-shadow: 0px -5px 30px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 12px 12px;
  border-top: 1px solid #eaeaea;
}

.ask-question-content {
  padding: 0px;
  overflow: auto;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.ask-question-content::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.ask-question-container .pop_boxes .fq_parent {
  margin-bottom: 0px;
}

.ask-question-container .pop_boxes .fq_select {
  margin-top: 10px;
}

.ask-question-container .pop_boxes .tx_fq_title {
  margin-top: 0px;
}

.q_submitted {
  height: 480px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.q_submitted div {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
  margin-top: 12px;
}

.cta-asking {
  display: none;
}

@media (max-width: 768px) {
  .event-logo {
    width: 96px;
    height: 32px;
  }
  .list-speakers > h1 {
    margin: 0px !important;
    margin-bottom: 10px !important;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.87);
  }
  .cta-asking {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 16px;
    background: #f5f5f5;
    border-radius: 8px;
    border: none;
    width: 100%;
    height: 30px;
  }

  .cta-asking p {
    margin: 0px !important;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
  }

  .parent-event {
    padding-top: 96px;
  }

  .event-cover {
    width: 100%;
    min-height: 100%;
    height: 167px;
    border: solid;
  }

  .lists-mobile {
    display: block;
  }

  .ask-question-container .pop_boxes {
    width: 100%;
    position: absolute;
    margin-bottom: 10px;
    height: 100%;
    max-height: 100%;
    border-radius: 0px;
    margin: 0px;
    justify-content: space-between;
  }

  .ask-question-footer {
    border-radius: 12px 12px 0px 0px;
  }
}

.event-container .section-ones {
  display: flex;
  margin-top: 36px;
  max-width: 480px;
  width: 100%;
  justify-content: center;
  border-radius: 6px;
  flex-wrap: wrap;
  padding: 0px 16px;
}

.parent-event .event-container .one {
  display: none;
}

.parent-event .event-container .two .two-left {
  display: none;
}

.parent-event .event-container .two .two-right .text-wantask {
  display: none;
}

.parent-event .event-container .two .tabs {
  display: none;
}

.parent-event .event-container .two .two-right .desktops {
  display: none;
}

.parent-event .event-container .two .two-right .mobiles {
  display: block;
}
/* .parent-event .event-container .last-event ~ .one ~ .two .app-bars {
  display: none;
} */
.parent-event .event-container .last-event ~ .one ~ .two .app-bars {
  position: fixed;
  top: 0px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.parent-event
  .event-container
  .last-event
  ~ .one
  ~ .two
  .app-bars
  .content-appbars {
  max-width: 1170px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.parent-event
  .event-container
  .last-event
  ~ .one
  ~ .two
  .app-bars
  .content-appbars
  .sectiones {
  display: flex;
  justify-content: center;
  width: 37%;
  margin-left: 100px;
}
.parent-event .event-container .last-event ~ .one ~ .two .app-bars .the-logos {
  width: 96px;
  display: block;
}
.parent-event
  .event-container
  .last-event
  ~ .one
  ~ .two
  .app-bars
  .parent-tabs {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.parent-event
  .event-container
  .last-event
  ~ .one
  ~ .two
  .app-bars
  .parent-tabs
  .item-tabs {
  padding: 10px;
  padding-bottom: 0px;
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  height: 64px;
}
.parent-event
  .event-container
  .last-event
  ~ .one
  ~ .two
  .app-bars
  .parent-tabs
  .item-tabs
  .head-tabs {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.parent-event
  .event-container
  .last-event
  ~ .one
  ~ .two
  .app-bars
  .parent-tabs
  .item-tabs
  p {
  margin: 0px !important;
  margin-top: 7px !important;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
}
.parent-event
  .event-container
  .last-event
  ~ .one
  ~ .two
  .app-bars
  .parent-tabs
  .item-tabs
  .tab-indicator {
  background: #fff;
  border-radius: 2px 2px 0px 0px;
  height: 2px;
  width: 100%;
}
.parent-event
  .event-container
  .last-event
  ~ .one
  ~ .two
  .app-bars
  .parent-tabs
  .item-tabs
  .active-tab-indicator {
  background: #6c60e0;
  border-radius: 2px 2px 0px 0px;
  height: 2px;
  width: 100%;
}
@media (min-width: 1000px) {

  .event-container>.section-ones,
  .event-container .menu,
  .event-container .menu~.last-event {
    display: none;
  }

  .event-container .last-event {
    padding: 0px !important;
  }

  .parent-header-e {
    display: none;
  }

  .parent-header-e .forced {
    display: flex;
  }

  .parent-event {
    background-color: #fcfcfc;
    /* height: 100vh;
    overflow-y: hidden; */
    height: auto;
    min-height: 100%;
    overflow: visible;
    overflow: initial;
  }

  .parent-event .event-container {
    max-width: 1170px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 28px;
  }

  .parent-event .event-container .one {
    display: block;
    width: 31%;
    background: #ffffff;
    /* border: 1px solid #eaeaea; */
    box-sizing: border-box;
    border-radius: 12px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  .parent-event .event-container .one .headss {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #eaeaea;
  }

  .parent-event .event-container .one .headss img {
    height: 41px;
  }

  .parent-event .event-container .one .contents {
    padding: 16px;
    padding-top: 18px;
    border-bottom: 1px solid #eaeaea;
  }

  .parent-event .event-container .one .contents .the-content {
    display: flex;
    align-items: center;
  }

  .parent-event .event-container .one .contents .the-content img {
    background: #ffffff;
    border: 1px solid #eaeaea;
    border-radius: 50%;
    width: 44px;
    height: 44px;
  }

  .parent-event .event-container .one .logins {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0px 16px;
    margin-bottom: 16px;
  }

  .parent-event .event-container .one .logins .msk {
    width: 47.5%;
    border: 1px solid #6c60e0;
    box-sizing: border-box;
    border-radius: 12px;
    background: #fff;

    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    height: 41px;
    text-align: center;
    color: #6c60e0;
  }

  .parent-event .event-container .one .logins .dft {
    width: 47.5%;
    border: unset;
    box-sizing: border-box;
    border-radius: 12px;
    background: #6c60e0;

    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    height: 41px;
    text-align: center;
    color: #fff;
  }

  .parent-event .event-container .one .cover-img {
    width: 100%;
    height: 80px;
    object-fit: cover;
    border: 1px solid #eaeaea;
    border-radius: 12px 12px 0px 0px;
    border-bottom: none;
  }

  .parent-event .event-container .one .contents h4 {
    margin: 0px !important;
    margin-left: 16px !important;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.87);
  }

  .parent-event .event-container .one .contents p {
    margin: 0px !important;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;

    color: rgba(0, 0, 0, 0.87);
  }

  .parent-event .event-container .last-event~.one~.two {
    width: 70%;
    padding-left: 32px;
  }

  .parent-event .event-container .last-event ~ .one ~ .two .app-bars .asking {
    display: flex;
    align-items: center;
    padding: 5px 12px;
    background: #f5f5f5;
    border-radius: 8px;
    border: none;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  .parent-event .event-container .last-event~.one~.two .app-bars .asking p {
    margin: 0px !important;
    margin-left: 8px !important;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: rgba(0, 0, 0, 0.87);
  }

  .parent-event .event-container .last-event~.one~.two .tabs {
    display: flex;
    padding-top: 20px;
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;
    background: #ffffff;
    border: 1px solid #eaeaea;
    box-sizing: border-box;
    border-radius: 12px;
    justify-content: space-between;
  }

  .parent-event .event-container .last-event~.one~.two .tabs .div>div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 14px;
    width: 100%;
  }

  .parent-event .event-container .last-event~.one~.two .tabs .div {
    cursor: pointer;
    width: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .parent-event .event-container .last-event~.one~.two .tabs .div .span {
    background: #fff;
    border-radius: 8px 8px 0px 0px;
    height: 6px;
    width: 100%;
    padding: 0px !important;
  }

  .parent-event .event-container .last-event~.one~.two .tabs .div.active .span {
    background: #6c60e0;
  }

  .parent-event .event-container .last-event~.one~.two .tabs .div.sche .span {
    background: #f159c4;
  }

  .parent-event .event-container .last-event~.one~.two .tabs div.active p {
    color: #6c60e0;
    font-weight: bold;
  }

  .parent-event .event-container .last-event~.one~.two .tabs div.sche p {
    color: #f159c4;
    font-weight: bold;
  }

  .parent-event .event-container .last-event~.one~.two .tabs .div.recr .span {
    background: #f9a33f;
  }

  .parent-event .event-container .last-event~.one~.two .tabs div.recr p {
    color: #f9a33f;
    font-weight: bold;
  }

  .parent-event .event-container .last-event~.one~.two .tabs .div.asks .span {
    background: #3fa985;
  }

  .parent-event .event-container .last-event~.one~.two .tabs div.asks p {
    color: #3fa985;
    font-weight: bold;
  }

  .parent-event .event-container .last-event~.one~.two .tabs .div.downs .span {
    background: #369cf7;
  }

  .parent-event .event-container .last-event~.one~.two .tabs div.downs p {
    color: #369cf7;
    font-weight: bold;
  }

  .parent-event .event-container .last-event~.one~.two .tabs .div p {
    margin: 0px !important;
    margin-left: 12px !important;

    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;

    color: #262626;
  }

  .parent-event .event-container .last-event~.one~.two .sectioner {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .parent-event .event-container .last-event~.one~.two .two-left {
    display: block;
    width: 54%;
    height: 100vh;
    padding-bottom: 114px;
    overflow-y: auto;
    scrollbar-width: none;
    box-sizing: border-box;
  }

  .parent-event .event-container .last-event~.one~.two .two-left::-webkit-scrollbar {
    display: none;
  }

  .parent-event .event-container .last-event~.one~.two .two-left::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  .parent-event .event-container .last-event~.one~.two .two-left::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
  }

  .parent-event .event-container .last-event~.one~.two .two-right {
    width: 42%;
    max-height: 80vh;
    height: 100%;
    overflow: auto;
    scrollbar-width: none;
  }

  .parent-event .event-container .last-event~.one~.two .two-right::-webkit-scrollbar {
    display: none;
  }

  .parent-event .event-container .last-event~.one~.two .two-right .text-wantask {
    margin: 0px !important;
    margin-bottom: 16px !important;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: block;
    color: rgba(0, 0, 0, 0.87);
  }

  .event-container .want-ask {
    padding: 18px 16px;
    margin-bottom: 24px;
    border-radius: 12px;
  }

  .event-container .want-ask div {
    display: block;
  }

  .event-container .want-ask div h4 {
    margin-left: 0px !important;
    margin-top: 16px !important;
  }

  .event-container .narsum {
    padding: 18px 16px;
    padding-top: 30px;
    background: #ffffff;
    border: 1px solid #eaeaea;
    box-sizing: border-box;
    border-radius: 12px;
  }

  .event-container .narsum .top {
    display: block;
  }

  .event-container .narsum .top h1 {
    margin-left: 0px !important;
    margin-top: 16px !important;
    margin-bottom: 6px !important;
  }

  .event-container .narsum .bottom img {
    display: none;
  }

  .event-container .feature {
    padding: 0px;
    margin-top: 24px;
  }

  .event-container .feature>h1~div div {
    background: #ffffff;
    border: 1px solid #eaeaea;
    box-sizing: border-box;
    border-radius: 12px;
  }

  .event-container .two-left .last-event {
    padding: 0px;
  }

  .event-container .two-left .card-events {
    margin-bottom: 16px;
  }

  .event-container .two-left .placeHolders {
    background: #ffffff;
    border: 1px solid #eaeaea;
    box-sizing: border-box;
    border-radius: 12px;
    width: 100%;
    height: 330px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }

  .event-container .two-left .placeHolders img {
    height: 84px;
    margin-bottom: 16px;
  }

  .event-container .two-left .placeHolders h4 {
    margin: 0px !important;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
  }

  .event-container .two-left .placeHolders p {
    margin: 6px 0px !important;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    max-width: 300px;
    color: rgba(0, 0, 0, 0.87);
  }

  .event-container .two-right .choose-time {
    background: #ffffff;
    border: 1px solid #eaeaea;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 16px;
    padding-bottom: 6px;
    width: 100%;
  }

  .event-container .two-right .choose-time div {
    display: flex;
    align-items: center;
    padding: 8px 10px;
    background: #ffffff;
    border: 1px solid #eaeaea;
    box-sizing: border-box;
    border-radius: 8px;
    margin-bottom: 10px;
    cursor: pointer;
  }

  .event-container .two-right .choose-time div p {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    margin: 0px !important;
    margin-left: 8px !important;
    color: rgba(0, 0, 0, 0.87);
  }

  .event-container .two-right .choose-time div.active {
    background: #e0d7ff;
    border: unset;
  }

  .event-container .two-right .choose-time div.active p {
    color: #6c60e0;
  }

  .parent-event .event-container .two .two-right .desktops {
    display: block;
  }

  .parent-event .event-container .two .two-right .mobiles {
    display: none;
  }

  .parent-event .event-container .two .two-left .card-certificate {
    background: #ffffff;
    border: 1px solid #eaeaea;
    box-sizing: border-box;
    border-radius: 12px;
    margin-bottom: 16px;
  }

  .parent-event .event-container .two .two-left .card-certificate .one {
    padding: 12px;
    border-bottom: 1px solid #eaeaea;
    width: 100% !important;
    border-radius: 12px 12px 0px 0px !important;
  }

  .parent-event .event-container .two .two-left .card-certificate .one h5 {
    margin-bottom: 8px;
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: #6c60e0;
  }

  .parent-event .event-container .two .two-left .card-certificate .one h1 {
    margin: 0px !important;
    margin-bottom: 12px !important;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.87);
  }

  .parent-event .event-container .two .two-left .card-certificate .one h1~div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .event-container {
    background: unset !important;
  }

  .parent-event .event-container .two .two-left .card-certificate .one h1~div div {
    display: flex;
    align-items: center;
    width: 48.5%;
  }

  .parent-event .event-container .two .two-left .card-certificate .one h1~div div p {
    margin: 0px !important;
    margin-left: 8px !important;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.87);
  }

  .parent-event .event-container .two .two-left .card-certificate .two {
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .parent-event .event-container .two .two-left .card-certificate .two div {
    display: flex;
    align-items: center;
    width: 48.5%;
  }

  .parent-event .event-container .two .two-left .card-certificate .two div p {
    margin: 0px !important;
    margin-left: 8px !important;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.87);
  }

  .parent-event .event-container .two .two-left .card-certificate .two button {
    width: 48.5%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    background: #6c60e0;
    border-radius: 8px;
    border: unset;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
  }

  .parent-event .event-container .parent-verif section {
    padding: 16px;
    background: #d7efff;
    border-radius: 12px 12px 0px 0px;
  }

  .parent-event .event-container .parent-verif section h1 {
    margin: 0px !important;
    margin-top: 16px !important;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.87);
  }

  .parent-event .event-container .parent-verif {
    margin-bottom: 24px;
  }

  .parent-event .event-container .parent-verif section~div {
    background: #ffffff;
    border: 1px solid #eaeaea;
    box-sizing: border-box;
    border-radius: 0px 0px 12px 12px;
  }

  .parent-event .event-container .parent-verif section~div .one {
    padding: 16px 12px;
    border-bottom: 1px solid #eaeaea;
    width: 100% !important;
    border-radius: 0px !important;
  }

  .parent-verif section~div .one div {
    display: flex;
    align-items: center;
  }

  .parent-verif section~div .one div p {
    margin: 0px !important;
    margin-left: 8px !important;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.87);
  }

  .parent-verif section~div .one h5 {
    margin: 0px !important;
    margin-left: 38px !important;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.87);
  }

  .parent-verif section~div .two {
    padding: 16px 12px;
  }

  .parent-verif section~div .two .head div {
    display: flex;
    align-items: center;
  }

  .parent-verif section~div .two .head div h5 {
    margin-left: 8px;
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: #f9a33f;
  }

  .parent-verif section~div .two .head p {
    margin: 0px !important;
    margin-left: 38px !important;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.87);
  }

  .parent-verif section~div .two .cta {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 16px;
  }

  .parent-verif section~div .two .cta .change {
    width: 48.5%;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    border-radius: 8px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.87);
  }

  .parent-verif section~div .two .cta .done {
    width: 48.5%;
    background: #ffffff;
    border: 1px solid #6c60e0;
    box-sizing: border-box;
    border-radius: 8px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #6c60e0;
  }

  .auths-box .warning {
    margin: 24px 0px;
  }

  .auths-box .warning div {
    display: flex;
    align-items: center;
  }

  .auths-box .warning h5 {
    margin-left: 8px;
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: #f9a33f;
  }

  .auths-box .warning p {
    margin: 0px !important;
    margin-left: 38px !important;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.87);
  }

  .parent-header-e .menu-mob {
    display: none;
  }

  .event-header div {
    justify-content: center;
    width: 100%;
  }

  .parent-header-e .menu-mobiles {
    display: none;
  }

  .parent-event .event-container .mained {
    display: block;
    padding: 16px;
    padding-top: 24px;
    border-top: 1px solid #eaeaea;
  }

  .parent-event .event-container .microsite-paids .prime {
    padding: 16px;
    border-bottom: 1px solid #eaeaea;
  }

  .parent-event .event-container .microsite-paids .prime h1 {
    margin: 0px !important;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.87);
  }

  .parent-event .event-container .microsite-paids .prime p {
    margin: 0px !important;
    margin-top: 16px !important;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.87);
  }

  .parent-event .event-container .microsite-paids .prime .flexed {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 24px;
  }

  .parent-event .event-container .microsite-paids .prime h5 {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #6c60e0;
    margin: 0px !important;
  }

  .parent-event .event-container .microsite-paids .prime h4 {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    margin: 0px !important;
    margin-top: 4px !important;
    color: rgba(0, 0, 0, 0.87);
  }

  .parent-event .event-container .microsite-paids .prime .flexed button {
    width: auto;
    background: #6c60e0;
    border-radius: 8px;
    border: none;
    padding: 6px 19px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
  }

  .parent-event .event-container .microsite-paids .prime .flexed button img {
    margin-right: 6px;
  }

  .parent-event .event-container .microsite-paids .bottomeds {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .parent-event .event-container .microsite-paids .bottomeds .flexed {
    display: flex;
    align-items: center;
  }

  .parent-event .event-container .microsite-paids .bottomeds .flexed p {
    margin: 0px !important;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: rgba(0, 0, 0, 0.87);
  }

  .parent-event .event-container .microsite-paids .bottomeds a {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #6c60e0;
    text-decoration: none;
  }

  .parent-event .event-container .mained h6 {
    margin: 0px;
    margin-left: 10px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: rgba(153, 153, 153, 0.87);
  }

  .parent-event .event-container .mained .menu-ava {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }

  .parent-event .event-container .mained .menu-ava p {
    margin: 0px !important;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
  }

  .parent-event .event-container .mained .menu-ava div {
    background: #6c60e0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
    width: 42px;
    border-radius: 12px;
    margin-right: 12px;
  }

  .parent-event .event-container .mained .menu-ava h5 {
    margin: 0px !important;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.87);
  }

  .parent-event .event-container .mained .menu-status {
    display: flex;
    align-items: center;
  }

  .parent-event .event-container .mained .menu-status div {
    margin-left: 12px;
  }

  .parent-event .event-container .mained .menu-status p {
    margin: 0px !important;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.87);
  }

  .parent-event .event-container .mained .menu-status span {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    margin: 0px !important;
    margin-top: 2px !important;
    color: rgba(0, 0, 0, 0.87);
  }

  .parent-event .event-container .logouts p {
    margin: 0px !important;
    margin-left: 16px !important;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.87);
  }

  .parent-event .event-container .logouts {
    padding: 16px;
    display: flex;
    align-items: center;
    width: 100%;
    border-top: 1px solid #eaeaea;
    cursor: pointer;
  }

  .materials-mobile {
    display: none !important;
  }

  .materials-desktop {
    display: block !important;
  }

  #material-webs {
    padding: 0px;
  }
}

#detail-schedule #e_mobs {
  display: none;
  padding-top: 32px;
}

@media (max-width: 768px) {
  #detail-schedule #e_mobs {
    display: block;
  }

  #detail-schedule .event-header div {
    justify-content: center !important;
    width: 100% !important;
    padding-left: 0px !important;
  }

  #detail-schedule .parent-header-e .menu-mob {
    display: none;
  }

  #detail-schedule.profile .event-header div {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    width: 60% !important;
    padding-left: 16px !important;
  }

  #detail-schedule.profile .parent-header-e .menu-mob {
    display: block;
  }

  #detail-schedule .parent-event .event-container {
    max-width: 448px !important;
    background: #fff !important;
    width: 100% !important;
    display: block !important;
    padding: 0px !important;
  }

  #detail-schedule .event-container .e_mobs .last-event {
    padding: 32px 16px !important;
    padding-bottom: 0px !important;
  }

  #detail-schedule .parent-event {
    height: auto !important;
  }

  .parent-allevent .placeHolders {
    height: 100vh;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .parent-allevent .placeHolders h4 {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
  }

  .parent-allevent .placeHolders p {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
  }

  .event-container .section-ones .mob-covers {
    width: 100%;
    height: auto;
    background-color: #fff;
    border: 1px solid #eaeaea;
    border-radius: 8px;
  }

  .event-container .section-ones .microsite-paids .prime {
    padding: 12px;
    border-bottom: 1px solid #eaeaea;
  }

  .event-container .section-ones .microsite-paids .prime h1 {
    margin: 0px !important;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.87);
  }

  .event-container .section-ones .microsite-paids .prime p {
    margin: 0px !important;
    margin-top: 16px !important;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.87);
  }

  .event-container .section-ones .microsite-paids .prime .flexed {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 24px;
  }

  .event-container .section-ones .microsite-paids .prime h5 {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #6c60e0;
    margin: 0px !important;
  }

  .event-container .section-ones .microsite-paids .prime h4 {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    margin: 0px !important;
    margin-top: 4px !important;
    color: rgba(0, 0, 0, 0.87);
  }

  .event-container .section-ones .microsite-paids .prime .flexed button {
    width: auto;
    background: #6c60e0;
    border-radius: 8px;
    border: none;
    padding: 6px 19px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
  }

  .event-container .section-ones .microsite-paids .prime .flexed button img {
    margin-right: 6px;
  }

  .event-container .section-ones .microsite-paids .bottomeds {
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .event-container .section-ones .microsite-paids .bottomeds .flexed {
    display: flex;
    align-items: center;
  }

  .event-container .section-ones .microsite-paids .bottomeds .flexed p {
    margin: 0px !important;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: rgba(0, 0, 0, 0.87);
  }

  .event-container .section-ones .microsite-paids .bottomeds a {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #6c60e0;
    text-decoration: none;
  }
  .parent-event .event-container .last-event ~ .one ~ .two .app-bars {
    bottom: 0px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    top: unset;
    border: 1px solid #eaeaea;
  }
  .parent-event
    .event-container
    .last-event
    ~ .one
    ~ .two
    .app-bars
    .the-logos {
    display: none;
  }
  .parent-event
    .event-container
    .last-event
    ~ .one
    ~ .two
    .app-bars
    .content-appbars
    .sectiones {
    margin: 0px;
    width: 100%;
  }
  .parent-event .event-container .last-event ~ .one ~ .two .app-bars .asking {
    display: none;
  }
}

#other_e .card-events {
  margin-bottom: 10px !important;
}

.divs-titles {
  padding: 12px;
  border-bottom: 1px solid #eaeaea;
}

.divs-titles h1 {
  margin: 0px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.87);
}

.testi-container .card-test {
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 12px;
  margin-top: 10px;
}

.testi-container .card-test .test-parent {
  display: flex;
  align-items: center;
  padding: 16px 12px;
  border-bottom: 1px solid #eaeaea;
}

.testi-container .card-test .test-parent .ava {
  background: #f5f0ff;
  border: 1px solid #6c60e0;
  box-sizing: border-box;
  border-radius: 12px;
  height: 42px;
  width: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.testi-container .card-test .test-parent .ava span {
  margin: 0px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin-right: 10px;
  color: #6c60e0;
}

.testi-container .card-test .test-parent .ava~div h1 {
  margin: 0px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.87);
}

.testi-container .card-test .test-parent .ava~div img {
  height: 12px;
  width: 12px;
}

.testi-container .card-test .score-parent {
  padding: 16px 12px;
}

.testi-container .card-test .score-parent p {
  margin: 0px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.87);
}

.testi-header .h1 {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin: 0px !important;
  color: rgba(0, 0, 0, 0.87);
}

.testi-header .card-sche {
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 12px;
  margin-top: 10px;
  margin-bottom: 24px;
}

.testi-header .card-sche .title-parent {
  padding: 16px 12px;
  border-bottom: 1px solid #eaeaea;
}

.testi-header .card-sche .title-parent h1 {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin: 0px !important;
  color: rgba(0, 0, 0, 0.87);
}

.testi-header .card-sche .score-parent {
  padding: 16px 12px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.testi-header .card-sche .score-parent div {
  display: flex;
  align-items: center;
  width: 50%;
}

.testi-header .card-sche .score-parent div p {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin: 0px !important;
  margin-left: 8px !important;
  color: rgba(0, 0, 0, 0.87);
}

@media (min-width: 1370px) {
  .parent-event .event-container .last-event~.one~.two .two-right {
    max-height: 100%;
  }
}

.logins-mob {
  display: none;
}

@media (max-width: 768px) {
  .logins-mob {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0px 16px;
    margin-bottom: 16px;
  }

  .logins-mob .msk {
    width: 47.5%;
    border: 1px solid #6c60e0;
    box-sizing: border-box;
    border-radius: 12px;
    background: #fff;

    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    height: 41px;
    text-align: center;
    color: #6c60e0;
  }

  .logins-mob .dft {
    width: 47.5%;
    border: unset;
    box-sizing: border-box;
    border-radius: 12px;
    background: #6c60e0;

    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    height: 41px;
    text-align: center;
    color: #fff;
  }
}

.esch_titles {
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 12px;
  margin-top: 18px;
  margin-bottom: 18px;
}

.esch_titles button {
  display: flex;
  align-items: center;
  background: #f5f0ff;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 12px 0px 0px 12px;
  padding: 12px;
  cursor: pointer;
}

.esch_titles button p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin-left: 8px;
  color: #6c60e0;
}

.esch_titles div p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin-left: 16px;
  color: rgba(0, 0, 0, 0.87);
}

@media (max-width: 768px) {
  #detail-schedule .two {
    display: none !important;
  }
}

#detail-schedule .kues_parent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 12px;
  margin-top: 12px;
}

#detail-schedule .kues_parent div {
  display: flex;
  align-items: center;
}

#detail-schedule .kues_parent div p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin-left: 16px;
  color: rgba(0, 0, 0, 0.87);
}

.p-quiz {
  width: 100%;
  background: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 12px;
  padding: 24px 0px;
  padding-right: 16px;
}

.p-quiz .l-quiz {
  display: flex;
  margin-bottom: 32px;
  width: 100%;
}

.p-quiz .l-quiz span {
  background: #6c60e0;
  border-radius: 0px 4px 4px 0px;
  width: 4px;
  height: 42px;
  border-radius: 0px 12px 12px 0px;
}

.p-quiz .l-quiz .s-quiz {
  margin-left: 12px;
  width: 100%;
}

.p-quiz .l-quiz .s-quiz h1 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.87);
  margin: 0px !important;
}

.p-quiz .l-quiz .s-quiz .multi-quiz {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.87);
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding: 18px;
  cursor: pointer;
  margin-top: 16px;
  width: 100%;
}

.p-quiz .l-quiz .s-quiz .multi-quiz.non-selectable {
  border: solid 1px #dedede;
}

.p-quiz .l-quiz .s-quiz .multi-quiz.active {
  background: #f5f0ff;
  border: 1px solid #6c60e0;
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding: 18px;
  cursor: pointer;
  margin-top: 16px;
  width: 100%;
}

.p-quiz .l-quiz .s-quiz .multi-quiz span {
  padding: 6px;
  height: auto;
  border-radius: 50%;
  background: #ffffff;
  border: 2px solid #999999;
  margin-right: 14px;
}

.p-quiz .l-quiz .s-quiz .multi-quiz.active span {
  padding: 6px;
  height: auto;
  border-radius: 50%;
  background: #ffffff;
  border: 4px solid #6c60e0;
  margin-right: 14px;
}

.p-quiz .l-quiz .s-quiz .multi-quiz p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin: 0px !important;
  color: rgba(0, 0, 0, 0.87);
}

.p-quiz .l-quiz .s-quiz .multi-quiz.active p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin: 0px !important;
  color: #6c60e0;
}

.p-quiz .l-quiz .s-quiz textarea {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.87);
  border-radius: 12px;
  width: 100%;
  resize: none;
  margin-top: 16px;
  padding: 16px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

.p-quiz .l-quiz .s-quiz textarea.inactive {
  background: #f3f3f3;
  border: solid 1px #dedede;
  color: #999999;
}

.p-quiz .l-quiz .s-quiz .ones-quiz {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.87);
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding: 18px;
  cursor: pointer;
  width: 100%;
  margin-top: 16px;
}

.p-quiz .l-quiz .s-quiz .ones-quiz.active {
  background: #f5f0ff;
  border: 1px solid #6c60e0;
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding: 18px;
  cursor: pointer;
  width: 100%;
  margin-top: 16px;
}

.p-quiz .l-quiz .s-quiz .ones-quiz p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin: 0px !important;
  margin-left: 14px !important;
  color: rgba(0, 0, 0, 0.87);
}

.p-quiz .l-quiz .s-quiz .ones-quiz.active p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin: 0px !important;
  margin-left: 14px !important;
  color: #6c60e0;
}

.p-quiz .l-quiz .s-quiz .results-quiz {
  margin-top: 16px;
  background: #f4f4f4;
  border-radius: 12px;
  padding: 16px;
}

.p-quiz .l-quiz .s-quiz .results-quiz .topped {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.p-quiz .l-quiz .s-quiz .results-quiz .topped .tx-titles {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin: 0px !important;
  color: rgba(0, 0, 0, 0.87);
}

.p-quiz .l-quiz .s-quiz .results-quiz .topped .tx-indicators {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  margin: 0px !important;
  color: #6c60e0;
}

.p-quiz .l-quiz .s-quiz .results-quiz .bars-quiz {
  width: 100%;
  background: #dedede;
  border-radius: 2px;
  height: 4px;
}

.p-quiz .l-quiz .s-quiz .results-quiz .bars-quiz .sliders {
  background: #6c60e0;
  border-radius: 2px;
  height: 4px;
}

.r-quiz {
  display: flex;
  align-items: center;
  background: #6c60e0;
  border-radius: 12px;
  padding: 20px 16px;
  margin-bottom: 18px;
}

.r-quiz p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  margin-left: 12px;
}

.section-scc {
  position: fixed;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
}

.section-scc .boxes-s {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  max-width: 360px;
  width: 100%;
}

.section-scc .boxes-s .tops {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 32px 0px;
}

.section-scc .boxes-s .tops img {
  width: 80px;
  height: 80px;
  margin-bottom: 16px;
}

.section-scc .boxes-s .tops p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  margin: 0px !important;
  color: rgba(0, 0, 0, 0.87);
}

.section-scc .boxes-s .bottoms {
  padding: 16px;
  border-top: 1px solid #eaeaea;
}

.section-scc .boxes-s .bottoms button {
  width: 100%;
  padding: 12px;
  text-align: center;
  border: unset;
  background: #6c60e0;
  border-radius: 12px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;
  color: #ffffff;
}

.wrapper_speakers {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .q-mobiles {
    position: absolute;
    top: 0px;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .q-mobiles .parent-mobiles {
    background: #6c60e0;
    min-height: 100vh;
    height: 100%;
    max-width: 450px;
    width: 100%;
  }

  .q-mobiles .parent-mobiles .tops-mobiles {
    padding: 16px;
  }

  .q-mobiles .parent-mobiles .tops-mobiles p {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    margin: 0px !important;
    margin-top: 16px !important;
    color: #ffffff;
  }

  #body.quiziz {
    margin: 0px !important;
  }

  .p-quiz {
    border-radius: 16px 16px 0px 0px;
  }

  .parent-sheets {
    display: flex;
    justify-content: center;
    width: 100%;
    position: fixed;
    z-index: 1000;
    bottom: 0px;
  }

  .parent-sheets .b-sheets {
    max-width: 450px;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px -5px 30px rgba(0, 0, 0, 0.1);
    border-radius: 12px 12px 0px 0px;
    padding: 16px;
  }

  .parent-sheets .b-sheets button {
    width: 100%;
    padding: 12px;
    text-align: center;
    border: unset;
    background: #6c60e0;
    border-radius: 12px;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    cursor: pointer;
    color: #ffffff;
  }

  .section-scc {
    display: none !important;
  }

  .scc-mob {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
  }

  .scc-mob img {
    height: 60px;
    width: 60px;
    margin-bottom: 20px;
  }

  .scc-mob p {
    margin: 0px !important;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
  }
}

#stream-join-btn {
  width: 100%;
  border: none;
  display: flex;
  margin-top: 12px;
  padding: 10px 17px;
  align-items: center;
  flex-direction: row;
  border-radius: 12px;
  justify-content: space-between;

  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}

@media (max-width: 768px) {
  .parent-event .event-container .two {
    min-height: unset !important;
  }
}
.main-all {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  padding-top: 72px;
  min-height: 100%;
  background-color: #f2f2f2;
}

#root.certificates {
  background-color: #fff !important;
  height: 100px !important;
  margin: 0px !important;
}
#body.certificates {
  margin: 0px !important;
}
.header {
  width: 100%;
  position: fixed;
  top: 0px;
  z-index: 1000;
  background: #ffffff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  padding: 16px;
}

.header p {
  margin: unset !important;
  margin-left: 12px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.87);
}
.parent-allevent {
  display: flex;
  justify-content: center;
  width: 100%;
}

.allevent-container {
  max-width: 448px;
  width: 100%;
  background-color: #fff;
  min-height: 100vh;
  margin-top: -24px;
  padding-top: 24px;
}
.allevent-container .schedule {
  padding: 0px 16px;
}

.allevent-container .filter {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}
.allevent-container .filter button {
  height: 30px;
  border-radius: 8px;
  background: #ffffff;
  border: 1px solid #eaeaea;
  padding: 8px 12px;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.87);
  margin-right: 8px;
  white-space: nowrap;
}
.allevent-container .filter button.active {
  background: #e0d7ff;
  border: unset;
  color: #6c60e0;
  font-weight: 500;
}

.card-events {
  width: 100%;
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 12px;
  margin-bottom: 16px;
}
.card-events > img {
  width: 100%;
  height: 160px;
  border-radius: 12px 12px 0px 0px;
}
.card-events .schedule {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 12px;
  border-bottom: 1px solid #eaeaea;
}
.card-events .schedule div {
  display: flex;
  align-items: center;
  width: 142px;
}
.card-events .schedule div p {
  margin: 0px !important;
  margin-left: 8px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.87);
}
.card-events .status {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 12px;
}
.card-events .status div {
  display: flex;
  align-items: center;
  width: 142px;
}
.card-events .status div p {
  margin: 0px !important;
  margin-left: 8px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.87);
}
.card-events .status .cta {
  width: 142px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
}
.card-events .status .cta.done {
  background: #ffffff;
  border: 1px solid #999999;
  color: #999999;
}
.card-events .status .cta.on {
  background: #6c60e0;
  border: unset;
  color: #fff;
}
.card-events .status .cta.off {
  background: #999;
  border: unset;
  color: #fff;
}
.card-events .status .cta.off.offline {
  background: #6c60e0;
  border: unset;
  color: #fff;
}
.parent-allevent .emptys {
  height: 70vh;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.parent-allevent .emptys div {
  display: flex;
  align-items: center;
  width: 290px;
  flex-direction: column;
}
.parent-allevent .emptys div img {
  height: 84px;
  margin-bottom: 16px;
}
.parent-allevent .emptys div h1 {
  margin: 0px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
}
.parent-allevent .emptys div p {
  margin: 0px !important;
  margin-top: 6px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
}

.parent-allevent .card-qna {
  margin-bottom: 10px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 12px;
}
.parent-allevent .card-qna .down .speakers {
  display: flex;
  align-items: center;
}
.parent-allevent .card-qna .down .speakers img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-right: 12px;
}
.parent-allevent .card-qna .up {
  padding: 16px 12px;
  border-bottom: 1px solid #eaeaea;
}
.parent-allevent .card-qna .up h1 {
  margin: unset !important;
  margin-bottom: 0px !important;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.87);
}
.parent-allevent .card-qna .up h5 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #999999;
  margin: unset !important;
  margin-top: 12px !important;
}
.parent-allevent .card-qna .down {
  padding: 16px 12px;
}
.parent-allevent .card-qna .down p {
  margin: 0px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 4px !important;
  color: rgba(0, 0, 0, 0.87);
}
.parent-allevent .card-qna .down h4 {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin: unset !important;
  margin-bottom: 4px !important;
  color: rgba(0, 0, 0, 0.87);
}
.parent-allevent .card-qna .down h5 {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin: unset !important;
  color: #999999;
}
.parent-allevent .card-qna .down .cta-sections {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 16px;
}
.parent-allevent .card-qna .down .cta-sections button {
  width: 47.5%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: unset;
  height: 30px;
}
.parent-allevent .card-qna .down div button img {
  height: 16px;
  margin-right: 4px;
}
.parent-allevent .card-qna .down div button p {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin: unset !important;
}
.parent-allevent .card-qna .down .cta-sections .actions {
  display: flex;
  align-items: center;
  margin-right: 12px;
  cursor: pointer;
}
.parent-allevent .card-qna .down .cta-sections .actions img {
  margin: 0px;
}
.parent-allevent .card-qna .down .cta-sections .actions p {
  margin: 0px !important;
  margin-left: 6px !important;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}
.parent-allevent .card-qna .down .cta-sections button {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: unset;
  width: 26px;
  padding: 4px;
  height: 26px;
}
.parent-allevent .card-qna .down div button.clock {
  background: #f5f0ff;
}
.parent-allevent .card-qna .down div button.clock p {
  color: #7d61d6;
}
.parent-allevent .card-qna .down div button.play {
  background: #7d61d6;
}
.parent-allevent .card-qna .down div button.play img {
  margin-right: 2px;
}
.parent-allevent .card-qna .down div button.play p {
  color: #fff;
}

.parent-download {
  margin-top: -16px;
}

.parent-verif {
  padding: 16px;
  height: 158px;
  background: #d7efff;
}
.parent-verif section {
  display: flex;
  align-items: center;
}
.parent-verif section h1 {
  margin: 0px !important;
  margin-left: 10px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.87);
}
.parent-verif section ~ div {
  margin-top: 16px;
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 12px;
}
.parent-verif section ~ div .one {
  padding: 16px 12px;
  border-bottom: 1px solid #eaeaea;
}
.parent-verif section ~ div .one div {
  display: flex;
  align-items: center;
}
.parent-verif section ~ div .one div p {
  margin: 0px !important;
  margin-left: 8px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.87);
}
.parent-verif section ~ div .one h5 {
  margin: 0px !important;
  margin-left: 38px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.87);
}
.parent-verif section ~ div .two {
  padding: 16px 12px;
}
.parent-verif section ~ div .two .head div {
  display: flex;
  align-items: center;
}
.parent-verif section ~ div .two .head div h5 {
  margin-left: 8px;
  font-family: Rubik;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #f9a33f;
}
.parent-verif section ~ div .two .head p {
  margin: 0px !important;
  margin-left: 38px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.87);
}
.parent-verif section ~ div .two .cta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 16px;
}
.parent-verif section ~ div .two .cta .change {
  width: 48.5%;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  border-radius: 8px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.87);
}
.parent-verif section ~ div .two .cta .done {
  width: 48.5%;
  background: #ffffff;
  border: 1px solid #6c60e0;
  box-sizing: border-box;
  border-radius: 8px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #6c60e0;
}

.parent-download .parent-list {
  margin-top: 150px;
  padding: 0px 16px;
}
.parent-download .parent-list > h1 {
  margin: 0px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.87);
}
.parent-download .parent-list > h1 ~ div {
  margin-top: 16px;
}
.parent-download .parent-list .card-certificate {
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 12px;
  margin-bottom: 16px;
}
.parent-download .parent-list .card-certificate .one {
  padding: 12px;
  border-bottom: 1px solid #eaeaea;
}
.parent-download .parent-list .card-certificate .one h5 {
  margin-bottom: 8px;
  font-family: Rubik;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #6c60e0;
}
.parent-download .parent-list .card-certificate .one h1 {
  margin: 0px !important;
  margin-bottom: 12px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.87);
}
.parent-download .parent-list .card-certificate .one h1 ~ div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.parent-download .parent-list .card-certificate .one h1 ~ div div {
  display: flex;
  align-items: center;
  width: 48.5%;
}
.parent-download .parent-list .card-certificate .one h1 ~ div div p {
  margin: 0px !important;
  margin-left: 8px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.87);
}
.parent-download .parent-list .card-certificate .two {
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.parent-download .parent-list .card-certificate .two div {
  display: flex;
  align-items: center;
  width: 48.5%;
}
.parent-download .parent-list .card-certificate .two div p {
  margin: 0px !important;
  margin-left: 8px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.87);
}
.parent-download .parent-list .card-certificate .two button {
  width: 48.5%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  background: #6c60e0;
  border-radius: 8px;
  border: unset;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
}
.auths-box .warning {
  margin: 24px 0px;
}
.auths-box .warning div {
  display: flex;
  align-items: center;
}
.auths-box .warning h5 {
  margin-left: 8px;
  font-family: Rubik;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #f9a33f;
}
.auths-box .warning p {
  margin: 0px !important;
  margin-left: 38px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.87);
}

.parent-download .parent-contain {
  padding: 16px;
}

.parent-download .parent-contain .detail {
  width: 100%;
  padding: 16px 12px;
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 12px;
}
.parent-download .parent-contain .detail h5 {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 8px;
  color: #6c60e0;
}

.parent-download .parent-contain .detail h1 {
  margin: 0px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.87);
}
.parent-download .parent-contain img {
  margin: 16px 0px;
  width: 100%;
  height: 231px;
}
.parent-download .parent-contain button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: unset;
  background: #6c60e0;
  border-radius: 12px;
  height: 40px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}
.parent-download .emptys {
  height: 70vh;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.parent-download .emptys div {
  display: flex;
  align-items: center;
  width: 290px;
  flex-direction: column;
}
.parent-download .emptys div img {
  height: 84px;
  margin-bottom: 16px;
}
.parent-download .emptys div h1 {
  margin: 0px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
}
.parent-download .emptys div p {
  margin: 0px !important;
  margin-top: 6px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
}
.emptys ~ .parent-wantask {
  display: flex;
  justify-content: center;
  width: 100%;
}
.emptys ~ .parent-wantask .want-ask {
  padding: 32px 16px;
  background: #f5f0ff;
  position: fixed;
  bottom: 0px;
  max-width: 448px;
  width: 100%;
  border-radius: 12px 12px 0px 0px;
}
.emptys ~ .parent-wantask .want-ask div {
  display: flex;
}
.emptys ~ .parent-wantask .want-ask div h4 {
  margin: 0px !important;
  margin-left: 10px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.87);
}
.emptys ~ .parent-wantask .want-ask button {
  margin-top: 16px;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #6c60e0;
  border-radius: 12px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  border: unset;
}
.divs-titles {
  padding: 12px;
  border-bottom: 1px solid #eaeaea;
}
.divs-titles h1 {
  margin: 0px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.87);
}
@media (max-width: 500px) {
  #certificate-container {
    max-width: 448px !important;
    width: 100% !important;
    visibility: hidden;
  }
}

#premium-dialog-box {
    position: fixed;
    top: 0px;
    height: 100vh;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1001;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#premium-dialog {
    max-width: 360px;
    height: auto;
    background: #FFFFFF;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding: 16px;
}

#premium-dialog .pd-head {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 24px;
}

#premium-dialog h1 {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.87);
    margin: 0;
    margin-left: 16px;
    max-width: 264px;
}

#premium-dialog p {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.87);
}


#premium-dialog .pd-info {
    background: #FFF3C6;
    border: 1px solid #F9A33F;
    border-radius: 12px;
    padding: 12px;
}

#premium-dialog .pd-info p {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.87);
    margin: 0;
}


#premium-dialog .pd-features {
    margin-top: 16px;
    margin-bottom: 24px;
}

#premium-dialog .pd-feature {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 12px;
}

#premium-dialog .pd-footer button {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #6C60E0;
    border-radius: 12px;
    padding: 8px 24px;
    height: 40px;
    border: 0;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    width: 100%;
}

#premium-dialog .pd-footer button img {
    margin-right: 24px;
}


#premium-dialog .pd-footer .pd-call-admin {
    color: #fff;
    margin-bottom: 16px;
}

#premium-dialog .pd-footer .pd-close {
    color: #000;
    background: #fff;
    border: 1px solid #999999;
}


@media (max-width:500px) {
    #premium-dialog-box {
        align-items: flex-end;
    }

    #premium-dialog {
        max-width: 100vw;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }
}
#profile_menu {
    position: relative;
}

#profile_menu p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: rgba(0, 0, 0, 0.87);
    margin: 0;
}

.backdrop {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 64px;
    left: 0;
}
#profile_menu_list {
    background: #FFFFFF;
}

#profile_menu_list h4 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.87);
    margin: 0;
    margin-bottom: 2px;
}

#profile_menu_list p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: rgba(0, 0, 0, 0.87);
    margin: 0;
}


#profile_menu_list .pm_box {
    border-radius: 6px;
    position: absolute;
    z-index: 99;
    top: 65px;
    right: 0;
}

#profile_menu_list .pm_head,
.pm_list,
.pm_logout {
    padding: 16px;
}


#profile_menu_list .pm_head,
.pm_list {
    border-bottom: 1px solid #EAEAEA;
}

#profile_menu_list .pm_list,
.pm_logout {
    display: flex;
    flex-direction: column;
    grid-row-gap: 16px;
    row-gap: 16px;
}


#profile_menu_list .pm_menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-column-gap: 16px;
    -webkit-column-gap: 16px;
            column-gap: 16px;
    cursor: pointer;
}


.backdrop {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 64px;
    left: 0;
}
.otb-login {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}

.otb-login button {
  border-radius: 12px;
  padding: 8px 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
}

.otb-login button img {
  margin-right: 25px;
}

.otb-login .login-pijar {
  background: #6C60E0;
  color: #fff;
  margin-bottom: 12px;
}

.otb-login .register {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.87);
  border-radius: 12px;
  color: rgba(0, 0, 0, 0.87);
}

.otb-login .divider {
  width: 100%;
  background-color: #999;
  height: 1px;
  margin: 24px 0px;
}
.auths-box {
  position: fixed;
  top: 0px;
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1001;
  left: 0px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.auths-box section {
  max-width: 448px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 12px 12px 0px 0px;
  padding: 16px;
}
.auths-box section > img {
  cursor: pointer;
}
.auths-box section h1 {
  margin: 0px !important;
  margin-top: 24px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 33px;
  color: rgba(0, 0, 0, 0.87);
}
.auths-box section .one {
  margin-top: 24px;
  width: 100% !important;
  display: block !important;
  border: unset !important;
}
.auths-box section .one button {
  width: 100%;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  border-radius: 12px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0px 24px;
}
.auths-box section .one button.login-cta {
  border: 1px solid #7d61d6;
}
.auths-box section .one button.login-cta p {
  color: #7d61d6;
}
.auths-box section .one button img {
  width: 24px;
}
.auths-box section .one button p {
  margin: 0px !important;
  margin-left: 24px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.87);
}
.auths-box section .one div {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin: 16px 0px;
}
.auths-box section .one div p {
  margin: 0px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #999999;
}
.auths-box section .one div hr {
  width: 45%;
  background-color: #999999;
  height: 1px;
}

.auths-box section .two {
  margin-top: 24px;
}
.auths-box section .two .form-register .form-item {
  width: 100%;
  margin-bottom: 16px;
}
.auths-box section .two .form-register .form-item label {
  margin: unset !important;
  margin-bottom: 8px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.87);
}
.auths-box section .two .form-register .form-item input {
  border: unset;
  outline: unset;
  width: 100%;
  font-size: 14px;
}
.auths-box section .two .form-register .form-item div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 41px;
  padding: 0px 14px;
  background: #ffffff;
  border: 1px solid #dedede;
  box-sizing: border-box;
  border-radius: 12px;
}
.auths-box section .two .form-register .form-item div img {
  cursor: pointer;
}
.auths-box section .two .form-register .kebijakan {
  margin-bottom: 24px;
}
.auths-box section .two .form-register .kebijakan p {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  margin: unset !important;
  color: #585858;
}

.auths-box section .two .form-register .kebijakan p a {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  margin: unset !important;
  color: #6c60e0;
  text-decoration: underline !important;
}

.auths-box section .two .form-register .btn-daftar {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #999999;
  border-radius: 12px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  border: unset;
  margin-bottom: 16px;
}
.auths-box section .two .form-register .btn-back {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 12px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #999999;
}
.auths-box section .step-four p {
  margin: 0px !important;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #585858;
}
.auths-box section .step-four {
  margin-top: 24px;
}
.auths-box section .step-four button {
  width: 100%;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  border-radius: 12px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0px 24px;
}
.auths-box section .step-four .reset-cta {
  background-color: #6c60e0;
  border: unset;
  margin-top: 16px;
  margin-bottom: 0px;
}
.auths-box section .step-four .reset-cta.fives {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  justify-content: center;
}
.auths-box section .step-four .remember-cta {
  background: #ffffff;
  border: 1px solid #6c60e0;
  margin-bottom: 0px;
}
.auths-box section .step-four .remember-cta p {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin: 0px !important;
  margin-left: 24px !important;
  color: #6c60e0;
}
.auths-box section .step-four .reset-cta p {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin: 0px !important;
  margin-left: 24px !important;
  color: #ffffff;
}
.auths-box section .step-four div {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin: 16px 0px;
}
.auths-box section .step-four div p {
  margin: 0px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #999999;
}
.auths-box section .step-four div hr {
  width: 45%;
  background-color: #999999;
  height: 1px;
  margin: 0px !important;
}
.auths-box section .step-four .back {
  margin-top: 16px;
  background: #ffffff;
  border: 1px solid #999999;
  text-align: center;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  justify-content: center;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.87);
}
.auths-box section .step-four .back.eights-cta {
  margin-top: 16px;
  background: #ffffff;
  border: 1px solid #999999;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.87);
}
.auths-box section .step-four .back.eights-cta p {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin: 0px !important;
  margin-left: 24px !important;
  color: rgba(0, 0, 0, 0.87);
}
.auths-box section .step-four .five-sections {
  width: 100%;
  margin-bottom: 16px;
  display: block;
}
.auths-box section .step-four .five-sections p {
  margin: unset !important;
  margin-bottom: 8px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.87);
  text-align: left;
}
.auths-box section .step-four .five-sections input {
  border: unset;
  outline: unset;
  width: 100%;
}
.auths-box section .step-four .five-sections div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 41px;
  padding: 0px 14px;
  background: #ffffff;
  border: 1px solid #dedede;
  box-sizing: border-box;
  border-radius: 12px;
  margin: 0px;
}
.auths-box section .reset-step {
  display: flex;
  align-items: center;
  padding: 12px;
  width: 100%;
  justify-content: space-between;
  background: #f5f0ff;
  border-radius: 12px;
}
.auths-box section .reset-step .steps {
  display: flex;
  align-items: center;
}
.auths-box section .reset-step .steps .numbers {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
}

.auths-box section .reset-step .steps .numbers p {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  margin: 0px !important;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
}

.auths-box section .reset-step .steps .numbers ~ p {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin: 0px 6px !important;
  color: rgba(0, 0, 0, 0.5);
}

.auths-box section .reset-step .steps hr {
  background: #999999;
  height: 1px;
  width: 14px;
  margin: 0px !important;
  margin-left: 7px !important;
}
.auths-box section .reset-step .steps.active hr {
  background: #6c60e0;
}
.auths-box section .reset-step .steps.active .numbers {
  background-color: #6c60e0;
}
.auths-box section .reset-step .steps.active .numbers p {
  color: #fff;
}
.auths-box section .reset-step .steps.active .numbers ~ p {
  color: #6c60e0;
}

@media (min-width: 1000px) {
  .auths-box {
    align-items: center !important;
  }
  .auths-box section {
    border-radius: 12px !important;
  }
  .auths-box section {
    max-width: 360px !important;
  }
}

#card-programs {
  background: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  padding: 12px;
  width: 100%;
  margin-bottom: 16px;
}

#card-programs .img {
  border-radius: 8px;
  height: 152px;
  margin-bottom: 16px;
  width: 100%;
}

#card-programs h5 {
  margin: 0px !important;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  color: rgba(0, 0, 0, 0.87);
}

#card-programs .flex-sec {
  display: flex;
  align-items: center;
  margin-top: 16px;
}
#card-programs .bar-sec {
  width: 100%;
  height: 4px;
  background: #eaeaea;
  border-radius: 2px;
  margin: 16px 0px;
}
#card-programs .bar-sec div {
  height: 4px;
  background: #6c60e0;
  border-radius: 2px;
}
#card-programs .flex-sec div {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

#card-programs .flex-sec div p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #7b7b7b;
  margin: 0px !important;
  margin-left: 6px !important;
}
#card-programs .last-sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#card-programs .last-sec div {
  display: flex;
  cursor: pointer;
  align-items: center;
}

#card-programs .last-sec div p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  margin: 0px !important;
  margin-right: 4px !important;
  color: #6c60e0;
}
.cta-enrolls button {
  background: #6c60e0;
  border-radius: 12px;
  width: 100%;
  border: none;
  height: 41px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  margin-top: 16px;
}
@media (max-width: 500px) {
  #card-programs.is-mobile {
    width: 100%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    margin-top: 10px;
    border: none;
  }
}

#right-program h1 {
  margin: 0px !important;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.87);
}

#right-program .box {
  background: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 12px;
  margin-top: 14px;
  padding: 12px;
}
#right-program .box .head {
  display: flex;
  align-items: center;
}
#right-program .box .head h1 {
  margin: 16px 0px !important;
  font-size: 14px;
  line-height: 17px;
}
#right-program .box .bottoms {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

#right-program .box .bottoms h5 {
  margin: 0px !important;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.87);
}

#right-program .box .bottoms button {
  background: #ffffff;
  display: flex;
  align-items: center;
  border: 1px solid #6c60e0;
  border-radius: 8px;
  padding: 9px;
  cursor: pointer;
}

#right-program .box .bottoms button p {
  margin: 0px !important;
  margin-left: 3px !important;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #6c60e0;
}

#list-programs {
  background: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 12px;
  margin-bottom: 16px;
  padding: 16px 12px;
}

#list-programs .head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

#list-programs .head .main {
  display: flex;
  align-items: center;
}
#list-programs .head .main .material {
  background: #f5f0ff;
  border-radius: 8px;
  padding: 2px 6px;
  margin-right: 8px;
  display: flex;
  align-items: center;
}

#list-programs .head .main .material p {
  margin: 0px !important;
  margin-left: 4px !important;

  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #6c60e0;
}
#list-programs .head .main .timming {
  background: #ffffff;
  border: 1px solid #ffd8ab;
  border-radius: 8px;
  padding: 6px;
  display: flex;
  align-items: center;
}
#list-programs .head .main .timming p {
  margin: 0px !important;
  margin-left: 4px !important;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #f9a33f;
}
#list-programs .head button {
  display: flex;
  align-items: center;
  cursor: pointer;
  background: #6c60e0;
  border-radius: 8px;
  padding: 6px 15px;
  border: none;
}

#list-programs .head button p {
  margin: 0px !important;
  margin-right: 4px !important;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
}
#list-programs h5 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin: 0px !important;
  color: rgba(0, 0, 0, 0.87);
}
#list-programs .bottom {
  margin-top: 12px;
}

#list-programs .bottom p {
  margin: 0px !important;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #7b7b7b;
}

#list-programs .details {
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid #eaeaea;
}

#list-programs .details .item-details {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  justify-content: space-between;
}
#list-programs .details .item-details div {
  display: flex;
  align-items: center;
  width: 47.5%;
}

#list-programs .details .item-details div p {
  margin: 0px !important;
  margin-left: 12px !important;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.87);
}
#list-programs .details .item-details div p span {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #999999;
}
#list-programs .details .item-details button {
  display: flex;
  align-items: center;
  justify-content: start;
  background: #ffffff;
  border: 1px solid #6c60e0;
  border-radius: 8px;
  height: 34px;
  width: 107px;
  padding: 11px;
}
#list-programs .details .item-details button p {
  margin: 0px !important;
  margin-left: 6px !important;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  color: #6c60e0;
}

#dialog-enroll {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 1001;
}

#dialog-enroll .box-dialog {
  max-width: 500px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.14);
  border-radius: 12px 12px 0px 0px;
  padding: 16px;
  position: inherit;
  bottom: 0px;
}

#dialog-enroll .box-dialog .heads {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

#dialog-enroll .box-dialog h1 {
  margin: 0px !important;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.87);
}
#dialog-enroll .box-dialog p {
  margin: 0px !important;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  color: rgba(0, 0, 0, 0.87);
}

@media (min-width: 1000px) {
  #dialog-enroll {
    align-items: center;
  }
  #dialog-enroll .box-dialog {
    bottom: unset;
    border-radius: 12px;
    max-width: 416px;
  }
}

#article-program {
  position: inherit;  
  overflow-y: none;
}
.close_button {
  display: none;
}
#article-program .img-bkg {
  width: 100%;
  height: 200px;
  background-size: cover;
  margin-bottom: 24px;
}

.bodys p {
  margin-bottom: 20px;;
}

#article-program .bodys img {
  width: 100% !important;
}

.img-bkg.mobile {
  display: none;
}

#article-program .heads .main .item-heads {
  display: flex;
  align-items: center;
  background: #f9a33f;
  border-radius: 6px;
  padding: 3px 6px;
}
#article-program .heads .main .item-heads p {
  margin: 0px !important;
  margin-left: 4px !important;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
}

#article-program .heads .main .item-heads-mains {
  display: flex;
  align-items: center;
  background: #f5f0ff;
  border-radius: 6px;
  padding: 3px 6px;
  margin-left: 10px;
}

#article-program .heads {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
#article-program .heads .main {
  display: flex;
  align-items: center;
}

#article-program .heads .main .item-heads-mains p {
  margin: 0px !important;
  margin-left: 4px !important;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #6c60e0;
}

#article-program h1 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin: 0px !important;
  margin-top: 12px !important;
  margin-bottom: 32px !important;
  color: rgba(0, 0, 0, 0.87);
}

#article-program .author-sec {
  display: none;
  align-items: center;
  margin-bottom: 32px;
}
#article-program .author-sec img {
  height: 48px;
  width: 48px;
  border-radius: 50%;
  margin-right: 16px;
}

#article-program .author-sec h5 {
  margin: 0px !important;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: rgba(0, 0, 0, 0.87);
}

#article-program .author-sec p {
  margin: 0px !important;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.87);
}
#article-program .author-sec .bodys p {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  margin: 0px !important;
  color: rgba(0, 0, 0, 0.87);
}

@media (max-width: 768px) {
  #article-program {
    /* position: fixed; */
    top: 0px;
    left: 0px;
    /* height: 110vh; */
    z-index: 1000;
    /* overflow-y: auto; */
  }
  /* #article-program div{
    position: fixed;
    bottom: 0px;
    height: 100%;
  } */
  .close_button {
    display: block;
  }
  #article-program .author-sec {
    display: block;
  }
  .img-bkg.mobile {
    display: block;
  }
}
.fq_parent {
  margin-bottom: 72px;
}

.pop_right .fq_parent {
  margin-bottom: 0px;
}

.tx_fq_title {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 16px;
}

.tx_fq_desc {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #999999;
}

.txar_fq {
  background: #ffffff;
  border: 1px solid #dedede;
  box-sizing: border-box;
  border-radius: 12px;
  width: 100%;
  padding: 12px 14px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  outline: none;
  resize: none;
  margin-top: 10px;
  height: 140px;
}

.txar_fq:focus {
  border: 1px solid #6c60e0;
}

.txar_fq.mandatory {
  border: 1px solid #EA4335;
}

.wrapper_fq {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.inp_item.mandatory {
  border: solid 1px #EA4335;
}

.fq_select {
  width: 47.5%;
  background: #ffffff;
  border: 1px solid #dedede;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 10px 12px;
  margin-top: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.fq_select.mandatory {
  border: 1px solid #EA4335;
}

.fq_select_active {
  width: 47.5%;
  /* background: #ffffff; */
  background: #F5F0FF;
  border: 1px solid #6c60e0;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 10px 12px;
  margin-top: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.fq_container {
  background: #FFFFFF;
  border-radius: 12px;
  padding: 16px;
  margin-bottom: 16px;
}

.fq_wa_opt {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.fq_wa_opt .active_opt {
  background: #F5F0FF;
  border: 1px solid #6C60E0;
  border-radius: 12px;
}

.fq_opt_btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #DEDEDE;
  border-radius: 12px;
  padding: 10px;
  flex-grow: 1;
}

.fq_btn_label {
  margin-left: 8px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #6C60E0;
}
.parent_fp {
  margin-bottom: 72px;
}
.fp_items {
  margin-bottom: 16px;
}

.fp_items .tx_label {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.87);
}

.inp_item {
  background: #ffffff;
  border: 1px solid #dedede;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 12px 14px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  outline: none;
  width: 100%;
  margin-top: 8px;
}

.fp_alert {
  display: flex;
  align-items: center;
  background: #f5f0ff;
  border-radius: 6px;
  margin-top: 10px;
  padding: 7px 12px;
}
.fp_alert p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  margin-left: 6px;
  color: rgba(0, 0, 0, 0.87);
}

.fp_gender {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
}
.fp_gender_item_active {
  width: 47.5%;
  background: #f5f0ff;
  padding: 11px 12px;
  border: 1px solid #6c60e0;
  box-sizing: border-box;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.fp_gender_item_active div {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 5px solid #6c60e0;
}
.fp_gender_item_active p {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin-left: 8px;
  color: #6c60e0;
}
.fp_gender_item {
  width: 47.5%;
  background: #ffffff;
  border: 1px solid #dedede;
  padding: 11px 12px;
  box-sizing: border-box;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.fp_gender_item.mandatory{
  border: solid 1px #EA4335; 
}
.fp_gender_item div {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 3px solid #dedede;
}
.fp_gender_item p {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin-left: 8px;
  color: #6c60e0;
}
.e_participant {
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 16px 12px;
  margin-top: 16px;
}
.e_participant .head_s {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.e_participant .head_s .part_s {
  display: flex;
  align-items: center;
}

.e_participant .head_s .part_s p {
  margin: 0px !important;
  margin-left: 8px !important;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.87);
}
.e_participant .head_s .quota_s {
  background: #d7efff;
  border-radius: 8px;
  padding: 8px 12px;
}
.e_participant .head_s .quota_s p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #369cf7;
  margin: 0px !important;
}
.e_participant .par_bars {
  background: #dedede;
  border-radius: 3px;
  height: 6px;
  margin-top: 10px;
}

.e_participant .par_bars .active_b {
  background: #369cf7;
  border-radius: 3px;
  height: 6px;
  width: 70%;
}

#audio_player {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 3px 4px 15px rgba(0, 0, 0, 0.05);
    border-top: 1px solid #EBEBEB;
}

.audio_row_styles {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.audio_wrapper {
    height: 100%;
    padding: 12px 98px 12px 132px;
    justify-content: space-between;
}


.audio_info {
    margin-left: 12px;
    width: 304px;
}

.audio_image {
    border-radius: 4px;
    width: 36px;
    height: 36px;
    margin-right: 12px;
}

.audio_titles {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.87);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px;
    margin-bottom: 5px;
}

.audio_description {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #999999;
}

.section_audios_bar {
    width: 100%;
    margin-left: 60px;
    margin-right: 60px;
}

.parent-indicator {
    background: rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    width: 100%;
    height: 3px;
    display: flex;
    cursor: pointer;
    align-items: center;
    margin-right: 10px;
    margin-left: 10px;
}

.indicator {
    background: #ffb800;
    border-radius: 6px;
    width: 20%;
    height: 3px;
    cursor: pointer;
}

.circle {
    background: rgba(0, 0, 0, 0.87);
    width: 7px;
    height: 7px;
    cursor: pointer;
    border-radius: 50%;
}

.text_times_audios {
    margin: unset !important;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #999999;
}


.close_audio_button {
    background: #FFFFFF;
    border: 1px solid #E2E2E2;
    border-radius: 12px;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.close_audio_button img {
    width: 12px;
    height: 12px;
}

@media (max-width:500px) {

    #audio_player {
        border-radius: 12px 12px 0px 0px;
    }

    .audio_wrapper {
        padding: 16px;
    }

    .section_audios_bar {
        margin: 0;
    }

    .parent-indicator {
        position: absolute;
        left: 0;
        bottom: 0;
        margin: 0;
    }


}


@media (min-width:1800px) {
    .audio_wrapper {
        padding: 12px 375px 12px 410px
    }
}
.box-payments {
  background: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 12px;
  width: 100%;
}
.box-payments .product-information {
  padding: 16px;
}
.box-payments h2 {
  margin: 0px !important;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.87);
}
.box-payments .product-information .boxeds {
  background: #ffffff;
  border: 1px solid #dedede;
  border-radius: 12px;
  width: 100%;
  padding: 16px;
  margin-top: 8px;
  display: flex;
  align-items: center;
}
.box-payments .product-information .boxeds div {
  margin-left: 12px;
}
.box-payments .product-information .boxeds img {
  height: 64px;
  width: 64px;
  background: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 12px;
}
.box-payments .product-information p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin: 0px !important;
  color: rgba(0, 0, 0, 0.87);
}
.box-payments .product-information span {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin-top: 6px;
  color: #6c60e0;
}

.box-payments .cta-boxs {
  padding: 16px;
  border-top: 1px solid #eaeaea;
}
.box-payments .cta-boxs button {
  width: 100%;
  border: none;
  background: #6c60e0;
  border-radius: 12px;
  cursor: pointer;
  padding: 12px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
}

.box-payments .periode-selector {
  padding: 0px 16px;
  margin-bottom: 16px;
}
.box-payments .periode-selector .boxeds {
  background: #ffffff;
  border: 1px solid #dedede;
  border-radius: 12px;
  width: 100%;
  padding: 16px;
  margin-top: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
}
.box-payments .periode-selector .boxeds p {
  margin: 0px !important;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.87);
}
.box-payments .periode-selector .boxeds div {
  display: flex;
  align-items: center;
}

.box-payments .periode-selector .boxeds span {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin-right: 12px;
  color: #6c60e0;
}

.box-payments .parent-sheet {
  height: 100vh;
  width: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  top: 0px;
  left: 0px;
  z-index: 100;
}
.box-payments .sheet-periode {
  padding: 16px;
  background: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 12px 12px 0px 0px;
  max-width: 360px;
  width: 100%;
}
.box-payments .sheet-periode .list-periode {
  background: #ffffff;
  border: 1px solid #dedede;
  border-radius: 12px;
  padding: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 10px;
  justify-content: space-between;
}
.box-payments .sheet-periode .list-periode.actived {
  background: #f5f0ff;
  border: 1px solid #6c60e0;
}
.box-payments .sheet-periode .list-periode div {
  background: #ffffff;
  border: 2px solid #999999;
  height: 16px;
  width: 16px;
  border-radius: 50%;
}
.box-payments .sheet-periode .list-periode div.actived {
  border: 4px solid #6c60e0;
}
.box-payments .sheet-periode .list-periode p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin: 0px !important;
  color: rgba(0, 0, 0, 0.87);
}
.box-payments .sheet-periode .ctas {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
}
.box-payments .sheet-periode .ctas button {
  width: 47.5%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  cursor: pointer;
}

.box-payments .sheet-periode .ctas button.closes {
  background-color: #fff;
  border: 1px solid #999999;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.87);
}
.box-payments .sheet-periode .ctas button.actions {
  background: #999999;
  border: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}
.box-payments .payment-selector {
  padding: 0px 16px;
  margin-bottom: 16px;
}
.box-payments .payment-selector .boxeds {
  background: #ffffff;
  border: 1px solid #dedede;
  border-radius: 12px;
  width: 100%;
  padding: 16px;
  margin-top: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
}
.box-payments .payment-selector .boxeds div {
  display: flex;
  align-items: center;
}
.box-payments .payment-selector .boxeds div .img {
  height: 18px;
  margin-right: 10px;
}
.box-payments .payment-selector .boxeds span {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin-right: 12px;
  color: #6c60e0;
}

@media (max-width: 768px) {
  .box-payments {
    border: none;
  }
  .box-payments .product-information {
    padding: 0px;
    margin-bottom: 16px;
  }
  .box-payments .periode-selector {
    padding: 0px;
  }
  .box-payments .payment-selector {
    padding: 0px;
  }
  .box-payments .cta-boxs {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: 1;
    background: #fff;
  }
  .box-payments .sheet-periode {
    max-width: unset;
  }
}

.audios-event {
  position: fixed;
  top: 0px;
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  left: 0px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.parent-aud {
  position: fixed;
  bottom: 0px;
  width: 100%;
  z-index: 1000;
  left: 0px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.parent-aud section {
  background: #ffffff;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 12px 12px 0px 0px;
  max-width: 448px;
  width: 100%;
}
.parent-aud section .content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
}
.parent-aud section .content > img {
  cursor: pointer;
}
.parent-aud section .content .up {
  display: block;
}
.parent-aud section .content > img ~ div {
  width: 65%;
}

.parent-aud section .content > img ~ div h5 {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  margin: 0px !important;
  margin-bottom: 2px !important;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.parent-aud section .content > img ~ div p {
  margin: 0px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: #999999;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.parent-aud section .parent-indicator {
  background: rgba(0, 0, 0, 0.05);
  width: 100%;
  height: 3px;
  border-radius: 6px;
}

.parent-aud section .indicator {
  width: 0%;
  height: 3px;
  background: #ffb800;
  border-radius: 6px;
}

.audios-event section {
  background: #ffffff;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 12px 12px 0px 0px;
  max-width: 448px;
  width: 100%;
  position: inherit;
  bottom: 0px;
}
.audios-event section img {
  cursor: pointer;
}
.audios-event section .head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 12px;
}
.audios-event section .content {
  padding: 0px 16px;
}

.audios-event section .content .one {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.audios-event section .content .one div {
  width: 81%;
}
.audios-event section .content .one div h5 {
  margin: 0px !important;
  margin-bottom: 8px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.87);
  text-overflow: ellipsis;
  display: -webkit-box;
  width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.audios-event section .content .one div p {
  margin: 0px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #999999;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}
.audios-event section .content .one img {
  height: 48px;
}
.audios-event section .content .two {
  margin: 24px 0px;
}
.audios-event section .content .two .parent-indicator {
  background: rgba(0, 0, 0, 0.05);
  width: 100%;
  height: 3px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 6px;
}
.audios-event section .content .two .indicator {
  width: 0%;
  height: 3px;
  background: #ffb800;
  border-radius: 6px;
}

.audios-event section .content .two .circle {
  background: rgba(0, 0, 0, 0.87);
  height: 7px;
  width: 7px;
  border-radius: 50%;
}
.audios-event section .content .two .parent-time {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.audios-event section .content .two .parent-time p {
  margin: 0px !important;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: #999999;
}
.audios-event section .content .three {
  padding-bottom: 24px;
  border-bottom: 1px solid #eaeaea;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.audios-event section .content .three div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 31%;
  background: #ffffff;
  border: 1px solid #999999;
  border-radius: 12px;
}

.audios-event section .content .three div p {
  margin: 0px !important;
  margin-left: 4px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.87);
}

.audios-event section .content .four {
  padding: 24px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.audios-event section .content .four p {
  margin: unset !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.87);
}
.audios-event section .content .four .rate {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  width: 61%;
}
.audios-event section .content .five {
  padding: 24px 0px;
  border-top: 1px solid #eaeaea;
}

.audios-event section .content .five p {
  margin: unset !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}
.audios-event section .content .five textarea {
  width: 100%;
  height: 145px;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  padding: 12px;
  border: 1px solid rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  border-radius: 12px;
  resize: none;
  margin-top: 8px;
}
.audios-event section .content .five button {
  margin-top: 24px;
  background: #7d61d6;
  border-radius: 12px;
  width: 100%;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  border: unset;
}

.parent-aud .loads {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 16px;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 32px;
  height: 32px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  margin: 0px;
  border: 4px solid #7d61d6;
  border-radius: 50%;
  -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #7d61d6 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}
@-webkit-keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.parent-aud section .content .play-desktop {
  display: none;
}
.parent-aud section .content .sectione-twos {
  display: none;
}
.parent-aud section .content .sectione-threes {
  display: none;
}

#reason-box {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
}
#reason-box section {
  width: 360px;
  height: auto;
  padding: 24px;
  padding-bottom: 8px;
  background: #ffffff;
  border-radius: 12px;
}
#reason-box section h4 {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  width: 100%;
  margin: 0px !important;
  color: rgba(0, 0, 0, 0.87);
}
#reason-box section h4.isi {
  text-align: left;
}
#reason-box section .stared img {
  height: 36px;
  margin-left: 6.5px;
}
#reason-box section .stared {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 12px 0px;
  padding-bottom: 16px !important ;
  border-bottom: 1px solid #eaeaea;
}
#reason-box section p {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  margin: 8px 0px !important;
  color: rgba(0, 0, 0, 0.87);
}
#reason-box section textarea {
  width: 100%;
  height: 145px;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  padding: 12px;
  border: 1px solid rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  border-radius: 12px;
  resize: none;
  margin-top: 8px;
}
#reason-box section .ctas {
  display: block;
  width: 100%;
  margin-top: 24px;
}
#reason-box section .ctas button {
  width: 100%;
  height: 41px;
  margin-bottom: 16px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#reason-box section .ctas button p {
  margin: 0px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}
#reason-box section .ctas .send {
  border: none;
  background: #7d61d6;
}

#reason-box section .ctas .send p {
  color: #fff;
}
#reason-box section .ctas .closes {
  border: 1px solid #000;
  background: none;
}
#reason-box section .ctas .closes p {
  color: #000;
}

.popup-dekstops {
  display: none;
}
@media (max-width: 500px) {
  #reason-box {
    padding: 0px;
    align-items: flex-end;
  }
  #reason-box section {
    padding: 16px;
    border-radius: 12px 12px 0px 0px;
  }
}

.audios-event section .head .minutesec {
  display: flex;
  align-items: center;
  background: #f9a33f;
  border-radius: 6px;
  padding: 3px 6px;
}
.audios-event section .head .minutesec p {
  margin: 0px !important;
  margin-left: 4px !important;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
}
@media (min-width: 1000px) {
  .popup-dekstops {
    display: block !important;
  }
  .parent-aud section {
    max-width: 1170px;
  }
  .parent-aud section .content {
    padding: 0px;
  }
  .parent-aud section .content .up {
    display: none;
  }
  .parent-aud section .content .play-mobiles {
    display: none;
  }
  .parent-aud section .content .play-desktop {
    display: block;
    height: 48px;
    width: 48px;
    margin-right: 16px;
  }
  .parent-aud section .content .close-im {
    display: none;
  }
  .parent-aud section .content .sectione-ones {
    width: 30.7%;
    display: flex;
    align-items: center;
    border-right: 1px solid #eaeaea;
    padding: 20px 16px;
    height: 114px;
  }
  .parent-aud section .content .sectione-ones.simple {
    border-right: none;
  }
  .parent-aud section .content > img ~ div h5 {
    white-space: normal;
    font-size: 16px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .parent-aud section .content .sectione-twos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #eaeaea;
    height: 114px;
    width: 41.3%;
    padding: 0px 32px;
  }
  .parent-aud section .content .sectione-twos.simple {
    width: 59.3%;
    border-right: none;
  }
  .parent-aud section .content .sectione-twos .two {
    width: 100%;
  }
  .parent-aud .content ~ .parent-indicator {
    display: none;
  }
  .parent-aud section .content .three {
    padding-bottom: 12px;
    border-bottom: 1px solid #eaeaea;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .parent-aud section .content .three div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 31%;
    background: #ffffff;
    border: 1px solid #999999;
    border-radius: 12px;
  }

  .parent-aud section .content .three div p {
    margin: 0px !important;
    margin-left: 4px !important;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.87);
  }
  .parent-aud section .content .two .parent-time {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .parent-aud section .content .two .parent-time p {
    margin: 0px !important;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #999999;
  }
  .parent-aud section .content .two .parent-indicator {
    background: rgba(0, 0, 0, 0.05);
    width: 100%;
    height: 3px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 6px;
  }
  .parent-aud section .content .two .indicator {
    width: 0%;
    height: 3px;
    background: #ffb800;
    border-radius: 6px;
  }

  .parent-aud section .content .two .circle {
    background: rgba(0, 0, 0, 0.87);
    height: 7px;
    width: 7px;
    border-radius: 50%;
  }
  .parent-aud section .content .sectione-threes {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 28%;
    height: 114px;
  }
  .parent-aud section .content .sectione-threes.simple {
    width: 10%;
    border-right: none;
  }
  .parent-aud section .content .four {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .parent-aud section .content .four p {
    margin: unset !important;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.87);
    text-align: center;
  }
  .parent-aud section .content .four .rate {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    width: 90%;
    cursor: pointer;
  }
  .parent-aud section .content .four .rate img {
    height: 36px;
  }
}

.itemcontainer-wrapper {
  display: none;
}

.section-label {
  position: relative;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
}

.banner-subscription {
  display: block;
}

.banner-subscription-mob {
  display: none;
}

.banner-mobile {
  display: none;
  padding-top: 24px;
}

.ketik-pertanyaan-wrapper {
  margin-top: 36px;
}

.ketik-pertanyaan-wrapper .title {
  font-family: "Rubik";
  font-size: 24px;
  font-weight: 500;
  padding-bottom: 0px;
}

.ketik-pertanyaan-wrapper .title .col-md-12 {
  padding: 0px;
}

.pertanyaan-form {
  font-family: "Rubik";
  font-size: 18px;
  font-weight: 500;
  line-height: 21.33px;
  margin-top: 16px;
}

.pertanyaan-form .left-side {
  padding: 0px;
}

.pertanyaan-form .right-side {
  padding: 0px;
  text-align: right;
}

.right-side .button-desktop,
.button-mobile {
  background: linear-gradient(180deg, #ff3f00 0%, #ff8057 100%);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 12px;
  padding: 18px 54px;
}

.button-mobile {
  display: none;
}

.pertanyaan-form .left-side .input-text {
  padding: 18px 24px;
  border: solid 1px #dedede;
  border-radius: 12px;
  width: 100%;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
}

#additional-banner-section {
  max-width: 1170px;
  width: 100%;
  margin: 24px auto;
  height: 80px;
  padding: 0;
}

#additional-banner-section-desktop {
  display: block;
}

#additional-banner-section-mobile {
  display: none;
}



@media (max-width: 768px) {
  #additional-banner-section {
    height: auto;
    padding: 0 16px;
  }

  #additional-banner-section-desktop {
    display: none;
  }

  #additional-banner-section-mobile {
    display: block;
  }

  .banner-mobile {
    display: block;
  }

  .home-head-m {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .home-head-m .one-head-m {
    width: 55%;
  }

  .one-head-m img {
    width: 75.77px;
    margin-bottom: 12px;
  }

  .one-head-m h1 {
    font-family: Fredoka One !important;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    margin: unset !important;
    display: flex;
    flex-direction: column;
    color: rgba(0, 0, 0, 0.87);
  }

  .one-head-m p {
    font-size: 10px !important;
    line-height: 12px !important;
    margin-top: 10px !important;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
  }

  .one-head-m h1 .span-home-h {
    color: #7d61d6;
  }

  .home-head-m .two-head-m {
    background: url("/assets/images/home-header-pict-m.png") right no-repeat;
    width: 40%;
    display: flex;
    align-items: center;
    background-size: contain;
  }

  .ketik-pertanyaan-wrapper {
    margin-top: 24px;
  }

  .ketik-pertanyaan-wrapper .title {
    font-size: 16px;
  }

  .ketik-pertanyaan-wrapper .title .col-md-12 {
    padding: 0px 8px;
  }

  .pertanyaan-form {
    font-size: 14px;
    line-height: 17px;
    margin-top: 10px;
  }

  .pertanyaan-form .left-side,
  .pertanyaan-form .right-side {
    padding: 0px 8px;
  }

  .button-mobile {
    display: block;
    padding: 13px 0;
    margin: 0 auto;
    font-size: 14px;
    width: 100%;
  }

  .button-desktop {
    display: none;
  }

  .pertanyaan-form .left-side .input-text {
    padding: 13px 24px;
    color: #999999;
  }

  .banner-subscription {
    display: none;
  }

  .banner-subscription-mob {
    display: block;
    padding: 0px 16px;
    margin: 8px 0px;
  }

  #parent-video-container .parent-video-container {
    margin: 0px !important;
    padding: 0px 16px !important;
  }

  #original-series img {
    margin-right: 16px !important;
  }
}

#parent-video-container .parent-video-container {
  background: #fdfbff !important;
  margin: 48px 0px;
  padding: 0px;
}

.semua {
  display: none;
}

#mitrakami .itemcontainer {
  height: 340px !important;
  padding: 20px 24px 20px;
}

.item .itemcontainer .mitracarousel {
  height: 200px !important;
}

.contentringkasan a.lihat-selengkapnya,
.contentringkasan a.lihat-selengkapnya:hover {
  font-family: Rubik;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  float: right;
  color: #7d61d6;
  margin-right: 40px;
}

.home-berkenalan {
  font-family: "Rubik";
  font-size: 32px;
  font-weight: 500;
  color: #7d61d6;
}

.home-berkenalan-adalah,
.home-mari-gabung {
  font-family: "Lato";
  font-size: 18px;
  margin-top: 14px;
  line-height: 21.6px;
}

.home-mari-gabung {
  max-width: 625px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.home-berkenalan-video {
  margin-top: 34px;
  border-radius: 12px;
  overflow: hidden;
  height: 396px;
}

.home-mitra-kami {
  font-family: "Rubik";
  font-size: 32px;
  font-weight: 500;
  color: #7d61d6;
  text-align: center;
  margin: 36px 0 34px 0;
}

.home-mitra-kami h5 {
  font-family: "Rubik";
  font-weight: 700;
}

.home-mari h3 {
  font-family: "Rubik";
  font-size: 32px;
  font-weight: 500;
}

.home-kata-mereka,
.home-mari-kerja h3,
#faq-home .alert {
  font-family: "Rubik";
  font-size: 32px;
  font-weight: 500;
  color: #7d61d6;
}

#home-fammi-introduction {
  position: relative;
}

#home-fammi-introduction .the-bg {
  background: #f9f4ff;
  height: 504px;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: -11;
}

#home-fammi-introduction .container {
  z-index: 2;
}

#home-fammi-introduction .bigitem,
#home-fammi-introduction p {
  text-align: left;
  padding: 0px;
}

#faq-home {
  margin-top: 48px;
}

#faq-home .aleft {
  margin-top: 0px;
}

#home-container #fammi-bannermenu {
  margin-top: 20px;
}

#footer .companydetail p {
  margin-bottom: 13px;
  margin-top: 32px;
}

.arrow-more {
  margin: 40px 0 10px 0;
}

.item .itemcontainer {
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;
  margin: 0 10px;
  padding: 0;
  text-align: center;
  height: 250px;
}

.item .itemcontainer h4 {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 18px;
  line-height: 20px;
  min-height: 38px;
  text-align: center;
  color: #333;
  margin: 14px 16px;
}

@media only screen and (max-width: 450px) {
  .ketik-pertanyaan-wrapper .title .col-md-12 {
    padding: 0px 16px;
  }

  .pertanyaan-form .left-side {
    padding: 0px 0px 0px 16px;
    padding-right: 16px;
  }

  .pertanyaan-form .right-side {
    padding: 0px 16px 0px 0px;
  }

  .section-label {
    display: none;
  }

  .home-berkenalan {
    font-size: 24px;
  }

  .home-berkenalan-video {
    height: 183px;
  }

  .home-mitra-kami,
  .home-mari h3,
  .home-kata-mereka,
  .home-mari-kerja h3,
  #faq-home .homesection h3 {
    font-size: 24px;
    line-height: 28.4px;
  }

  #home-fammi-intoduction .the-bg {
    height: 805px;
  }

  #fammi-intoduction iframe {
    height: 100%;
  }

  #home-fammi-introduction .item.bigitem {
    margin-top: 24px;
    height: 185px;
  }

  .itemcontainer-wrapper {
    display: block;
  }

  .arrow-more {
    margin-top: -10px;
  }

  .fammibutton2 {
    display: none;
  }

  .title-section {
    display: flex;
    font-family: Rubik !important;
    font-style: normal !important;
    font-size: 14px !important;
    font-weight: 500 !important;
  }

  .contentringkasan a.lihat-selengkapnya,
  .contentringkasan a.lihat-selengkapnya:hover {
    visibility: hidden;
  }

  .containeringkasan a.lihat-selengkapnya,
  .contentringkasan a.lihat-selengkapnya:hover ::after {
    content: "Semua";
    visibility: visible;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    float: right;
    color: #7d61d6;
    margin-right: 24px;
    margin-top: -29px;
  }

  .semua {
    font-family: Rubik !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 17px !important;
    text-align: right !important;
    color: #7d61d6 !important;
    display: block;
  }
}

/* New Css */

.title-section {
  font-family: Rubik !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 24px !important;
  line-height: 28px !important;
  margin: unset;
  text-transform: capitalize;
  width: 100px !important;
}

@media (max-width: 450px) {
  .semua {
    display: none;
  }
}

@media (max-width: 992px) {
  .title-section {
    font-size: 14px !important;
  }

  .semua {
    display: none;
  }

  .wrapper-heads-cat {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    padding: 0px 24px;
    width: 100%;
    margin-top: 24px;
  }

  .title-heads-cat {
    font-family: Rubik !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 17px !important;
    margin: unset !important;
    color: rgba(0, 0, 0, 0.87) !important;
    text-transform: capitalize !important;
  }

  .button-heads-cat {
    font-family: Rubik !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 17px !important;
    text-align: right !important;
    color: #7d61d6 !important;
  }

  .itemcontainer-wrapper {
    display: none;
  }

  .fammibutton2 {
    display: none;
  }

  .section-label {
    display: none;
  }

  .contentringkasan {
    padding: 4px;
    padding-top: 0;
  }

  .MultiCarousel {
    padding: 4px;
  }

  .contentringkasan a.lihat-selengkapnya,
  .contentringkasan a.lihat-selengkapnya:hover {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    /* line-height: 19px; */
    float: right;
    color: #7d61d6;
    margin-right: 24px;
    margin-top: -29px;
  }

  .div-section {
    margin-top: 0;
  }

  #fammi-ringkasan {
    padding: 0;
  }
}

.wrapper-heads-cat {
  display: none;
}

.title-div {
  height: 182px;
  /* width: 180px !important; */
  padding-left: 24px;
  margin-top: 45px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title-div .fammibutton2 {
  width: 173px !important;
}

.div-new {
  padding: unset !important;
}

@media (max-width: 1200px) {
  .title-div {
    height: 190px;
  }
}

@media (max-width: 992px) {
  .title-div {
    display: none;
  }

  .div-new {
    display: none;
  }

  .lihat-selengkapnya {
    display: none;
  }
}

.lihat-selengkapnya {
  z-index: 980 !important;
  position: relative !important;
}

@media (max-width: 768px) {
  .contentringkasan {
    margin-top: 0px !important;
  }
}

.originalContain {
  height: 188px;
}

.itemContainer {
  box-shadow: 1px 1px 15px rgb(0 0 0 / 10%);
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;
  padding: 0;
}

@media (max-width: 992px) {
  .itemContainer {
    height: 214.688px !important;
  }

  .button-heads-cat {
    display: none;
  }
}

.item .itemcontainer {
  height: 200px !important;
}

@media (max-width: 450px) {

  /* .itemContainer {
    height: 205.688px !important;
  } */
  .button-heads-cat {
    display: block;
  }
}

.look-more {
  display: none;
}

@media (max-width: 992px) {
  .look-more {
    display: block;
    font-family: Rubik !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 17px !important;
    text-align: right !important;
    color: #7d61d6 !important;
  }
}

@media (max-width: 450px) {
  .look-more {
    display: none;
  }

  .mob-list {
    display: block !important;
    margin-bottom: 60px;
  }

  #fammi-bannermenu .test-dot {
    display: block !important;
  }
}

.fammi-homecontainer .row h4 {
  font-weight: 700px;
}

.fammibutton2 {
  text-align: center !important;
}

@media (max-width: 992px) {
  #fammi-bannermenu .carousel-indicators {
    display: flex !important;
    justify-content: center;
    width: 100% !important;
    margin: unset !important;
    left: 0% !important;
  }
}

.mob-list {
  display: none;
}

@media (min-width: 500px) {
  .mob-division {
    margin-top: -15px;
  }

  .mob-list {
    display: none;
  }

  #fammi-bannermenu .carousel-indicators {
    display: none !important;
  }
}

@media (min-width: 360px) {
  .item .itemcontainer {
    height: auto !important;
  }
}
#testimoni-subscription {
  margin-top: 36px !important;
}
.container-subscription {
  margin: auto;
  max-width: 1170px;
  width: 100%;
}
.head-testimoni-subs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.wrapper-testimoni-subs {
  display: flex;
  align-items: center;
  margin-top: 22px;
  overflow-x: hidden;
  flex-wrap: nowrap;
  -webkit-overflow-scrolling: touch;
}
.wrapper-testimoni-subs::-webkit-scrollbar {
  display: none;
}
.text-title-testimoni-subs {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  margin: unset !important;
  color: rgba(0, 0, 0, 0.87);
}
.parent-button-testimoni-subs {
  display: flex;
  align-items: center;
}
.btn-arrow-testimoni {
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  height: 34px;
  width: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
  border-radius: 50%;
}
.parent-card-testimoni-subs {
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 12px;
  display: flex;
  width: 374px;
  height: 157px;
  flex: 0 0 auto;
  margin-right: 24px;
}
.img-testimoni-subs {
  object-fit: cover;
  width: 121px;
  border-radius: 12px 0px 0px 12px;
}
.wraps-about-tes {
  display: block;
}
.wraps-about-tes-mob {
  display: none;
}
.section-testimoni-subs {
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.text-name-testimoni-subs {
  margin: unset !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.87);
}
.text-about-testimoni-subs {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin: unset !important;
  color: #999999;
  margin-top: 4px !important;
}
.text-content-testimoni-subs {
  margin: unset !important;
  font-family: Lato;
  font-style: italic;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.87);
}
.dummy-box-tes {
  display: none;
}
.wrapper-content-testimoni-subs {
  margin-top: 12px;
}
.link-testimoni-subs {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  /* margin-top: 8px; */
  color: #7d61d6;
  cursor: pointer;
}
.link-testimoni-subs:focus {
  color: #7d61d6;
}
.link-testimoni-subs:hover {
  color: #7d61d6;
}
.text-tes-subs-desktop {
  display: block;
}
.text-tes-subs-mobile {
  display: none;
}
/* Video Section */
.parent-video-container {
  margin-top: 48px;
  background: #f5f0ff;
  padding: 48px 0px;
}
.parent-content-video {
  display: flex;
  align-items: center;
  width: 100%;
}
.section-one-video {
  width: 35%;
}
.section-separator-video {
  width: 15%;
}
.section-two-video {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.text-title-video-subs {
  margin-bottom: 12px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.87);
  width: 75%;
  text-align: left;
  white-space: nowrap;
}
.text-description-video-subs {
  margin: unset !important;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.87);
  margin-top: 12px !important;
}
.video-subs {
  border-radius: 12px;
}

@media (max-width: 768px) {
  .container-subscription {
    margin: unset;
    max-width: 100%;
  }
  .text-title-testimoni-subs {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.87);
  }
  .dummy-box-tes {
    flex: 0 0 auto;
    width: 5px;
    height: 16px;
    display: block;
  }
  .head-testimoni-subs {
    padding: 16px;
  }
  .parent-button-testimoni-subs {
    display: none;
  }
  #testimoni-subscription {
    margin-top: 24px !important;
  }
  .wrapper-testimoni-subs {
    padding: 16px;
    padding-right: 16px;
    margin-top: 0px;
    padding-top: 0px;
    overflow-x: auto;
  }
  .parent-card-testimoni-subs {
    width: 304px;
    margin-right: 16px;
  }
  .wraps-about-tes {
    display: none;
  }
  .wraps-about-tes-mob {
    display: block;
  }
  .text-tes-subs-mobile {
    display: block;
  }
  .text-tes-subs-desktop {
    display: none;
  }
  /* Video Section */
  .parent-video-container {
    margin-top: 36px;
    padding: 24px 16px;
  }
  .parent-content-video {
    display: block;
    margin-bottom: 0px !important;
  }
  .section-one-video {
    width: 100%;
  }
  .section-separator-video {
    display: none;
  }
  .section-two-video {
    width: 100%;
    margin-top: 16px;
    justify-content: center;
  }
  .text-title-video-subs {
    width: 100%;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.87);
  }
  .text-description-video-subs {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    margin-top: 10px !important;
    color: rgba(0, 0, 0, 0.87);
  }
  .video-subs {
    height: 185px;
  }
}

#footer-container #footer .companydetail p {
  margin-bottom: 13px;
  margin-top:22px;
}

#footer-container .footerlink ul li {
	line-height: 0px;
}

#footer-container .footerlink ul li {
	margin-bottom: 29px;
}
#footer-container .footerlink ul.footersocial li {
	margin-bottom: 22px;
}
#footer-container .footerlink ul li:last-child {
	margin-bottom: 0px;
}

#footer-container h4 {
	margin-bottom: 0px;
	margin-top:0px;
}
#footer-container ul.footerhubungi li {
	margin-bottom: 24px;
}
#footer-container ul.download li {
	margin-bottom: 14px;
}
#footer-container .footerlink h4 {
	margin-bottom: 24px;
}

#footer-container  .footerlink .footersocial h4 {
	margin-bottom: 20px;
}

@media only screen and (max-width : 430px) {
	.footerlink ul {
		margin-top:20px;
	}

	.download {
		margin-bottom: 70px
	}
}
#cart-window .window {
  height: auto;
  min-height: 0;
  min-height: initial;
}
.fammibuttonalt.teangan-eta-kelas-sina-meuli {
  margin-bottom: 0px;
}
#menu-search.rek-kelaskonsultasi .item {
  display: inline-block;
}
.rek-kelaskonsultasi .item a {
  height: 100%;
}

.menu-content-utama ul h4 {
  margin-bottom: 24px !important;
}
#kategori-hover {
  font-size: 14px;
}
.menu-content-utama .sidebar:nth-child(3) > .sidebar:nth-child(2) ul {
  /* column-count: 2; */
  height: 620px !important;
  -webkit-column-fill: auto;
          column-fill: auto;
}
.menu-content-utama
  .sidebar:nth-child(3)
  > .sidebar:nth-child(2)
  ul
  li:nth-child(14) {
  padding-top: 63px;
}
#menu-header .menu-kategori p:hover ~ #menu-content,
#menu-header .menu-kategori #menu-content:hover {
  max-height: 803px !important;
  z-index: 99;
}

.close-cat {
  position: absolute;
  margin-top: 10px;
  right: 5%;
}

.close-menus {
  position: absolute;
  top: 32px;
  right: 16px;
  cursor: pointer;
}

.sidebar ul li {
  list-style: none;
  padding: 0;
  margin: 0;
  white-space: nowrap !important;
}
.title {
  padding-bottom: 14px;
}

.sidebar ul li {
  margin-bottom: 24px;
}

#menu-header .menu-kategori p:hover ~ #menu-content,
#menu-header .menu-kategori #menu-content:hover {
  max-height: 803px;
  z-index: 99;
}

.menu-content-utama ul h4 {
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 24px;
}

.menu-content-utama .sidebar:nth-child(3) > .sidebar:nth-child(2) ul {
  -webkit-column-count: 2;
          column-count: 2;
  height: 625px;
  -webkit-column-fill: auto;
          column-fill: auto;
}

.menu-content-utama
  .sidebar:nth-child(3)
  > .sidebar:nth-child(2)
  ul
  li:nth-child(14) {
  padding-top: 63px;
}

@media only screen and (max-width: 400px) {
  #inputcode-window .window,
  #cart-window .window {
    border-radius: 12px 12px 0 0;
  }
  .rek-kelaskonsultasi .item a {
    height: 74px;
  }
  .sidebar h4 {
    font-family: Rubik;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 24px;
  }
  .menu-content-utama ul h4 {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 24px !important;
  }
  .close-menu {
    width: 12px !important;
    height: 12px !important;
  }
}

.button-kerja-sama {
  color: #000000 !important;
  border-radius: 12px;
  font-family: Rubik;
  font-weight: 500;
  font-size: 14px;
  line-height: 38px;
  height: 40px;
  border: 1px solid #000;
  text-align: center;
  width: 130px;
  display: block;
  margin: 0px 16px !important;
  margin-right: 0px !important;
  margin-bottom: 24px !important;
}

.menu-content-utama ul li {
  font-size: 16px;
  line-height: 24px;
  color: #585858;
  text-decoration: none;
  list-style: none;
  margin-bottom: 24px;
}
.fitur-login ul {
  margin-left: -41px;
}
.fitur-login a {
  color: #000000;
}
@media (max-width: 1200px) and (min-width: 992px) {
  #menu-search {
    width: 39.8% !important;
    margin-left: 16px !important;
  }

  #menu-search.loggedin.member-search {
    width: 61% !important;
  }

  #header-menu-button .menu-button .fammibuttonalt {
    width: 91px !important;
    margin: 0px !important;
    margin-left: 16px !important;
  }

  #menu-kerjasama {
    display: block !important;
  }

  .menu-content-utama ul h4 {
    margin-bottom: 24px !important;
  }
  .close-menu {
    width: 12px !important;
    height: 12px !important;
  }
}

@media (max-width: 450px) {
  .menu-content-utama .sidebar:nth-child(3) > .sidebar:nth-child(2) ul {
    height: 650px !important;
    -webkit-column-count: unset;
            column-count: unset;
  }
  .menu-content-utama
    .sidebar:nth-child(3)
    > .sidebar:nth-child(2)
    ul
    li:nth-child(14) {
    padding-top: 0;
  }
  .sidebar ul li a {
    padding-bottom: 24px;
  }
  #profile-user p {
    font-size: 16px;
    font-weight: 400;
  }
}

@media (min-width: 1430px) {
  #menu-search {
    width: 580px !important;
  }
  #menu-search.loggedin {
    width: 580px !important;
  }

  .container {
    width: 1170px !important;
  }
}

#search-recomendation {
  width: 535px !important;
}
@media (max-width: 992px) {
  #search-recomendation {
    width: 100% !important;
  }
  #mobile-kerjasama {
    border-bottom: 1px solid #dedede;
    padding-bottom: 10px;
    margin-bottom: 15px;
    width: 232px !important;
  }
}
@media (max-width: 1198px) and (min-width: 992px) {
  #search-recomendation {
    width: 302px !important;
  }
}

.header-closed {
  display: none;
}

@media (max-width: 992px) {
  .header-closed {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: -5px;
  }
  .menu-content-utama .sidebar:nth-child(3) > .sidebar:nth-child(2) ul {
    -webkit-column-count: unset;
            column-count: unset;
  }
  .sidebar ul li a {
    padding-bottom: 24px;
  }
  .menu-content-utama
    .sidebar:nth-child(3)
    > .sidebar:nth-child(2)
    ul
    li:nth-child(14) {
    padding-top: 0;
  }
}

@media (max-width: 768px) {
  #fammi-bannermenu .row .item {
    width: 50% !important;
  }
  .close-menus {
    display: none;
  }
}

@media (max-width: 450px) {
  #fammi-bannermenu .row .item {
    width: 100% !important;
  }
}

@media (max-width: 768px) {
  .inactiveblock {
    display: none !important;
  }

  .inactiveblock-mob {
    background: #000;
    opacity: 0;
    z-index: -10;
    transition: opacity 0.2s ease-out;
    position: absolute;
    height: 1px;
    width: 3000px;
    left: 0px;
    display: block !important;
  }
  #profile-user {
    display: none;
  }
  #profile-user-mob {
    display: flex !important;
    align-items: center;
    margin-bottom: 32px;
  }
  #profile-user-mob img {
    border-radius: 50%;
    margin-right: 10px;
  }
  #work-btn {
    display: none !important;
  }
  #work-btn-mob {
    display: block !important;
  }
  #section-auth-btn {
    display: none !important;
  }
  #section-auth-btn-mob {
    display: flex !important;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
}
#section-auth-btn {
  display: flex;
  align-items: center;
}
#section-auth-btn-mob {
  display: none;
}

#work-btn-mob {
  display: none;
}
#profile-user-mob {
  display: none;
}
.inactiveblock-mob {
  display: none;
}
.inactiveblock {
  background: #000;
  opacity: 0;
  z-index: -10;
  transition: opacity 0.2s ease-out;
  position: absolute;
  height: 2000px;
  width: 3000px;
  left: 0px;
}
#menu-cart {
  border-left: 1px solid #888 !important;
  margin-left: 20px;
}
@media (max-width: 992px) {
  #menu-search .search-container input {
    right: 16px;
  }
  #menu-cart {
    border-left: unset !important;
  }
  .header-closed {
    background: #ffffff;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
    padding: 13px 24px;
  }
  #burger-menu-search {
    right: 16px;
  }
  #mobile-button-content {
    width: 100%;
    margin: unset;
    padding: 0px;
  }
  .text-credits {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    margin: unset !important;
    color: #ff784c;
    margin-top: 6px !important;
  }
  .menu-content-utama.grey-border {
    margin: unset !important;
    margin-top: -4px !important;
  }
  #menu-header .menu-kategori .burger-checkbox:checked ~ #menu-content {
    width: 100%;
    left: 0px;
  }
  .pad-sides {
    padding: 0px 24px;
  }
  #mobile-kerjasama {
    width: 100%;
    border-bottom: unset;
  }
  .border-sides {
    width: 100%;
    height: 1px;
    background-color: #dedede;
    margin: 16px 0px;
  }
  #section-auth-btn-mob .menu-button {
    width: 47.5%;
  }
  #section-auth-btn-mob .menu-button .fammibuttonalt {
    width: 100% !important;
  }
}
#menu-header .row > div {
  margin-left: unset !important;
}
.menu-search-container:focus-within input {
  border: 1px solid #f4f4f4;
}
.menu-search-container:focus-within input {
  border: 1px solid #7d61d6;
}

.menu-search-container:focus-within ~ .inactiveblock {
  display: block !important;
}
@media (max-width: 500px) {
  .menu-search-container:focus-within input {
    border: unset;
  }
  .menu-search-container:focus-within input {
    border: unset;
  }

  .menu-search-container:focus-within ~ .inactiveblock {
    display: none !important;
  }
}
.separator-notlogin {
  width: 1px;
  height: 40px;
  background-color: #dedede;
  float: left;
  margin-left: 24px;
  margin-right: 8px;
}
.button-kerja-sama.notlogin {
  width: 180px !important;
}
@media (max-width: 500px) {
  .separator-notlogin {
    display: none;
  }
  .menu-search-container {
    padding-left: 24px !important;
  }
  #section-credits.menu-button {
    display: none !important;
  }
}

.right-24 {
  right: 24px !important;
}

#burger-menu-search.righted-24 {
  right: 24px !important;
}
@media (min-width: 1200px) {
  #menu-search.loggedin {
    width: 580px !important;
    margin-left: 16px !important;
  }
  #menu-search.loggedin.member-search {
    width: 61% !important;
  }
  #menu-search {
    width: 50% !important;
  }
  #header-menu-button .menu-button .fammibuttonalt {
    width: 91px !important;
    margin: 0px !important;
    margin-left: 16px !important;
  }
  #header-menu-button {
    margin-right: 0px !important;
  }
  .menu-search-container {
    margin-left: 0px !important;
  }
}
#section-credits.menu-button {
  padding-left: 16px;
  border-left: 1px solid #dedede;
  border-right: 1px solid #dedede;
  margin-left: 16px;
  padding-right: 16px;
  display: flex;
  height: 80%;
  align-items: center;
}
#section-credits.menu-button .asking {
  margin-right: 16px;
  display: flex;
  align-items: center;
}
#section-credits.menu-button .asking span {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin-left: 4px;
  color: #ffffff;
  justify-content: center;
  background: #7d61d6;
}

#section-credits.menu-button .hearing span {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin-left: 4px;
  color: #ffffff;
  justify-content: center;
  background: #fd8c25;
}
#section-credits.menu-button .hearing {
  display: flex;
  align-items: center;
}
#section-credits.menu-button button {
  background: #ff784c;
  border-radius: 20.5px;
  padding: 9px 12px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  border: unset !important;
}

#profile-submenu {
  width: 305px !important;
  right: 0px !important;
}
#profile-submenu.sub-profs {
  display: flex;
}
#profile-submenu.sub-profs h1 {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin: unset !important;
  color: rgba(0, 0, 0, 0.87);
}

#profile-submenu.sub-profs .subs-member {
  width: 214px;
  padding: 16px;
  padding-right: 15px;
  border-right: 1px solid #dedede;
}
#profile-submenu.sub-profs .subs-menus {
  width: 100%;
}
#profile-submenu.sub-profs .subs-menus li a {
  padding: 5px 16px !important;
}
#profile-submenu.sub-profs .subs-menus h1 {
  margin-left: 16px !important;
}
#profile-submenu.sub-profs .subs-member .menuz {
  display: flex;
  align-items: center;
  line-height: 15px;
  cursor: pointer;
  margin-top: 14px;
}

#profile-submenu.emp-tent {
  display: flex;
}
#profile-submenu.emp-tent h1 {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin: unset !important;
  color: rgba(0, 0, 0, 0.87);
}

#profile-submenu.emp-tent .subs-member {
  width: 214px;
  padding: 16px;
  padding-right: 15px;
  border-right: 1px solid #dedede;
}
#profile-submenu.emp-tent .subs-menus {
  width: 100%;
  padding: 16px 0px;
}
#profile-submenu.emp-tent .subs-menus li a {
  padding: 5px 16px !important;
}
#profile-submenu.emp-tent .subs-menus h1 {
  margin-left: 16px !important;
}
#profile-submenu.emp-tent .subs-member .menuz {
  display: flex;
  align-items: center;
  line-height: 15px;
  cursor: pointer;
  margin-top: 14px;
}

#profile-submenu.sub-profs .subs-member .menuz img {
  height: 37px;
  width: 37px;
  object-fit: cover;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 6px;
  margin-right: 8px;
}
#profile-submenu.sub-profs .subs-member .menuz p {
  margin: unset !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  height: unset !important;
  color: rgba(0, 0, 0, 0.87);
  max-width: 150px;
  text-overflow: ellipsis;
}
#profile-submenu.sub-profs .subs-member .menuz span {
  margin: unset !important;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.87);
}

#profile-user {
  margin-left: 16px !important;
  width: auto !important;
}
#profile-user #prof-parent {
  display: flex;
  align-items: center;
}
#profile-user #prof-parent .d {
  margin-left: 14px;
}
#profile-user #prof-parent .has-sub {
  margin: 0px !important;
  height: auto !important;
}
#profile-user #prof-parent:hover ~ #profile-submenu,
#profile-submenu:hover {
  width: 305px !important;
  right: 0px !important;
  max-height: 540px !important;
}
#profile-user #prof-parent:hover ~ .inactiveblock,
#profile-submenu:hover ~ .inactiveblock {
  display: block;
  opacity: 0.7;
  left: -2063px;
  top: 51px;
}

#profile-submenu .named {
  display: flex;
  align-items: center;
  width: 100%;
  background: unset !important;
  border: unset;
  border-bottom: 1px solid #dedede;
  padding: 16px;
}
#profile-submenu .named div {
  display: flex;
  flex-direction: column;
  text-align: left;
}
#profile-submenu .named div p {
  margin: 0px !important;
  height: auto !important;
  line-height: 14px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.87);
}
#profile-submenu .named div span {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
}
#profile-submenu .menu-1 {
  padding: 16px;
  padding-bottom: 0px;
  border-bottom: 1px solid #dedede;
}
#profile-submenu .menu-1 .btn {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0px !important;
  background: unset !important;
  border: unset;
  margin-bottom: 16px;
}
#profile-submenu .menu-1 .btn div {
  display: block;
  text-align: left;
}
#profile-submenu .menu-1 .btn div p {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin: 0px !important;
  height: auto !important;
  color: rgba(0, 0, 0, 0.87);
}
#profile-submenu .menu-1 .btn div span {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
}
#profile-submenu .menu-1 .btn div span a {
  cursor: pointer;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin-left: 8px;
  padding: 0px !important;
  color: #7d61d6;
}
#profile-submenu .menu-2 {
  padding: 16px;
  padding-bottom: 8px;
  border-bottom: 1px solid #dedede;
}
#profile-submenu .menu-2 img {
  margin-right: 20px;
}
#profile-submenu .menu-2 .btn div {
  display: block;
  text-align: left;
}
#profile-submenu .menu-2 .btn div p {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin: 0px !important;
  height: auto !important;
  color: rgba(0, 0, 0, 0.87);
}
#profile-submenu .menu-2 .btn {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0px !important;
  background: unset !important;
  border: unset;
  margin-bottom: 16px;
}
#profile-submenu .menu-3 {
  padding: 16px;
  padding-bottom: 8px;
}
#profile-submenu .menu-3 img {
  margin-right: 20px !important;
}
#profile-submenu .menu-3 .btn div {
  display: block;
  text-align: left;
}
#profile-submenu .menu-3 .btn div p {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin: 0px !important;
  height: auto !important;
  color: rgba(0, 0, 0, 0.87);
}
#profile-submenu .menu-3 .btn {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0px !important;
  background: unset !important;
  border: unset;
  margin-bottom: 16px;
}
#subs-infos {
  padding: 24px 0px;
  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
  margin-bottom: 24px;
}

#subs-infos h1 {
  margin: unset !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.87);
}

#subs-infos .menuz {
  display: flex;
  align-items: center;
  line-height: 15px;
  cursor: pointer;
  margin-top: 14px;
}
#subs-infos .menuz img {
  width: 54px;
  height: 54px;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 6px;
  object-fit: cover;
  margin-right: 16px;
}
#subs-infos .menuz p {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin: unset !important;
  color: rgba(0, 0, 0, 0.87);
}
#subs-infos .menuz span {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin: unset !important;
  color: rgba(0, 0, 0, 0.87);
}
#mobile-kerjasama h1 {
  margin: unset !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 16px !important;
}

#profile-submenu-mob {
  display: none;
}
@media (max-width: 768px) {
  #profile-submenu-mob {
    display: block;
  }
  #profile-submenu-mob .named {
    display: flex;
    align-items: center;
    width: 100%;
    background: unset !important;
    border: unset;
    border-bottom: 1px solid #dedede;
    padding: 16px;
  }
  #profile-submenu-mob .named div {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-left: 12px;
  }
  #profile-submenu-mob .named div p {
    margin: 0px !important;
    height: auto !important;
    line-height: 14px !important;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.87);
  }
  #profile-submenu-mob .named div span {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.87);
  }
  #profile-submenu-mob .menu-1 {
    padding: 16px;
    padding-bottom: 0px;
    border-bottom: 1px solid #dedede;
  }
  #profile-submenu-mob .menu-1 .btn {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0px !important;
    background: unset !important;
    border: unset;
    margin-bottom: 16px;
  }
  #profile-submenu-mob .menu-1 .btn div {
    display: block;
    text-align: left;
    margin-left: 12px;
  }
  #profile-submenu-mob .menu-1 .btn div p {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    margin: 0px !important;
    height: auto !important;
    color: rgba(0, 0, 0, 0.87);
  }
  #profile-submenu-mob .menu-1 .btn div span {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.87);
  }
  #profile-submenu-mob .menu-1 .btn div span a {
    cursor: pointer;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    margin-left: 8px;
    color: #7d61d6;
  }
  #profile-submenu-mob .menu-2 {
    padding: 24px;
    padding-bottom: 8px;
    border-bottom: 1px solid #dedede;
  }
  #profile-submenu-mob .menu-2 .btn div {
    display: block;
    text-align: left;
    margin-left: 20px;
  }
  #profile-submenu-mob .menu-2 .btn div p {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    margin: 0px !important;
    height: auto !important;
    color: rgba(0, 0, 0, 0.87);
  }
  #profile-submenu-mob .menu-2 .btn {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0px !important;
    background: unset !important;
    border: unset;
    margin-bottom: 16px;
  }
  #profile-submenu-mob .menu-3 {
    padding: 24px;
    padding-bottom: 8px;
  }
  #profile-submenu-mob .menu-3 .btn div {
    display: block;
    text-align: left;
    margin-left: 20px;
  }
  #profile-submenu-mob .menu-3 .btn div p {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    margin: 0px !important;
    height: auto !important;
    color: rgba(0, 0, 0, 0.87);
  }
  #profile-submenu-mob .menu-3 .btn {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0px !important;
    background: unset !important;
    border: unset;
    margin-bottom: 16px;
  }
  .button-kerja-sama {
    width: 100%;
    margin: 0px !important;
  }
}

.home-slider .carousel-inner .item img.mobile-pict {
  display: none;
}

.item-carousel {
  border-radius: 12px;
  /* height: 264px !important; */
  /* object-fit: cover; */
  width: 1440px;
}
.container-maincarousel {
  width: 1170px;
  margin: auto;
  margin-top: 24px;
}

#home-container {
  padding-top: 24px !important;
}
@media (max-width: 1200px) {
  .container-maincarousel {
    width: 970px;
  }
}

@media (max-width: 992px) {
  .container-maincarousel {
    width: 750px;
  }
  #fammi-maincarousel .carousel-indicators {
    display: inline !important;
    display: initial !important;
  }
  .carousel-indicators {
    left: 5% !important;
  }
}

@media (max-width: 768px) {
  .parent-home-h {
    flex-direction: column;
    padding: 16px;
    margin-top: 36px;
  }
  .parent-home-h .one-home-h {
    width: 100% !important;
  }
  .parent-home-h .two-home-h {
    width: 100% !important;
    height: 291px !important;
    margin-top: 36px;
  }
  .one-home-h img {
    height: 23px !important;
    margin-left: 0px !important;
  }
  .one-home-h h1 {
    font-size: 28px !important;
    line-height: 34px !important;
    margin-top: 16px !important;
    margin-bottom: 12px !important;
  }
  .one-home-h .p-home-h {
    max-width: 100% !important;
    font-size: 12px !important;
    line-height: 16px !important;
    margin-top: 0px !important;
    border-bottom: 0px !important;
  }

  .one-home-h button {
    margin-top: 24px !important;
    width: 100% !important;
    padding-right: 16px !important;
    height: 48px !important;
  }
  .one-home-h button p {
    font-size: 16px !important;
    line-height: 19px !important;
    /* margin-right: 48px !important; */
    text-align: center;
  }
  .container-maincarousel {
    margin-right: 24px;
    margin-left: 24px;
  }
  .category-container {
    margin-right: 24px;
    margin-left: 24px;
  }
  #fammi-maincarousel .carousel-indicators {
    display: inline !important;
    display: initial !important;
    margin-bottom: 75px;
  }
}
@media (max-width: 360px) {
  /* #home-container {
    padding-top: 0 !important;
  } */
  .iqbal-tambah {
    margin-top: unset !important;
    margin-bottom: unset !important;
  }
  #fammi-maincarousel .carousel-indicators {
    display: none !important;
  }
}

@media (max-width: 450px) {
  #fammi-maincarousel .carousel-indicators {
    display: inline !important;
    display: initial !important;
    margin-bottom: 60px !important;
  }
  #home-container {
    padding-top: 0 !important;
  }
  #fammi-maincarousel .carousel-indicators li {
    background-color: rgba(255, 255, 255, 0.5) !important;
  }
  #fammi-maincarousel .carousel-indicators .active {
    background-color: #ffffff !important;
  }
  .home-slider .carousel-inner .item img.mobile-pict {
    display: inline;
    display: initial;
    width: 100%;
  }
  .container-maincarousel {
    width: 100%;
    margin: unset;
  }
  #home-container {
    padding-top: 0px;
  }
}

.parent-home-h {
  display: flex;
  width: 100%;
}

.parent-home-h .one-home-h {
  width: 55%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.one-home-h img {
  height: 40px;
}

.one-home-h h1 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 60px;
  margin-left: -3px;
}
.one-home-h .p-home-h {
  max-width: 476px;
}

.one-home-h button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(180deg, #ff3f00 0%, #ff8057 100%);
  border-radius: 14px;
  border: none;
  margin-top: 30px;
  width: 300px;
  padding: 0px 24px;
  height: 56px;
}
.one-home-h button p {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  text-align: center;
  width: 90%;
}
.one-home-h button img {
  height: 24px;
}
.parent-home-h .two-home-h {
  background: url("/assets/images/home-header.png") right no-repeat;
  width: 45%;
  height: 328px;
  display: flex;
  align-items: center;
  background-size: contain;
}
.two-home-h .qna-home-h {
  width: 100%;
}

.qna-home-h .example-question-h {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: #7d61d6;
  border-radius: 24px 24px 0px 24px;
  padding: 14px;
}
.qna-home-h .example-question-h p {
  margin: 0px !important;
  font-family: Rubik !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  color: #ffffff;
}

.qna-home-h .parent-answer-h {
  display: flex;
  align-items: center;
  margin-top: 19px;
}
.parent-answer-h .logo-on-h {
  background: #7d61d6;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
}

.parent-answer-h .example-answer-h {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 24px 24px 24px 0px;
  margin-left: 8px;
  padding: 14px;
}

.example-answer-h p {
  margin: 0px !important;
  font-family: Rubik !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  color: rgba(0, 0, 0, 0.87);
}

.home_ask_container {
  width: 100%;
  height: 100%;
  background: rgb(30 30 30 / 84%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
}

.hac_content {
  background: #FFF;
  border-radius: 12px;
  width: 360px;
  max-height: 620px;
  display: flex;
  flex-direction: column;
}

.hac_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #FFF;
  padding: 16px;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
}

.hac_header h4 {
  margin: 0;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #262626;
}

.hac_form {
  height: 100%;
  overflow: auto;
  padding: 0px;
}

.hac_button {
  padding: 16px;
  background-color: #FFF;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}

.fq_parent {
  margin: 0
}

.tx_fq_title {
  margin: 0;
  margin-bottom: 16px;
}


@media (max-width:500px) {

  .hac_content,
  .hac_header,
  .hac_button {
    background: #fff;
    border-radius: 0;
  }

  .hac_header {
    flex-direction: row-reverse;
    justify-content: left;
  }

  .hac_header h4 {
    margin-left: 16px;
  }

  .hac_content {
    width: 100%;
    height: 100%;
    max-height: none;
  }

  .hac_form {
    padding: 0;
  }

  .hac_button {
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    box-shadow: 0 -2px 10px 10px #eee;
  }

}
.parent-carousels {
  margin-top: 36px;
}
.parent-heads-carousels {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dummy-carousels-box {
  display: none;
}
.text-title-carousels {
  margin: unset !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.87);
}
.text-link-carousels {
  margin: unset !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;
  color: #7d61d6;
}
.parent-indicator-carousels {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.parent-btn-carousels {
  display: flex;
  align-items: center;
}
.parent-dots-carousels {
  display: flex;
  align-items: center;
}
.dots-carousels {
  background: rgba(125, 97, 214, 0.2);
  width: 10px;
  border-radius: 50%;
  height: 10px;
  margin-right: 12px;
  cursor: pointer;
}
.dots-carousels-active {
  background: rgba(125, 97, 214, 0.2);
  width: 10px;
  cursor: pointer;
  border-radius: 50%;
  height: 10px;
  margin-right: 12px;
  background: #7d61d6;
}
.btn-arrow-carousels {
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  height: 34px;
  width: 34px;
  display: flex;
  margin-left: 16px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.wrapper-carousels {
  display: flex;
  align-items: center;
  overflow-x: hidden;
  flex-wrap: nowrap;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
}

.wrapper-carousels::-webkit-scrollbar {
  display: none;
}
.wrapper-carousels > div {
  flex: 0 0 auto;
}

@media (max-width: 768px) {
  .wrapper-carousels {
    overflow-x: auto;
    overflow-y: hidden;
    padding-left: 16px;
  }
  .text-title-carousels {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.87);
  }
  .text-link-carousels {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #7d61d6;
  }
  .parent-indicator-carousels {
    display: none;
  }
  .parent-heads-carousels {
    padding: 0px 16px;
  }
  .dummy-carousels-box {
    flex: 0 0 auto;
    width: 1px;
    height: 16px;
    display: block;
  }
}

.parent-card-narasumber {
  margin-right: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  width: 125px;
  justify-content: center;
  margin-bottom: 16px !important;
}
#narasumber-section .wrapper-carousels {
  align-items: flex-start;
}
.img-narasumber {
  width: 125px;
  height: 125px;
  border-radius: 50%;
  margin-bottom: 24px;
}
.text-title-narasumber {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  margin: unset !important;
  color: rgba(0, 0, 0, 0.87);
  height: 34px;
}
@media (max-width: 768px) {
  .parent-card-narasumber {
    margin-right: 16px;
    width: 91px;
    margin-bottom: unset !important;
  }
  .img-narasumber {
    width: 91px;
    height: 91px;
    margin-bottom: 8px;
  }
  .text-title-narasumber {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
  }
}

.parent-content-video {
  max-width: 1170px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 55px;
}

.text-title-video-subs {
  font-family: "Rubik";
  font-weight: 500;
  font-size: 24px;
}

.text-description-video-subs {
  font-family: "Lato";
  font-size: 14;
}

.video-subs {
  border-radius: 12px;
}


@media (max-width:768px) {
  .parent-content-video {
    display: block !important;
    margin-bottom: 0px !important;
  }

  .text-title-video-subs {
    width: 100%;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.87);
  }
}
.parent-card-playlists {
  margin-right: 24px;
  width: 215px;
}
.parent-card-playlists > p {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin-top: 16px !important;
}
.parent-card-playlists div {
  margin-top: 6px !important;
  padding: 6px 8px !important;
  background: #f5f0ff;
  border-radius: 6px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #7d61d6;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.parent-card-playlists > p.desktop {
  display: block;
}
.parent-card-playlists > p.mobile {
  display: none;
}
.parent-card-playlists div p {
  margin: unset !important;
}
.img-playlists {
  width: 215px;
  height: 215px;
  border-radius: 12px;
  object-fit: cover;
  cursor: pointer;
}
#neweps {
  align-items: flex-start !important;
}
@media (max-width: 768px) {
  .parent-card-playlists {
    margin-right: 0px;
    width: 144px;
  }
  .parent-card-playlists.non-classname{
    margin-right: 16px;
    width: 144px;
  }
  .img-playlists {
    width: 144px;
    height: 144px;
  }
  .parent-card-playlists > p.desktop {
    display: none;
  }
  .parent-card-playlists > p.mobile {
    display: block;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
  }
  .parent-card-playlists div {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
  }
}

.parent-card-news {
  margin-right: 24px;
  cursor: pointer;
}
.parent-card-news img {
  border-radius: 12px;
  width: 274px;
  height: 274px;
  object-fit: cover;
}

.audio-materials-container {
  max-width: 175px;
  margin-right: 18px;
  margin-bottom: 32px;
}

.audio-materials .parent-card-news {
  width: 100%;
}

.audio-materials-container:nth-child(4n) {
  margin-right: 0px;
}

.audio-materials .parent-card-news img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 768px) {
  .parent-card-news img {
    width: 156px;
    height: 156px;
  }
  .parent-card-news {
    margin-right: 16px;
  }
  .audio-materials-container {
    flex: 50% 1;
    max-width: 48%;
    /* max-height: 156px; */
    margin-right: 16px;
    margin-bottom: 16px;
  }
  .audio-materials-container:nth-child(even) {
    margin-right: 0px;
  }
}
@media (max-width: 420px) {
  .audio-materials-container {
    max-width: 47%;
  }
}
.parent-card-playlist {
  margin-right: 24px;
}
.img-playlist {
  width: 175px;
  height: 175px;
  border-radius: 12px;
  object-fit: cover;
  cursor: pointer;
}
@media (max-width: 768px) {
  .parent-card-playlist {
    margin-right: 16px;
  }
  .img-playlist {
    width: 144px;
    height: 144px;
  }
}

#end-credits .box-popups {
  width: 640px;
  height: 347px;
  display: flex;
  border-radius: 12px;
}
#end-credits .image-popups {
  background: url(/assets/images/popup-subs-qna.png) center no-repeat;
  background-size: cover;
  height: 100%;
  width: 265px;
  border-radius: 12px 0px 0px 12px;
}

#end-credits .content-popups {
  background-color: #fff;
  width: 375px;
  border-radius: 0px 12px 12px 0px;
  padding-left: 24px;
  padding-bottom: 24px;
  height: 100%;
}
#end-credits .close-popups {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 12px;
  padding-bottom: 0px;
}
#end-credits .close-popups img {
  cursor: pointer;
}
#end-credits .main-popups {
  padding-right: 24px;
  margin-top: -8px;
}

#end-credits .main-popups #titlenyo {
  font-family: Rubik;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.87);
  margin: unset !important;
  margin-right: 8px !important;
}

#end-credits .main-popups .parent-item .item-one {
  padding-bottom: 12px;
  border-bottom: 1px solid #dedede;
  margin-bottom: 24px;
}
#end-credits .main-popups .parent-item .item-one .head-one {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
#end-credits .main-popups .parent-item .item-one .head-one .active-package {
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 6px;
  background: #fd8c25;
  border-radius: 4px;
}
#end-credits .main-popups .parent-item .item-one .head-one .active-package p {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  line-height: 9px;
  margin: 0px !important;
  color: #ffffff;
}
#end-credits .descone div {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
#end-credits .descone div img {
  height: 24px;
  margin-right: 8px;
}
#end-credits .descone div p {
  margin: 0px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.87);
}
#end-credits .options-popup {
  margin-top: 24px;
}
#end-credits .options-popup p {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin: unset !important;
  color: rgba(0, 0, 0, 0.87);
}
#end-credits .options-popup div {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
#end-credits .options-popup div .langganan {
  display: flex;
  align-items: center;
  border: unset;
  background: #7d61d6 !important;
  box-sizing: border-box !;
  border-radius: 12px;
  padding: 8.5px 22px;
  background: unset;
  width: auto;
  justify-content: center;
}
#end-credits .options-popup div .langganan p {
  margin: unset !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #fff;
  text-align: center;
}

#end-credits .options-popup div .facebook {
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  border-radius: 12px;
  padding: 8.5px 28px;
  background: unset;
  width: auto;
  justify-content: center;
}
#end-credits .options-popup div .facebook p {
  margin: unset !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
}

#end-credits .options-popup > button {
  width: 100%;
  padding: 12px 0px;
  text-align: center;
  background: #ffffff;
  border-radius: 12px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #7d61d6;
  border: 1px solid #7d61d6;
  margin-top: 16px;
}
#end-credits .parent-closeM {
  display: none;
}
.desktops {
  display: block;
}
.mobiles {
  display: none;
}
@media (max-width: 500px) {
  #end-credits.parent-voice-over {
    padding: 0px 16px;
    align-items: center;
  }
  #end-credits .box-popups {
    width: 100%;
    flex-direction: column;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  #end-credits .image-popups {
    width: 100%;
    height: 164px;
    background: url(/assets/images/popup-subs-qna-mob.png) center no-repeat;
    background-size: cover;
    border-radius: 12px 12px 0px 0px;
  }
  #end-credits .close-popups {
    display: none;
  }
  #end-credits .content-popups {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 0px 0px 12px 12px;
    padding: 0px;
  }
  #end-credits .main-popups {
    margin-top: 0px;
    padding: 16px;
  }
  #end-credits .main-popups h1 {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.87);
  }
  #end-credits.descone {
    margin-top: 8px !important;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.87);
  }
  #end-credits .options-popup {
    margin-top: 16px;
  }
  #end-credits .options-popup p {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.87);
  }
  #end-credits .parent-closeM {
    display: flex;
    justify-content: flex-end;
    padding: 8px;
  }
  #end-credits .options-popup div .langganan {
    width: 47.5%;
    height: 41px;
  }

  #end-credits .options-popup div .facebook {
    width: 47.5%;
    height: 41px;
  }
  #end-credits .main-popups .parent-item .item-one {
    padding-bottom: 4px;
    margin-bottom: 16px;
  }

  .desktops {
    display: none !important;
  }
  .mobiles {
    display: block !important;
  }
}

.box-popups {
  width: 640px;
  height: 265px;
  display: flex;
  border-radius: 12px;
}
.image-popups {
  background: url(/assets/images/popups-bg.png) center no-repeat;
  background-size: cover;
  height: 100%;
  width: 265px;
  border-radius: 12px 0px 0px 12px;
}

.content-popups {
  background-color: #fff;
  width: 375px;
  border-radius: 0px 12px 12px 0px;
  padding-left: 24px;
  padding-bottom: 24px;
  height: 100%;
}
.close-popups {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 12px;
  padding-bottom: 0px;
}
.close-popups img {
  cursor: pointer;
}
.main-popups {
  padding-right: 24px;
  margin-top: -8px;
}

.main-popups h1 {
  font-family: Rubik;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.87);
  margin: 0px !important;
}
.descone {
  margin-top: 12px !important;
  margin-bottom: 0px !important;
}
.descone div {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 12px;
}
.descone div img {
  height: 24px;
  margin-right: 8px;
}
.descone div p {
  margin: 0px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.87);
}
.options-popups {
  margin-top: 16px;
}
.options-popups p {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin: unset !important;
  color: rgba(0, 0, 0, 0.87);
}
.options-popups div {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.options-popups div .google {
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  border-radius: 12px;
  padding: 8.5px 0px;
  background: unset;
  width: 47.5%;
  justify-content: center;
}
.options-popups div .google p {
  margin: unset !important;
  margin-left: 8px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
}

.options-popups div .facebook {
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  border-radius: 12px;
  padding: 8.5px 0px;
  background: unset;
  width: 47.5%;
  justify-content: center;
}
.options-popups div .facebook p {
  margin: unset !important;
  margin-left: 8px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
}

.options-popups > button {
  width: 100%;
  padding: 12px 0px;
  text-align: center;
  background: #ffffff;
  border-radius: 12px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #7d61d6;
  border: 1px solid #7d61d6;
  margin-top: 16px;
}
.parent-closeM {
  display: none;
}
@media (max-width: 500px) {
  #logins.parent-voice-over {
    padding: 0px 16px;
    align-items: center;
  }
  .box-popups {
    width: 100%;
    flex-direction: column;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .image-popups {
    width: 100%;
    height: 164px;
    background: url(/assets/images/popups-bg-mob.png) center no-repeat;
    background-size: cover;
    border-radius: 12px 12px 0px 0px;
  }
  .close-popups {
    display: none;
  }
  .content-popups {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 0px 0px 12px 12px;
    padding: 0px;
  }
  .main-popups {
    margin-top: 0px;
    padding: 16px;
  }

  .main-popups h1 {
    font-size: 18px;
    line-height: 21px;
  }
  .options-popups p {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.87);
  }
  .parent-closeM {
    display: flex;
    justify-content: flex-end;
    padding: 8px;
  }
}

/*Delip Code*/
button:focus,
select:focus,
input:focus,
textarea:focus,
button:visited,
select:visited,
input:visited,
textarea:visited {
  outline: none !important;
}

.colorbg6:hover,
.colorbg6:focus,
.colorbg2:hover,
.colorbg2:focus {
  color: white;
}

.left-left {
  width: 50%;
  float: left;
}

.right-right {
  width: 50%;
  float: left;
}

.r-23 {
  margin-right: 23px !important;
}
.l-23 {
  margin-left: 23px !important;
}

.w-4 {
  width: 25%;
  float: left;
  position: relative;
}

.step-id {
  margin: 40px 0;
  position: relative;
  /*height: 48px;*/
}

#step-in {
  text-align: center;
  margin: 0 auto;
  max-width: 826px;
  overflow: hidden;
}

#step-in .steps {
  height: 54px;
  width: 54px;
  cursor: pointer;
  /*margin: 34px 0px;*/
  color: grey;
  background-color: white;
  border: none;
  border-radius: 50%;
  display: inline-block;
  opacity: 1;
  line-height: 54px;
  font-family: Rubik;
  font-weight: 500;
  position: relative;
  font-size: 18px;
}

#step-in .steps.active {
  background: #f75490;
  color: white;
}

#step-in .w-4:after {
  content: ' ';
  display: block;
  background-color: white;
  width: 100%;
  height: 6px;
  position: absolute;
  top: 46%;
  left: 0;
}

#step-in .w-4.active:first-child:after {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 250, 252, 0) 50%,
    rgba(247, 84, 144, 1) 50%
  );
}

#step-in .w-4.active:last-child:after {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 250, 252, 0) 50%,
    rgba(247, 84, 144, 1) 50%
  );
}

#step-in .w-4:last-child:after {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 250, 252, 0) 50%,
    rgba(255, 255, 255, 1) 50%
  );
}

#step-in .w-4.active:after {
  content: ' ';
  display: block;
  background-color: #f75490;
  width: 100%;
  height: 6px;
  position: absolute;
  top: 46%;
  left: 23px;
  z-index: 2;
}

#step-in .steps:first-child:after {
  display: none;
}

#step-in .steps:first-child {
  z-index: 4;
}

#step-in .steps:nth-child(3) {
  z-index: 3;
}

#step-in .steps:nth-child(2) {
  z-index: 2;
}

.#step-in .steps:last-child {
  z-index: 1;
}

.daftar-page {
  margin-top: 24px;
  background-color: #fff !important;
  border: 1px solid #eaeaea;
}

#login {
  /*margin-top: 0 !important;*/
}
#formlogin {
  margin-top: 0 !important;
}

#navigator-utama {
  left: 0;
}

.goto-register {
  color: black;
}

html,
body,
#root {
  height: 100%;
}

body {
  /*overflow-x: hidden;*/
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  /*margin-top: 64px !important;*/
  background: #fdfbff;
}

p {
  margin-bottom: 0;
}

a {
  color: #7d61d6;
}

#py-container h2 {
  margin-top: 0;
}

.kelassaya-wrap,
.profil-wrapping,
.testimony-wrap {
  padding-top: 24px;
}
#filterDate2 {
  z-index: 1;
}

.testimony-wrap {
  position: relative;
  max-width: 425px;
  width: 100%;
  height: 560px;
  margin: 0px auto;
}

.forget-password {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;
  margin-top: 8px;
  font-weight: 700;
  font-size: 12px;
}

.forget-password:hover {
  color: #7d61d6;
}

.testimony-wrap h2 {
  font-size: 24px;
  font-weight: 600;
  font-family: Rubik;
  margin-top: 0;
}

.form-control {
  border: solid 1px #eaeaea;
  box-shadow: none;
}

.form-control:focus {
  border: solid 1px #eaeaea;
  box-shadow: none;
}

a:hover,
a:focus,
.form-control:focus,
.search-input input,
textarea:focus,
select:focus,
button:focus,
input:focus {
  outline: none !important;
}

.img-full {
  display: block;
  margin: 0 auto;
  width: 100%;
}

.checkit {
  position: relative;
}
.checkit input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.text-checkit {
  padding-left: 35px;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  /*background-color: #eee;*/
  background-color: white;
  border: solid 2px #999999;
}

/* On mouse-over, add a grey background color */
.checkit:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkit input:checked ~ .checkmark {
  background-color: #7d61d6;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkit input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkit .checkmark:after {
  left: 7px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.navbar-dashboard {
  padding: 0 !important;
  margin: 0;
  border: transparent;
}

.navbar-dashboard ul li a {
  padding: 24px;
  font-size: 14px !important;
  font-family: Rubik !important;
  font-weight: 400;
}

.brand-fammi {
  margin: 18px;
  height: auto;
  padding: 0;
}

.back-fammi {
  float: left;
  margin-right: 24px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.list-menu-dashboard {
  font-family: Rubik !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: center;
}

.list-menu-dashboard.active a {
  background: white !important;
  color: #7d61d6 !important;
  font-family: Rubik;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: center;
}

.list-menu-dashboard.active a:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  background: #7d61d6;
  width: 100%;
  height: 5px;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
}

.rel-pos {
  position: relative;
}

#header-mitra nav {
  position: fixed;
  top: 0;
  background: white;
  width: 100%;
  padding: 20px 15px;
  z-index: 5;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.2);
}

#header-mitra p {
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
}

#signup-page .white-version,
#signup-page .white-version .signup-table {
  background: #f5f5f5 !important;
}

.white-version .wrap-register-mitra {
  border: solid 1px #eaeaea;
}

#signup-page {
  height: 100% !important;
  width: 100%;
  top: 0px;
  background-color: #ffffff;
}

.signup-table {
  display: table;
  width: 100%;
  height: 100%;
}

.signup-middle {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 100%;
}

#signup-stepnav-2 {
  text-align: center;
  margin: 0 auto;
  max-width: 826px;
}

.pull-left {
  float: left;
}
.pull-right {
  float: right;
}

.mar-left-20 {
  margin-left: 20px;
}
.mar-top-10 {
  margin-top: 10px !important;
}

.mar-top-14 {
  margin-top: 14px !important;
}
.mar-top-20 {
  margin-top: 20px !important;
}
.mar-top-24 {
  margin-top: 24px !important;
}
.mar-btm-24 {
  margin-bottom: 24px !important;
}
.mar-btm-20 {
  margin-bottom: 20px !important;
}
.mar-btm-10 {
  margin-bottom: 10px !important;
}

.content-artikel-detail {
  padding: 24px;
  border-radius: 12px;
  background: white;
  border: solid 1px #eaeaea;
  margin-bottom: 24px;
}

.content-artikel-detail h2 {
  font-family: Rubik;
  font-size: 32px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 38px !important;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 0;
}

#signup-stepnav-2 .step {
  height: 54px;
  width: 54px;
  cursor: pointer;
  margin: 34px 0px;
  color: grey;
  background-color: white;
  border: none;
  border-radius: 50%;
  display: inline-block;
  opacity: 1;
  line-height: 54px;
  font-family: Rubik;
  font-weight: 500;
  position: relative;
  font-size: 18px;
}

#signup-stepnav-2 .step.active {
  background: #f75490;
  color: white;
}

#signup-stepnav-2 .step:after {
  content: ' ';
  display: block;
  background-color: white;
  width: 332px;
  height: 6px;
  position: absolute;
  top: 46%;
  right: 100%;
}

#signup-stepnav-2 .step.active:after {
  content: ' ';
  display: block;
  background-color: #f75490;
  width: 332px;
  height: 6px;
  position: absolute;
  top: 46%;
  right: 100%;
}

#signup-stepnav-2 .step:first-child:after {
  display: none;
}

#signup-stepnav-2 .step:first-child {
  z-index: 3;
}

#signup-stepnav-2 .step:nth-child(2) {
  z-index: 2;
}

#signup-stepnav-2 .step:last-child {
  z-index: 1;
}

.wrap-register-mitra {
  background: white;
  max-width: 826px;
  width: 100%;
  margin: 0 auto;
  border-radius: 12px;
  padding: 24px;
  margin-bottom: 24px;
  margin-top: 24px;
}

.wrap-register-mitra h1 {
  font-family: Rubik;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: black;
}

.wrap-register-mitra p {
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: #999999;
  margin-bottom: 8px;
}

.wrap-register-mitra label {
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}

.wrap-register-mitra input {
  padding: 15px;
  width: 100%;
  border: 1px solid #dedede;
  border-radius: 12px;
  font-family: Lato;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 16px;
}

.wrap-register-mitra select {
  border: 1px solid #dedede;
  border-radius: 12px;
  line-height: 49px;
  height: 49px;
  width: 100%;
  padding: 0 15px;
  font-family: Lato;
  margin-bottom: 6px;
  color: #999999;
}

.wrap-register-mitra textarea {
  border: 1px solid #dedede;
  border-radius: 12px;
  line-height: 49px;
  min-height: 124px;
  width: 100%;
  padding: 0 15px;
  font-family: Lato;
  margin-bottom: 6px;
}

.input-ghost {
  display: none !important;
}

.input-file {
  height: 50px;
  border-top-left-radius: 12px !important;
  border-bottom-left-radius: 12px !important;
}

.judul-af,
.judul-af-td {
  min-width: 190px;
  max-width: 190px;
}
.peserta-af,
.peserta-af-td {
  min-width: 80px;
  max-width: 80px;
}
.tgl-af,
.tgl-td {
  min-width: 110px;
  max-width: 110px;
}
.nar-af,
.nar-td {
  min-width: 130px;
  max-width: 130px;
}
.harga-af,
.harga-td {
  min-width: 80px;
  max-width: 80px;
}
.link-af,
.link-td {
  min-width: 105px;
  max-width: 105px;
  word-break: break-all;
}

.btn-choose {
  background: #7d61d6;
  padding: 15px 25px;
  color: white;
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}

.btn-purple {
  background: #7d61d6;
  width: 100%;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  padding: 7.5px 12px;
  color: white;
  border-radius: 12px;
}

.btn-purple:hover,
.btn-purple:focus {
  color: white !important;
}

.btn-white-t {
  background: transparent;
  width: 100%;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  padding: 12px;
  color: black;
  border-radius: 12px;
}

.btn-purple-t:hover,
.btn-purple-t:focus {
  color: black;
}

.btn-remix-kategori {
  width: 100%;
  overflow: hidden;
  clear: both;
}

.btn-remix-kategori .col-sm-6 {
  padding-left: 7.5px;
  padding-right: 7.5px;
}

.btn-remix-kategori .col-sm-6:first-child {
  padding-left: 0;
}

.btn-remix-kategori .col-sm-6:last-child {
  padding-right: 0;
}

.btn-white {
  background: white;
  width: 100%;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  padding: 12px;
  color: #7d61d6;
  border-radius: 12px;
  border: solid 1px #7d61d6;
}

.btn-white:hover,
.btn-white:focus {
  color: #7d61d6;
}

.btn-grey-outline {
  background: white;
  width: 100%;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  padding: 7px;
  height: 41px;
  color: #999999;
  border-radius: 12px;
  border: solid 1px #999999;
}

.btn-grey-outline:hover,
.btn-grey-outline:focus {
  color: #999999;
}

.btn-grey {
  background: #999999;
  width: 100%;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  padding: 7px;
  /*height: 41px;*/
  color: white;
  border-radius: 12px;
}

.btn-grey:hover,
.btn-grey:focus {
  color: white;
}

.btn-remix .col-sm-6 {
  padding: 0 7.5px;
}

.global-popup {
  background: rgb(0 0 0 / 36%);
}

.global-popup .content-copy-link {
  background: white !important;
  padding: 24px !important;
}
.global-popup .content-copy-link h3 {
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 0;
}
.global-popup .content-copy-link p {
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}

.eye-password img {
  margin: 16px 0 !important;
  opacity: 0.3;
}

.eye-password img.active-eye {
  opacity: 1;
}

/*.wajib-isi {
	line-height: 35px !important;
}*/

.wajib-isi {
  line-height: 7px !important;
}

.form-wajib-diisi .col-sm-6:first-child {
  padding-right: 0;
}

.table-das-af {
  padding: 24px;
  border: solid 1px #eaeaea;
  border-radius: 12px;
  background: white;
  font-size: 14px;
}

.table-das-af th {
  padding-bottom: 20px !important;
  padding-top: 0 !important;
  border-bottom: transparent !important;
}

.table-das-af td {
  padding: 15px 8px !important;
}

.share-af-td,
.viewer-af-td,
.tgl-af-td,
.no-af-td {
  line-height: 40px !important;
}

.banner-affiliate {
  width: 100%;
  float: left;
  border-radius: 12px;
  background: white;
  border: solid 1px #eaeaea;
  margin-top: 30px;
}

.img-profile-af,
.text-profile-af {
  display: inline-block;
}

.img-profile-af {
  width: 15%;
  vertical-align: top;
}
.text-profile-af {
  width: 70%;
}

.text-profile-af {
  margin-left: 24px;
}

.left-affiliate {
  width: 40%;
  float: left;
  padding: 24px;
  border-right: solid 1px #eaeaea;
}

.right-affiliate {
  width: 60%;
  float: left;
  padding: 24px;
}

.left-affiliate h2 {
  font-family: Rubik;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
}

.right-affiliate p {
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left !important;
  margin-bottom: 8px;
}

.right-affiliate h3 {
  margin-top: 0;
  font-family: Lato;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 5px;
}

.content-dashboard-af {
  margin-top: 0;
  /*margin-top: 30px;*/
  /*margin-bottom: 30px;*/
}

.content-statistic {
  margin-top: 15px;
}
.content-statistic h2,
.content-dashboard-af h2 {
  font-family: Rubik;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 40px;
}

.search-input {
  position: relative;
}

.search-input input {
  height: 41px;
  line-height: 41px;
  background: #f4f4f4;
  border-radius: 12px;
  border: none;
  padding-left: 45px;
  padding-right: 15px;
  width: 100%;
  margin-bottom: 24px;
  display: block;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  position: relative;
}

.search-input:before {
  content: url('http://beta.fammi.ly/images/searchicon.png');
  z-index: 2;
  position: absolute;
  left: 10px;
  top: 10px;
}

.input-remix input {
  height: 41px;
  line-height: 41px;
  background: white;
  border: solid 1px #eaeaea;
  border-radius: 12px;
  padding-left: 45px;
  padding-right: 15px;
  width: 100%;
  margin-bottom: 24px;
  display: block;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  position: relative;
  border-radius: 12px;
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}

.input-remix:before {
  content: url('http://beta.fammi.ly/public_html/assets/images/none-calendar.png');
  z-index: 4;
  position: absolute;
  left: 10px;
  top: 10px;
}

.input-remix.first:after {
  content: url('http://beta.fammi.ly/public_html/assets/images/arrow-calendar.png');
  z-index: 4;
  position: absolute;
  right: -10px;
  top: 10px;
  width: 8px;
}

.date-input {
  position: relative;
}

.date-input input {
  height: 41px;
  line-height: 41px;
  background: white;
  border: solid 1px #eaeaea;
  border-radius: 12px;
  padding-left: 45px;
  padding-right: 15px;
  width: 100%;
  margin-bottom: 24px;
  display: block;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  position: relative;
}

.date-input:before {
  content: url('http://beta.fammi.ly/public_html/assets/images/none-calendar.png');
  z-index: 2;
  position: absolute;
  left: 10px;
  top: 10px;
}

.date-input.first:after {
  content: url('http://beta.fammi.ly/public_html/assets/images/arrow-calendar.png');
  z-index: 2;
  position: absolute;
  right: -10px;
  top: 10px;
  width: 8px;
}

.statistic-bottom {
  border-radius: 12px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  background: white;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.statistic-bottom .card-kelas-jual {
  background: transparent;
  border-radius: 0;
  border: transparent;
  border-right: solid 1px #eaeaea;
}

.statistic-bottom .card-kelas-jual.no-border {
  border-right: transparent;
}

.pad-left-35 {
  padding-left: 35px !important;
}

.pad-right-35 {
  padding-right: 35px !important;
}

.pagination-global {
  margin: 0 auto;
  text-align: center;
  margin-bottom: 30px;
}

.pagination-global .prev,
.pagination-global .next {
  background: red;
  border-radius: 50px;
  border-top-left-radius: 50px !important;
  border-bottom-left-radius: 50px !important;
  border-top-right-radius: 50px !important;
  border-bottom-right-radius: 50px !important;
  padding: 15px 20px;
  background: #ffffff;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  border: transparent;
}

.pagination-global .prev:hover,
.pagination-global .next:hover {
  background: #eaeaea !important;
}

.pagination-global a {
  padding: 15px 20px !important;
  margin-left: 24px !important;
  border-radius: 50px;
  border-top-left-radius: 50px !important;
  border-bottom-left-radius: 50px !important;
  border-top-right-radius: 50px !important;
  border-bottom-right-radius: 50px !important;
  color: black !important;
  font-family: Rubik;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  width: 57px;
  height: 57px;
  text-align: center;
  border: transparent !important;
  background-color: transparent !important;
}

.pagination-global a:hover,
.pagination-global a.active {
  background: #7d61d6 !important;
  color: white !important;
}

#content-global {
  position: relative;
}

.pop-copy-link {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.pop-copy-link .wrap-copy-table {
  padding: 15px;
  display: table;
  margin: 0 auto;
  width: 100%;
  height: 100%;
}

.pop-copy-link .wrap-copy-center {
  display: table-cell;
  vertical-align: middle;
  margin: 0 auto;
}

.pop-copy-link .content-copy-link {
  max-width: 400px;
  background: #63ce59;
  margin: 0 auto;
  padding: 15px;
  border-radius: 12px;
}

.search-empty-table {
  display: table;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  margin-bottom: 24px;
}

.search-empty-center {
  display: table-cell;
  vertical-align: middle;
  margin: 0 auto;
}

.search-empty-content {
  max-width: 100%;
  background: white;
  border: solid 1px #eaeaea;
  margin: 0 auto;
  padding: 20px;
  border-radius: 12px;
  text-align: center;
  padding: 149px 15px;
}

.search-empty-content img {
  max-width: 200px;
  display: block;
  margin: 0 auto;
}

.search-empty-content a {
  max-width: 200px;
  margin: 0 auto;
  text-align: center;
  margin-top: 24px;
}

.search-empty-content h2 {
  text-align: center;
  font-family: Rubik;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
}

.search-empty-content p {
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: #999999;
}

.card-kelas-jual {
  padding: 24px;
  border: solid 1px #eaeaea;
  border-radius: 12px;
  background: white;
}

.kelas-text h3 {
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 10px;
  margin-top: 0;
}

.card-kelas-jual h2 {
  font-family: Rubik;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 43px;
  letter-spacing: 0em;
  text-align: left;
}

.card-kelas-jual p {
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}

.content-detail-jual {
  padding-top: 24px;
}

.wrap-icon-detail-kelas {
  margin-bottom: 24px;
}

.info-detail-kelas {
  padding: 24px;
  background: white;
  border-radius: 12px;
  border: solid 1px #eaeaea;
}

.info-detail-kelas h2 {
  font-family: Rubik;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 25px;
  margin-top: 0;
}

.icon-detail-kelas,
.text-icon-kelas {
  display: inline-block;
}

.icon-detail-kelas {
  width: 8%;
  vertical-align: top;
}
.icon-detail-kelas img {
  width: 20px !important;
}
.text-icon-kelas {
  width: 85%;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 10px;
}

.content-detail-jual img {
  width: 100%;
}

.icon-kelas-jual,
.kelas-text {
  display: inline-block;
}

.left-icon,
.text-left-icon {
  display: inline-block;
  color: white;
}
.left-icon {
  width: 10%;
  vertical-align: top;
  padding-top: 5px;
}
.text-left-icon {
  width: 80%;
  padding-left: 15px;
}

.text-left-icon h3 {
  margin-top: 0;
  margin-bottom: 8px;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: left;
}
.text-left-icon p {
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: left;
}

.img-doc-fammi img {
  width: 100%;
}

.small-doc {
  max-width: 425px;
}

.large-doc {
  max-width: 900px;
}

.empty-img,
.img-doc-fammi {
  display: block;
  margin: 0 auto;
  padding: 15px;
}

.empty-img img,
.img-doc-fammi img {
  display: block;
  margin: 0 auto;
}

.empty-img h1,
.empty-img p {
  text-align: center;
}

.img-doc-fammi img {
  margin-bottom: 24px;
}

.transparent-version .wrap-register-mitra {
  background: transparent;
  border: transparent;
}

.form-select select {
  border: 1px solid #dedede;
  border-radius: 12px;
  line-height: 49px;
  height: 49px;
  width: 100%;
  padding: 0 15px;
  font-family: Lato;
  margin-bottom: 6px;
  color: #999999;
}

.arrow-select-remix select {
  height: 41px !important;
  line-height: 41px !important;
  background: white;
}

.arrow-select {
  position: relative;
}

.arrow-select select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  position: relative;
  padding: 0 15px !important;
  background: white;
}
.arrow-select:after {
  content: '';
  width: 20px;
  height: 20px;
  background: url('http://beta.fammi.ly/images-delip/arrow-top.png') no-repeat
    center center;
  background-size: 12px;
  position: absolute;
  top: 15px;
  right: 10px;
}

.register-container .arrow-select:after {
  right: 20px;
}

.table-table {
  display: table;
  min-height: 400px;
  margin: 0 auto;
}
.table-middle {
  display: table-cell;
  vertical-align: middle;
}

.title-version {
  max-width: 1200px;
  margin: 0 auto;
}

.white-version .wrap-register-mitra {
  max-width: 100%;
  /*min-height: 400px;*/
}

.white-version .signup-middle h1 {
  font-family: Rubik;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}

.white-version .signup-middle h2 {
  font-family: Rubik;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 15px;
  margin-top: 15px;
}

.pos-rel {
  position: relative;
}
.abs-post-nav {
  position: absolute;
  width: 100%;
  top: 0;
}
.abs-post-nav h2 {
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
}

/* Chrome, Safari and Opera syntax */
:-webkit-full-screen {
  background-color: yellow;
}

/* Firefox syntax */
:-moz-full-screen {
  background-color: yellow;
}

/* IE/Edge syntax */
:-ms-fullscreen {
  background-color: yellow;
}

/* Standard syntax */
:-webkit-full-screen {
  background-color: yellow;
}
:-ms-fullscreen {
  background-color: yellow;
}
:fullscreen {
  background-color: yellow;
}

.alert-form {
  border: solid 1px red !important;
}

.wrap-status-artikel {
  padding: 34px 0px;
}

.kategori-artikel {
  padding: 24px;
  background: white;
  border-radius: 12px;
  border: solid 1px #eaeaea;
}

.kategori-artikel h2 {
  margin-top: 0;
  font-family: Rubik;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.list-kategori {
  padding: 8px 12px;
  background: #dedede;
  color: black;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  display: inline-block;
  border-radius: 12px;
}

.editor-fammi {
  padding: 24px;
  background: white;
  border-radius: 12px;
  border: solid 1px #eaeaea;
  position: relative;
}

.text-judul {
  width: 100%;
  padding: 24px;
  resize: none;
  overflow: hidden;
  height: 90px;
  font-family: Rubik;
  font-size: 32px;
  border-radius: 12px;
  border: solid 1px #eaeaea;
  margin-bottom: 23px;
  font-weight: 500;
}

textarea.text-judul::-webkit-input-placeholder,
textarea.text-cap-judul::-webkit-input-placeholder {
  color: #dddddd;
}

textarea.text-judul:-ms-input-placeholder,
textarea.text-cap-judul:-ms-input-placeholder {
  color: #dddddd;
}

textarea.text-judul::-webkit-input-placeholder, textarea.text-cap-judul:::placeholder {
  color: #dddddd;
}

textarea.text-judul:-ms-input-placeholder, textarea.text-cap-judul:::placeholder {
  color: #dddddd;
}

textarea.text-judul::-ms-input-placeholder, textarea.text-cap-judul:::placeholder {
  color: #dddddd;
}

textarea.text-judul::placeholder,
textarea.text-cap-judul:::placeholder {
  color: #dddddd;
}

.abs-btn-editor {
  position: absolute;
  top: 24px;
  right: 0;
  width: 50%;
}

.select-remix .col-sm-6:first-child {
  padding-left: 24px;
}
.select-remix .col-sm-6:last-child {
  padding-right: 24px;
}

.ql-editor {
  color: #dddddd;
}

.ql-editor.active-text {
  color: black;
}

#editor {
  border: transparent;
  min-height: 300px;
}

.editor-fammi .ql-snow .ql-picker {
  height: auto;
  min-width: 130px !important;
  max-width: 130px !important;
  width: auto !important;
}
.editor-fammi .ql-toolbar.ql-snow {
  padding: 0 !important;
  display: inline-block;
  border: transparent;
}

.editor-fammi .ql-video {
  width: 100%;
  height: 400px;
  text-align: center;
  margin: 0 auto;
  padding-bottom: 20px;
}

.editor-fammi .ql-formats {
  padding: 7px;
  background: white;
  border: solid 1px #dedede;
  border-radius: 12px;
}

#editor .ql-editor {
  padding: 24px 0;
}

#editor .ql-editor h1 {
  margin-bottom: 10px;
  font-family: Rubik;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
}

#editor .ql-editor p {
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.ava-register-blog {
  display: block;
  padding: 24px;
  border: solid 1px #7d61d6;
  border-radius: 12px;
  margin-top: 15px;
}

.ava-register-blog .img-ava-blog {
  display: inline-block;
  /*width: 20%;*/
}

.ava-register-blog .name-ava-blog {
  display: inline-block;
  padding-left: 15px;
  /*width: 75%;*/
}

.datepicker {
  z-index: 2;
}

.content-panduan {
  padding: 16px 24px;
}

#headerlogin {
  font-family: 'Rubik';
}

.dateselect-remix {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

#panduan h1 {
  font-family: Rubik;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
  color: #7d61d6;
}

#panduan h2 {
  font-family: Rubik;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 0;
}

#panduan h3 {
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
}

#panduan .img-arrow {
  padding-top: 0;
}

.content-panduan ol {
  padding-left: 15px;
}

.content-panduan ol li {
  padding-left: 10px;
}

#panduan .box-border-question,
#panduan .box-content-question {
  float: left;
  width: 100%;
}

.empty-search-active {
  margin-top: 18px;
}

.search-container-remix:before {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVCAYAAACpF6WWAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAInSURBVHgBrZRRbtNAEIZn1pXSCh58A8IjaRHpDXwEOEG3XKDpExIlOCEKEk+4ByBxTwA3aHoCp6IljzU3sFQeSpXsMLO2i91aSSz1l+xN1rufZmfnH4Qleq8v2gBOWwE8k/8KIZ7T4vxzuDNdtg+rJo/0zGOAD0Be1XcCmCrA4NP4xclKqK8jd4GNgCf3sqkEiH4QqDhdTG1+cfTQzHYHg1HrcCm0uz87zaJLiCj4A7fHQbibwIOTXHQcVD5H7AJSOBht71dCu28ve0DIR4Z4QebNqry901GzgY3IgskcDsKdoAT19axpkK6yOW8wbp3BGjrSPzn3jpwuuabb5/mplLwM0IFdRXyUNYGiYfhywgM/6D6FzYN83kIRwUvjxhBqytCin+79XykWynmRG4Vr+nsONbUBc5t7WxlFaK6qm16l/t0edEtQlHpkdbhOoab8uz2UlKDiEBmfwEYbamoOm3YPEU7LkRJO7IiODzWlVOo+KlxydvybY0kBP57U3rpA6REcouaf3GhuzkpQSTa7yJaGQvVdzLAK6HMHc5DGNkqu7y/hblyCioZsMwJzIrdo0ERd9nc1LHLF0oTqlLLGQqrcQx60vq7+9ZVnO9nHmBCmZNJLQAVNJHpt/W4jhEluHLZ5fzja7lVCRdyctQNqD3Kn3VMG64mlP+pLny+pVwRXQnOlnWjrlaHULRx1vKVo8uFb63dx3X3wUmgdFcGPBhXZtKG6+gdcge3Mm6GdSwAAAABJRU5ErkJggg==);
  z-index: 99;
  position: absolute;
  left: 15px !important;
  top: 9px;
}
.search-container-remix {
  padding: 0 0 !important;
}

.search-container-remix input {
  padding-left: 50px !important;
}

@media (min-width: 768px) {
  .r-75 {
    padding-right: 7.5px;
  }
  .l-75 {
    padding-left: 7.5px;
  }

  .nopadding {
    padding: 0 !important;
    margin: 0 !important;
  }
  .datepicker-orient-bottom {
    top: 583px !important;
  }

  .datepicker-orient-top {
    top: 285px !important;
  }
  .kelassaya-wrap .item {
    padding-left: 8px;
    padding-right: 8px;
  }
}

@media (max-width: 991px) {
  .search-container {
    margin-top: 16px;
  }
}

@media (max-width: 768px) {
  .r-75 {
    padding-right: 7.5px;
  }
  .l-75 {
    padding-left: 7.5px;
  }
  /*#menu-search {
		display: none !important;
	}*/

  .register-container #fammi-logo-header,
  .register-container .menuutama .row > div > a > img,
  .register-container #mobile-button-content > img {
    position: inherit;
  }

  .loginheader {
    padding: 0 12px;
  }
  #step-in .steps {
    background: #eaeaea;
    color: #999999;
  }
  #step-in .w-4:after {
    background-color: #eaeaea;
    width: 100%;
    height: 3px !important;
    top: 46%;
    left: 0;
  }
  #step-in .w-4:last-child:after {
    background: rgb(234, 234, 234);
    background: linear-gradient(
      90deg,
      rgba(234, 234, 234, 1) 0%,
      rgba(234, 234, 234, 1) 50%,
      rgba(255, 255, 255, 0) 50%
    );
  }

  .kelassaya-wrap {
    padding: 24px 9px;
  }
  #menu-search .search-container:before {
    display: none !important;
  }
  .search-container {
    padding: 0 0 !important;
    margin-top: 16px;
  }
  .masuk-kelas-header.no-wid-mobile {
    min-width: inherit !important;
  }
  .testimony-wrap {
    padding: 24px;
  }
  #signup-stepnav-2 {
    overflow: hidden;
  }
  .signup-table {
    padding: 0 24px;
  }
  .hidden-xs {
    display: none !important;
  }
  .transparent-version,
  .transparent-version .wrap-register-mitra {
    padding: 0;
  }
  .wrap-nav-dashboard {
    float: left;
    width: 100%;
  }
  .wrap-nav-dashboard .nav {
    margin: 0px -15px;
  }
  .wrap-nav-dashboard li {
    float: left;
  }

  /*.judul-af, .peserta-af, .tgl-af, .nar-af, .harga-af, .link-af{
		min-width: auto;
		max-width: inherit;
	}*/
  .link-af {
    min-width: 130px;
    max-width: 130px;
  }

  .table-das-af.table-responsive > .table > tbody > tr > td,
  .table-das-af.table-responsive > .table > tbody > tr > th,
  .table-das-af.table-responsive > .table > tfoot > tr > td,
  .table-das-af.table-responsive > .table > tfoot > tr > th,
  .table-das-af.table-responsive > .table > thead > tr > td,
  .table-das-af.table-responsive > .table > thead > tr > th {
    white-space: inherit !important;
  }
  .left-affiliate {
    width: 100%;
    float: left;
    padding: 24px;
    border-right: transparent;
    border-bottom: solid 1px #eaeaea;
  }

  .right-affiliate {
    width: 100%;
    float: left;
    padding: 24px;
  }
  .banner-affiliate {
    margin-bottom: 24px;
  }
  .pagination-global a {
    padding: 10px 15px !important;
    width: 48px;
    height: 48px;
    margin-left: 0 !important;
  }
  .table-das-af {
    font-size: 14px;
  }
  .judul-af {
    min-width: 140px;
    max-width: 140px;
  }
  .global-popup .content-copy-link {
    margin-top: 0px !important;
    max-width: inherit;
  }
  .global-popup .wrap-copy-table {
    padding: 0px;
  }

  .global-popup .wrap-copy-center {
    vertical-align: bottom;
  }
  .content-copy-link {
    margin-top: -135px !important;
  }
  .back-fammi {
    margin-right: 0;
    margin-left: 24px;
  }
  .info-detail-kelas {
    margin-top: 24px;
  }
  .card-kelas-jual {
    margin-bottom: 24px;
  }
  .no-margin-top {
    margin-top: 0 !important;
  }
  .pad-left-35 {
    padding-left: 24px !important;
  }
  .pad-right-35 {
    padding-right: 24px !important;
  }
  .statistic-bottom .card-kelas-jual {
    border-right: transparent;
    border-bottom: solid 1px #eaeaea;
  }
  .statistic-bottom .card-kelas-jual.no-border {
    border-bottom: transparent;
  }
  .text-icon-kelas {
    padding-left: 15px;
  }
  .btn-remix-kategori .col-sm-6 {
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 20px;
  }
  .abs-btn-editor {
    position: inherit;
    top: 0px;
    right: 0;
    width: 100%;
    margin-bottom: 8px;
  }

  .select-remix .col-sm-6:first-child {
    padding-left: 0px;
    padding-right: 0px;
  }
  .select-remix .col-sm-6:last-child {
    padding-left: 0px;
    padding-right: 0px;
  }
  .editor-fammi .ql-formats {
    margin-bottom: 10px;
  }
  #filterDate2 {
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .image-banner {
    padding: 0 16px 16px 16px;
    margin: 0px !important;
  }
}

.metode-pembayaran {
  background: #ffffff;
  border: 1px solid #dedede;
  box-sizing: border-box;
  border-radius: 12px;
  margin: 16px 0 36px;
  width: 100%;
  height: 56px;
  padding: 8px 24px 8px 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.overlay-modal-pembayaran {
  position: fixed;
  bottom: 0px;
  right: 0px;
  top: 0px;
  left: 0px;
  display: flex;
  background: rgba(0, 0, 0, 0.65);
  height: 100vh;
  z-index: 1000;
}
.modal-pembayaran {
  background: #ffffff;
  border-radius: 12px;
  position: fixed;
  width: 400px;
  padding: 24px;
  z-index: 9999;
  margin: 0px auto;
  transition: all ease 0.5s;
}

@media (max-width: 900px) {
  .modal-pembayaran {
    bottom: 0;
    width: 100%;
    border-radius: 12px 12px 0 0;
  }
}

.wrapper-or-social {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.divide-or {
  height: 1px;
  background-color: #dedede;
  width: 103px;
}
.text-or {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin: unset !important;
  color: #999999 !important;
}

.section-social-login {
  margin-top: 24px;
}
.btn-socials {
  background-color: #fff !important;
  width: 48%;
  height: 41px !important;
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.87) !important;
  box-sizing: border-box;
  border-radius: 12px;
  justify-content: center;
  margin: 0 !important;
}
.text-socials {
  font-family: Rubik !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  margin: unset !important;
  color: rgba(0, 0, 0, 0.87) !important;
  margin-left: 10px !important;
}
.daftar-in-btn:hover {
  color: #7d61d6 !important;
}
.daftar-page p span a {
  color: #7d61d6;
}
.btn-daftar:hover {
  opacity: 1 !important;
}
.btn-socials:hover {
  opacity: 1 !important;
}
.already-reg {
  display: none;
}
#bar-register #new-header {
  display: none;
}
.kebijakan-section {
  margin-bottom: 24px;
  margin-top: 36px;
}
.kebijakan-section span {
  cursor: pointer;
  text-decoration: underline;
  font-weight: 500;
  color: #7d61d6;
}
.kebijakan-section p {
  font-family: Lato !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.87);
}
.span-commit {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.87);
}
@media (max-width: 768px) {
  .daftar-page {
    border: unset !important;
    margin-top: 0px !important;
    width: 100% !important;
  }
  #signup-page {
    background-color: #fff !important;
  }
  #bar-login #menu-header {
    box-shadow: unset !important;
  }
  #bar-register #menu-header {
    display: none !important;
  }
  #bar-register #navigator-utama {
    position: absolute !important;
  }
  #bar-register #new-header {
    display: flex;
    padding: 16px;
    align-items: center;
  }
  .signup-table {
    padding: 0px !important;
  }
  .text-or {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    margin: unset !important;
    color: #999999;
  }
  .btn-socials {
    width: 48%;
    height: 41px;
  }
  #formlogin button {
    height: 41px !important;
  }
  .text-socials {
    font-family: Rubik !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 17px !important;
    color: rgba(0, 0, 0, 0.87) !important;
    margin: unset !important;
    margin-left: 10px !important;
  }
  .already-reg {
    display: block;
  }
  .text-ready {
    font-family: Rubik !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    margin: unset !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  #formlogin button.btn-masuk {
    border: 1px solid #7d61d6 !important;
    box-sizing: border-box;
    border-radius: 12px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    width: 100%;
    height: 41px;
    margin: unset !important;
    margin-top: 10px !important;
    background-color: #fff !important;
    color: #7d61d6 !important;
  }
  #fammi-logo-header,
  .menuutama .row > div > a > img,
  #mobile-button-content > img {
    display: block;
  }
  #signup-page #headerlogin {
    display: none;
  }
  #arrow-back-header {
    position: absolute;
    top: 16px;
    left: calc(50% - 54px);
  }
  .kebijakan-section p {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.87);
  }
  .row.loginheader > div > a > img {
    display: none !important;
  }
  #formlogin {
    padding: 24px 16px !important;
  }
}

.parent-voice-over {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.65);
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: hidden !important;
}
.box-voice-over {
  background: #ffffff;
  border-radius: 12px;
  width: 375px;
  height: 411px;
}
.header-voice-over {
  background: url(/assets/images/banner-popup-over.svg) center no-repeat;
  border-radius: 12px 12px 0px 0px;
  background-size: cover;
  width: 100%;
  height: 212px;
}
.parent-content-over {
  padding: 24px;
}

.text-title-over {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  margin: unset !important;
  color: rgba(0, 0, 0, 0.87);
}
.text-description-over {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  margin-top: 10px;
  margin-bottom: 24px;
  color: rgba(0, 0, 0, 0.87);
}
.parent-btn-over {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.btn-masuk-over {
  width: 48%;
  border: 1px solid #7d61d6;
  box-sizing: border-box;
  border-radius: 12px;
  background: #fff;
  height: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-masuk-over p {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin: unset !important;
  color: #7d61d6;
}
.btn-daftar-over {
  width: 48%;
  box-sizing: border-box;
  border-radius: 12px;
  background: #7d61d6;
  height: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: unset;
}
.btn-daftar-over p {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin: unset !important;
  color: #fff;
}

@media (max-width: 768px) {
  .parent-voice-over {
    align-items: flex-end;
  }
  .box-voice-over {
    width: 100%;
    border-radius: 12px 12px 0px 0px;
  }
  .text-title-over {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.87);
  }
  .text-description-over {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.87);
  }
}

.cards-qna {
  width: 215px;
  height: auto;
  background: #ffffff;
  border: 1px solid #dedede;
  box-sizing: border-box;
  margin-right: 24px;
  border-radius: 12px;

  flex: unset !important;
}
.cards-qna img {
  height: 215px;
  width: 215px;
  border-radius: 12px 12px 0px 0px;
  object-fit: cover;
}
.cards-qna .contents {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: auto;
  width: 100%;
}
.cards-qna .contents .name {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 17px;
  margin: unset !important;
  color: rgba(0, 0, 0, 0.87);
}

.cards-qna .contents .description {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin: unset !important;
  margin-top: 14px !important;
  margin-bottom: 8px !important;
  color: rgba(0, 0, 0, 0.87);
}
.cards-qna .contents .description span {
  font-family: Lato;
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 14px;
}
.cards-qna .contents .description span.isSoon {
  color: #7d61d6;
}
.cards-qna .contents .description span.isEnd {
  color: #000;
}
.cards-qna .contents .description span.isOnGoing {
  color: #fd8c25;
}

.cards-qna .contents .time {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin: unset !important;
  padding-bottom: 12px;
  color: rgba(0, 0, 0, 0.87);
}

.cards-qna .contents button {
  background: #7d61d6;
  border-radius: 12px;
  margin-top: 16px;
  width: 100%;
  height: 41px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  border: unset;
}

.the-answer {
  margin-top: 12px;
  margin-bottom: 12px;
}

@media (max-width: 768px) {
  .cards-qna {
    width: 46%;
  }
  .cards-qna img {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .the-answer {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .cards-qna .contents .description span {
    font-weight: 400;
    font-size: 10px;
  }
  .cards-qna {
    width: 46%;
    height: auto;
    margin-right: 16px;
  }
  .cards-qna img {
    height: 144px;
    width: 100%;
  }
  .cards-qna .contents .name {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    width: 128px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 14px;
    margin: unset !important;
    color: rgba(0, 0, 0, 0.87);
  }
  .cards-qna .contents .description {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    margin: unset !important;
    margin-top: 12px !important;
    margin-bottom: 4px !important;
    color: rgba(0, 0, 0, 0.87);
  }
  .cards-qna .contents {
    padding: 8px;
    height: auto;
  }

  .cards-qna .contents .time {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: rgba(0, 0, 0, 0.87);
  }
  .cards-qna .contents button {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #ffffff;
  }
}

.parent-dialog-tes {
  background: rgba(0, 0, 0, 0.5);
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.box-dialog-tes {
  background: #fff;
  border-radius: 12px;
  padding: 24px;
  width: 364px;
}
.section-header-dialog-tes {
  display: flex;
  align-items: center;
  width: 100%;
}
.img-dialog-tes {
  height: 58px;
  width: 58px;
  border-radius: 50%;
  object-fit: cover;
}
.section-titles-tes {
  width: 242px;
  margin-left: 15px;
}
.text-name-dialog-tes {
  margin: unset !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.87);
}
.text-about-dialog-tes {
  margin: unset !important;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin-top: 4px !important;
  color: #999999;
}
.section-content-testimoni {
  margin-top: 30px;
  margin-bottom: 24px;
}
.text-content-dialog-tes {
  margin: unset !important;
  font-family: Lato;
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.87);
}
.btn-dialog-tes {
  width: 100%;
  background: #7d61d6;
  border-radius: 12px;
  padding: 12px 0px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  border: unset;
  color: #ffffff;
}
@media (max-width: 900px) {
  .box-dialog-tes {
    width: 100%;
    border-radius: 12px 12px 0px 0px;
  }
  .parent-dialog-tes {
    align-items: flex-end;
  }
}

.container-topepisode {
  max-width: 1170px;
  width: 100%;
  margin: 24px auto;
}
.parents-card-tops {
  display: flex;
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 12px;
  width: 374px;
  height: 174px;
  margin-right: 24px;
  cursor: pointer;
}
.img-card-tops {
  height: 100%;
  width: 175px;
  object-fit: cover;
  border-radius: 12px 0px 0px 12px;
}
.div-content-tops {
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.parent-naras-tops {
  display: flex;
  align-items: center;
}
.img-naras-tops {
  height: 48px;
  width: 48px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}
.text-title-naras-tops {
  margin: unset !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.87);
}
.text-title-naras-tops-mob {
  display: none;
}
.text-content-tops {
  margin: 16px 0px !important;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.87);
}
.parent-plays-tops {
  display: flex;
  align-items: center;
}
.text-duration-tops {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin: unset !important;
  color: #999999;
  margin-left: 12px !important;
}
.text-jabatan {
  display: none;
}
.text-title-naras-tops-med {
  display: none;
}
.text-jabatan-425 {
  display: none;
}
@media (max-width: 768px) {
  .container-topepisode {
    max-width: 100%;
    width: 100%;
    margin: 0px;
    margin-top: 24px;
  }
  .parent-carousels {
    margin-top: 16px;
  }

  .parents-card-tops {
    margin-right: 16px;
    width: 256px;
    height: 128px;
  }
  .img-naras-tops {
    height: 34px;
    width: 34px;
  }
  .text-content-tops {
    display: none;
  }
  .parent-naras-tops {
    display: block;
  }
  .text-title-naras-tops {
    display: none;
  }
  .text-title-naras-tops-mob {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 12px;
    display: block;
    margin-top: 13px !important;
    color: rgba(0, 0, 0, 0.87);
  }
  .text-duration-tops {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #999999;
  }
  .parent-plays-tops img {
    width: 25px;
    height: 25px;
  }
  .img-card-tops {
    width: 128px;
  }
  .div-content-tops {
    padding: 12px;
  }
}

.card-register-company {
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 12px;
  width: 750px;
  display: flex;
}
.parent-sidesect {
  width: 50%;
  background: url(/assets/images/bg-partner-log.svg) center no-repeat;
  background-size: cover;
  border-radius: 12px 0px 0px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.parent-sidesect .menuz {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;
  text-align: center;
}
.parent-sidesect img {
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 6px;
  width: 100px;
  height: 100px;
}
.parent-sidesect h1 {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  margin-top: 14px !important;
  margin-bottom: 16px !important;
  color: #ffffff;
}
.parent-sidesect p {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  margin: unset !important;
  color: #ffffff;
}
.parent-formsect {
  width: 50%;
  padding: 24px;
}
.title-register-company {
  font-family: Rubik;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  margin: unset !important;
  color: rgba(0, 0, 0, 0.87);
}
.parent-form-company {
  margin-top: 24px;
}
.list-register-company {
  display: block;
  width: 100%;
  margin-bottom: 16px;
}
.list-register-company span {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.87);
}
.input-register-company {
  width: 100%;
  margin-top: 8px;
  background: #ffffff;
  border: 1px solid #dedede;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 12px;
}
.input-register-company:focus {
  outline: unset;
  background: #ffffff;
  border: 1px solid #7d61d6;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 12px;
}
.input-register-company::-webkit-input-placeholder {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}
.input-register-company:-ms-input-placeholder {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}
.input-register-company::-ms-input-placeholder {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}
.input-register-company::placeholder {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}
.kebijakan-register-company {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  margin: unset !important;
  color: #585858;
}
.kebijakan-register-company b {
  text-decoration: underline;
  cursor: pointer;
  color: #7d61d6;
}

.btn-register-company {
  background: #7d61d6;
  border-radius: 12px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  height: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: unset;
  margin-top: 24px;
  width: 100%;
}

.alert-register-company {
  display: flex;
  align-items: center;
  margin-top: 24px;
  background: #fff8db;
  border: 1px solid #ffc400;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 16px;
}
.text-alert-company {
  font-family: Rubik;
  font-style: normal;
  font-size: 12px;
  line-height: 14px;
  margin: unset !important;
  margin-left: 14px !important;
  color: rgba(0, 0, 0, 0.87);
}

.or-section-company {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 24px;
}
.divider-or-company {
  background: #dedede;
  height: 1px;
  width: 30%;
}
.text-or-company {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin: unset !important;
  color: #999999;
}
.parent-btn-social {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  width: 100%;
}

.btn-social-auth {
  display: flex;
  align-items: center;
  width: 48%;
  border: 1px solid rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  border-radius: 12px;
  background: #fff;
  height: 41px;
  justify-content: center;
}
.btn-social-auth p {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin: unset !important;
  margin-left: 10px !important;
  color: rgba(0, 0, 0, 0.87);
}
.parent-eye-ind {
  position: relative;
  width: 12%;
  float: right;
  margin-top: -36px;
  cursor: pointer;
}
.text-belum-acc {
  margin: unset !important;
  margin-top: 24px !important;
  margin-bottom: 10px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.87);
}
.btn-daftar-partner {
  width: 100%;
  border: 1px solid #7d61d6;
  box-sizing: border-box;
  border-radius: 12px;
  background: #fff;
  height: 41px;
  justify-content: center;
  align-items: center;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #7d61d6;
}

@media (max-width: 768px) {
  #logins.card-register-company {
    width: 100%;
    display: block;
    border: unset;
    padding: 0px !important;
  }
  .parent-sidesect {
    width: 100%;
    border-radius: 0px;
    height: 193px;
    background-size: cover !important;
    background: url(/assets/images/bg-partner-login-mob.svg) center no-repeat;
    padding: 0px 24px;
  }
  .parent-sidesect .menuz {
    flex-direction: row;
    width: 100%;
    text-align: left;
  }
  .parent-sidesect .menuz img {
    width: 81px;
    height: 81px;
    margin-right: 16px;
  }
  .parent-sidesect .menuz h1 {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    margin-top: 0px !important;
    margin-bottom: 6px !important;
    color: #ffffff;
  }
  .parent-sidesect .menuz p {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
  }
  .parent-formsect {
    width: 100%;
    padding: 16px;
  }
}

.header-register-company {
  width: 100%;
  top: 0px;
  position: fixed;
  background: #ffffff;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
}
#new-header {
  display: none;
}
.container-header-company {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1170px;
  margin: auto;
  padding: 12px 0px;
}
.text-header-register-company {
  margin: unset !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.87);
}
.text-header-register-company b {
  cursor: pointer;
  text-decoration: underline;
  color: #7d61d6;
}
.container-register-company {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  margin-top: -90px;
}
@media (max-width: 768px) {
  .container-register-company {
    height: auto;
    margin-top: unset;
  }
  .header-register-company {
    box-shadow: unset;
    background-color: #fdfbff;
  }
  .parent-form-company {
    margin-top: 16px !important;
  }
  .card-register-company {
    border: unset !important;
    background-color: unset !important;
    width: 100% !important;
    padding: 16px !important;
  }
  .container-header-company {
    display: none;
  }
  .header-register-company #new-header {
    display: block !important;
    padding: 16px;
  }
}

#detail_section {
    display: flex;
    flex-direction: column;
    margin: 24px 0px;
}

#detail_section hr {
    margin: 0px;
}

#detail_section .ds_head {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

#detail_section .ds_title {
    display: flex;
    flex-direction: row;
    align-items: center;
}

#detail_section .ds_title h6 {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.87);
    margin: 0;
}

#detail_section .dot {
    width: 4px;
    height: 17px;
    background: #6C60E0;
    border-radius: 0px 4px 4px 0px;
    margin-right: 12px;
}

#detail_section .ds_box {
    border-radius: 12px;
    border: 1px solid #EAEAEA;
    margin: 0px 16px;
    margin-top: 10px;
}

#detail_section .ds_img {
    padding-right: 16px;
}


/* Section Content */
#section_content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
}

#section_content p {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.87);
}

#section_content div>p:nth-child(1) {
    color: #999999;
    margin-bottom: 4px;
}

#section_content button {
    background: #FFFFFF;
    border-width: 1px;
    border-style: solid;
    border-color: #6C60E0;
    border-radius: 8px;
    padding: 8px;
    width: 60px;
}

#section_content button>p {
    color: #6C60E0;
}


/* Payment Info */
#payment_info {
    border-radius: 12px;
    border-width: 1px;
    border-style: solid;
    padding: 12px;
    margin: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

#payment_info p {
    margin-left: 8px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #6C60E0;
}


/* Paymend Fixed Button */
#pf_button.related {
    position: relative;
    bottom: 0px;
    left: 0px;
    width: 100%;
    background: #FFFFFF;
    box-shadow: none;
    border-top: solid 1px #EAEAEA;
    border-radius: 0px;
    padding: 16px;
}

#pf_button button {
    background: #6C60E0;
    border-radius: 12px;
    width: 100%;
    padding: 12px;
    border: none;
    color: #fff;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
}


@media (min-width:1000px) {
    /* #pf_button {
        max-width: 360px;
    } */
}
#payment_success .ps_program {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
}

#payment_success .ps_program p {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.87);
}

#payment_success .ps_notes {
    margin: 16px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.87);
}
#detail_section .payment_status {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 14px 16px;
}

#detail_section .payment_status p {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.87);
}
#payment_instructions .pi_head {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 14px 16px;
}

#payment_instructions p {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.87);
}

#payment_instructions .pi_content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    padding-right: 12px;
}

#payment_instructions .pi_content p {
    color: #6C60E0;
}

#payment_instructions .pi_content button {
    width: 60px;
    height: 30px;
    background: #FFFFFF;
    border: 1px solid #6C60E0;
    border-radius: 8px;
}

#payment_instructions .pi_instruction {
    padding: 0px 16px 16px 16px;
}

#payment_instructions .pi_instruction div {
    display: flex;
    flex-direction: row;
    align-items: start;
}

#payment_instructions .pi_instruction p {
    font-weight: 400;
    line-height: 18px;
}
#payment-package .headers {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05);
  position: fixed;
  top: 0px;
  left: 0px;
  padding: 12px;
}
#payment-package .parent-containers {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 32px;
  background: #ffffff;
  min-height: 100vh;
}

#payment-package .containers {
  max-width: 360px;
  width: 100%;
}
@media (max-width: 768px) {
  #payment-package .headers img {
    height: 32px;
  }
  #payment-package .containers {
    max-width: unset;
    padding: 16px;
  }
  #payment-package .parent-containers {
    padding-top: 0px;
  }
}

.parent-call {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 12px;
  padding: 16px;
}
.parent-call div {
  width: 50%;
}
.parent-call h1 {
  margin: 0px !important;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.87);
}
.parent-call h5 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  margin-top: 12px;
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.87);
}
.parent-call button {
  display: flex;
  align-items: center;
  padding: 7px 17px;
  background: linear-gradient(180deg, #20b16e 0%, #00bb66 100%);
  border-radius: 8px;
  cursor: pointer;
  border: none;
}
.parent-call button p {
  margin: 0px !important;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
}

.parent-call button img {
  margin-right: 8px;
  height: 16px;
  width: 16px;
}

/* @media (max-width: 768px) {
  .parent-call {
    border: none;
  }
} */

#prog-profile {
  position: fixed;
  z-index: 2000;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  height: 100vh;
  left: 0px;
  top: 0px;
}
#prog-profile .mandatory {
  border: solid 1px #EA4335 !important; 
}
#prog-profile.profs {
  position: unset !important;
  background: unset;
  width: unset;
  display: unset;
  height: auto;
}
#prog-profile .boxes {
  width: 100%;
  max-width: 360px;
  background: #ffffff;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  padding: 0px;
  overflow-x: hidden;
  height: auto;
}
#prog-profile.profs .boxes {
  overflow-x: unset !important;
  height: auto;
  max-width: unset;
}

#prog-profile .boxes .headered p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.87);
  margin: 0px !important;
  margin-left: 12px !important;
}
#prog-profile.profs .boxes .bodys {
  height: 100%;
  max-height: unset;
  overflow-y: unset;
  margin-bottom: 90px;
}
#prog-profile .boxes .headered {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
}
#prog-profile .boxes .headered div {
  display: flex;
  align-items: center;
}

#prog-profile .boxes .bodys .item-list {
  margin-bottom: 16px;
}
#prog-profile .boxes .bodys .pg {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin: 0px !important;
  color: rgba(0, 0, 0, 0.87);
}
#prog-profile .boxes .bodys .alerto {
  display: flex;
  background: #f5f0ff;
  border-radius: 6px;
  padding: 7px 12px;
  margin-top: 10px;
}
#prog-profile .boxes .bodys .alerto p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  margin: 0px !important;
  margin-left: 6px !important;
  color: rgba(0, 0, 0, 0.87);
}
#prog-profile .boxes .bodys input {
  width: 100%;
  padding: 12px 14px;
  background: #ffffff;
  border: 1px solid #dedede;
  border-radius: 12px;
  margin-top: 8px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  outline: none;
}
#prog-profile input[disabled] {
  color: #000 !important;
}
#prog-profile .boxes .bodys textarea {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  width: 100%;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  outline: none;
  resize: none;
  background: #ffffff;
  border: 1px solid #dedede;
  border-radius: 12px;
  padding: 12px;
  margin-top: 10px;
}
#prog-profile .boxes .bodys .pg-desc {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin: 0px !important;
  margin-top: 10px !important;
  color: #999999;
}
#prog-profile .boxes .bodys input:focus {
  border: 1px solid #6c60e0;
}

#prog-profile .boxes .bodys {
  height: auto;
  max-height: 450px;
  overflow-y: scroll;
  scrollbar-width: none;
  padding: 16px;
}
#prog-profile .boxes .bodys::-webkit-scrollbar {
  display: none;
}

#prog-profile .boxes .bodys::-webkit-scrollbar-track {
  border-radius: 10px;
}
#prog-profile .boxes .bodys::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

#prog-profile .boxes .cta-bottom {
  padding: 16px;
  box-shadow: 0px -5px 30px rgba(0, 0, 0, 0.1);
}
#prog-profile .boxes .cta-bottom button {
  background: #6c60e0;
  border-radius: 12px;
  border: none;
  width: 100%;
  padding: 12px 0px;
  text-align: center;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}
#prog-profile .boxes .headered .close-icon {
  display: none;
}
.fp_gender {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
}
.fp_gender_item_active {
  width: 47.5%;
  background: #f5f0ff;
  padding: 11px 12px;
  border: 1px solid #6c60e0;
  box-sizing: border-box;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.fp_gender_item_active.profile_disabled {
  background: #f3f3f3;
  border: 1px solid #dedede;
  color: #999;
}
.fp_gender_item_active div {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 5px solid #6c60e0;
}
.fp_gender_item_active p {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin: 0px !important;
  margin-left: 8px !important;
  color: #6c60e0;
}
.fp_gender_item_active.profile_disabled p {
  color: #999;
}
.fp_gender_item_active.profile_disabled div {
  border: 5px solid #999;
}
.fp_gender_item {
  width: 47.5%;
  background: #ffffff;
  border: 1px solid #dedede;
  padding: 11px 12px;
  box-sizing: border-box;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.fp_gender_item.profile_disabled {
  background: #f3f3f3;
  border: 1px solid #dedede;
}
.fp_gender_item div {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 3px solid #dedede;
}
.fp_gender_item p {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin: 0px !important;
  margin-left: 8px !important;
  color: #6c60e0;
}
.fp_gender_item.profile_disabled p {
  color: #999;
}
.fq_select, .fq_select_active {
  width: 100% !important;
}
.fq_select p, .survey .fp_gender_item p {
  color: #000 !important;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin: 0px !important;
  margin-left: 8px !important;
}
.fq_select_active p {
  color:#6C60E0;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin: 0px !important;
  margin-left: 8px !important;
}
@media (max-width: 500px) {
  #prog-profile {
    align-items: flex-end;
  }
  #prog-profile .boxes {
    max-width: 100%;
    height: auto;
    max-height: 90%;
    border-radius: 12px 12px 0px 0px;
  }
  #prog-profile .boxes .cta-bottom {
    position: fixed;
    bottom: 0px;
    z-index: 99999;
    background: #fff;
    width: 100%;
  }
  #prog-profile .boxes .bodys {
    max-height: 100%;
    margin-bottom: 64px;
  }
  #prog-profile .boxes .scrolled-mob {
    height: auto;
    max-height: 80%;
    overflow-y: scroll;
    scrollbar-width: none;
  }
  #prog-profile .boxes .scrolled-mob::-webkit-scrollbar {
    display: none;
  }

  #prog-profile .boxes .scrolled-mob::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  #prog-profile .boxes .scrolled-mob::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
  }
  #prog-profile .boxes .headered .close-icon {
    display: block;
    cursor: pointer;
  }
  #prog-profile.profs .boxes .bodys {
    margin-bottom: 0px;
  }
}

#detail_section .payment_status {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 14px 16px;
}

#detail_section .payment_status p {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.87);
}

#detail_section .ps_notes {
    margin: 16px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.87);
}
#detail_section {
    display: flex;
    flex-direction: column;
    margin: 24px 0px;
}

#detail_section hr {
    margin: 0px;
}

#detail_section .ds_head {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

#detail_section .ds_title {
    display: flex;
    flex-direction: row;
    align-items: center;
}

#detail_section .ds_title h6 {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.87);
    margin: 0;
}

#detail_section .dot {
    width: 4px;
    height: 17px;
    background: #6C60E0;
    border-radius: 0px 4px 4px 0px;
    margin-right: 12px;
}

#detail_section .ds_box {
    border-radius: 12px;
    border: 1px solid #EAEAEA;
    margin: 0px 16px;
    margin-top: 10px;
}

#detail_section .ds_img {
    padding-right: 16px;
}


/* Section Content */
#section_content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
}

#section_content p {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.87);
}

#section_content div>p:nth-child(1) {
    color: #999999;
    margin-bottom: 4px;
}

#section_content button {
    background: #FFFFFF;
    border-width: 1px;
    border-style: solid;
    border-color: #6C60E0;
    border-radius: 8px;
    padding: 8px;
    width: 60px;
}

#section_content button>p {
    color: #6C60E0;
}


/* Payment Info */
#payment_info {
    border-radius: 12px;
    border-width: 1px;
    border-style: solid;
    padding: 12px;
    margin: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

#payment_info p {
    margin-left: 8px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #6C60E0;
}


/* Paymend Fixed Button */
#pf_button {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px -5px 30px rgba(0, 0, 0, 0.1);
    border-radius: 12px 12px 0px 0px;
    padding: 16px;
}

#pf_button button {
    background: #6C60E0;
    border-radius: 12px;
    width: 100%;
    padding: 12px;
    border: none;
    color: #fff;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
}


@media (min-width:1000px) {
    /* #pf_button {
        max-width: 360px;
    } */
}
#payment_instructions .pi_head {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 14px 16px;
}

#payment_instructions p {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.87);
}

#payment_instructions .pi_content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    padding-right: 12px;
}

#payment_instructions .pi_content p {
    color: #6C60E0;
}

#payment_instructions .pi_content button {
    width: 60px;
    height: 30px;
    background: #FFFFFF;
    border: 1px solid #6C60E0;
    border-radius: 8px;
}

#payment_instructions .pi_instruction {
    padding: 0px 16px 16px 16px;
}

#payment_instructions .pi_instruction div {
    display: flex;
    flex-direction: row;
    align-items: start;
}

#payment_instructions .pi_instruction p {
    font-weight: 400;
    line-height: 18px;
}
#payment_success .ps_program {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
}

#payment_success .ps_program p {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.87);
}

#payment_success .ps_notes {
    margin: 16px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.87);
}
#detail_section {
    display: flex;
    flex-direction: column;
    margin: 24px 0px;
}

#detail_section hr {
    margin: 0px;
}

#detail_section .ds_head {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

#detail_section .ds_title {
    display: flex;
    flex-direction: row;
    align-items: center;
}

#detail_section .ds_title h6 {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.87);
    margin: 0;
}

#detail_section .dot {
    width: 4px;
    height: 17px;
    background: #6C60E0;
    border-radius: 0px 4px 4px 0px;
    margin-right: 12px;
}

#detail_section .ds_box {
    border-radius: 12px;
    border: 1px solid #EAEAEA;
    margin: 0px 16px;
    margin-top: 10px;
}

#detail_section .ds_img {
    padding-right: 16px;
}


/* Section Content */
#section_content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
}

#section_content p {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.87);
}

#section_content div>p:nth-child(1) {
    color: #999999;
    margin-bottom: 4px;
}

#section_content button {
    background: #FFFFFF;
    border-width: 1px;
    border-style: solid;
    border-color: #6C60E0;
    border-radius: 8px;
    padding: 8px;
    width: 60px;
}

#section_content button>p {
    color: #6C60E0;
}


/* Payment Info */
#payment_info {
    border-radius: 12px;
    border-width: 1px;
    border-style: solid;
    padding: 12px;
    margin: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

#payment_info p {
    margin-left: 8px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #6C60E0;
}


/* Paymend Fixed Button */
#pf_button {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px -5px 30px rgba(0, 0, 0, 0.1);
    border-radius: 12px 12px 0px 0px;
    padding: 16px;
}

#pf_button button {
    background: #6C60E0;
    border-radius: 12px;
    width: 100%;
    padding: 12px;
    border: none;
    color: #fff;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
}


@media (min-width:1000px) {
    /* #pf_button {
        max-width: 360px;
    } */
}
#payment_success .ps_program {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
}

#payment_success .ps_program p {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.87);
}

#payment_success .ps_notes {
    margin: 16px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.87);
}
