.fq_parent {
  margin-bottom: 72px;
}

.pop_right .fq_parent {
  margin-bottom: 0px;
}

.tx_fq_title {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 16px;
}

.tx_fq_desc {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #999999;
}

.txar_fq {
  background: #ffffff;
  border: 1px solid #dedede;
  box-sizing: border-box;
  border-radius: 12px;
  width: 100%;
  padding: 12px 14px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  outline: none;
  resize: none;
  margin-top: 10px;
  height: 140px;
}

.txar_fq:focus {
  border: 1px solid #6c60e0;
}

.txar_fq.mandatory {
  border: 1px solid #EA4335;
}

.wrapper_fq {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.inp_item.mandatory {
  border: solid 1px #EA4335;
}

.fq_select {
  width: 47.5%;
  background: #ffffff;
  border: 1px solid #dedede;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 10px 12px;
  margin-top: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.fq_select.mandatory {
  border: 1px solid #EA4335;
}

.fq_select_active {
  width: 47.5%;
  /* background: #ffffff; */
  background: #F5F0FF;
  border: 1px solid #6c60e0;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 10px 12px;
  margin-top: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.fq_container {
  background: #FFFFFF;
  border-radius: 12px;
  padding: 16px;
  margin-bottom: 16px;
}

.fq_wa_opt {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.fq_wa_opt .active_opt {
  background: #F5F0FF;
  border: 1px solid #6C60E0;
  border-radius: 12px;
}

.fq_opt_btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #DEDEDE;
  border-radius: 12px;
  padding: 10px;
  flex-grow: 1;
}

.fq_btn_label {
  margin-left: 8px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #6C60E0;
}