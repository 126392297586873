#payment_instructions .pi_head {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 14px 16px;
}

#payment_instructions p {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.87);
}

#payment_instructions .pi_content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    padding-right: 12px;
}

#payment_instructions .pi_content p {
    color: #6C60E0;
}

#payment_instructions .pi_content button {
    width: 60px;
    height: 30px;
    background: #FFFFFF;
    border: 1px solid #6C60E0;
    border-radius: 8px;
}

#payment_instructions .pi_instruction {
    padding: 0px 16px 16px 16px;
}

#payment_instructions .pi_instruction div {
    display: flex;
    flex-direction: row;
    align-items: start;
}

#payment_instructions .pi_instruction p {
    font-weight: 400;
    line-height: 18px;
}