.card-register-company {
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 12px;
  width: 750px;
  display: flex;
}
.parent-sidesect {
  width: 50%;
  background: url(/assets/images/bg-partner-log.svg) center no-repeat;
  background-size: cover;
  border-radius: 12px 0px 0px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.parent-sidesect .menuz {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;
  text-align: center;
}
.parent-sidesect img {
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 6px;
  width: 100px;
  height: 100px;
}
.parent-sidesect h1 {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  margin-top: 14px !important;
  margin-bottom: 16px !important;
  color: #ffffff;
}
.parent-sidesect p {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  margin: unset !important;
  color: #ffffff;
}
.parent-formsect {
  width: 50%;
  padding: 24px;
}
.title-register-company {
  font-family: Rubik;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  margin: unset !important;
  color: rgba(0, 0, 0, 0.87);
}
.parent-form-company {
  margin-top: 24px;
}
.list-register-company {
  display: block;
  width: 100%;
  margin-bottom: 16px;
}
.list-register-company span {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.87);
}
.input-register-company {
  width: 100%;
  margin-top: 8px;
  background: #ffffff;
  border: 1px solid #dedede;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 12px;
}
.input-register-company:focus {
  outline: unset;
  background: #ffffff;
  border: 1px solid #7d61d6;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 12px;
}
.input-register-company::placeholder {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}
.kebijakan-register-company {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  margin: unset !important;
  color: #585858;
}
.kebijakan-register-company b {
  text-decoration: underline;
  cursor: pointer;
  color: #7d61d6;
}

.btn-register-company {
  background: #7d61d6;
  border-radius: 12px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  height: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: unset;
  margin-top: 24px;
  width: 100%;
}

.alert-register-company {
  display: flex;
  align-items: center;
  margin-top: 24px;
  background: #fff8db;
  border: 1px solid #ffc400;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 16px;
}
.text-alert-company {
  font-family: Rubik;
  font-style: normal;
  font-size: 12px;
  line-height: 14px;
  margin: unset !important;
  margin-left: 14px !important;
  color: rgba(0, 0, 0, 0.87);
}

.or-section-company {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 24px;
}
.divider-or-company {
  background: #dedede;
  height: 1px;
  width: 30%;
}
.text-or-company {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin: unset !important;
  color: #999999;
}
.parent-btn-social {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  width: 100%;
}

.btn-social-auth {
  display: flex;
  align-items: center;
  width: 48%;
  border: 1px solid rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  border-radius: 12px;
  background: #fff;
  height: 41px;
  justify-content: center;
}
.btn-social-auth p {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin: unset !important;
  margin-left: 10px !important;
  color: rgba(0, 0, 0, 0.87);
}
.parent-eye-ind {
  position: relative;
  width: 12%;
  float: right;
  margin-top: -36px;
  cursor: pointer;
}
.text-belum-acc {
  margin: unset !important;
  margin-top: 24px !important;
  margin-bottom: 10px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.87);
}
.btn-daftar-partner {
  width: 100%;
  border: 1px solid #7d61d6;
  box-sizing: border-box;
  border-radius: 12px;
  background: #fff;
  height: 41px;
  justify-content: center;
  align-items: center;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #7d61d6;
}

@media (max-width: 768px) {
  #logins.card-register-company {
    width: 100%;
    display: block;
    border: unset;
    padding: 0px !important;
  }
  .parent-sidesect {
    width: 100%;
    border-radius: 0px;
    height: 193px;
    background-size: cover !important;
    background: url(/assets/images/bg-partner-login-mob.svg) center no-repeat;
    padding: 0px 24px;
  }
  .parent-sidesect .menuz {
    flex-direction: row;
    width: 100%;
    text-align: left;
  }
  .parent-sidesect .menuz img {
    width: 81px;
    height: 81px;
    margin-right: 16px;
  }
  .parent-sidesect .menuz h1 {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    margin-top: 0px !important;
    margin-bottom: 6px !important;
    color: #ffffff;
  }
  .parent-sidesect .menuz p {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
  }
  .parent-formsect {
    width: 100%;
    padding: 16px;
  }
}
