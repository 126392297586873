.parent-voice-over {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.65);
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: hidden !important;
}
.box-voice-over {
  background: #ffffff;
  border-radius: 12px;
  width: 375px;
  height: 411px;
}
.header-voice-over {
  background: url(/assets/images/banner-popup-over.svg) center no-repeat;
  border-radius: 12px 12px 0px 0px;
  background-size: cover;
  width: 100%;
  height: 212px;
}
.parent-content-over {
  padding: 24px;
}

.text-title-over {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  margin: unset !important;
  color: rgba(0, 0, 0, 0.87);
}
.text-description-over {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  margin-top: 10px;
  margin-bottom: 24px;
  color: rgba(0, 0, 0, 0.87);
}
.parent-btn-over {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.btn-masuk-over {
  width: 48%;
  border: 1px solid #7d61d6;
  box-sizing: border-box;
  border-radius: 12px;
  background: #fff;
  height: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-masuk-over p {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin: unset !important;
  color: #7d61d6;
}
.btn-daftar-over {
  width: 48%;
  box-sizing: border-box;
  border-radius: 12px;
  background: #7d61d6;
  height: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: unset;
}
.btn-daftar-over p {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin: unset !important;
  color: #fff;
}

@media (max-width: 768px) {
  .parent-voice-over {
    align-items: flex-end;
  }
  .box-voice-over {
    width: 100%;
    border-radius: 12px 12px 0px 0px;
  }
  .text-title-over {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.87);
  }
  .text-description-over {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.87);
  }
}
