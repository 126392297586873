.parent-card-news {
  margin-right: 24px;
  cursor: pointer;
}
.parent-card-news img {
  border-radius: 12px;
  width: 274px;
  height: 274px;
  object-fit: cover;
}

.audio-materials-container {
  max-width: 175px;
  margin-right: 18px;
  margin-bottom: 32px;
}

.audio-materials .parent-card-news {
  width: 100%;
}

.audio-materials-container:nth-child(4n) {
  margin-right: 0px;
}

.audio-materials .parent-card-news img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 768px) {
  .parent-card-news img {
    width: 156px;
    height: 156px;
  }
  .parent-card-news {
    margin-right: 16px;
  }
  .audio-materials-container {
    flex: 50%;
    max-width: 48%;
    /* max-height: 156px; */
    margin-right: 16px;
    margin-bottom: 16px;
  }
  .audio-materials-container:nth-child(even) {
    margin-right: 0px;
  }
}
@media (max-width: 420px) {
  .audio-materials-container {
    max-width: 47%;
  }
}