#dialog-enroll {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 1001;
}

#dialog-enroll .box-dialog {
  max-width: 500px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.14);
  border-radius: 12px 12px 0px 0px;
  padding: 16px;
  position: inherit;
  bottom: 0px;
}

#dialog-enroll .box-dialog .heads {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

#dialog-enroll .box-dialog h1 {
  margin: 0px !important;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.87);
}
#dialog-enroll .box-dialog p {
  margin: 0px !important;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  color: rgba(0, 0, 0, 0.87);
}

@media (min-width: 1000px) {
  #dialog-enroll {
    align-items: center;
  }
  #dialog-enroll .box-dialog {
    bottom: unset;
    border-radius: 12px;
    max-width: 416px;
  }
}
