@import url("https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap");

.itemcontainer-wrapper {
  display: none;
}

.section-label {
  position: relative;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
}

.banner-subscription {
  display: block;
}

.banner-subscription-mob {
  display: none;
}

.banner-mobile {
  display: none;
  padding-top: 24px;
}

.ketik-pertanyaan-wrapper {
  margin-top: 36px;
}

.ketik-pertanyaan-wrapper .title {
  font-family: "Rubik";
  font-size: 24px;
  font-weight: 500;
  padding-bottom: 0px;
}

.ketik-pertanyaan-wrapper .title .col-md-12 {
  padding: 0px;
}

.pertanyaan-form {
  font-family: "Rubik";
  font-size: 18px;
  font-weight: 500;
  line-height: 21.33px;
  margin-top: 16px;
}

.pertanyaan-form .left-side {
  padding: 0px;
}

.pertanyaan-form .right-side {
  padding: 0px;
  text-align: right;
}

.right-side .button-desktop,
.button-mobile {
  background: linear-gradient(180deg, #ff3f00 0%, #ff8057 100%);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 12px;
  padding: 18px 54px;
}

.button-mobile {
  display: none;
}

.pertanyaan-form .left-side .input-text {
  padding: 18px 24px;
  border: solid 1px #dedede;
  border-radius: 12px;
  width: 100%;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
}

#additional-banner-section {
  max-width: 1170px;
  width: 100%;
  margin: 24px auto;
  height: 80px;
  padding: 0;
}

#additional-banner-section-desktop {
  display: block;
}

#additional-banner-section-mobile {
  display: none;
}



@media (max-width: 768px) {
  #additional-banner-section {
    height: auto;
    padding: 0 16px;
  }

  #additional-banner-section-desktop {
    display: none;
  }

  #additional-banner-section-mobile {
    display: block;
  }

  .banner-mobile {
    display: block;
  }

  .home-head-m {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .home-head-m .one-head-m {
    width: 55%;
  }

  .one-head-m img {
    width: 75.77px;
    margin-bottom: 12px;
  }

  .one-head-m h1 {
    font-family: Fredoka One !important;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    margin: unset !important;
    display: flex;
    flex-direction: column;
    color: rgba(0, 0, 0, 0.87);
  }

  .one-head-m p {
    font-size: 10px !important;
    line-height: 12px !important;
    margin-top: 10px !important;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
  }

  .one-head-m h1 .span-home-h {
    color: #7d61d6;
  }

  .home-head-m .two-head-m {
    background: url("/assets/images/home-header-pict-m.png") right no-repeat;
    width: 40%;
    display: flex;
    align-items: center;
    background-size: contain;
  }

  .ketik-pertanyaan-wrapper {
    margin-top: 24px;
  }

  .ketik-pertanyaan-wrapper .title {
    font-size: 16px;
  }

  .ketik-pertanyaan-wrapper .title .col-md-12 {
    padding: 0px 8px;
  }

  .pertanyaan-form {
    font-size: 14px;
    line-height: 17px;
    margin-top: 10px;
  }

  .pertanyaan-form .left-side,
  .pertanyaan-form .right-side {
    padding: 0px 8px;
  }

  .button-mobile {
    display: block;
    padding: 13px 0;
    margin: 0 auto;
    font-size: 14px;
    width: 100%;
  }

  .button-desktop {
    display: none;
  }

  .pertanyaan-form .left-side .input-text {
    padding: 13px 24px;
    color: #999999;
  }

  .banner-subscription {
    display: none;
  }

  .banner-subscription-mob {
    display: block;
    padding: 0px 16px;
    margin: 8px 0px;
  }

  #parent-video-container .parent-video-container {
    margin: 0px !important;
    padding: 0px 16px !important;
  }

  #original-series img {
    margin-right: 16px !important;
  }
}

#parent-video-container .parent-video-container {
  background: #fdfbff !important;
  margin: 48px 0px;
  padding: 0px;
}

.semua {
  display: none;
}

#mitrakami .itemcontainer {
  height: 340px !important;
  padding: 20px 24px 20px;
}

.item .itemcontainer .mitracarousel {
  height: 200px !important;
}

.contentringkasan a.lihat-selengkapnya,
.contentringkasan a.lihat-selengkapnya:hover {
  font-family: Rubik;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  float: right;
  color: #7d61d6;
  margin-right: 40px;
}

.home-berkenalan {
  font-family: "Rubik";
  font-size: 32px;
  font-weight: 500;
  color: #7d61d6;
}

.home-berkenalan-adalah,
.home-mari-gabung {
  font-family: "Lato";
  font-size: 18px;
  margin-top: 14px;
  line-height: 21.6px;
}

.home-mari-gabung {
  max-width: 625px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.home-berkenalan-video {
  margin-top: 34px;
  border-radius: 12px;
  overflow: hidden;
  height: 396px;
}

.home-mitra-kami {
  font-family: "Rubik";
  font-size: 32px;
  font-weight: 500;
  color: #7d61d6;
  text-align: center;
  margin: 36px 0 34px 0;
}

.home-mitra-kami h5 {
  font-family: "Rubik";
  font-weight: 700;
}

.home-mari h3 {
  font-family: "Rubik";
  font-size: 32px;
  font-weight: 500;
}

.home-kata-mereka,
.home-mari-kerja h3,
#faq-home .alert {
  font-family: "Rubik";
  font-size: 32px;
  font-weight: 500;
  color: #7d61d6;
}

#home-fammi-introduction {
  position: relative;
}

#home-fammi-introduction .the-bg {
  background: #f9f4ff;
  height: 504px;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: -11;
}

#home-fammi-introduction .container {
  z-index: 2;
}

#home-fammi-introduction .bigitem,
#home-fammi-introduction p {
  text-align: left;
  padding: 0px;
}

#faq-home {
  margin-top: 48px;
}

#faq-home .aleft {
  margin-top: 0px;
}

#home-container #fammi-bannermenu {
  margin-top: 20px;
}

#footer .companydetail p {
  margin-bottom: 13px;
  margin-top: 32px;
}

.arrow-more {
  margin: 40px 0 10px 0;
}

.item .itemcontainer {
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;
  margin: 0 10px;
  padding: 0;
  text-align: center;
  height: 250px;
}

.item .itemcontainer h4 {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 18px;
  line-height: 20px;
  min-height: 38px;
  text-align: center;
  color: #333;
  margin: 14px 16px;
}

@media only screen and (max-width: 450px) {
  .ketik-pertanyaan-wrapper .title .col-md-12 {
    padding: 0px 16px;
  }

  .pertanyaan-form .left-side {
    padding: 0px 0px 0px 16px;
    padding-right: 16px;
  }

  .pertanyaan-form .right-side {
    padding: 0px 16px 0px 0px;
  }

  .section-label {
    display: none;
  }

  .home-berkenalan {
    font-size: 24px;
  }

  .home-berkenalan-video {
    height: 183px;
  }

  .home-mitra-kami,
  .home-mari h3,
  .home-kata-mereka,
  .home-mari-kerja h3,
  #faq-home .homesection h3 {
    font-size: 24px;
    line-height: 28.4px;
  }

  #home-fammi-intoduction .the-bg {
    height: 805px;
  }

  #fammi-intoduction iframe {
    height: 100%;
  }

  #home-fammi-introduction .item.bigitem {
    margin-top: 24px;
    height: 185px;
  }

  .itemcontainer-wrapper {
    display: block;
  }

  .arrow-more {
    margin-top: -10px;
  }

  .fammibutton2 {
    display: none;
  }

  .title-section {
    display: flex;
    font-family: Rubik !important;
    font-style: normal !important;
    font-size: 14px !important;
    font-weight: 500 !important;
  }

  .contentringkasan a.lihat-selengkapnya,
  .contentringkasan a.lihat-selengkapnya:hover {
    visibility: hidden;
  }

  .containeringkasan a.lihat-selengkapnya,
  .contentringkasan a.lihat-selengkapnya:hover ::after {
    content: "Semua";
    visibility: visible;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    float: right;
    color: #7d61d6;
    margin-right: 24px;
    margin-top: -29px;
  }

  .semua {
    font-family: Rubik !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 17px !important;
    text-align: right !important;
    color: #7d61d6 !important;
    display: block;
  }
}

/* New Css */

.title-section {
  font-family: Rubik !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 24px !important;
  line-height: 28px !important;
  margin: unset;
  text-transform: capitalize;
  width: 100px !important;
}

@media (max-width: 450px) {
  .semua {
    display: none;
  }
}

@media (max-width: 992px) {
  .title-section {
    font-size: 14px !important;
  }

  .semua {
    display: none;
  }

  .wrapper-heads-cat {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    padding: 0px 24px;
    width: 100%;
    margin-top: 24px;
  }

  .title-heads-cat {
    font-family: Rubik !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 17px !important;
    margin: unset !important;
    color: rgba(0, 0, 0, 0.87) !important;
    text-transform: capitalize !important;
  }

  .button-heads-cat {
    font-family: Rubik !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 17px !important;
    text-align: right !important;
    color: #7d61d6 !important;
  }

  .itemcontainer-wrapper {
    display: none;
  }

  .fammibutton2 {
    display: none;
  }

  .section-label {
    display: none;
  }

  .contentringkasan {
    padding: 4px;
    padding-top: 0;
  }

  .MultiCarousel {
    padding: 4px;
  }

  .contentringkasan a.lihat-selengkapnya,
  .contentringkasan a.lihat-selengkapnya:hover {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    /* line-height: 19px; */
    float: right;
    color: #7d61d6;
    margin-right: 24px;
    margin-top: -29px;
  }

  .div-section {
    margin-top: 0;
  }

  #fammi-ringkasan {
    padding: 0;
  }
}

.wrapper-heads-cat {
  display: none;
}

.title-div {
  height: 182px;
  /* width: 180px !important; */
  padding-left: 24px;
  margin-top: 45px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title-div .fammibutton2 {
  width: 173px !important;
}

.div-new {
  padding: unset !important;
}

@media (max-width: 1200px) {
  .title-div {
    height: 190px;
  }
}

@media (max-width: 992px) {
  .title-div {
    display: none;
  }

  .div-new {
    display: none;
  }

  .lihat-selengkapnya {
    display: none;
  }
}

.lihat-selengkapnya {
  z-index: 980 !important;
  position: relative !important;
}

@media (max-width: 768px) {
  .contentringkasan {
    margin-top: 0px !important;
  }
}

.originalContain {
  height: 188px;
}

.itemContainer {
  box-shadow: 1px 1px 15px rgb(0 0 0 / 10%);
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;
  padding: 0;
}

@media (max-width: 992px) {
  .itemContainer {
    height: 214.688px !important;
  }

  .button-heads-cat {
    display: none;
  }
}

.item .itemcontainer {
  height: 200px !important;
}

@media (max-width: 450px) {

  /* .itemContainer {
    height: 205.688px !important;
  } */
  .button-heads-cat {
    display: block;
  }
}

.look-more {
  display: none;
}

@media (max-width: 992px) {
  .look-more {
    display: block;
    font-family: Rubik !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 17px !important;
    text-align: right !important;
    color: #7d61d6 !important;
  }
}

@media (max-width: 450px) {
  .look-more {
    display: none;
  }

  .mob-list {
    display: block !important;
    margin-bottom: 60px;
  }

  #fammi-bannermenu .test-dot {
    display: block !important;
  }
}

.fammi-homecontainer .row h4 {
  font-weight: 700px;
}

.fammibutton2 {
  text-align: center !important;
}

@media (max-width: 992px) {
  #fammi-bannermenu .carousel-indicators {
    display: flex !important;
    justify-content: center;
    width: 100% !important;
    margin: unset !important;
    left: 0% !important;
  }
}

.mob-list {
  display: none;
}

@media (min-width: 500px) {
  .mob-division {
    margin-top: -15px;
  }

  .mob-list {
    display: none;
  }

  #fammi-bannermenu .carousel-indicators {
    display: none !important;
  }
}

@media (min-width: 360px) {
  .item .itemcontainer {
    height: auto !important;
  }
}