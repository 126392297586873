.parent-card-playlist {
  margin-right: 24px;
}
.img-playlist {
  width: 175px;
  height: 175px;
  border-radius: 12px;
  object-fit: cover;
  cursor: pointer;
}
@media (max-width: 768px) {
  .parent-card-playlist {
    margin-right: 16px;
  }
  .img-playlist {
    width: 144px;
    height: 144px;
  }
}
