.container-topepisode {
  max-width: 1170px;
  width: 100%;
  margin: 24px auto;
}
.parents-card-tops {
  display: flex;
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 12px;
  width: 374px;
  height: 174px;
  margin-right: 24px;
  cursor: pointer;
}
.img-card-tops {
  height: 100%;
  width: 175px;
  object-fit: cover;
  border-radius: 12px 0px 0px 12px;
}
.div-content-tops {
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.parent-naras-tops {
  display: flex;
  align-items: center;
}
.img-naras-tops {
  height: 48px;
  width: 48px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}
.text-title-naras-tops {
  margin: unset !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.87);
}
.text-title-naras-tops-mob {
  display: none;
}
.text-content-tops {
  margin: 16px 0px !important;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.87);
}
.parent-plays-tops {
  display: flex;
  align-items: center;
}
.text-duration-tops {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin: unset !important;
  color: #999999;
  margin-left: 12px !important;
}
.text-jabatan {
  display: none;
}
.text-title-naras-tops-med {
  display: none;
}
.text-jabatan-425 {
  display: none;
}
@media (max-width: 768px) {
  .container-topepisode {
    max-width: 100%;
    width: 100%;
    margin: 0px;
    margin-top: 24px;
  }
  .parent-carousels {
    margin-top: 16px;
  }

  .parents-card-tops {
    margin-right: 16px;
    width: 256px;
    height: 128px;
  }
  .img-naras-tops {
    height: 34px;
    width: 34px;
  }
  .text-content-tops {
    display: none;
  }
  .parent-naras-tops {
    display: block;
  }
  .text-title-naras-tops {
    display: none;
  }
  .text-title-naras-tops-mob {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 12px;
    display: block;
    margin-top: 13px !important;
    color: rgba(0, 0, 0, 0.87);
  }
  .text-duration-tops {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #999999;
  }
  .parent-plays-tops img {
    width: 25px;
    height: 25px;
  }
  .img-card-tops {
    width: 128px;
  }
  .div-content-tops {
    padding: 12px;
  }
}
