#right-program h1 {
  margin: 0px !important;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.87);
}

#right-program .box {
  background: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 12px;
  margin-top: 14px;
  padding: 12px;
}
#right-program .box .head {
  display: flex;
  align-items: center;
}
#right-program .box .head h1 {
  margin: 16px 0px !important;
  font-size: 14px;
  line-height: 17px;
}
#right-program .box .bottoms {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

#right-program .box .bottoms h5 {
  margin: 0px !important;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.87);
}

#right-program .box .bottoms button {
  background: #ffffff;
  display: flex;
  align-items: center;
  border: 1px solid #6c60e0;
  border-radius: 8px;
  padding: 9px;
  cursor: pointer;
}

#right-program .box .bottoms button p {
  margin: 0px !important;
  margin-left: 3px !important;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #6c60e0;
}
