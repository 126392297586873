#prog-profile {
  position: fixed;
  z-index: 2000;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  height: 100vh;
  left: 0px;
  top: 0px;
}
#prog-profile .mandatory {
  border: solid 1px #EA4335 !important; 
}
#prog-profile.profs {
  position: unset !important;
  background: unset;
  width: unset;
  display: unset;
  height: auto;
}
#prog-profile .boxes {
  width: 100%;
  max-width: 360px;
  background: #ffffff;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  padding: 0px;
  overflow-x: hidden;
  height: auto;
}
#prog-profile.profs .boxes {
  overflow-x: unset !important;
  height: auto;
  max-width: unset;
}

#prog-profile .boxes .headered p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.87);
  margin: 0px !important;
  margin-left: 12px !important;
}
#prog-profile.profs .boxes .bodys {
  height: 100%;
  max-height: unset;
  overflow-y: unset;
  margin-bottom: 90px;
}
#prog-profile .boxes .headered {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
}
#prog-profile .boxes .headered div {
  display: flex;
  align-items: center;
}

#prog-profile .boxes .bodys .item-list {
  margin-bottom: 16px;
}
#prog-profile .boxes .bodys .pg {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin: 0px !important;
  color: rgba(0, 0, 0, 0.87);
}
#prog-profile .boxes .bodys .alerto {
  display: flex;
  background: #f5f0ff;
  border-radius: 6px;
  padding: 7px 12px;
  margin-top: 10px;
}
#prog-profile .boxes .bodys .alerto p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  margin: 0px !important;
  margin-left: 6px !important;
  color: rgba(0, 0, 0, 0.87);
}
#prog-profile .boxes .bodys input {
  width: 100%;
  padding: 12px 14px;
  background: #ffffff;
  border: 1px solid #dedede;
  border-radius: 12px;
  margin-top: 8px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  outline: none;
}
#prog-profile input[disabled] {
  color: #000 !important;
}
#prog-profile .boxes .bodys textarea {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  width: 100%;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  outline: none;
  resize: none;
  background: #ffffff;
  border: 1px solid #dedede;
  border-radius: 12px;
  padding: 12px;
  margin-top: 10px;
}
#prog-profile .boxes .bodys .pg-desc {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin: 0px !important;
  margin-top: 10px !important;
  color: #999999;
}
#prog-profile .boxes .bodys input:focus {
  border: 1px solid #6c60e0;
}

#prog-profile .boxes .bodys {
  height: auto;
  max-height: 450px;
  overflow-y: scroll;
  scrollbar-width: none;
  padding: 16px;
}
#prog-profile .boxes .bodys::-webkit-scrollbar {
  display: none;
}

#prog-profile .boxes .bodys::-webkit-scrollbar-track {
  border-radius: 10px;
}
#prog-profile .boxes .bodys::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

#prog-profile .boxes .cta-bottom {
  padding: 16px;
  box-shadow: 0px -5px 30px rgba(0, 0, 0, 0.1);
}
#prog-profile .boxes .cta-bottom button {
  background: #6c60e0;
  border-radius: 12px;
  border: none;
  width: 100%;
  padding: 12px 0px;
  text-align: center;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}
#prog-profile .boxes .headered .close-icon {
  display: none;
}
.fp_gender {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
}
.fp_gender_item_active {
  width: 47.5%;
  background: #f5f0ff;
  padding: 11px 12px;
  border: 1px solid #6c60e0;
  box-sizing: border-box;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.fp_gender_item_active.profile_disabled {
  background: #f3f3f3;
  border: 1px solid #dedede;
  color: #999;
}
.fp_gender_item_active div {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 5px solid #6c60e0;
}
.fp_gender_item_active p {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin: 0px !important;
  margin-left: 8px !important;
  color: #6c60e0;
}
.fp_gender_item_active.profile_disabled p {
  color: #999;
}
.fp_gender_item_active.profile_disabled div {
  border: 5px solid #999;
}
.fp_gender_item {
  width: 47.5%;
  background: #ffffff;
  border: 1px solid #dedede;
  padding: 11px 12px;
  box-sizing: border-box;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.fp_gender_item.profile_disabled {
  background: #f3f3f3;
  border: 1px solid #dedede;
}
.fp_gender_item div {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 3px solid #dedede;
}
.fp_gender_item p {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin: 0px !important;
  margin-left: 8px !important;
  color: #6c60e0;
}
.fp_gender_item.profile_disabled p {
  color: #999;
}
.fq_select, .fq_select_active {
  width: 100% !important;
}
.fq_select p, .survey .fp_gender_item p {
  color: #000 !important;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin: 0px !important;
  margin-left: 8px !important;
}
.fq_select_active p {
  color:#6C60E0;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin: 0px !important;
  margin-left: 8px !important;
}
@media (max-width: 500px) {
  #prog-profile {
    align-items: flex-end;
  }
  #prog-profile .boxes {
    max-width: 100%;
    height: auto;
    max-height: 90%;
    border-radius: 12px 12px 0px 0px;
  }
  #prog-profile .boxes .cta-bottom {
    position: fixed;
    bottom: 0px;
    z-index: 99999;
    background: #fff;
    width: 100%;
  }
  #prog-profile .boxes .bodys {
    max-height: 100%;
    margin-bottom: 64px;
  }
  #prog-profile .boxes .scrolled-mob {
    height: auto;
    max-height: 80%;
    overflow-y: scroll;
    scrollbar-width: none;
  }
  #prog-profile .boxes .scrolled-mob::-webkit-scrollbar {
    display: none;
  }

  #prog-profile .boxes .scrolled-mob::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  #prog-profile .boxes .scrolled-mob::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
  }
  #prog-profile .boxes .headered .close-icon {
    display: block;
    cursor: pointer;
  }
  #prog-profile.profs .boxes .bodys {
    margin-bottom: 0px;
  }
}
