.parent-content-video {
  max-width: 1170px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 55px;
}

.text-title-video-subs {
  font-family: "Rubik";
  font-weight: 500;
  font-size: 24px;
}

.text-description-video-subs {
  font-family: "Lato";
  font-size: 14;
}

.video-subs {
  border-radius: 12px;
}


@media (max-width:768px) {
  .parent-content-video {
    display: block !important;
    margin-bottom: 0px !important;
  }

  .text-title-video-subs {
    width: 100%;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.87);
  }
}