#payment-package .headers {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05);
  position: fixed;
  top: 0px;
  left: 0px;
  padding: 12px;
}
#payment-package .parent-containers {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 32px;
  background: #ffffff;
  min-height: 100vh;
}

#payment-package .containers {
  max-width: 360px;
  width: 100%;
}
@media (max-width: 768px) {
  #payment-package .headers img {
    height: 32px;
  }
  #payment-package .containers {
    max-width: unset;
    padding: 16px;
  }
  #payment-package .parent-containers {
    padding-top: 0px;
  }
}
