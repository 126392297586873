@import url("https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap");
.home-slider .carousel-inner .item img.mobile-pict {
  display: none;
}

.item-carousel {
  border-radius: 12px;
  /* height: 264px !important; */
  /* object-fit: cover; */
  width: 1440px;
}
.container-maincarousel {
  width: 1170px;
  margin: auto;
  margin-top: 24px;
}

#home-container {
  padding-top: 24px !important;
}
@media (max-width: 1200px) {
  .container-maincarousel {
    width: 970px;
  }
}

@media (max-width: 992px) {
  .container-maincarousel {
    width: 750px;
  }
  #fammi-maincarousel .carousel-indicators {
    display: initial !important;
  }
  .carousel-indicators {
    left: 5% !important;
  }
}

@media (max-width: 768px) {
  .parent-home-h {
    flex-direction: column;
    padding: 16px;
    margin-top: 36px;
  }
  .parent-home-h .one-home-h {
    width: 100% !important;
  }
  .parent-home-h .two-home-h {
    width: 100% !important;
    height: 291px !important;
    margin-top: 36px;
  }
  .one-home-h img {
    height: 23px !important;
    margin-left: 0px !important;
  }
  .one-home-h h1 {
    font-size: 28px !important;
    line-height: 34px !important;
    margin-top: 16px !important;
    margin-bottom: 12px !important;
  }
  .one-home-h .p-home-h {
    max-width: 100% !important;
    font-size: 12px !important;
    line-height: 16px !important;
    margin-top: 0px !important;
    border-bottom: 0px !important;
  }

  .one-home-h button {
    margin-top: 24px !important;
    width: 100% !important;
    padding-right: 16px !important;
    height: 48px !important;
  }
  .one-home-h button p {
    font-size: 16px !important;
    line-height: 19px !important;
    /* margin-right: 48px !important; */
    text-align: center;
  }
  .container-maincarousel {
    margin-right: 24px;
    margin-left: 24px;
  }
  .category-container {
    margin-right: 24px;
    margin-left: 24px;
  }
  #fammi-maincarousel .carousel-indicators {
    display: initial !important;
    margin-bottom: 75px;
  }
}
@media (max-width: 360px) {
  /* #home-container {
    padding-top: 0 !important;
  } */
  .iqbal-tambah {
    margin-top: unset !important;
    margin-bottom: unset !important;
  }
  #fammi-maincarousel .carousel-indicators {
    display: none !important;
  }
}

@media (max-width: 450px) {
  #fammi-maincarousel .carousel-indicators {
    display: initial !important;
    margin-bottom: 60px !important;
  }
  #home-container {
    padding-top: 0 !important;
  }
  #fammi-maincarousel .carousel-indicators li {
    background-color: rgba(255, 255, 255, 0.5) !important;
  }
  #fammi-maincarousel .carousel-indicators .active {
    background-color: #ffffff !important;
  }
  .home-slider .carousel-inner .item img.mobile-pict {
    display: initial;
    width: 100%;
  }
  .container-maincarousel {
    width: 100%;
    margin: unset;
  }
  #home-container {
    padding-top: 0px;
  }
}

.parent-home-h {
  display: flex;
  width: 100%;
}

.parent-home-h .one-home-h {
  width: 55%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.one-home-h img {
  height: 40px;
}

.one-home-h h1 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 60px;
  margin-left: -3px;
}
.one-home-h .p-home-h {
  max-width: 476px;
}

.one-home-h button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(180deg, #ff3f00 0%, #ff8057 100%);
  border-radius: 14px;
  border: none;
  margin-top: 30px;
  width: 300px;
  padding: 0px 24px;
  height: 56px;
}
.one-home-h button p {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  text-align: center;
  width: 90%;
}
.one-home-h button img {
  height: 24px;
}
.parent-home-h .two-home-h {
  background: url("/assets/images/home-header.png") right no-repeat;
  width: 45%;
  height: 328px;
  display: flex;
  align-items: center;
  background-size: contain;
}
.two-home-h .qna-home-h {
  width: 100%;
}

.qna-home-h .example-question-h {
  width: fit-content;
  background-color: #7d61d6;
  border-radius: 24px 24px 0px 24px;
  padding: 14px;
}
.qna-home-h .example-question-h p {
  margin: 0px !important;
  font-family: Rubik !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  color: #ffffff;
}

.qna-home-h .parent-answer-h {
  display: flex;
  align-items: center;
  margin-top: 19px;
}
.parent-answer-h .logo-on-h {
  background: #7d61d6;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
}

.parent-answer-h .example-answer-h {
  width: fit-content;
  background-color: #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 24px 24px 24px 0px;
  margin-left: 8px;
  padding: 14px;
}

.example-answer-h p {
  margin: 0px !important;
  font-family: Rubik !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  color: rgba(0, 0, 0, 0.87);
}
