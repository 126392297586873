#audio_player {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 3px 4px 15px rgba(0, 0, 0, 0.05);
    border-top: 1px solid #EBEBEB;
}

.audio_row_styles {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.audio_wrapper {
    height: 100%;
    padding: 12px 98px 12px 132px;
    justify-content: space-between;
}


.audio_info {
    margin-left: 12px;
    width: 304px;
}

.audio_image {
    border-radius: 4px;
    width: 36px;
    height: 36px;
    margin-right: 12px;
}

.audio_titles {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.87);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px;
    margin-bottom: 5px;
}

.audio_description {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #999999;
}

.section_audios_bar {
    width: 100%;
    margin-left: 60px;
    margin-right: 60px;
}

.parent-indicator {
    background: rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    width: 100%;
    height: 3px;
    display: flex;
    cursor: pointer;
    align-items: center;
    margin-right: 10px;
    margin-left: 10px;
}

.indicator {
    background: #ffb800;
    border-radius: 6px;
    width: 20%;
    height: 3px;
    cursor: pointer;
}

.circle {
    background: rgba(0, 0, 0, 0.87);
    width: 7px;
    height: 7px;
    cursor: pointer;
    border-radius: 50%;
}

.text_times_audios {
    margin: unset !important;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #999999;
}


.close_audio_button {
    background: #FFFFFF;
    border: 1px solid #E2E2E2;
    border-radius: 12px;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.close_audio_button img {
    width: 12px;
    height: 12px;
}

@media (max-width:500px) {

    #audio_player {
        border-radius: 12px 12px 0px 0px;
    }

    .audio_wrapper {
        padding: 16px;
    }

    .section_audios_bar {
        margin: 0;
    }

    .parent-indicator {
        position: absolute;
        left: 0;
        bottom: 0;
        margin: 0;
    }


}


@media (min-width:1800px) {
    .audio_wrapper {
        padding: 12px 375px 12px 410px
    }
}