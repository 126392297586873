#list-programs {
  background: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 12px;
  margin-bottom: 16px;
  padding: 16px 12px;
}

#list-programs .head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

#list-programs .head .main {
  display: flex;
  align-items: center;
}
#list-programs .head .main .material {
  background: #f5f0ff;
  border-radius: 8px;
  padding: 2px 6px;
  margin-right: 8px;
  display: flex;
  align-items: center;
}

#list-programs .head .main .material p {
  margin: 0px !important;
  margin-left: 4px !important;

  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #6c60e0;
}
#list-programs .head .main .timming {
  background: #ffffff;
  border: 1px solid #ffd8ab;
  border-radius: 8px;
  padding: 6px;
  display: flex;
  align-items: center;
}
#list-programs .head .main .timming p {
  margin: 0px !important;
  margin-left: 4px !important;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #f9a33f;
}
#list-programs .head button {
  display: flex;
  align-items: center;
  cursor: pointer;
  background: #6c60e0;
  border-radius: 8px;
  padding: 6px 15px;
  border: none;
}

#list-programs .head button p {
  margin: 0px !important;
  margin-right: 4px !important;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
}
#list-programs h5 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin: 0px !important;
  color: rgba(0, 0, 0, 0.87);
}
#list-programs .bottom {
  margin-top: 12px;
}

#list-programs .bottom p {
  margin: 0px !important;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #7b7b7b;
}

#list-programs .details {
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid #eaeaea;
}

#list-programs .details .item-details {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  justify-content: space-between;
}
#list-programs .details .item-details div {
  display: flex;
  align-items: center;
  width: 47.5%;
}

#list-programs .details .item-details div p {
  margin: 0px !important;
  margin-left: 12px !important;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.87);
}
#list-programs .details .item-details div p span {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #999999;
}
#list-programs .details .item-details button {
  display: flex;
  align-items: center;
  justify-content: start;
  background: #ffffff;
  border: 1px solid #6c60e0;
  border-radius: 8px;
  height: 34px;
  width: 107px;
  padding: 11px;
}
#list-programs .details .item-details button p {
  margin: 0px !important;
  margin-left: 6px !important;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  color: #6c60e0;
}
