#card-programs {
  background: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  padding: 12px;
  width: 100%;
  margin-bottom: 16px;
}

#card-programs .img {
  border-radius: 8px;
  height: 152px;
  margin-bottom: 16px;
  width: 100%;
}

#card-programs h5 {
  margin: 0px !important;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  color: rgba(0, 0, 0, 0.87);
}

#card-programs .flex-sec {
  display: flex;
  align-items: center;
  margin-top: 16px;
}
#card-programs .bar-sec {
  width: 100%;
  height: 4px;
  background: #eaeaea;
  border-radius: 2px;
  margin: 16px 0px;
}
#card-programs .bar-sec div {
  height: 4px;
  background: #6c60e0;
  border-radius: 2px;
}
#card-programs .flex-sec div {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

#card-programs .flex-sec div p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #7b7b7b;
  margin: 0px !important;
  margin-left: 6px !important;
}
#card-programs .last-sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#card-programs .last-sec div {
  display: flex;
  cursor: pointer;
  align-items: center;
}

#card-programs .last-sec div p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  margin: 0px !important;
  margin-right: 4px !important;
  color: #6c60e0;
}
.cta-enrolls button {
  background: #6c60e0;
  border-radius: 12px;
  width: 100%;
  border: none;
  height: 41px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  margin-top: 16px;
}
@media (max-width: 500px) {
  #card-programs.is-mobile {
    width: 100%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    margin-top: 10px;
    border: none;
  }
}
