.header-register-company {
  width: 100%;
  top: 0px;
  position: fixed;
  background: #ffffff;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
}
#new-header {
  display: none;
}
.container-header-company {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1170px;
  margin: auto;
  padding: 12px 0px;
}
.text-header-register-company {
  margin: unset !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.87);
}
.text-header-register-company b {
  cursor: pointer;
  text-decoration: underline;
  color: #7d61d6;
}
.container-register-company {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  margin-top: -90px;
}
@media (max-width: 768px) {
  .container-register-company {
    height: auto;
    margin-top: unset;
  }
  .header-register-company {
    box-shadow: unset;
    background-color: #fdfbff;
  }
  .parent-form-company {
    margin-top: 16px !important;
  }
  .card-register-company {
    border: unset !important;
    background-color: unset !important;
    width: 100% !important;
    padding: 16px !important;
  }
  .container-header-company {
    display: none;
  }
  .header-register-company #new-header {
    display: block !important;
    padding: 16px;
  }
}
