.home_ask_container {
  width: 100%;
  height: 100%;
  background: rgb(30 30 30 / 84%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
}

.hac_content {
  background: #FFF;
  border-radius: 12px;
  width: 360px;
  max-height: 620px;
  display: flex;
  flex-direction: column;
}

.hac_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #FFF;
  padding: 16px;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
}

.hac_header h4 {
  margin: 0;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #262626;
}

.hac_form {
  height: 100%;
  overflow: auto;
  padding: 0px;
}

.hac_button {
  padding: 16px;
  background-color: #FFF;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}

.fq_parent {
  margin: 0
}

.tx_fq_title {
  margin: 0;
  margin-bottom: 16px;
}


@media (max-width:500px) {

  .hac_content,
  .hac_header,
  .hac_button {
    background: #fff;
    border-radius: 0;
  }

  .hac_header {
    flex-direction: row-reverse;
    justify-content: left;
  }

  .hac_header h4 {
    margin-left: 16px;
  }

  .hac_content {
    width: 100%;
    height: 100%;
    max-height: none;
  }

  .hac_form {
    padding: 0;
  }

  .hac_button {
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    box-shadow: 0 -2px 10px 10px #eee;
  }

}