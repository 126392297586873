/*Delip Code*/
.left-menu-materi {
  background: red;
  height: 100%;
}

html,
body,
#root {
  height: 100%;
}

body {
  /*overflow-x: hidden;*/
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}

button:focus {
  outline: none !important;
}

#menu-search .search-container:before {
  left: auto !important;
}

.box-cta-pertanyaan .left-cta {
  width: 35% !important;
}
.box-cta-pertanyaan .right-cta {
  width: 65% !important;
}

.box-cta-pertanyaan .left-cta button,
.box-cta-pertanyaan .right-cta button {
  width: 100%;
}

#login {
  margin-top: 64px !important;
}

.rel-pos {
  position: relative;
}

.well,
.wrap-sub-materi {
  display: table;
  background: rgb(255, 255, 255);
  border-radius: 12px;
  min-height: 423px;
  font-family: Rubik;
  overflow: auto;
  width: 100%;
}

.wrap-sub-materi {
  display: table;
  max-width: 320px;
  margin: 0 auto;
  padding: 5px 15px;
}

.button-new-question {
  width: 48px;
  height: 48px;
  background: #7d61d6;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 50px;
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 4;
}

.button-new-question img {
  width: 20px;
  display: block;
  margin: 0 auto;
  padding-top: 10px;
}

.well .empty-state,
.wrap-sub-materi .empty-state {
  display: table-cell;
  vertical-align: middle;
  margin: 0 auto !important;
  width: 100%;
}

.wrap-sub-materi .empty-state {
  background: white;
  border: solid 1px #eaeaea;
  border-radius: 12px;
  padding: 35px;
}

.wrap-sub-materi .empty-state img {
  max-width: 150px;
  display: block;
  margin: 0 auto;
}

.wrap-sub-materi .empty-state h2 {
  font-family: Rubik;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
}

.wrap-sub-materi .empty-state p {
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  /*line-height: 17px;*/
  letter-spacing: 0em;
  text-align: center;
  color: #999999;
}

.empty-state h1 {
  width: 100%;
  font-weight: 500;
  font-size: 24px;
  /*line-height: 28px;*/
  color: #7d61d6;
  text-align: center;
}

.empty-state p {
  margin-top: 14px;
  font-family: Lato;
  font-size: 18px;
  /*line-height:22px;*/
  text-align: center;
}

.img-support {
  margin-top: 24px;
}

.img-support img {
  width: 100%;
}

.img-support .col-sm-5 {
  padding: 7.5px;
}

.wrap-nav-top {
  position: fixed;
  top: 0px;
  width: 100%;
  text-align: center;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  z-index: 2;
  background: #fff;
  padding: 0px;
}

.wrap-bottom-ques {
  width: 100%;
  height: 66px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  z-index: 2;
  background-color: #fff;
  padding: 0px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.wrap-btn {
  margin-top: 24px;
  text-align: center;
}

.wrap-btn .btn-secondary {
  max-width: 198px;
  width: 100%;
  float: none;
}

.masuk-kelas-header {
  padding: 0;
}

.pad-0 {
  padding: 0;
}

.logo-mb {
  padding: 16px 0;
  text-align: left;
}

.logo-mb i {
  margin-right: 20px;
  margin-left: 40px;
}

.logo-mb img {
  width: 108px;
  height: 32px;
}

.jadwal,
.questions,
.discussions,
.certificate,
.info {
  padding: 20px 0;
  cursor: pointer;
}

.box-contact-data a,
.box-contact-data a:hover,
.box-contact-data a:focus {
  color: black;
}

#wrap-materi {
  /* overflow: hidden; */
  margin-top: 94px;
  padding-top: 0;
}
.wrap-sub-materi {
  background: none;
}

.sub-pertanyaan {
  position: relative;
  width: 100%;
  padding: 0 15px;
  margin: 0px auto;
  float: left;
  width: 100%;
}

.sub-pertanyaan .modal.in {
  display: table !important;
  width: 100%;
  height: 100%;
  max-width: 375px;
  margin: 0 auto;
}

.sub-pertanyaan .modal-dialog {
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}

.sub-pertanyaan .modal-dialog .modal-body img {
  width: 190px;
  display: block;
  margin: 0 auto;
  padding: 20px;
}

.sub-pertanyaan .modal-dialog .modal-body h2 {
  font-family: Rubik;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 0;
}

.sub-pertanyaan .modal-dialog .modal-body p {
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: #999999;
}

.sub-pertanyaan .modal-dialog .modal-content {
  border: solid 1px white;
}

.sub-pertanyaan .modal-dialog .modal-footer {
  margin: 0 auto;
  text-align: center;
  border-top: transparent;
  padding: 24px;
  padding-top: 0;
}

.sub-pertanyaan .modal-dialog .modal-footer button,
.sub-pertanyaan .modal-dialog .modal-footer a {
  float: none;
}

.sub-pertanyaan h1 {
  margin-top: 0;
  font-family: Rubik;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  float: left;
  width: 100%;
}

.box-pertanyaan {
  margin-top: 16px;
  background: white;
  border: solid 1px #eaeaea;
  border-radius: 12px;
  float: left;
  width: 100%;
}

.box-pertanyaan.no-border {
  border: transparent;
}

.box-pertanyaan h3 {
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  padding: 0 24px;
}

.tulis-jawaban {
  margin-top: 0;
}

.tulis-jawaban h3,
.tulis-jawaban textarea {
  padding: 0 24px !important;
}

.tulis-jawaban textarea {
  height: 90px !important;
  color: #999999;
}

.jawaban-text {
  padding-bottom: 24px;
}

.jawaban-text p {
  padding: 0 24px;
}

.box-pertanyaan textarea {
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  border: none;
  width: 100%;
  padding: 0 24px 24px;
  height: 130px;
}

.box-pertanyaan textarea:hover,
.box-pertanyaan textarea:focus {
  border: transparent;
  outline: transparent;
}

.box-cta-tanya {
  margin-top: 24px;
  border: solid 1px #eaeaea;
  border-radius: 12px;
  padding: 15px;
  display: block;
  width: 100%;
  float: left;
}

.left-cta {
  display: inline-block;
  width: 95%;
  vertical-align: top;
  padding-right: 5px;
  /*padding-top: 6px;*/
}

.left-cta-full span:first-child {
  display: inline-block;
  width: 5%;
  vertical-align: top;
  margin-bottom: 20px;
}

.left-cta-full span:last-child {
  display: inline-block;
  width: 90%;
  padding-left: 10px;
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

.right-cta {
  display: inline-block;
  width: 49%;
  /*padding-top: 1px;*/
  padding-left: 5px;
}

.sertifikat-wrap {
  padding: 0;
}

.sertifikat-content img {
  width: 100%;
  margin-top: 24px;
  margin-bottom: 8px;
}

.btn-purple {
  background: #7d61d6;
  width: 100%;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  padding: 12px;
  color: white;
  border-radius: 12px;
}

.btn-purple:hover {
  color: white;
}

.wrap-profil {
  margin: 0 auto;
  display: block;
  max-width: 475px;
  padding: 15px;
  padding-top: 0;
}

.wrap-profil h1 {
  font-family: Rubik;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 0;
}

.wrap-banner {
  background: #fff;
  border: 1px solid #eaeaea;
  border-radius: 12px;
  width: 100%;
  height: auto;
  /*height: 256px;*/
}

.box-top-banner {
  height: 154px;
  background: #7d61d6;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
}

.box-bottom-avatar {
  padding: 20px;
}

.ava-profile {
  max-width: 54px;
  width: 54px;
}

.box-bottom-avatar h5 {
  font-weight: bold;
  text-align: left;
  padding-left: 10px;
  line-height: 40px;
}

.box-contact-data {
  margin-top: 20px;
  background: #fff;
  border: 1px solid #eaeaea;
  border-radius: 12px;
  width: 100%;
}

.notelp > img,
.tanggallahir > img,
.kotasal > img {
  display: block;
  margin: 0 auto !important;
  padding-bottom: 20px;
  padding-top: 20px;
}

.signup-button-nav .col-xs-5 {
  padding-right: 7.5px;
  padding-left: 0;
}

.signup-button-nav .col-xs-7 {
  padding-left: 7.5px;
  padding-right: 0;
}

.signup-button-nav button {
  padding: 10px 15px !important;
}

.arrow-select {
  position: relative;
}

.arrow-select select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  position: relative;
  padding: 0 10px !important;
}
.arrow-select:after {
  content: "";
  width: 20px;
  height: 20px;
  background: url("http://beta.fammi.ly/images-delip/arrow-top.png") no-repeat
    center center;
  background-size: 12px;
  position: absolute;
  top: 15px;
  right: 10px;
}
.arrow-select.justflag:after {
  content: "";
  width: 20px;
  height: 20px;
  background: url("http://beta.fammi.ly/images-delip/arrow-top.png") no-repeat
    center center;
  background-size: 12px;
  position: absolute;
  top: 16px;
  left: 78px;
  z-index: 1;
}

.no-country.hide-codearea input {
  width: 100% !important;
  border-radius: 12px !important;
}

/*http://beta.fammi.ly/images-delip/arrow-top.png*/

.box-top-mitra {
  padding: 24px;
  background: #ffffff;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  margin-bottom: 24px;
}

.box-top-mitra.active {
  border: solid 1px #7d61d6;
  position: relative;
}

.box-top-mitra .img-active {
  position: absolute;
  top: -5px;
  right: 15px;
  visibility: hidden;
}

.box-top-mitra.active .img-active {
  position: absolute;
  top: -5px;
  right: 15px;
  visibility: visible;
}

.syarat h3 {
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #7d61d6;
}

.syarat p.small-info {
  font-size: 14px;
}

.syarat ul {
  font-size: 14px;
  padding-left: 20px;
}
.syarat li {
  margin-bottom: 15px;
}

/*.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}*/

#headerlogin p span {
  font-size: 14px;
}

/* Hide the browser's default checkbox */
.checkit {
  position: relative;
}
.checkit input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.text-checkit {
  padding-left: 35px;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.checkit:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkit input:checked ~ .checkmark {
  background-color: #7d61d6;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkit input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkit .checkmark:after {
  left: 7px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.img-mitra,
.text-mitra {
  display: inline-block;
}

.img-mitra img {
  width: 100%;
  margin: 0 auto !important;
  vertical-align: bottom;
}

.img-mitra {
  width: 30%;
}
.text-mitra {
  width: 70%;
  padding-left: 15px;
}

.text-mitra h2 {
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0 auto;
  margin-bottom: 8px;
}

.text-mitra p {
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0 auto;
}

@media (min-width: 1300px) {
  .well {
    max-width: 765px;
  }
}

/*@media(min-width: 1400px){
	.well {
  	max-width: 900px;
	}
}*/

@media (min-width: 768px) {
  .embed-container {
    border-radius: 12px;
  }
  .well {
    position: fixed;
    width: 58%;
  }
  .max-box-wrap,
  .wrap-sub-materi {
    max-width: 475px;
    margin: 0 auto;
  }
  .wrap-sub-materi.min-h {
    min-height: 633px;
  }
  .filter-tanya {
    font-size: 12px !important;
    padding: 12px 21px !important;
  }
}

#formlogin .step:first-child {
  z-index: 4;
}

#formlogin .step:nth-child(2) {
  z-index: 3;
}

#formlogin .step:nth-child(3) {
  z-index: 2;
}

#formlogin .step:last-child {
  z-index: 1;
}

#signup-stepnav > .active ~ .step {
  background: white;
  color: #999999;
}

#signup-stepnav > .active ~ .step.active {
  background: red;
}

#formlogin {
  position: relative;
  margin: 0 auto;
}

.row-remix {
  margin-left: -7.5px;
  margin-right: -7.5px;
}

@media (max-width: 768px) {
  body {
    margin-top: 55px !important;
  }

  .box-question {
    padding: 0 15px;
  }

  .mar-on-mb {
    margin-left: 0px;
    margin-right: 0px;
  }

  .ql-snow .ql-picker.ql-size {
    width: 100%;
  }

  /*.box-border-question {
		width: auto !important;
	}*/

  /*.box-question-remix .box-border-question {
		width: auto !important;
	}*/

  .max-box-wrap {
    padding: 0 30px;
  }
  .sub-pertanyaan .max-box-wrap {
    padding: 0 15px;
  }

  .box-cta-pertanyaan .left-cta .btn-secondary {
    min-width: inherit !important;
  }

  .left-cta-full {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .size-mobile-fammi {
  }

  .table-sign {
    width: 100%;
  }

  #wrap-materi.container {
    padding-left: 15px;
  }

  .no-wa select.codearea {
    padding-left: 30px !important;
  }

  #formlogin input {
    padding: 15px 10px;
  }

  .daftar-page #form-utama {
    padding: 0;
  }

  #signup-stepnav > .active ~ .step {
    background: #eaeaea;
  }

  .signup-table {
    background: transparent !important;
  }

  #formlogin .step {
    margin: 34px 10px !important;
  }

  .daftar-page {
    padding: 0 !important;
  }
  .loginheader img {
    left: 0 !important;
  }
  .wanlang > .col-xs-12 {
    margin: 0;
    padding: 0 15px;
  }
  .wrap-profil {
    padding: 0;
  }
  .empty-state h1 {
    font-size: 18px;
    margin-top: 0;
  }
  .empty-state p {
    font-size: 12px;
  }
  .sub-pertanyaan .modal-dialog .modal-footer button {
    width: 100%;
  }
  .sub-pertanyaan .modal-dialog {
    vertical-align: bottom;
  }
  .sub-pertanyaan .modal-dialog .modal-content {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .well {
    border-radius: 0 !important;
    min-height: 220px !important;
  }
  #wrap-materi [class*="col-"] {
    padding-left: 0;
    padding-right: 0;
  }
  #wrap-materi li {
    border-radius: 0 !important;
  }
  .jadwal-active,
  .jadwal-active .col-sm-1,
  .questions-active,
  .questions-active .questions .col-sm-2,
  .discussions-active,
  .discussions-active .discussions .col-sm-2,
  .certificate-active,
  .certificate-active .certificate .col-sm-2,
  .info-active,
  .info-active .info .col-sm-1 {
    float: left;
  }
  .masuk-kelas-header {
    min-width: 570px;
  }
  .wrap-nav-top {
    overflow-x: scroll;
  }
  .wrap-nav-top ::-webkit-scrollbar {
    display: none;
  }
  .wrap-nav-top {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .wrap-nav-top .col-sm-2,
  .wrap-nav-top .col-sm-1 {
    padding: 20px 25px !important;
    float: left;
  }

  .wrap-nav-top .col-sm-1.one {
    padding: 20px 0px 14px 33px !important;
  }

  .wrap-nav-top .logo-mb {
    padding: 17px 10px !important;
  }
  .jadwal {
    margin-left: 30px;
  }
  .jadwal-active .jadwal:after,
  .questions-active .questions:after,
  .discussions-active .discussions:after,
  .certificate-active .certificate:after,
  .info-active .info:after {
    bottom: 0px !important;
  }
  #wrap-materi {
    margin-top: 60px !important;
  }
  .sub-pertanyaan,
  .min-h {
    margin-top: 24px !important;
  }
}

.faq.jadwalkelas > li {
  /*padding: 24px;*/
  /*background: red;*/
  border: solid 1px rgb(204, 204, 204);
  cursor: pointer;
  border-bottom: transparent;
}

.faq.jadwalkelas > li:first-child {
  border-radius: 12px 12px 0 0;
}

.faq.jadwalkelas > li:last-child {
  border-radius: 0 0 12px 12px;
  border-bottom: solid 1px rgb(204, 204, 204);
}

.wrap-materi {
  padding: 24px;
}

.box-materi {
  vertical-align: top;
}

.box-materi,
.title-materi {
  display: inline-block;
}

.box-materi {
  border-radius: 5px;
  border: solid 1px #000;
  min-width: 30px;
  min-height: 36px;
}

.box-materi.icon-active {
  margin: 0;
  padding: 6px 5px;
  max-width: 30%;
}

.box-materi.icon-active img {
  width: 18px;
  vertical-align: top;
  padding-top: 2px;
  display: block;
  margin: 0 auto;
}

.box-materi.icon-active img.custom {
  padding-top: 3px;
}

.box-materi.date-active {
  margin: 0;
  padding: 3px 3px;
  max-width: 30%;
  min-width: 30px;
  min-height: 36px;
}

.box-materi.date-active p,
.box-materi.date-active h3 {
  margin: 0;
  padding: 0;
}

.box-materi.date-active p {
  font-size: 8px;
  line-height: 9px;
  text-align: center;
  font-family: "Rubik";
}

.box-materi.date-active h3 {
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  font-family: "Rubik";
  font-weight: normal;
}

.title-materi {
  padding-left: 16px;
  max-width: 70%;
}

.title-materi.text-icon h3 {
  line-height: 35px;
}

.title-materi.text-icon.l-text h3 {
  line-height: normal;
}

.title-materi h3,
.title-materi p {
  margin: 0;
  padding: 0;
  font-size: 12px;
}

.title-materi span {
  background: red;
  padding: 0px 5px;
  text-transform: uppercase;
  color: white;
  font-weight: bold;
  border-radius: 3px;
}

.img-arrow {
  float: right;
  padding-top: 8px;
}

.img-arrow img {
  width: 15px;
}

.title-materi h3 {
  font-family: Rubik;
  font-weight: 500;
  line-height: 19px;
}

.title-materi p {
  line-height: 16px;
}

.materi-open {
  /*margin-top: 15px;*/
  padding: 0 24px 0px 24px;
  margin-top: 0;
  padding-bottom: 1px;
}

.list-materi {
  padding-left: 20px;
}

.list-materi li {
  list-style: decimal;
  font-size: 12px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 15px;
  padding-left: 26px;
}
.list-materi li:active {
  color: blue;
}

a {
  color: #7d61d6;
}

.jadwal,
.questions,
.discussions,
.certificate,
.info {
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  color: grey;
  position: relative;
}

.jadwal-active .jadwal,
.questions-active .questions,
.discussions-active .discussions,
.certificate-active .certificate,
.info-active .info {
  color: #7d61d6;
  border-bottom: transparent !important;
}

.jadwal-active .jadwal:after,
.questions-active .questions:after,
.discussions-active .discussions:after,
.certificate-active .certificate:after,
.info-active .info:after {
  content: "";
  width: 100%;
  height: 5px;
  position: absolute;
  background: #7d61d6;
  bottom: -4px;
  left: 0;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.btn-secondary {
  float: right;
  min-width: 103px;
  border-radius: 12px;
  color: #fff;
}

.btn-secondary.active-btn {
  background: #7d61d6;
  color: white;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
}

.btn-secondary.no-active-btn {
  background: white;
  color: #7d61d6;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  border: solid 1px #7d61d6;
}

.box-question {
  position: relative;
  /*max-width: 425px; */
  /*max-width: 320px;*/
  width: 100%;
  margin: 0px auto;
  border-radius: 12px;
  margin-top: 16px;
  float: left;
}

.box-question hr {
  margin: 0;
}

.box-content-question {
  padding: 24px;
}

.name-question {
  font-family: Rubik;
  font-size: 14px;
}

.box-question .name-question span {
  font-size: 14px;
  font-weight: 600;
  margin-left: 14px;
}

.box-question .question-list {
  margin-top: 15px;
  font-size: 14px;
}

.box-border-question {
  border: solid 1px #eaeaea;
  /*margin:5px 15px;*/
  border-radius: 12px;
  background: white;
  float: left;
  width: 100%;
}

.box-answer {
  width: 100%;
  float: left;
}

.box-answer span {
  font-size: 14px;
}

.box-answer div.belum-dijawab {
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

.box-answer div.belum-dijawab img {
  width: 7px;
  float: right;
  padding-top: 3px;
}

.box-answer a.belum-dijawab {
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  padding: 24px;
  float: left;
  width: 100%;
  color: black;
}

.box-answer a.belum-dijawab img {
  width: 7px;
  float: right;
  padding-top: 3px;
}

.box-link-question {
  padding: 0 !important;
  margin: 0 !important;
  float: left;
  width: 100%;
}

.filter-tanya {
  padding: 12px 7px;
  background: white;
  display: inline-block;
  border-radius: 20px;
  color: #999999;
  font-family: Rubik;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: center;
  border: solid 1px #999999;
  margin-top: 15px;
  margin-left: 10px;
}

.filter-tanya:hover,
.filter-tanya.active {
  background: #7d61d6;
  color: white;
  border: solid 1px #7d61d6;
  cursor: pointer;
}

.filter-tanya:first-child {
  margin-left: 0px;
}

.jawaban-list {
  padding: 50px 0;
}

.jawaban-list h3 {
  font-family: Rubik;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
}
.jawaban-list p {
  font-family: Lato;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: #999999;
}

.custom-box {
  padding: 16px 24px;
}

.wrap-load {
  display: table;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  margin: 0 auto;
  text-align: center;
  z-index: 100;
  background: white;
  left: 0;
}
.content-load {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 100%;
}

.content-load p {
  margin-top: 15px;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: #999999;
}

.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #7d61d6;
  width: 54px;
  height: 54px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  margin: 0 auto;
}

.box-form {
  padding: 20px;
}

.box-form h5 {
  font-weight: bold;
  text-align: left;
}

.arrow-r-icon {
  width: 7px;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.info-wrap .box-border-question {
  margin: 0 auto;
  margin-top: 16px;
}

.info-wrap p {
  margin: 0;
  padding: 0;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}

.info-wrap h2 {
  font-family: Rubik;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.img-center {
  display: block;
  margin: 0 auto;
}

.box-testi-done {
  padding: 40px;
}

.box-testi-done p {
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: #999999;
}

.text-center {
  text-align: center !important;
}

.mar-top-24 {
  margin-top: 24px !important;
}

.mar-btm-24 {
  margin-bottom: 24px !important;
}

.mar-btm-15 {
  margin-bottom: 15px !important;
}

.mar-top-36 {
  margin-top: 36px !important;
}

.mar-top-30 {
  margin-top: 30px !important;
}

.mar-top-48 {
  margin-top: 48px !important;
}

.list-info {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  margin-bottom: 0;
}

.list-info li {
  margin-bottom: 15px;
}

.list-info li:last-child {
  margin-bottom: 0px;
}

.img-info {
  display: inline-block;
  vertical-align: top;
  width: 3%;
}

.text-info-custom {
  display: inline-block;
  padding-left: 15px;
  width: 95%;
  padding-top: 2px;
  font-size: 14px;
}

.testimoni-wrap .box-border-question {
  margin: 0;
}

.testimoni-wrap h2 {
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
}

.testimoni-wrap textarea {
  border: solid 1px #eaeaea;
  padding: 15px;
  width: 100%;
  min-height: 100px;
  margin-top: 15px;
  border-radius: 12px;
}

.state-testi {
  font-family: Lato;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: center;
  color: #999999;
}

.star-wrap {
  margin: 0 auto;
  display: block;
  text-align: center;
}

.star-wrap img {
  padding-right: 30px;
}

.star-wrap img:last-child {
  padding-right: 0;
}

.daftar-page h4 {
  font-family: Rubik;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 24px;
}

.daftar-page label {
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 8px;
}

.daftar-page input {
  text-align: center;
}

.daftar-page p {
  word-break: break-word;
  font-family: Rubik !important;
}

.counting {
  margin-top: 24px;
  font-size: 14px !important;
}

.caption-label {
  color: #999999;
  margin: 16px 0 24px 0;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}

.wrap-login button {
  background: #7d61d6;
  color: #fff;
  border-radius: "12px";
  margin-left: 0px;
  margin-top: "24px";
}

.wrap-login > img {
  display: block;
  margin: 0 auto !important;
  padding-bottom: 20px;
  padding-top: 13px;
}

.wrap-login h5 {
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: black;
}

.otp-style {
  font-family: Lato;
  font-size: 14px;
  font-style: normal !important;
  font-weight: normal;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: #999999;
}

#formlogin {
  margin-top: -30px;
}

#signup-page {
  height: 100% !important;
  /*position:absolute; */
  width: 100%;
  top: 0px;
}

.signup-table {
  display: table;
  width: 100%;
  height: 100%;
}

.signup-middle {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 100%;
}

#formlogin .step {
  margin: 34px 24px;
}

#signup-stepnav > .step:after {
  height: 4px !important;
  width: 50px;
}

/*#formlogin .step.active {
	z-index: 2;
}*/

.info-layanan p {
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #585858;
  margin: 15px 0 !important;
}

.info-layanan span a {
  font-weight: 700 !important;
  font-family: Lato;
  color: #585858;
}

.form-groups {
  margin-bottom: 15px;
}

.form-groups:last-child {
  margin-bottom: 0;
}

.form-groups span {
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 8px;
  display: block;
}

.form-groups input {
  margin-bottom: 0 !important;
  text-align: left;
}

.eye-password {
  position: absolute;
  top: 20px;
  right: 10px;
}

.daftar-page {
  border-radius: 12px;
  margin: 45px 0;
}

#legal h1 {
  font-family: Rubik;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
  color: #7d61d6;
  text-transform: capitalize;
}

#legal h2 {
  font-family: Rubik;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}

#legal h3 {
  font-family: Rubik;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}
#legal p {
  margin-bottom: 20px;
}

.list-legal {
  /*list-style: lower-latin;*/
}

.box-terms {
  max-height: 290px;
  overflow: scroll;
  margin-top: 8px;
}

.bg-modal-fammi {
  background: rgb(0 0 0 / 50%);
}

.bg-modal-fammi .modal-dialog {
  max-width: 345px;
  min-width: 345px;
  width: 100% !important;
}

.table-sign {
  display: table;
  height: 100%;
  margin: 0 auto;
}

.justflag {
  display: none;
}

.justflag input {
  border-radius: 12px !important;
  width: 100% !important;
}

.search-empty-table {
  display: table;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  margin-bottom: 24px;
}

.search-empty-center {
  display: table-cell;
  vertical-align: middle;
  margin: 0 auto;
}

.search-empty-content {
  max-width: 100%;
  background: white;
  border: solid 1px #eaeaea;
  margin: 0 auto;
  padding: 20px;
  border-radius: 12px;
  text-align: center;
  padding: 149px 15px;
}

.search-empty-content img {
  max-width: 200px;
  display: block;
  margin: 0 auto;
}

.search-empty-content a {
  max-width: 200px;
  margin: 0 auto;
  text-align: center;
  margin-top: 24px;
}

.search-empty-content h2 {
  text-align: center;
  font-family: Rubik;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
}

.search-empty-content p {
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: #999999;
}

@media (max-width: 992px) {
  .faq.jadwalkelas {
    margin-bottom: 66px;
  }
}

@media (max-width: 768px) {
  .signup-table {
    height: 100%;
    max-height: 0px !important;
  }
  #signup-page {
    background-color: #fff !important;
  }
  .text-or {
    white-space: nowrap !important;
  }
  .daftar-page h4 {
    font-size: 16px !important;
    font-weight: 500 !important;
  }
}

.search-container input {
  margin-bottom: 0 !important;
}

.goto-register {
  font-weight: 500 !important;
}

.multiitem-container {
  /*padding-top: 0;*/
  padding: 0 15px;
}

.search-container:before {
  left: 20px !important;
}

/*.scroll {
  white-space: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.scroll {
  background: red;
}

.logo {
  text-align: center;
  font-weight: 700;
  color: white;
  border-right: 1px solid rgba(#727c87, 0.4);
  padding: 12px 24px 13px;
}

.nav-item {
  padding: 12px 16px 13px;
}

.logo,
.nav-item,
.vertical-align-middle {
  display: inline-block;
  vertical-align: middle;
}

.title {
  margin: 24px 0 6px;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  color: #999;
}

.scroll::-webkit-scrollbar {
  display: none;
}

<div class="wrap-load hide">
          <div class="content-load">
            <div class="loader">&nbsp;</div>
            <p>Harap Tunggu</p>
          </div>
        </div>



*/
