.parent-card-playlists {
  margin-right: 24px;
  width: 215px;
}
.parent-card-playlists > p {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin-top: 16px !important;
}
.parent-card-playlists div {
  margin-top: 6px !important;
  padding: 6px 8px !important;
  background: #f5f0ff;
  border-radius: 6px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #7d61d6;
  width: fit-content;
}
.parent-card-playlists > p.desktop {
  display: block;
}
.parent-card-playlists > p.mobile {
  display: none;
}
.parent-card-playlists div p {
  margin: unset !important;
}
.img-playlists {
  width: 215px;
  height: 215px;
  border-radius: 12px;
  object-fit: cover;
  cursor: pointer;
}
#neweps {
  align-items: flex-start !important;
}
@media (max-width: 768px) {
  .parent-card-playlists {
    margin-right: 0px;
    width: 144px;
  }
  .parent-card-playlists.non-classname{
    margin-right: 16px;
    width: 144px;
  }
  .img-playlists {
    width: 144px;
    height: 144px;
  }
  .parent-card-playlists > p.desktop {
    display: none;
  }
  .parent-card-playlists > p.mobile {
    display: block;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
  }
  .parent-card-playlists div {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
  }
}
