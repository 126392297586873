.parent-dialog-tes {
  background: rgba(0, 0, 0, 0.5);
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.box-dialog-tes {
  background: #fff;
  border-radius: 12px;
  padding: 24px;
  width: 364px;
}
.section-header-dialog-tes {
  display: flex;
  align-items: center;
  width: 100%;
}
.img-dialog-tes {
  height: 58px;
  width: 58px;
  border-radius: 50%;
  object-fit: cover;
}
.section-titles-tes {
  width: 242px;
  margin-left: 15px;
}
.text-name-dialog-tes {
  margin: unset !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.87);
}
.text-about-dialog-tes {
  margin: unset !important;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin-top: 4px !important;
  color: #999999;
}
.section-content-testimoni {
  margin-top: 30px;
  margin-bottom: 24px;
}
.text-content-dialog-tes {
  margin: unset !important;
  font-family: Lato;
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.87);
}
.btn-dialog-tes {
  width: 100%;
  background: #7d61d6;
  border-radius: 12px;
  padding: 12px 0px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  border: unset;
  color: #ffffff;
}
@media (max-width: 900px) {
  .box-dialog-tes {
    width: 100%;
    border-radius: 12px 12px 0px 0px;
  }
  .parent-dialog-tes {
    align-items: flex-end;
  }
}
