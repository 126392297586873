.main-all {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  padding-top: 72px;
  min-height: 100%;
  background-color: #f2f2f2;
}

#root.certificates {
  background-color: #fff !important;
  height: 100px !important;
  margin: 0px !important;
}
#body.certificates {
  margin: 0px !important;
}
.header {
  width: 100%;
  position: fixed;
  top: 0px;
  z-index: 1000;
  background: #ffffff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  padding: 16px;
}

.header p {
  margin: unset !important;
  margin-left: 12px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.87);
}
.parent-allevent {
  display: flex;
  justify-content: center;
  width: 100%;
}

.allevent-container {
  max-width: 448px;
  width: 100%;
  background-color: #fff;
  min-height: 100vh;
  margin-top: -24px;
  padding-top: 24px;
}
.allevent-container .schedule {
  padding: 0px 16px;
}

.allevent-container .filter {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}
.allevent-container .filter button {
  height: 30px;
  border-radius: 8px;
  background: #ffffff;
  border: 1px solid #eaeaea;
  padding: 8px 12px;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.87);
  margin-right: 8px;
  white-space: nowrap;
}
.allevent-container .filter button.active {
  background: #e0d7ff;
  border: unset;
  color: #6c60e0;
  font-weight: 500;
}

.card-events {
  width: 100%;
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 12px;
  margin-bottom: 16px;
}
.card-events > img {
  width: 100%;
  height: 160px;
  border-radius: 12px 12px 0px 0px;
}
.card-events .schedule {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 12px;
  border-bottom: 1px solid #eaeaea;
}
.card-events .schedule div {
  display: flex;
  align-items: center;
  width: 142px;
}
.card-events .schedule div p {
  margin: 0px !important;
  margin-left: 8px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.87);
}
.card-events .status {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 12px;
}
.card-events .status div {
  display: flex;
  align-items: center;
  width: 142px;
}
.card-events .status div p {
  margin: 0px !important;
  margin-left: 8px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.87);
}
.card-events .status .cta {
  width: 142px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
}
.card-events .status .cta.done {
  background: #ffffff;
  border: 1px solid #999999;
  color: #999999;
}
.card-events .status .cta.on {
  background: #6c60e0;
  border: unset;
  color: #fff;
}
.card-events .status .cta.off {
  background: #999;
  border: unset;
  color: #fff;
}
.card-events .status .cta.off.offline {
  background: #6c60e0;
  border: unset;
  color: #fff;
}
.parent-allevent .emptys {
  height: 70vh;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.parent-allevent .emptys div {
  display: flex;
  align-items: center;
  width: 290px;
  flex-direction: column;
}
.parent-allevent .emptys div img {
  height: 84px;
  margin-bottom: 16px;
}
.parent-allevent .emptys div h1 {
  margin: 0px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
}
.parent-allevent .emptys div p {
  margin: 0px !important;
  margin-top: 6px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
}

.parent-allevent .card-qna {
  margin-bottom: 10px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 12px;
}
.parent-allevent .card-qna .down .speakers {
  display: flex;
  align-items: center;
}
.parent-allevent .card-qna .down .speakers img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-right: 12px;
}
.parent-allevent .card-qna .up {
  padding: 16px 12px;
  border-bottom: 1px solid #eaeaea;
}
.parent-allevent .card-qna .up h1 {
  margin: unset !important;
  margin-bottom: 0px !important;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.87);
}
.parent-allevent .card-qna .up h5 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #999999;
  margin: unset !important;
  margin-top: 12px !important;
}
.parent-allevent .card-qna .down {
  padding: 16px 12px;
}
.parent-allevent .card-qna .down p {
  margin: 0px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 4px !important;
  color: rgba(0, 0, 0, 0.87);
}
.parent-allevent .card-qna .down h4 {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin: unset !important;
  margin-bottom: 4px !important;
  color: rgba(0, 0, 0, 0.87);
}
.parent-allevent .card-qna .down h5 {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin: unset !important;
  color: #999999;
}
.parent-allevent .card-qna .down .cta-sections {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 16px;
}
.parent-allevent .card-qna .down .cta-sections button {
  width: 47.5%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: unset;
  height: 30px;
}
.parent-allevent .card-qna .down div button img {
  height: 16px;
  margin-right: 4px;
}
.parent-allevent .card-qna .down div button p {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin: unset !important;
}
.parent-allevent .card-qna .down .cta-sections .actions {
  display: flex;
  align-items: center;
  margin-right: 12px;
  cursor: pointer;
}
.parent-allevent .card-qna .down .cta-sections .actions img {
  margin: 0px;
}
.parent-allevent .card-qna .down .cta-sections .actions p {
  margin: 0px !important;
  margin-left: 6px !important;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}
.parent-allevent .card-qna .down .cta-sections button {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: unset;
  width: 26px;
  padding: 4px;
  height: 26px;
}
.parent-allevent .card-qna .down div button.clock {
  background: #f5f0ff;
}
.parent-allevent .card-qna .down div button.clock p {
  color: #7d61d6;
}
.parent-allevent .card-qna .down div button.play {
  background: #7d61d6;
}
.parent-allevent .card-qna .down div button.play img {
  margin-right: 2px;
}
.parent-allevent .card-qna .down div button.play p {
  color: #fff;
}

.parent-download {
  margin-top: -16px;
}

.parent-verif {
  padding: 16px;
  height: 158px;
  background: #d7efff;
}
.parent-verif section {
  display: flex;
  align-items: center;
}
.parent-verif section h1 {
  margin: 0px !important;
  margin-left: 10px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.87);
}
.parent-verif section ~ div {
  margin-top: 16px;
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 12px;
}
.parent-verif section ~ div .one {
  padding: 16px 12px;
  border-bottom: 1px solid #eaeaea;
}
.parent-verif section ~ div .one div {
  display: flex;
  align-items: center;
}
.parent-verif section ~ div .one div p {
  margin: 0px !important;
  margin-left: 8px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.87);
}
.parent-verif section ~ div .one h5 {
  margin: 0px !important;
  margin-left: 38px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.87);
}
.parent-verif section ~ div .two {
  padding: 16px 12px;
}
.parent-verif section ~ div .two .head div {
  display: flex;
  align-items: center;
}
.parent-verif section ~ div .two .head div h5 {
  margin-left: 8px;
  font-family: Rubik;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #f9a33f;
}
.parent-verif section ~ div .two .head p {
  margin: 0px !important;
  margin-left: 38px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.87);
}
.parent-verif section ~ div .two .cta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 16px;
}
.parent-verif section ~ div .two .cta .change {
  width: 48.5%;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  border-radius: 8px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.87);
}
.parent-verif section ~ div .two .cta .done {
  width: 48.5%;
  background: #ffffff;
  border: 1px solid #6c60e0;
  box-sizing: border-box;
  border-radius: 8px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #6c60e0;
}

.parent-download .parent-list {
  margin-top: 150px;
  padding: 0px 16px;
}
.parent-download .parent-list > h1 {
  margin: 0px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.87);
}
.parent-download .parent-list > h1 ~ div {
  margin-top: 16px;
}
.parent-download .parent-list .card-certificate {
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 12px;
  margin-bottom: 16px;
}
.parent-download .parent-list .card-certificate .one {
  padding: 12px;
  border-bottom: 1px solid #eaeaea;
}
.parent-download .parent-list .card-certificate .one h5 {
  margin-bottom: 8px;
  font-family: Rubik;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #6c60e0;
}
.parent-download .parent-list .card-certificate .one h1 {
  margin: 0px !important;
  margin-bottom: 12px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.87);
}
.parent-download .parent-list .card-certificate .one h1 ~ div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.parent-download .parent-list .card-certificate .one h1 ~ div div {
  display: flex;
  align-items: center;
  width: 48.5%;
}
.parent-download .parent-list .card-certificate .one h1 ~ div div p {
  margin: 0px !important;
  margin-left: 8px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.87);
}
.parent-download .parent-list .card-certificate .two {
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.parent-download .parent-list .card-certificate .two div {
  display: flex;
  align-items: center;
  width: 48.5%;
}
.parent-download .parent-list .card-certificate .two div p {
  margin: 0px !important;
  margin-left: 8px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.87);
}
.parent-download .parent-list .card-certificate .two button {
  width: 48.5%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  background: #6c60e0;
  border-radius: 8px;
  border: unset;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
}
.auths-box .warning {
  margin: 24px 0px;
}
.auths-box .warning div {
  display: flex;
  align-items: center;
}
.auths-box .warning h5 {
  margin-left: 8px;
  font-family: Rubik;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #f9a33f;
}
.auths-box .warning p {
  margin: 0px !important;
  margin-left: 38px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.87);
}

.parent-download .parent-contain {
  padding: 16px;
}

.parent-download .parent-contain .detail {
  width: 100%;
  padding: 16px 12px;
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 12px;
}
.parent-download .parent-contain .detail h5 {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 8px;
  color: #6c60e0;
}

.parent-download .parent-contain .detail h1 {
  margin: 0px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.87);
}
.parent-download .parent-contain img {
  margin: 16px 0px;
  width: 100%;
  height: 231px;
}
.parent-download .parent-contain button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: unset;
  background: #6c60e0;
  border-radius: 12px;
  height: 40px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}
.parent-download .emptys {
  height: 70vh;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.parent-download .emptys div {
  display: flex;
  align-items: center;
  width: 290px;
  flex-direction: column;
}
.parent-download .emptys div img {
  height: 84px;
  margin-bottom: 16px;
}
.parent-download .emptys div h1 {
  margin: 0px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
}
.parent-download .emptys div p {
  margin: 0px !important;
  margin-top: 6px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
}
.emptys ~ .parent-wantask {
  display: flex;
  justify-content: center;
  width: 100%;
}
.emptys ~ .parent-wantask .want-ask {
  padding: 32px 16px;
  background: #f5f0ff;
  position: fixed;
  bottom: 0px;
  max-width: 448px;
  width: 100%;
  border-radius: 12px 12px 0px 0px;
}
.emptys ~ .parent-wantask .want-ask div {
  display: flex;
}
.emptys ~ .parent-wantask .want-ask div h4 {
  margin: 0px !important;
  margin-left: 10px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.87);
}
.emptys ~ .parent-wantask .want-ask button {
  margin-top: 16px;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #6c60e0;
  border-radius: 12px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  border: unset;
}
.divs-titles {
  padding: 12px;
  border-bottom: 1px solid #eaeaea;
}
.divs-titles h1 {
  margin: 0px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.87);
}
@media (max-width: 500px) {
  #certificate-container {
    max-width: 448px !important;
    width: 100% !important;
    visibility: hidden;
  }
}
