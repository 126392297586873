/*Delip Code*/
button:focus,
select:focus,
input:focus,
textarea:focus,
button:visited,
select:visited,
input:visited,
textarea:visited {
  outline: none !important;
}

.colorbg6:hover,
.colorbg6:focus,
.colorbg2:hover,
.colorbg2:focus {
  color: white;
}

.left-left {
  width: 50%;
  float: left;
}

.right-right {
  width: 50%;
  float: left;
}

.r-23 {
  margin-right: 23px !important;
}
.l-23 {
  margin-left: 23px !important;
}

.w-4 {
  width: 25%;
  float: left;
  position: relative;
}

.step-id {
  margin: 40px 0;
  position: relative;
  /*height: 48px;*/
}

#step-in {
  text-align: center;
  margin: 0 auto;
  max-width: 826px;
  overflow: hidden;
}

#step-in .steps {
  height: 54px;
  width: 54px;
  cursor: pointer;
  /*margin: 34px 0px;*/
  color: grey;
  background-color: white;
  border: none;
  border-radius: 50%;
  display: inline-block;
  opacity: 1;
  line-height: 54px;
  font-family: Rubik;
  font-weight: 500;
  position: relative;
  font-size: 18px;
}

#step-in .steps.active {
  background: #f75490;
  color: white;
}

#step-in .w-4:after {
  content: ' ';
  display: block;
  background-color: white;
  width: 100%;
  height: 6px;
  position: absolute;
  top: 46%;
  left: 0;
}

#step-in .w-4.active:first-child:after {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 250, 252, 0) 50%,
    rgba(247, 84, 144, 1) 50%
  );
}

#step-in .w-4.active:last-child:after {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 250, 252, 0) 50%,
    rgba(247, 84, 144, 1) 50%
  );
}

#step-in .w-4:last-child:after {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 250, 252, 0) 50%,
    rgba(255, 255, 255, 1) 50%
  );
}

#step-in .w-4.active:after {
  content: ' ';
  display: block;
  background-color: #f75490;
  width: 100%;
  height: 6px;
  position: absolute;
  top: 46%;
  left: 23px;
  z-index: 2;
}

#step-in .steps:first-child:after {
  display: none;
}

#step-in .steps:first-child {
  z-index: 4;
}

#step-in .steps:nth-child(3) {
  z-index: 3;
}

#step-in .steps:nth-child(2) {
  z-index: 2;
}

.#step-in .steps:last-child {
  z-index: 1;
}

.daftar-page {
  margin-top: 24px;
  background-color: #fff !important;
  border: 1px solid #eaeaea;
}

#login {
  /*margin-top: 0 !important;*/
}
#formlogin {
  margin-top: 0 !important;
}

#navigator-utama {
  left: 0;
}

.goto-register {
  color: black;
}

html,
body,
#root {
  height: 100%;
}

body {
  /*overflow-x: hidden;*/
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  /*margin-top: 64px !important;*/
  background: #fdfbff;
}

p {
  margin-bottom: 0;
}

a {
  color: #7d61d6;
}

#py-container h2 {
  margin-top: 0;
}

.kelassaya-wrap,
.profil-wrapping,
.testimony-wrap {
  padding-top: 24px;
}
#filterDate2 {
  z-index: 1;
}

.testimony-wrap {
  position: relative;
  max-width: 425px;
  width: 100%;
  height: 560px;
  margin: 0px auto;
}

.forget-password {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;
  margin-top: 8px;
  font-weight: 700;
  font-size: 12px;
}

.forget-password:hover {
  color: #7d61d6;
}

.testimony-wrap h2 {
  font-size: 24px;
  font-weight: 600;
  font-family: Rubik;
  margin-top: 0;
}

.form-control {
  border: solid 1px #eaeaea;
  box-shadow: none;
}

.form-control:focus {
  border: solid 1px #eaeaea;
  box-shadow: none;
}

a:hover,
a:focus,
.form-control:focus,
.search-input input,
textarea:focus,
select:focus,
button:focus,
input:focus {
  outline: none !important;
}

.img-full {
  display: block;
  margin: 0 auto;
  width: 100%;
}

.checkit {
  position: relative;
}
.checkit input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.text-checkit {
  padding-left: 35px;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  /*background-color: #eee;*/
  background-color: white;
  border: solid 2px #999999;
}

/* On mouse-over, add a grey background color */
.checkit:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkit input:checked ~ .checkmark {
  background-color: #7d61d6;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkit input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkit .checkmark:after {
  left: 7px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.navbar-dashboard {
  padding: 0 !important;
  margin: 0;
  border: transparent;
}

.navbar-dashboard ul li a {
  padding: 24px;
  font-size: 14px !important;
  font-family: Rubik !important;
  font-weight: 400;
}

.brand-fammi {
  margin: 18px;
  height: auto;
  padding: 0;
}

.back-fammi {
  float: left;
  margin-right: 24px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.list-menu-dashboard {
  font-family: Rubik !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: center;
}

.list-menu-dashboard.active a {
  background: white !important;
  color: #7d61d6 !important;
  font-family: Rubik;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: center;
}

.list-menu-dashboard.active a:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  background: #7d61d6;
  width: 100%;
  height: 5px;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
}

.rel-pos {
  position: relative;
}

#header-mitra nav {
  position: fixed;
  top: 0;
  background: white;
  width: 100%;
  padding: 20px 15px;
  z-index: 5;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.2);
}

#header-mitra p {
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
}

#signup-page .white-version,
#signup-page .white-version .signup-table {
  background: #f5f5f5 !important;
}

.white-version .wrap-register-mitra {
  border: solid 1px #eaeaea;
}

#signup-page {
  height: 100% !important;
  width: 100%;
  top: 0px;
  background-color: #ffffff;
}

.signup-table {
  display: table;
  width: 100%;
  height: 100%;
}

.signup-middle {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 100%;
}

#signup-stepnav-2 {
  text-align: center;
  margin: 0 auto;
  max-width: 826px;
}

.pull-left {
  float: left;
}
.pull-right {
  float: right;
}

.mar-left-20 {
  margin-left: 20px;
}
.mar-top-10 {
  margin-top: 10px !important;
}

.mar-top-14 {
  margin-top: 14px !important;
}
.mar-top-20 {
  margin-top: 20px !important;
}
.mar-top-24 {
  margin-top: 24px !important;
}
.mar-btm-24 {
  margin-bottom: 24px !important;
}
.mar-btm-20 {
  margin-bottom: 20px !important;
}
.mar-btm-10 {
  margin-bottom: 10px !important;
}

.content-artikel-detail {
  padding: 24px;
  border-radius: 12px;
  background: white;
  border: solid 1px #eaeaea;
  margin-bottom: 24px;
}

.content-artikel-detail h2 {
  font-family: Rubik;
  font-size: 32px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 38px !important;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 0;
}

#signup-stepnav-2 .step {
  height: 54px;
  width: 54px;
  cursor: pointer;
  margin: 34px 0px;
  color: grey;
  background-color: white;
  border: none;
  border-radius: 50%;
  display: inline-block;
  opacity: 1;
  line-height: 54px;
  font-family: Rubik;
  font-weight: 500;
  position: relative;
  font-size: 18px;
}

#signup-stepnav-2 .step.active {
  background: #f75490;
  color: white;
}

#signup-stepnav-2 .step:after {
  content: ' ';
  display: block;
  background-color: white;
  width: 332px;
  height: 6px;
  position: absolute;
  top: 46%;
  right: 100%;
}

#signup-stepnav-2 .step.active:after {
  content: ' ';
  display: block;
  background-color: #f75490;
  width: 332px;
  height: 6px;
  position: absolute;
  top: 46%;
  right: 100%;
}

#signup-stepnav-2 .step:first-child:after {
  display: none;
}

#signup-stepnav-2 .step:first-child {
  z-index: 3;
}

#signup-stepnav-2 .step:nth-child(2) {
  z-index: 2;
}

#signup-stepnav-2 .step:last-child {
  z-index: 1;
}

.wrap-register-mitra {
  background: white;
  max-width: 826px;
  width: 100%;
  margin: 0 auto;
  border-radius: 12px;
  padding: 24px;
  margin-bottom: 24px;
  margin-top: 24px;
}

.wrap-register-mitra h1 {
  font-family: Rubik;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: black;
}

.wrap-register-mitra p {
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: #999999;
  margin-bottom: 8px;
}

.wrap-register-mitra label {
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}

.wrap-register-mitra input {
  padding: 15px;
  width: 100%;
  border: 1px solid #dedede;
  border-radius: 12px;
  font-family: Lato;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 16px;
}

.wrap-register-mitra select {
  border: 1px solid #dedede;
  border-radius: 12px;
  line-height: 49px;
  height: 49px;
  width: 100%;
  padding: 0 15px;
  font-family: Lato;
  margin-bottom: 6px;
  color: #999999;
}

.wrap-register-mitra textarea {
  border: 1px solid #dedede;
  border-radius: 12px;
  line-height: 49px;
  min-height: 124px;
  width: 100%;
  padding: 0 15px;
  font-family: Lato;
  margin-bottom: 6px;
}

.input-ghost {
  display: none !important;
}

.input-file {
  height: 50px;
  border-top-left-radius: 12px !important;
  border-bottom-left-radius: 12px !important;
}

.judul-af,
.judul-af-td {
  min-width: 190px;
  max-width: 190px;
}
.peserta-af,
.peserta-af-td {
  min-width: 80px;
  max-width: 80px;
}
.tgl-af,
.tgl-td {
  min-width: 110px;
  max-width: 110px;
}
.nar-af,
.nar-td {
  min-width: 130px;
  max-width: 130px;
}
.harga-af,
.harga-td {
  min-width: 80px;
  max-width: 80px;
}
.link-af,
.link-td {
  min-width: 105px;
  max-width: 105px;
  word-break: break-all;
}

.btn-choose {
  background: #7d61d6;
  padding: 15px 25px;
  color: white;
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}

.btn-purple {
  background: #7d61d6;
  width: 100%;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  padding: 7.5px 12px;
  color: white;
  border-radius: 12px;
}

.btn-purple:hover,
.btn-purple:focus {
  color: white !important;
}

.btn-white-t {
  background: transparent;
  width: 100%;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  padding: 12px;
  color: black;
  border-radius: 12px;
}

.btn-purple-t:hover,
.btn-purple-t:focus {
  color: black;
}

.btn-remix-kategori {
  width: 100%;
  overflow: hidden;
  clear: both;
}

.btn-remix-kategori .col-sm-6 {
  padding-left: 7.5px;
  padding-right: 7.5px;
}

.btn-remix-kategori .col-sm-6:first-child {
  padding-left: 0;
}

.btn-remix-kategori .col-sm-6:last-child {
  padding-right: 0;
}

.btn-white {
  background: white;
  width: 100%;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  padding: 12px;
  color: #7d61d6;
  border-radius: 12px;
  border: solid 1px #7d61d6;
}

.btn-white:hover,
.btn-white:focus {
  color: #7d61d6;
}

.btn-grey-outline {
  background: white;
  width: 100%;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  padding: 7px;
  height: 41px;
  color: #999999;
  border-radius: 12px;
  border: solid 1px #999999;
}

.btn-grey-outline:hover,
.btn-grey-outline:focus {
  color: #999999;
}

.btn-grey {
  background: #999999;
  width: 100%;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  padding: 7px;
  /*height: 41px;*/
  color: white;
  border-radius: 12px;
}

.btn-grey:hover,
.btn-grey:focus {
  color: white;
}

.btn-remix .col-sm-6 {
  padding: 0 7.5px;
}

.global-popup {
  background: rgb(0 0 0 / 36%);
}

.global-popup .content-copy-link {
  background: white !important;
  padding: 24px !important;
}
.global-popup .content-copy-link h3 {
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 0;
}
.global-popup .content-copy-link p {
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}

.eye-password img {
  margin: 16px 0 !important;
  opacity: 0.3;
}

.eye-password img.active-eye {
  opacity: 1;
}

/*.wajib-isi {
	line-height: 35px !important;
}*/

.wajib-isi {
  line-height: 7px !important;
}

.form-wajib-diisi .col-sm-6:first-child {
  padding-right: 0;
}

.table-das-af {
  padding: 24px;
  border: solid 1px #eaeaea;
  border-radius: 12px;
  background: white;
  font-size: 14px;
}

.table-das-af th {
  padding-bottom: 20px !important;
  padding-top: 0 !important;
  border-bottom: transparent !important;
}

.table-das-af td {
  padding: 15px 8px !important;
}

.share-af-td,
.viewer-af-td,
.tgl-af-td,
.no-af-td {
  line-height: 40px !important;
}

.banner-affiliate {
  width: 100%;
  float: left;
  border-radius: 12px;
  background: white;
  border: solid 1px #eaeaea;
  margin-top: 30px;
}

.img-profile-af,
.text-profile-af {
  display: inline-block;
}

.img-profile-af {
  width: 15%;
  vertical-align: top;
}
.text-profile-af {
  width: 70%;
}

.text-profile-af {
  margin-left: 24px;
}

.left-affiliate {
  width: 40%;
  float: left;
  padding: 24px;
  border-right: solid 1px #eaeaea;
}

.right-affiliate {
  width: 60%;
  float: left;
  padding: 24px;
}

.left-affiliate h2 {
  font-family: Rubik;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
}

.right-affiliate p {
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left !important;
  margin-bottom: 8px;
}

.right-affiliate h3 {
  margin-top: 0;
  font-family: Lato;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 5px;
}

.content-dashboard-af {
  margin-top: 0;
  /*margin-top: 30px;*/
  /*margin-bottom: 30px;*/
}

.content-statistic {
  margin-top: 15px;
}
.content-statistic h2,
.content-dashboard-af h2 {
  font-family: Rubik;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 40px;
}

.search-input {
  position: relative;
}

.search-input input {
  height: 41px;
  line-height: 41px;
  background: #f4f4f4;
  border-radius: 12px;
  border: none;
  padding-left: 45px;
  padding-right: 15px;
  width: 100%;
  margin-bottom: 24px;
  display: block;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  position: relative;
}

.search-input:before {
  content: url('http://beta.fammi.ly/images/searchicon.png');
  z-index: 2;
  position: absolute;
  left: 10px;
  top: 10px;
}

.input-remix input {
  height: 41px;
  line-height: 41px;
  background: white;
  border: solid 1px #eaeaea;
  border-radius: 12px;
  padding-left: 45px;
  padding-right: 15px;
  width: 100%;
  margin-bottom: 24px;
  display: block;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  position: relative;
  border-radius: 12px;
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}

.input-remix:before {
  content: url('http://beta.fammi.ly/public_html/assets/images/none-calendar.png');
  z-index: 4;
  position: absolute;
  left: 10px;
  top: 10px;
}

.input-remix.first:after {
  content: url('http://beta.fammi.ly/public_html/assets/images/arrow-calendar.png');
  z-index: 4;
  position: absolute;
  right: -10px;
  top: 10px;
  width: 8px;
}

.date-input {
  position: relative;
}

.date-input input {
  height: 41px;
  line-height: 41px;
  background: white;
  border: solid 1px #eaeaea;
  border-radius: 12px;
  padding-left: 45px;
  padding-right: 15px;
  width: 100%;
  margin-bottom: 24px;
  display: block;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  position: relative;
}

.date-input:before {
  content: url('http://beta.fammi.ly/public_html/assets/images/none-calendar.png');
  z-index: 2;
  position: absolute;
  left: 10px;
  top: 10px;
}

.date-input.first:after {
  content: url('http://beta.fammi.ly/public_html/assets/images/arrow-calendar.png');
  z-index: 2;
  position: absolute;
  right: -10px;
  top: 10px;
  width: 8px;
}

.statistic-bottom {
  border-radius: 12px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  background: white;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.statistic-bottom .card-kelas-jual {
  background: transparent;
  border-radius: 0;
  border: transparent;
  border-right: solid 1px #eaeaea;
}

.statistic-bottom .card-kelas-jual.no-border {
  border-right: transparent;
}

.pad-left-35 {
  padding-left: 35px !important;
}

.pad-right-35 {
  padding-right: 35px !important;
}

.pagination-global {
  margin: 0 auto;
  text-align: center;
  margin-bottom: 30px;
}

.pagination-global .prev,
.pagination-global .next {
  background: red;
  border-radius: 50px;
  border-top-left-radius: 50px !important;
  border-bottom-left-radius: 50px !important;
  border-top-right-radius: 50px !important;
  border-bottom-right-radius: 50px !important;
  padding: 15px 20px;
  background: #ffffff;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  border: transparent;
}

.pagination-global .prev:hover,
.pagination-global .next:hover {
  background: #eaeaea !important;
}

.pagination-global a {
  padding: 15px 20px !important;
  margin-left: 24px !important;
  border-radius: 50px;
  border-top-left-radius: 50px !important;
  border-bottom-left-radius: 50px !important;
  border-top-right-radius: 50px !important;
  border-bottom-right-radius: 50px !important;
  color: black !important;
  font-family: Rubik;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  width: 57px;
  height: 57px;
  text-align: center;
  border: transparent !important;
  background-color: transparent !important;
}

.pagination-global a:hover,
.pagination-global a.active {
  background: #7d61d6 !important;
  color: white !important;
}

#content-global {
  position: relative;
}

.pop-copy-link {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.pop-copy-link .wrap-copy-table {
  padding: 15px;
  display: table;
  margin: 0 auto;
  width: 100%;
  height: 100%;
}

.pop-copy-link .wrap-copy-center {
  display: table-cell;
  vertical-align: middle;
  margin: 0 auto;
}

.pop-copy-link .content-copy-link {
  max-width: 400px;
  background: #63ce59;
  margin: 0 auto;
  padding: 15px;
  border-radius: 12px;
}

.search-empty-table {
  display: table;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  margin-bottom: 24px;
}

.search-empty-center {
  display: table-cell;
  vertical-align: middle;
  margin: 0 auto;
}

.search-empty-content {
  max-width: 100%;
  background: white;
  border: solid 1px #eaeaea;
  margin: 0 auto;
  padding: 20px;
  border-radius: 12px;
  text-align: center;
  padding: 149px 15px;
}

.search-empty-content img {
  max-width: 200px;
  display: block;
  margin: 0 auto;
}

.search-empty-content a {
  max-width: 200px;
  margin: 0 auto;
  text-align: center;
  margin-top: 24px;
}

.search-empty-content h2 {
  text-align: center;
  font-family: Rubik;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
}

.search-empty-content p {
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: #999999;
}

.card-kelas-jual {
  padding: 24px;
  border: solid 1px #eaeaea;
  border-radius: 12px;
  background: white;
}

.kelas-text h3 {
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 10px;
  margin-top: 0;
}

.card-kelas-jual h2 {
  font-family: Rubik;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 43px;
  letter-spacing: 0em;
  text-align: left;
}

.card-kelas-jual p {
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}

.content-detail-jual {
  padding-top: 24px;
}

.wrap-icon-detail-kelas {
  margin-bottom: 24px;
}

.info-detail-kelas {
  padding: 24px;
  background: white;
  border-radius: 12px;
  border: solid 1px #eaeaea;
}

.info-detail-kelas h2 {
  font-family: Rubik;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 25px;
  margin-top: 0;
}

.icon-detail-kelas,
.text-icon-kelas {
  display: inline-block;
}

.icon-detail-kelas {
  width: 8%;
  vertical-align: top;
}
.icon-detail-kelas img {
  width: 20px !important;
}
.text-icon-kelas {
  width: 85%;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 10px;
}

.content-detail-jual img {
  width: 100%;
}

.icon-kelas-jual,
.kelas-text {
  display: inline-block;
}

.left-icon,
.text-left-icon {
  display: inline-block;
  color: white;
}
.left-icon {
  width: 10%;
  vertical-align: top;
  padding-top: 5px;
}
.text-left-icon {
  width: 80%;
  padding-left: 15px;
}

.text-left-icon h3 {
  margin-top: 0;
  margin-bottom: 8px;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: left;
}
.text-left-icon p {
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: left;
}

.img-doc-fammi img {
  width: 100%;
}

.small-doc {
  max-width: 425px;
}

.large-doc {
  max-width: 900px;
}

.empty-img,
.img-doc-fammi {
  display: block;
  margin: 0 auto;
  padding: 15px;
}

.empty-img img,
.img-doc-fammi img {
  display: block;
  margin: 0 auto;
}

.empty-img h1,
.empty-img p {
  text-align: center;
}

.img-doc-fammi img {
  margin-bottom: 24px;
}

.transparent-version .wrap-register-mitra {
  background: transparent;
  border: transparent;
}

.form-select select {
  border: 1px solid #dedede;
  border-radius: 12px;
  line-height: 49px;
  height: 49px;
  width: 100%;
  padding: 0 15px;
  font-family: Lato;
  margin-bottom: 6px;
  color: #999999;
}

.arrow-select-remix select {
  height: 41px !important;
  line-height: 41px !important;
  background: white;
}

.arrow-select {
  position: relative;
}

.arrow-select select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  position: relative;
  padding: 0 15px !important;
  background: white;
}
.arrow-select:after {
  content: '';
  width: 20px;
  height: 20px;
  background: url('http://beta.fammi.ly/images-delip/arrow-top.png') no-repeat
    center center;
  background-size: 12px;
  position: absolute;
  top: 15px;
  right: 10px;
}

.register-container .arrow-select:after {
  right: 20px;
}

.table-table {
  display: table;
  min-height: 400px;
  margin: 0 auto;
}
.table-middle {
  display: table-cell;
  vertical-align: middle;
}

.title-version {
  max-width: 1200px;
  margin: 0 auto;
}

.white-version .wrap-register-mitra {
  max-width: 100%;
  /*min-height: 400px;*/
}

.white-version .signup-middle h1 {
  font-family: Rubik;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}

.white-version .signup-middle h2 {
  font-family: Rubik;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 15px;
  margin-top: 15px;
}

.pos-rel {
  position: relative;
}
.abs-post-nav {
  position: absolute;
  width: 100%;
  top: 0;
}
.abs-post-nav h2 {
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
}

/* Chrome, Safari and Opera syntax */
:-webkit-full-screen {
  background-color: yellow;
}

/* Firefox syntax */
:-moz-full-screen {
  background-color: yellow;
}

/* IE/Edge syntax */
:-ms-fullscreen {
  background-color: yellow;
}

/* Standard syntax */
:fullscreen {
  background-color: yellow;
}

.alert-form {
  border: solid 1px red !important;
}

.wrap-status-artikel {
  padding: 34px 0px;
}

.kategori-artikel {
  padding: 24px;
  background: white;
  border-radius: 12px;
  border: solid 1px #eaeaea;
}

.kategori-artikel h2 {
  margin-top: 0;
  font-family: Rubik;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.list-kategori {
  padding: 8px 12px;
  background: #dedede;
  color: black;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  display: inline-block;
  border-radius: 12px;
}

.editor-fammi {
  padding: 24px;
  background: white;
  border-radius: 12px;
  border: solid 1px #eaeaea;
  position: relative;
}

.text-judul {
  width: 100%;
  padding: 24px;
  resize: none;
  overflow: hidden;
  height: 90px;
  font-family: Rubik;
  font-size: 32px;
  border-radius: 12px;
  border: solid 1px #eaeaea;
  margin-bottom: 23px;
  font-weight: 500;
}

textarea.text-judul::-webkit-input-placeholder,
textarea.text-cap-judul::-webkit-input-placeholder {
  color: #dddddd;
}

textarea.text-judul:-moz-placeholder,
textarea.text-cap-judul:-moz-placeholder {
  /* Firefox 18- */
  color: #dddddd;
}

textarea.text-judul::-moz-placeholder,
textarea.text-cap-judul::-moz-placeholder {
  /* Firefox 19+ */
  color: #dddddd;
}

textarea.text-judul:-ms-input-placeholder,
textarea.text-cap-judul:-ms-input-placeholder {
  color: #dddddd;
}

textarea.text-judul::placeholder,
textarea.text-cap-judul:::placeholder {
  color: #dddddd;
}

.abs-btn-editor {
  position: absolute;
  top: 24px;
  right: 0;
  width: 50%;
}

.select-remix .col-sm-6:first-child {
  padding-left: 24px;
}
.select-remix .col-sm-6:last-child {
  padding-right: 24px;
}

.ql-editor {
  color: #dddddd;
}

.ql-editor.active-text {
  color: black;
}

#editor {
  border: transparent;
  min-height: 300px;
}

.editor-fammi .ql-snow .ql-picker {
  height: auto;
  min-width: 130px !important;
  max-width: 130px !important;
  width: auto !important;
}
.editor-fammi .ql-toolbar.ql-snow {
  padding: 0 !important;
  display: inline-block;
  border: transparent;
}

.editor-fammi .ql-video {
  width: 100%;
  height: 400px;
  text-align: center;
  margin: 0 auto;
  padding-bottom: 20px;
}

.editor-fammi .ql-formats {
  padding: 7px;
  background: white;
  border: solid 1px #dedede;
  border-radius: 12px;
}

#editor .ql-editor {
  padding: 24px 0;
}

#editor .ql-editor h1 {
  margin-bottom: 10px;
  font-family: Rubik;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
}

#editor .ql-editor p {
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.ava-register-blog {
  display: block;
  padding: 24px;
  border: solid 1px #7d61d6;
  border-radius: 12px;
  margin-top: 15px;
}

.ava-register-blog .img-ava-blog {
  display: inline-block;
  /*width: 20%;*/
}

.ava-register-blog .name-ava-blog {
  display: inline-block;
  padding-left: 15px;
  /*width: 75%;*/
}

.datepicker {
  z-index: 2;
}

.content-panduan {
  padding: 16px 24px;
}

#headerlogin {
  font-family: 'Rubik';
}

.dateselect-remix {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

#panduan h1 {
  font-family: Rubik;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
  color: #7d61d6;
}

#panduan h2 {
  font-family: Rubik;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 0;
}

#panduan h3 {
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
}

#panduan .img-arrow {
  padding-top: 0;
}

.content-panduan ol {
  padding-left: 15px;
}

.content-panduan ol li {
  padding-left: 10px;
}

#panduan .box-border-question,
#panduan .box-content-question {
  float: left;
  width: 100%;
}

.empty-search-active {
  margin-top: 18px;
}

.search-container-remix:before {
  content: url(../images/searchicon.png);
  z-index: 99;
  position: absolute;
  left: 15px !important;
  top: 9px;
}
.search-container-remix {
  padding: 0 0 !important;
}

.search-container-remix input {
  padding-left: 50px !important;
}

@media (min-width: 768px) {
  .r-75 {
    padding-right: 7.5px;
  }
  .l-75 {
    padding-left: 7.5px;
  }

  .nopadding {
    padding: 0 !important;
    margin: 0 !important;
  }
  .datepicker-orient-bottom {
    top: 583px !important;
  }

  .datepicker-orient-top {
    top: 285px !important;
  }
  .kelassaya-wrap .item {
    padding-left: 8px;
    padding-right: 8px;
  }
}

@media (max-width: 991px) {
  .search-container {
    margin-top: 16px;
  }
}

@media (max-width: 768px) {
  .r-75 {
    padding-right: 7.5px;
  }
  .l-75 {
    padding-left: 7.5px;
  }
  /*#menu-search {
		display: none !important;
	}*/

  .register-container #fammi-logo-header,
  .register-container .menuutama .row > div > a > img,
  .register-container #mobile-button-content > img {
    position: inherit;
  }

  .loginheader {
    padding: 0 12px;
  }
  #step-in .steps {
    background: #eaeaea;
    color: #999999;
  }
  #step-in .w-4:after {
    background-color: #eaeaea;
    width: 100%;
    height: 3px !important;
    top: 46%;
    left: 0;
  }
  #step-in .w-4:last-child:after {
    background: rgb(234, 234, 234);
    background: linear-gradient(
      90deg,
      rgba(234, 234, 234, 1) 0%,
      rgba(234, 234, 234, 1) 50%,
      rgba(255, 255, 255, 0) 50%
    );
  }

  .kelassaya-wrap {
    padding: 24px 9px;
  }
  #menu-search .search-container:before {
    display: none !important;
  }
  .search-container {
    padding: 0 0 !important;
    margin-top: 16px;
  }
  .masuk-kelas-header.no-wid-mobile {
    min-width: inherit !important;
  }
  .testimony-wrap {
    padding: 24px;
  }
  #signup-stepnav-2 {
    overflow: hidden;
  }
  .signup-table {
    padding: 0 24px;
  }
  .hidden-xs {
    display: none !important;
  }
  .transparent-version,
  .transparent-version .wrap-register-mitra {
    padding: 0;
  }
  .wrap-nav-dashboard {
    float: left;
    width: 100%;
  }
  .wrap-nav-dashboard .nav {
    margin: 0px -15px;
  }
  .wrap-nav-dashboard li {
    float: left;
  }

  /*.judul-af, .peserta-af, .tgl-af, .nar-af, .harga-af, .link-af{
		min-width: auto;
		max-width: inherit;
	}*/
  .link-af {
    min-width: 130px;
    max-width: 130px;
  }

  .table-das-af.table-responsive > .table > tbody > tr > td,
  .table-das-af.table-responsive > .table > tbody > tr > th,
  .table-das-af.table-responsive > .table > tfoot > tr > td,
  .table-das-af.table-responsive > .table > tfoot > tr > th,
  .table-das-af.table-responsive > .table > thead > tr > td,
  .table-das-af.table-responsive > .table > thead > tr > th {
    white-space: inherit !important;
  }
  .left-affiliate {
    width: 100%;
    float: left;
    padding: 24px;
    border-right: transparent;
    border-bottom: solid 1px #eaeaea;
  }

  .right-affiliate {
    width: 100%;
    float: left;
    padding: 24px;
  }
  .banner-affiliate {
    margin-bottom: 24px;
  }
  .pagination-global a {
    padding: 10px 15px !important;
    width: 48px;
    height: 48px;
    margin-left: 0 !important;
  }
  .table-das-af {
    font-size: 14px;
  }
  .judul-af {
    min-width: 140px;
    max-width: 140px;
  }
  .global-popup .content-copy-link {
    margin-top: 0px !important;
    max-width: inherit;
  }
  .global-popup .wrap-copy-table {
    padding: 0px;
  }

  .global-popup .wrap-copy-center {
    vertical-align: bottom;
  }
  .content-copy-link {
    margin-top: -135px !important;
  }
  .back-fammi {
    margin-right: 0;
    margin-left: 24px;
  }
  .info-detail-kelas {
    margin-top: 24px;
  }
  .card-kelas-jual {
    margin-bottom: 24px;
  }
  .no-margin-top {
    margin-top: 0 !important;
  }
  .pad-left-35 {
    padding-left: 24px !important;
  }
  .pad-right-35 {
    padding-right: 24px !important;
  }
  .statistic-bottom .card-kelas-jual {
    border-right: transparent;
    border-bottom: solid 1px #eaeaea;
  }
  .statistic-bottom .card-kelas-jual.no-border {
    border-bottom: transparent;
  }
  .text-icon-kelas {
    padding-left: 15px;
  }
  .btn-remix-kategori .col-sm-6 {
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 20px;
  }
  .abs-btn-editor {
    position: inherit;
    top: 0px;
    right: 0;
    width: 100%;
    margin-bottom: 8px;
  }

  .select-remix .col-sm-6:first-child {
    padding-left: 0px;
    padding-right: 0px;
  }
  .select-remix .col-sm-6:last-child {
    padding-left: 0px;
    padding-right: 0px;
  }
  .editor-fammi .ql-formats {
    margin-bottom: 10px;
  }
  #filterDate2 {
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .image-banner {
    padding: 0 16px 16px 16px;
    margin: 0px !important;
  }
}

.metode-pembayaran {
  background: #ffffff;
  border: 1px solid #dedede;
  box-sizing: border-box;
  border-radius: 12px;
  margin: 16px 0 36px;
  width: 100%;
  height: 56px;
  padding: 8px 24px 8px 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.overlay-modal-pembayaran {
  position: fixed;
  bottom: 0px;
  right: 0px;
  top: 0px;
  left: 0px;
  display: flex;
  background: rgba(0, 0, 0, 0.65);
  height: 100vh;
  z-index: 1000;
}
.modal-pembayaran {
  background: #ffffff;
  border-radius: 12px;
  position: fixed;
  width: 400px;
  padding: 24px;
  z-index: 9999;
  margin: 0px auto;
  transition: all ease 0.5s;
}

@media (max-width: 900px) {
  .modal-pembayaran {
    bottom: 0;
    width: 100%;
    border-radius: 12px 12px 0 0;
  }
}
