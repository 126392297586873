#cart-window .window {
  height: auto;
  min-height: initial;
}
.fammibuttonalt.teangan-eta-kelas-sina-meuli {
  margin-bottom: 0px;
}
#menu-search.rek-kelaskonsultasi .item {
  display: inline-block;
}
.rek-kelaskonsultasi .item a {
  height: 100%;
}

.menu-content-utama ul h4 {
  margin-bottom: 24px !important;
}
#kategori-hover {
  font-size: 14px;
}
.menu-content-utama .sidebar:nth-child(3) > .sidebar:nth-child(2) ul {
  /* column-count: 2; */
  height: 620px !important;
  column-fill: auto;
}
.menu-content-utama
  .sidebar:nth-child(3)
  > .sidebar:nth-child(2)
  ul
  li:nth-child(14) {
  padding-top: 63px;
}
#menu-header .menu-kategori p:hover ~ #menu-content,
#menu-header .menu-kategori #menu-content:hover {
  max-height: 803px !important;
  z-index: 99;
}

.close-cat {
  position: absolute;
  margin-top: 10px;
  right: 5%;
}

.close-menus {
  position: absolute;
  top: 32px;
  right: 16px;
  cursor: pointer;
}

.sidebar ul li {
  list-style: none;
  padding: 0;
  margin: 0;
  white-space: nowrap !important;
}
.title {
  padding-bottom: 14px;
}

.sidebar ul li {
  margin-bottom: 24px;
}

#menu-header .menu-kategori p:hover ~ #menu-content,
#menu-header .menu-kategori #menu-content:hover {
  max-height: 803px;
  z-index: 99;
}

.menu-content-utama ul h4 {
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 24px;
}

.menu-content-utama .sidebar:nth-child(3) > .sidebar:nth-child(2) ul {
  column-count: 2;
  height: 625px;
  column-fill: auto;
}

.menu-content-utama
  .sidebar:nth-child(3)
  > .sidebar:nth-child(2)
  ul
  li:nth-child(14) {
  padding-top: 63px;
}

@media only screen and (max-width: 400px) {
  #inputcode-window .window,
  #cart-window .window {
    border-radius: 12px 12px 0 0;
  }
  .rek-kelaskonsultasi .item a {
    height: 74px;
  }
  .sidebar h4 {
    font-family: Rubik;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 24px;
  }
  .menu-content-utama ul h4 {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 24px !important;
  }
  .close-menu {
    width: 12px !important;
    height: 12px !important;
  }
}

.button-kerja-sama {
  color: #000000 !important;
  border-radius: 12px;
  font-family: Rubik;
  font-weight: 500;
  font-size: 14px;
  line-height: 38px;
  height: 40px;
  border: 1px solid #000;
  text-align: center;
  width: 130px;
  display: block;
  margin: 0px 16px !important;
  margin-right: 0px !important;
  margin-bottom: 24px !important;
}

.menu-content-utama ul li {
  font-size: 16px;
  line-height: 24px;
  color: #585858;
  text-decoration: none;
  list-style: none;
  margin-bottom: 24px;
}
.fitur-login ul {
  margin-left: -41px;
}
.fitur-login a {
  color: #000000;
}
@media (max-width: 1200px) and (min-width: 992px) {
  #menu-search {
    width: 39.8% !important;
    margin-left: 16px !important;
  }

  #menu-search.loggedin.member-search {
    width: 61% !important;
  }

  #header-menu-button .menu-button .fammibuttonalt {
    width: 91px !important;
    margin: 0px !important;
    margin-left: 16px !important;
  }

  #menu-kerjasama {
    display: block !important;
  }

  .menu-content-utama ul h4 {
    margin-bottom: 24px !important;
  }
  .close-menu {
    width: 12px !important;
    height: 12px !important;
  }
}

@media (max-width: 450px) {
  .menu-content-utama .sidebar:nth-child(3) > .sidebar:nth-child(2) ul {
    height: 650px !important;
    column-count: unset;
  }
  .menu-content-utama
    .sidebar:nth-child(3)
    > .sidebar:nth-child(2)
    ul
    li:nth-child(14) {
    padding-top: 0;
  }
  .sidebar ul li a {
    padding-bottom: 24px;
  }
  #profile-user p {
    font-size: 16px;
    font-weight: 400;
  }
}

@media (min-width: 1430px) {
  #menu-search {
    width: 580px !important;
  }
  #menu-search.loggedin {
    width: 580px !important;
  }

  .container {
    width: 1170px !important;
  }
}

#search-recomendation {
  width: 535px !important;
}
@media (max-width: 992px) {
  #search-recomendation {
    width: 100% !important;
  }
  #mobile-kerjasama {
    border-bottom: 1px solid #dedede;
    padding-bottom: 10px;
    margin-bottom: 15px;
    width: 232px !important;
  }
}
@media (max-width: 1198px) and (min-width: 992px) {
  #search-recomendation {
    width: 302px !important;
  }
}

.header-closed {
  display: none;
}

@media (max-width: 992px) {
  .header-closed {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: -5px;
  }
  .menu-content-utama .sidebar:nth-child(3) > .sidebar:nth-child(2) ul {
    column-count: unset;
  }
  .sidebar ul li a {
    padding-bottom: 24px;
  }
  .menu-content-utama
    .sidebar:nth-child(3)
    > .sidebar:nth-child(2)
    ul
    li:nth-child(14) {
    padding-top: 0;
  }
}

@media (max-width: 768px) {
  #fammi-bannermenu .row .item {
    width: 50% !important;
  }
  .close-menus {
    display: none;
  }
}

@media (max-width: 450px) {
  #fammi-bannermenu .row .item {
    width: 100% !important;
  }
}

@media (max-width: 768px) {
  .inactiveblock {
    display: none !important;
  }

  .inactiveblock-mob {
    background: #000;
    opacity: 0;
    z-index: -10;
    transition: opacity 0.2s ease-out;
    position: absolute;
    height: 1px;
    width: 3000px;
    left: 0px;
    display: block !important;
  }
  #profile-user {
    display: none;
  }
  #profile-user-mob {
    display: flex !important;
    align-items: center;
    margin-bottom: 32px;
  }
  #profile-user-mob img {
    border-radius: 50%;
    margin-right: 10px;
  }
  #work-btn {
    display: none !important;
  }
  #work-btn-mob {
    display: block !important;
  }
  #section-auth-btn {
    display: none !important;
  }
  #section-auth-btn-mob {
    display: flex !important;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
}
#section-auth-btn {
  display: flex;
  align-items: center;
}
#section-auth-btn-mob {
  display: none;
}

#work-btn-mob {
  display: none;
}
#profile-user-mob {
  display: none;
}
.inactiveblock-mob {
  display: none;
}
.inactiveblock {
  background: #000;
  opacity: 0;
  z-index: -10;
  transition: opacity 0.2s ease-out;
  position: absolute;
  height: 2000px;
  width: 3000px;
  left: 0px;
}
#menu-cart {
  border-left: 1px solid #888 !important;
  margin-left: 20px;
}
@media (max-width: 992px) {
  #menu-search .search-container input {
    right: 16px;
  }
  #menu-cart {
    border-left: unset !important;
  }
  .header-closed {
    background: #ffffff;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
    padding: 13px 24px;
  }
  #burger-menu-search {
    right: 16px;
  }
  #mobile-button-content {
    width: 100%;
    margin: unset;
    padding: 0px;
  }
  .text-credits {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    margin: unset !important;
    color: #ff784c;
    margin-top: 6px !important;
  }
  .menu-content-utama.grey-border {
    margin: unset !important;
    margin-top: -4px !important;
  }
  #menu-header .menu-kategori .burger-checkbox:checked ~ #menu-content {
    width: 100%;
    left: 0px;
  }
  .pad-sides {
    padding: 0px 24px;
  }
  #mobile-kerjasama {
    width: 100%;
    border-bottom: unset;
  }
  .border-sides {
    width: 100%;
    height: 1px;
    background-color: #dedede;
    margin: 16px 0px;
  }
  #section-auth-btn-mob .menu-button {
    width: 47.5%;
  }
  #section-auth-btn-mob .menu-button .fammibuttonalt {
    width: 100% !important;
  }
}
#menu-header .row > div {
  margin-left: unset !important;
}
.menu-search-container:focus-within input {
  border: 1px solid #f4f4f4;
}
.menu-search-container:focus-within input {
  border: 1px solid #7d61d6;
}

.menu-search-container:focus-within ~ .inactiveblock {
  display: block !important;
}
@media (max-width: 500px) {
  .menu-search-container:focus-within input {
    border: unset;
  }
  .menu-search-container:focus-within input {
    border: unset;
  }

  .menu-search-container:focus-within ~ .inactiveblock {
    display: none !important;
  }
}
.separator-notlogin {
  width: 1px;
  height: 40px;
  background-color: #dedede;
  float: left;
  margin-left: 24px;
  margin-right: 8px;
}
.button-kerja-sama.notlogin {
  width: 180px !important;
}
@media (max-width: 500px) {
  .separator-notlogin {
    display: none;
  }
  .menu-search-container {
    padding-left: 24px !important;
  }
  #section-credits.menu-button {
    display: none !important;
  }
}

.right-24 {
  right: 24px !important;
}

#burger-menu-search.righted-24 {
  right: 24px !important;
}
@media (min-width: 1200px) {
  #menu-search.loggedin {
    width: 580px !important;
    margin-left: 16px !important;
  }
  #menu-search.loggedin.member-search {
    width: 61% !important;
  }
  #menu-search {
    width: 50% !important;
  }
  #header-menu-button .menu-button .fammibuttonalt {
    width: 91px !important;
    margin: 0px !important;
    margin-left: 16px !important;
  }
  #header-menu-button {
    margin-right: 0px !important;
  }
  .menu-search-container {
    margin-left: 0px !important;
  }
}
#section-credits.menu-button {
  padding-left: 16px;
  border-left: 1px solid #dedede;
  border-right: 1px solid #dedede;
  margin-left: 16px;
  padding-right: 16px;
  display: flex;
  height: 80%;
  align-items: center;
}
#section-credits.menu-button .asking {
  margin-right: 16px;
  display: flex;
  align-items: center;
}
#section-credits.menu-button .asking span {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin-left: 4px;
  color: #ffffff;
  justify-content: center;
  background: #7d61d6;
}

#section-credits.menu-button .hearing span {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin-left: 4px;
  color: #ffffff;
  justify-content: center;
  background: #fd8c25;
}
#section-credits.menu-button .hearing {
  display: flex;
  align-items: center;
}
#section-credits.menu-button button {
  background: #ff784c;
  border-radius: 20.5px;
  padding: 9px 12px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  border: unset !important;
}

#profile-submenu {
  width: 305px !important;
  right: 0px !important;
}
#profile-submenu.sub-profs {
  display: flex;
}
#profile-submenu.sub-profs h1 {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin: unset !important;
  color: rgba(0, 0, 0, 0.87);
}

#profile-submenu.sub-profs .subs-member {
  width: 214px;
  padding: 16px;
  padding-right: 15px;
  border-right: 1px solid #dedede;
}
#profile-submenu.sub-profs .subs-menus {
  width: 100%;
}
#profile-submenu.sub-profs .subs-menus li a {
  padding: 5px 16px !important;
}
#profile-submenu.sub-profs .subs-menus h1 {
  margin-left: 16px !important;
}
#profile-submenu.sub-profs .subs-member .menuz {
  display: flex;
  align-items: center;
  line-height: 15px;
  cursor: pointer;
  margin-top: 14px;
}

#profile-submenu.emp-tent {
  display: flex;
}
#profile-submenu.emp-tent h1 {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin: unset !important;
  color: rgba(0, 0, 0, 0.87);
}

#profile-submenu.emp-tent .subs-member {
  width: 214px;
  padding: 16px;
  padding-right: 15px;
  border-right: 1px solid #dedede;
}
#profile-submenu.emp-tent .subs-menus {
  width: 100%;
  padding: 16px 0px;
}
#profile-submenu.emp-tent .subs-menus li a {
  padding: 5px 16px !important;
}
#profile-submenu.emp-tent .subs-menus h1 {
  margin-left: 16px !important;
}
#profile-submenu.emp-tent .subs-member .menuz {
  display: flex;
  align-items: center;
  line-height: 15px;
  cursor: pointer;
  margin-top: 14px;
}

#profile-submenu.sub-profs .subs-member .menuz img {
  height: 37px;
  width: 37px;
  object-fit: cover;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 6px;
  margin-right: 8px;
}
#profile-submenu.sub-profs .subs-member .menuz p {
  margin: unset !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  height: unset !important;
  color: rgba(0, 0, 0, 0.87);
  max-width: 150px;
  text-overflow: ellipsis;
}
#profile-submenu.sub-profs .subs-member .menuz span {
  margin: unset !important;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.87);
}

#profile-user {
  margin-left: 16px !important;
  width: auto !important;
}
#profile-user #prof-parent {
  display: flex;
  align-items: center;
}
#profile-user #prof-parent .d {
  margin-left: 14px;
}
#profile-user #prof-parent .has-sub {
  margin: 0px !important;
  height: auto !important;
}
#profile-user #prof-parent:hover ~ #profile-submenu,
#profile-submenu:hover {
  width: 305px !important;
  right: 0px !important;
  max-height: 540px !important;
}
#profile-user #prof-parent:hover ~ .inactiveblock,
#profile-submenu:hover ~ .inactiveblock {
  display: block;
  opacity: 0.7;
  left: -2063px;
  top: 51px;
}

#profile-submenu .named {
  display: flex;
  align-items: center;
  width: 100%;
  background: unset !important;
  border: unset;
  border-bottom: 1px solid #dedede;
  padding: 16px;
}
#profile-submenu .named div {
  display: flex;
  flex-direction: column;
  text-align: left;
}
#profile-submenu .named div p {
  margin: 0px !important;
  height: auto !important;
  line-height: 14px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.87);
}
#profile-submenu .named div span {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
}
#profile-submenu .menu-1 {
  padding: 16px;
  padding-bottom: 0px;
  border-bottom: 1px solid #dedede;
}
#profile-submenu .menu-1 .btn {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0px !important;
  background: unset !important;
  border: unset;
  margin-bottom: 16px;
}
#profile-submenu .menu-1 .btn div {
  display: block;
  text-align: left;
}
#profile-submenu .menu-1 .btn div p {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin: 0px !important;
  height: auto !important;
  color: rgba(0, 0, 0, 0.87);
}
#profile-submenu .menu-1 .btn div span {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
}
#profile-submenu .menu-1 .btn div span a {
  cursor: pointer;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin-left: 8px;
  padding: 0px !important;
  color: #7d61d6;
}
#profile-submenu .menu-2 {
  padding: 16px;
  padding-bottom: 8px;
  border-bottom: 1px solid #dedede;
}
#profile-submenu .menu-2 img {
  margin-right: 20px;
}
#profile-submenu .menu-2 .btn div {
  display: block;
  text-align: left;
}
#profile-submenu .menu-2 .btn div p {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin: 0px !important;
  height: auto !important;
  color: rgba(0, 0, 0, 0.87);
}
#profile-submenu .menu-2 .btn {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0px !important;
  background: unset !important;
  border: unset;
  margin-bottom: 16px;
}
#profile-submenu .menu-3 {
  padding: 16px;
  padding-bottom: 8px;
}
#profile-submenu .menu-3 img {
  margin-right: 20px !important;
}
#profile-submenu .menu-3 .btn div {
  display: block;
  text-align: left;
}
#profile-submenu .menu-3 .btn div p {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin: 0px !important;
  height: auto !important;
  color: rgba(0, 0, 0, 0.87);
}
#profile-submenu .menu-3 .btn {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0px !important;
  background: unset !important;
  border: unset;
  margin-bottom: 16px;
}
#subs-infos {
  padding: 24px 0px;
  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
  margin-bottom: 24px;
}

#subs-infos h1 {
  margin: unset !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.87);
}

#subs-infos .menuz {
  display: flex;
  align-items: center;
  line-height: 15px;
  cursor: pointer;
  margin-top: 14px;
}
#subs-infos .menuz img {
  width: 54px;
  height: 54px;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 6px;
  object-fit: cover;
  margin-right: 16px;
}
#subs-infos .menuz p {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin: unset !important;
  color: rgba(0, 0, 0, 0.87);
}
#subs-infos .menuz span {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin: unset !important;
  color: rgba(0, 0, 0, 0.87);
}
#mobile-kerjasama h1 {
  margin: unset !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 16px !important;
}

#profile-submenu-mob {
  display: none;
}
@media (max-width: 768px) {
  #profile-submenu-mob {
    display: block;
  }
  #profile-submenu-mob .named {
    display: flex;
    align-items: center;
    width: 100%;
    background: unset !important;
    border: unset;
    border-bottom: 1px solid #dedede;
    padding: 16px;
  }
  #profile-submenu-mob .named div {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-left: 12px;
  }
  #profile-submenu-mob .named div p {
    margin: 0px !important;
    height: auto !important;
    line-height: 14px !important;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.87);
  }
  #profile-submenu-mob .named div span {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.87);
  }
  #profile-submenu-mob .menu-1 {
    padding: 16px;
    padding-bottom: 0px;
    border-bottom: 1px solid #dedede;
  }
  #profile-submenu-mob .menu-1 .btn {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0px !important;
    background: unset !important;
    border: unset;
    margin-bottom: 16px;
  }
  #profile-submenu-mob .menu-1 .btn div {
    display: block;
    text-align: left;
    margin-left: 12px;
  }
  #profile-submenu-mob .menu-1 .btn div p {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    margin: 0px !important;
    height: auto !important;
    color: rgba(0, 0, 0, 0.87);
  }
  #profile-submenu-mob .menu-1 .btn div span {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.87);
  }
  #profile-submenu-mob .menu-1 .btn div span a {
    cursor: pointer;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    margin-left: 8px;
    color: #7d61d6;
  }
  #profile-submenu-mob .menu-2 {
    padding: 24px;
    padding-bottom: 8px;
    border-bottom: 1px solid #dedede;
  }
  #profile-submenu-mob .menu-2 .btn div {
    display: block;
    text-align: left;
    margin-left: 20px;
  }
  #profile-submenu-mob .menu-2 .btn div p {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    margin: 0px !important;
    height: auto !important;
    color: rgba(0, 0, 0, 0.87);
  }
  #profile-submenu-mob .menu-2 .btn {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0px !important;
    background: unset !important;
    border: unset;
    margin-bottom: 16px;
  }
  #profile-submenu-mob .menu-3 {
    padding: 24px;
    padding-bottom: 8px;
  }
  #profile-submenu-mob .menu-3 .btn div {
    display: block;
    text-align: left;
    margin-left: 20px;
  }
  #profile-submenu-mob .menu-3 .btn div p {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    margin: 0px !important;
    height: auto !important;
    color: rgba(0, 0, 0, 0.87);
  }
  #profile-submenu-mob .menu-3 .btn {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0px !important;
    background: unset !important;
    border: unset;
    margin-bottom: 16px;
  }
  .button-kerja-sama {
    width: 100%;
    margin: 0px !important;
  }
}
