.box-payments {
  background: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 12px;
  width: 100%;
}
.box-payments .product-information {
  padding: 16px;
}
.box-payments h2 {
  margin: 0px !important;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.87);
}
.box-payments .product-information .boxeds {
  background: #ffffff;
  border: 1px solid #dedede;
  border-radius: 12px;
  width: 100%;
  padding: 16px;
  margin-top: 8px;
  display: flex;
  align-items: center;
}
.box-payments .product-information .boxeds div {
  margin-left: 12px;
}
.box-payments .product-information .boxeds img {
  height: 64px;
  width: 64px;
  background: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 12px;
}
.box-payments .product-information p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin: 0px !important;
  color: rgba(0, 0, 0, 0.87);
}
.box-payments .product-information span {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin-top: 6px;
  color: #6c60e0;
}

.box-payments .cta-boxs {
  padding: 16px;
  border-top: 1px solid #eaeaea;
}
.box-payments .cta-boxs button {
  width: 100%;
  border: none;
  background: #6c60e0;
  border-radius: 12px;
  cursor: pointer;
  padding: 12px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
}

.box-payments .periode-selector {
  padding: 0px 16px;
  margin-bottom: 16px;
}
.box-payments .periode-selector .boxeds {
  background: #ffffff;
  border: 1px solid #dedede;
  border-radius: 12px;
  width: 100%;
  padding: 16px;
  margin-top: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
}
.box-payments .periode-selector .boxeds p {
  margin: 0px !important;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.87);
}
.box-payments .periode-selector .boxeds div {
  display: flex;
  align-items: center;
}

.box-payments .periode-selector .boxeds span {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin-right: 12px;
  color: #6c60e0;
}

.box-payments .parent-sheet {
  height: 100vh;
  width: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  top: 0px;
  left: 0px;
  z-index: 100;
}
.box-payments .sheet-periode {
  padding: 16px;
  background: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 12px 12px 0px 0px;
  max-width: 360px;
  width: 100%;
}
.box-payments .sheet-periode .list-periode {
  background: #ffffff;
  border: 1px solid #dedede;
  border-radius: 12px;
  padding: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 10px;
  justify-content: space-between;
}
.box-payments .sheet-periode .list-periode.actived {
  background: #f5f0ff;
  border: 1px solid #6c60e0;
}
.box-payments .sheet-periode .list-periode div {
  background: #ffffff;
  border: 2px solid #999999;
  height: 16px;
  width: 16px;
  border-radius: 50%;
}
.box-payments .sheet-periode .list-periode div.actived {
  border: 4px solid #6c60e0;
}
.box-payments .sheet-periode .list-periode p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin: 0px !important;
  color: rgba(0, 0, 0, 0.87);
}
.box-payments .sheet-periode .ctas {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
}
.box-payments .sheet-periode .ctas button {
  width: 47.5%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  cursor: pointer;
}

.box-payments .sheet-periode .ctas button.closes {
  background-color: #fff;
  border: 1px solid #999999;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.87);
}
.box-payments .sheet-periode .ctas button.actions {
  background: #999999;
  border: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}
.box-payments .payment-selector {
  padding: 0px 16px;
  margin-bottom: 16px;
}
.box-payments .payment-selector .boxeds {
  background: #ffffff;
  border: 1px solid #dedede;
  border-radius: 12px;
  width: 100%;
  padding: 16px;
  margin-top: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
}
.box-payments .payment-selector .boxeds div {
  display: flex;
  align-items: center;
}
.box-payments .payment-selector .boxeds div .img {
  height: 18px;
  margin-right: 10px;
}
.box-payments .payment-selector .boxeds span {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin-right: 12px;
  color: #6c60e0;
}

@media (max-width: 768px) {
  .box-payments {
    border: none;
  }
  .box-payments .product-information {
    padding: 0px;
    margin-bottom: 16px;
  }
  .box-payments .periode-selector {
    padding: 0px;
  }
  .box-payments .payment-selector {
    padding: 0px;
  }
  .box-payments .cta-boxs {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: 1;
    background: #fff;
  }
  .box-payments .sheet-periode {
    max-width: unset;
  }
}
