#detail_section {
    display: flex;
    flex-direction: column;
    margin: 24px 0px;
}

#detail_section hr {
    margin: 0px;
}

#detail_section .ds_head {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

#detail_section .ds_title {
    display: flex;
    flex-direction: row;
    align-items: center;
}

#detail_section .ds_title h6 {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.87);
    margin: 0;
}

#detail_section .dot {
    width: 4px;
    height: 17px;
    background: #6C60E0;
    border-radius: 0px 4px 4px 0px;
    margin-right: 12px;
}

#detail_section .ds_box {
    border-radius: 12px;
    border: 1px solid #EAEAEA;
    margin: 0px 16px;
    margin-top: 10px;
}

#detail_section .ds_img {
    padding-right: 16px;
}


/* Section Content */
#section_content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
}

#section_content p {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.87);
}

#section_content div>p:nth-child(1) {
    color: #999999;
    margin-bottom: 4px;
}

#section_content button {
    background: #FFFFFF;
    border-width: 1px;
    border-style: solid;
    border-color: #6C60E0;
    border-radius: 8px;
    padding: 8px;
    width: 60px;
}

#section_content button>p {
    color: #6C60E0;
}


/* Payment Info */
#payment_info {
    border-radius: 12px;
    border-width: 1px;
    border-style: solid;
    padding: 12px;
    margin: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

#payment_info p {
    margin-left: 8px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #6C60E0;
}


/* Paymend Fixed Button */
#pf_button {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px -5px 30px rgba(0, 0, 0, 0.1);
    border-radius: 12px 12px 0px 0px;
    padding: 16px;
}

#pf_button button {
    background: #6C60E0;
    border-radius: 12px;
    width: 100%;
    padding: 12px;
    border: none;
    color: #fff;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
}


@media (min-width:1000px) {
    /* #pf_button {
        max-width: 360px;
    } */
}