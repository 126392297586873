.audios-event {
  position: fixed;
  top: 0px;
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  left: 0px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.parent-aud {
  position: fixed;
  bottom: 0px;
  width: 100%;
  z-index: 1000;
  left: 0px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.parent-aud section {
  background: #ffffff;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 12px 12px 0px 0px;
  max-width: 448px;
  width: 100%;
}
.parent-aud section .content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
}
.parent-aud section .content > img {
  cursor: pointer;
}
.parent-aud section .content .up {
  display: block;
}
.parent-aud section .content > img ~ div {
  width: 65%;
}

.parent-aud section .content > img ~ div h5 {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  margin: 0px !important;
  margin-bottom: 2px !important;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.parent-aud section .content > img ~ div p {
  margin: 0px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: #999999;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.parent-aud section .parent-indicator {
  background: rgba(0, 0, 0, 0.05);
  width: 100%;
  height: 3px;
  border-radius: 6px;
}

.parent-aud section .indicator {
  width: 0%;
  height: 3px;
  background: #ffb800;
  border-radius: 6px;
}

.audios-event section {
  background: #ffffff;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 12px 12px 0px 0px;
  max-width: 448px;
  width: 100%;
  position: inherit;
  bottom: 0px;
}
.audios-event section img {
  cursor: pointer;
}
.audios-event section .head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 12px;
}
.audios-event section .content {
  padding: 0px 16px;
}

.audios-event section .content .one {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.audios-event section .content .one div {
  width: 81%;
}
.audios-event section .content .one div h5 {
  margin: 0px !important;
  margin-bottom: 8px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.87);
  text-overflow: ellipsis;
  display: -webkit-box;
  width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.audios-event section .content .one div p {
  margin: 0px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #999999;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}
.audios-event section .content .one img {
  height: 48px;
}
.audios-event section .content .two {
  margin: 24px 0px;
}
.audios-event section .content .two .parent-indicator {
  background: rgba(0, 0, 0, 0.05);
  width: 100%;
  height: 3px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 6px;
}
.audios-event section .content .two .indicator {
  width: 0%;
  height: 3px;
  background: #ffb800;
  border-radius: 6px;
}

.audios-event section .content .two .circle {
  background: rgba(0, 0, 0, 0.87);
  height: 7px;
  width: 7px;
  border-radius: 50%;
}
.audios-event section .content .two .parent-time {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.audios-event section .content .two .parent-time p {
  margin: 0px !important;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: #999999;
}
.audios-event section .content .three {
  padding-bottom: 24px;
  border-bottom: 1px solid #eaeaea;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.audios-event section .content .three div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 31%;
  background: #ffffff;
  border: 1px solid #999999;
  border-radius: 12px;
}

.audios-event section .content .three div p {
  margin: 0px !important;
  margin-left: 4px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.87);
}

.audios-event section .content .four {
  padding: 24px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.audios-event section .content .four p {
  margin: unset !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.87);
}
.audios-event section .content .four .rate {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  width: 61%;
}
.audios-event section .content .five {
  padding: 24px 0px;
  border-top: 1px solid #eaeaea;
}

.audios-event section .content .five p {
  margin: unset !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}
.audios-event section .content .five textarea {
  width: 100%;
  height: 145px;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  padding: 12px;
  border: 1px solid rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  border-radius: 12px;
  resize: none;
  margin-top: 8px;
}
.audios-event section .content .five button {
  margin-top: 24px;
  background: #7d61d6;
  border-radius: 12px;
  width: 100%;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  border: unset;
}

.parent-aud .loads {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 16px;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 32px;
  height: 32px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  margin: 0px;
  border: 4px solid #7d61d6;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #7d61d6 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.parent-aud section .content .play-desktop {
  display: none;
}
.parent-aud section .content .sectione-twos {
  display: none;
}
.parent-aud section .content .sectione-threes {
  display: none;
}

#reason-box {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
}
#reason-box section {
  width: 360px;
  height: auto;
  padding: 24px;
  padding-bottom: 8px;
  background: #ffffff;
  border-radius: 12px;
}
#reason-box section h4 {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  width: 100%;
  margin: 0px !important;
  color: rgba(0, 0, 0, 0.87);
}
#reason-box section h4.isi {
  text-align: left;
}
#reason-box section .stared img {
  height: 36px;
  margin-left: 6.5px;
}
#reason-box section .stared {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 12px 0px;
  padding-bottom: 16px !important ;
  border-bottom: 1px solid #eaeaea;
}
#reason-box section p {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  margin: 8px 0px !important;
  color: rgba(0, 0, 0, 0.87);
}
#reason-box section textarea {
  width: 100%;
  height: 145px;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  padding: 12px;
  border: 1px solid rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  border-radius: 12px;
  resize: none;
  margin-top: 8px;
}
#reason-box section .ctas {
  display: block;
  width: 100%;
  margin-top: 24px;
}
#reason-box section .ctas button {
  width: 100%;
  height: 41px;
  margin-bottom: 16px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#reason-box section .ctas button p {
  margin: 0px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}
#reason-box section .ctas .send {
  border: none;
  background: #7d61d6;
}

#reason-box section .ctas .send p {
  color: #fff;
}
#reason-box section .ctas .closes {
  border: 1px solid #000;
  background: none;
}
#reason-box section .ctas .closes p {
  color: #000;
}

.popup-dekstops {
  display: none;
}
@media (max-width: 500px) {
  #reason-box {
    padding: 0px;
    align-items: flex-end;
  }
  #reason-box section {
    padding: 16px;
    border-radius: 12px 12px 0px 0px;
  }
}

.audios-event section .head .minutesec {
  display: flex;
  align-items: center;
  background: #f9a33f;
  border-radius: 6px;
  padding: 3px 6px;
}
.audios-event section .head .minutesec p {
  margin: 0px !important;
  margin-left: 4px !important;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
}
@media (min-width: 1000px) {
  .popup-dekstops {
    display: block !important;
  }
  .parent-aud section {
    max-width: 1170px;
  }
  .parent-aud section .content {
    padding: 0px;
  }
  .parent-aud section .content .up {
    display: none;
  }
  .parent-aud section .content .play-mobiles {
    display: none;
  }
  .parent-aud section .content .play-desktop {
    display: block;
    height: 48px;
    width: 48px;
    margin-right: 16px;
  }
  .parent-aud section .content .close-im {
    display: none;
  }
  .parent-aud section .content .sectione-ones {
    width: 30.7%;
    display: flex;
    align-items: center;
    border-right: 1px solid #eaeaea;
    padding: 20px 16px;
    height: 114px;
  }
  .parent-aud section .content .sectione-ones.simple {
    border-right: none;
  }
  .parent-aud section .content > img ~ div h5 {
    white-space: normal;
    font-size: 16px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .parent-aud section .content .sectione-twos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #eaeaea;
    height: 114px;
    width: 41.3%;
    padding: 0px 32px;
  }
  .parent-aud section .content .sectione-twos.simple {
    width: 59.3%;
    border-right: none;
  }
  .parent-aud section .content .sectione-twos .two {
    width: 100%;
  }
  .parent-aud .content ~ .parent-indicator {
    display: none;
  }
  .parent-aud section .content .three {
    padding-bottom: 12px;
    border-bottom: 1px solid #eaeaea;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .parent-aud section .content .three div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 31%;
    background: #ffffff;
    border: 1px solid #999999;
    border-radius: 12px;
  }

  .parent-aud section .content .three div p {
    margin: 0px !important;
    margin-left: 4px !important;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.87);
  }
  .parent-aud section .content .two .parent-time {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .parent-aud section .content .two .parent-time p {
    margin: 0px !important;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #999999;
  }
  .parent-aud section .content .two .parent-indicator {
    background: rgba(0, 0, 0, 0.05);
    width: 100%;
    height: 3px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 6px;
  }
  .parent-aud section .content .two .indicator {
    width: 0%;
    height: 3px;
    background: #ffb800;
    border-radius: 6px;
  }

  .parent-aud section .content .two .circle {
    background: rgba(0, 0, 0, 0.87);
    height: 7px;
    width: 7px;
    border-radius: 50%;
  }
  .parent-aud section .content .sectione-threes {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 28%;
    height: 114px;
  }
  .parent-aud section .content .sectione-threes.simple {
    width: 10%;
    border-right: none;
  }
  .parent-aud section .content .four {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .parent-aud section .content .four p {
    margin: unset !important;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.87);
    text-align: center;
  }
  .parent-aud section .content .four .rate {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    width: 90%;
    cursor: pointer;
  }
  .parent-aud section .content .four .rate img {
    height: 36px;
  }
}
