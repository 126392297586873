#profile_menu {
    position: relative;
}

#profile_menu p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: rgba(0, 0, 0, 0.87);
    margin: 0;
}

.backdrop {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 64px;
    left: 0;
}