.parent_fp {
  margin-bottom: 72px;
}
.fp_items {
  margin-bottom: 16px;
}

.fp_items .tx_label {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.87);
}

.inp_item {
  background: #ffffff;
  border: 1px solid #dedede;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 12px 14px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  outline: none;
  width: 100%;
  margin-top: 8px;
}

.fp_alert {
  display: flex;
  align-items: center;
  background: #f5f0ff;
  border-radius: 6px;
  margin-top: 10px;
  padding: 7px 12px;
}
.fp_alert p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  margin-left: 6px;
  color: rgba(0, 0, 0, 0.87);
}

.fp_gender {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
}
.fp_gender_item_active {
  width: 47.5%;
  background: #f5f0ff;
  padding: 11px 12px;
  border: 1px solid #6c60e0;
  box-sizing: border-box;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.fp_gender_item_active div {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 5px solid #6c60e0;
}
.fp_gender_item_active p {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin-left: 8px;
  color: #6c60e0;
}
.fp_gender_item {
  width: 47.5%;
  background: #ffffff;
  border: 1px solid #dedede;
  padding: 11px 12px;
  box-sizing: border-box;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.fp_gender_item.mandatory{
  border: solid 1px #EA4335; 
}
.fp_gender_item div {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 3px solid #dedede;
}
.fp_gender_item p {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin-left: 8px;
  color: #6c60e0;
}
.e_participant {
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 16px 12px;
  margin-top: 16px;
}
.e_participant .head_s {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.e_participant .head_s .part_s {
  display: flex;
  align-items: center;
}

.e_participant .head_s .part_s p {
  margin: 0px !important;
  margin-left: 8px !important;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.87);
}
.e_participant .head_s .quota_s {
  background: #d7efff;
  border-radius: 8px;
  padding: 8px 12px;
}
.e_participant .head_s .quota_s p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #369cf7;
  margin: 0px !important;
}
.e_participant .par_bars {
  background: #dedede;
  border-radius: 3px;
  height: 6px;
  margin-top: 10px;
}

.e_participant .par_bars .active_b {
  background: #369cf7;
  border-radius: 3px;
  height: 6px;
  width: 70%;
}
