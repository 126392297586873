.box-popups {
  width: 640px;
  height: 265px;
  display: flex;
  border-radius: 12px;
}
.image-popups {
  background: url(/assets/images/popups-bg.png) center no-repeat;
  background-size: cover;
  height: 100%;
  width: 265px;
  border-radius: 12px 0px 0px 12px;
}

.content-popups {
  background-color: #fff;
  width: 375px;
  border-radius: 0px 12px 12px 0px;
  padding-left: 24px;
  padding-bottom: 24px;
  height: 100%;
}
.close-popups {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 12px;
  padding-bottom: 0px;
}
.close-popups img {
  cursor: pointer;
}
.main-popups {
  padding-right: 24px;
  margin-top: -8px;
}

.main-popups h1 {
  font-family: Rubik;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.87);
  margin: 0px !important;
}
.descone {
  margin-top: 12px !important;
  margin-bottom: 0px !important;
}
.descone div {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 12px;
}
.descone div img {
  height: 24px;
  margin-right: 8px;
}
.descone div p {
  margin: 0px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.87);
}
.options-popups {
  margin-top: 16px;
}
.options-popups p {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin: unset !important;
  color: rgba(0, 0, 0, 0.87);
}
.options-popups div {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.options-popups div .google {
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  border-radius: 12px;
  padding: 8.5px 0px;
  background: unset;
  width: 47.5%;
  justify-content: center;
}
.options-popups div .google p {
  margin: unset !important;
  margin-left: 8px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
}

.options-popups div .facebook {
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  border-radius: 12px;
  padding: 8.5px 0px;
  background: unset;
  width: 47.5%;
  justify-content: center;
}
.options-popups div .facebook p {
  margin: unset !important;
  margin-left: 8px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
}

.options-popups > button {
  width: 100%;
  padding: 12px 0px;
  text-align: center;
  background: #ffffff;
  border-radius: 12px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #7d61d6;
  border: 1px solid #7d61d6;
  margin-top: 16px;
}
.parent-closeM {
  display: none;
}
@media (max-width: 500px) {
  #logins.parent-voice-over {
    padding: 0px 16px;
    align-items: center;
  }
  .box-popups {
    width: 100%;
    flex-direction: column;
    height: fit-content;
  }
  .image-popups {
    width: 100%;
    height: 164px;
    background: url(/assets/images/popups-bg-mob.png) center no-repeat;
    background-size: cover;
    border-radius: 12px 12px 0px 0px;
  }
  .close-popups {
    display: none;
  }
  .content-popups {
    width: 100%;
    height: fit-content;
    border-radius: 0px 0px 12px 12px;
    padding: 0px;
  }
  .main-popups {
    margin-top: 0px;
    padding: 16px;
  }

  .main-popups h1 {
    font-size: 18px;
    line-height: 21px;
  }
  .options-popups p {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.87);
  }
  .parent-closeM {
    display: flex;
    justify-content: flex-end;
    padding: 8px;
  }
}
