.auths-box {
  position: fixed;
  top: 0px;
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1001;
  left: 0px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.auths-box section {
  max-width: 448px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 12px 12px 0px 0px;
  padding: 16px;
}
.auths-box section > img {
  cursor: pointer;
}
.auths-box section h1 {
  margin: 0px !important;
  margin-top: 24px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 33px;
  color: rgba(0, 0, 0, 0.87);
}
.auths-box section .one {
  margin-top: 24px;
  width: 100% !important;
  display: block !important;
  border: unset !important;
}
.auths-box section .one button {
  width: 100%;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  border-radius: 12px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0px 24px;
}
.auths-box section .one button.login-cta {
  border: 1px solid #7d61d6;
}
.auths-box section .one button.login-cta p {
  color: #7d61d6;
}
.auths-box section .one button img {
  width: 24px;
}
.auths-box section .one button p {
  margin: 0px !important;
  margin-left: 24px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.87);
}
.auths-box section .one div {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin: 16px 0px;
}
.auths-box section .one div p {
  margin: 0px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #999999;
}
.auths-box section .one div hr {
  width: 45%;
  background-color: #999999;
  height: 1px;
}

.auths-box section .two {
  margin-top: 24px;
}
.auths-box section .two .form-register .form-item {
  width: 100%;
  margin-bottom: 16px;
}
.auths-box section .two .form-register .form-item label {
  margin: unset !important;
  margin-bottom: 8px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.87);
}
.auths-box section .two .form-register .form-item input {
  border: unset;
  outline: unset;
  width: 100%;
  font-size: 14px;
}
.auths-box section .two .form-register .form-item div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 41px;
  padding: 0px 14px;
  background: #ffffff;
  border: 1px solid #dedede;
  box-sizing: border-box;
  border-radius: 12px;
}
.auths-box section .two .form-register .form-item div img {
  cursor: pointer;
}
.auths-box section .two .form-register .kebijakan {
  margin-bottom: 24px;
}
.auths-box section .two .form-register .kebijakan p {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  margin: unset !important;
  color: #585858;
}

.auths-box section .two .form-register .kebijakan p a {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  margin: unset !important;
  color: #6c60e0;
  text-decoration: underline !important;
}

.auths-box section .two .form-register .btn-daftar {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #999999;
  border-radius: 12px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  border: unset;
  margin-bottom: 16px;
}
.auths-box section .two .form-register .btn-back {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 12px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #999999;
}
.auths-box section .step-four p {
  margin: 0px !important;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #585858;
}
.auths-box section .step-four {
  margin-top: 24px;
}
.auths-box section .step-four button {
  width: 100%;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  border-radius: 12px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0px 24px;
}
.auths-box section .step-four .reset-cta {
  background-color: #6c60e0;
  border: unset;
  margin-top: 16px;
  margin-bottom: 0px;
}
.auths-box section .step-four .reset-cta.fives {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  justify-content: center;
}
.auths-box section .step-four .remember-cta {
  background: #ffffff;
  border: 1px solid #6c60e0;
  margin-bottom: 0px;
}
.auths-box section .step-four .remember-cta p {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin: 0px !important;
  margin-left: 24px !important;
  color: #6c60e0;
}
.auths-box section .step-four .reset-cta p {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin: 0px !important;
  margin-left: 24px !important;
  color: #ffffff;
}
.auths-box section .step-four div {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin: 16px 0px;
}
.auths-box section .step-four div p {
  margin: 0px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #999999;
}
.auths-box section .step-four div hr {
  width: 45%;
  background-color: #999999;
  height: 1px;
  margin: 0px !important;
}
.auths-box section .step-four .back {
  margin-top: 16px;
  background: #ffffff;
  border: 1px solid #999999;
  text-align: center;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  justify-content: center;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.87);
}
.auths-box section .step-four .back.eights-cta {
  margin-top: 16px;
  background: #ffffff;
  border: 1px solid #999999;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.87);
}
.auths-box section .step-four .back.eights-cta p {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin: 0px !important;
  margin-left: 24px !important;
  color: rgba(0, 0, 0, 0.87);
}
.auths-box section .step-four .five-sections {
  width: 100%;
  margin-bottom: 16px;
  display: block;
}
.auths-box section .step-four .five-sections p {
  margin: unset !important;
  margin-bottom: 8px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.87);
  text-align: left;
}
.auths-box section .step-four .five-sections input {
  border: unset;
  outline: unset;
  width: 100%;
}
.auths-box section .step-four .five-sections div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 41px;
  padding: 0px 14px;
  background: #ffffff;
  border: 1px solid #dedede;
  box-sizing: border-box;
  border-radius: 12px;
  margin: 0px;
}
.auths-box section .reset-step {
  display: flex;
  align-items: center;
  padding: 12px;
  width: 100%;
  justify-content: space-between;
  background: #f5f0ff;
  border-radius: 12px;
}
.auths-box section .reset-step .steps {
  display: flex;
  align-items: center;
}
.auths-box section .reset-step .steps .numbers {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
}

.auths-box section .reset-step .steps .numbers p {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  margin: 0px !important;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
}

.auths-box section .reset-step .steps .numbers ~ p {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin: 0px 6px !important;
  color: rgba(0, 0, 0, 0.5);
}

.auths-box section .reset-step .steps hr {
  background: #999999;
  height: 1px;
  width: 14px;
  margin: 0px !important;
  margin-left: 7px !important;
}
.auths-box section .reset-step .steps.active hr {
  background: #6c60e0;
}
.auths-box section .reset-step .steps.active .numbers {
  background-color: #6c60e0;
}
.auths-box section .reset-step .steps.active .numbers p {
  color: #fff;
}
.auths-box section .reset-step .steps.active .numbers ~ p {
  color: #6c60e0;
}

@media (min-width: 1000px) {
  .auths-box {
    align-items: center !important;
  }
  .auths-box section {
    border-radius: 12px !important;
  }
  .auths-box section {
    max-width: 360px !important;
  }
}
