#premium-dialog-box {
    position: fixed;
    top: 0px;
    height: 100vh;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1001;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#premium-dialog {
    max-width: 360px;
    height: auto;
    background: #FFFFFF;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding: 16px;
}

#premium-dialog .pd-head {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 24px;
}

#premium-dialog h1 {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.87);
    margin: 0;
    margin-left: 16px;
    max-width: 264px;
}

#premium-dialog p {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.87);
}


#premium-dialog .pd-info {
    background: #FFF3C6;
    border: 1px solid #F9A33F;
    border-radius: 12px;
    padding: 12px;
}

#premium-dialog .pd-info p {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.87);
    margin: 0;
}


#premium-dialog .pd-features {
    margin-top: 16px;
    margin-bottom: 24px;
}

#premium-dialog .pd-feature {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 12px;
}

#premium-dialog .pd-footer button {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #6C60E0;
    border-radius: 12px;
    padding: 8px 24px;
    height: 40px;
    border: 0;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    width: 100%;
}

#premium-dialog .pd-footer button img {
    margin-right: 24px;
}


#premium-dialog .pd-footer .pd-call-admin {
    color: #fff;
    margin-bottom: 16px;
}

#premium-dialog .pd-footer .pd-close {
    color: #000;
    background: #fff;
    border: 1px solid #999999;
}


@media (max-width:500px) {
    #premium-dialog-box {
        align-items: flex-end;
    }

    #premium-dialog {
        max-width: 100vw;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }
}