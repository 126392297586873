.cards-qna {
  width: 215px;
  height: auto;
  background: #ffffff;
  border: 1px solid #dedede;
  box-sizing: border-box;
  margin-right: 24px;
  border-radius: 12px;

  flex: unset !important;
}
.cards-qna img {
  height: 215px;
  width: 215px;
  border-radius: 12px 12px 0px 0px;
  object-fit: cover;
}
.cards-qna .contents {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: auto;
  width: 100%;
}
.cards-qna .contents .name {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 17px;
  margin: unset !important;
  color: rgba(0, 0, 0, 0.87);
}

.cards-qna .contents .description {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin: unset !important;
  margin-top: 14px !important;
  margin-bottom: 8px !important;
  color: rgba(0, 0, 0, 0.87);
}
.cards-qna .contents .description span {
  font-family: Lato;
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 14px;
}
.cards-qna .contents .description span.isSoon {
  color: #7d61d6;
}
.cards-qna .contents .description span.isEnd {
  color: #000;
}
.cards-qna .contents .description span.isOnGoing {
  color: #fd8c25;
}

.cards-qna .contents .time {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin: unset !important;
  padding-bottom: 12px;
  color: rgba(0, 0, 0, 0.87);
}

.cards-qna .contents button {
  background: #7d61d6;
  border-radius: 12px;
  margin-top: 16px;
  width: 100%;
  height: 41px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  border: unset;
}

.the-answer {
  margin-top: 12px;
  margin-bottom: 12px;
}

@media (max-width: 768px) {
  .cards-qna {
    width: 46%;
  }
  .cards-qna img {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .the-answer {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .cards-qna .contents .description span {
    font-weight: 400;
    font-size: 10px;
  }
  .cards-qna {
    width: 46%;
    height: auto;
    margin-right: 16px;
  }
  .cards-qna img {
    height: 144px;
    width: 100%;
  }
  .cards-qna .contents .name {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    width: 128px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 14px;
    margin: unset !important;
    color: rgba(0, 0, 0, 0.87);
  }
  .cards-qna .contents .description {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    margin: unset !important;
    margin-top: 12px !important;
    margin-bottom: 4px !important;
    color: rgba(0, 0, 0, 0.87);
  }
  .cards-qna .contents {
    padding: 8px;
    height: auto;
  }

  .cards-qna .contents .time {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: rgba(0, 0, 0, 0.87);
  }
  .cards-qna .contents button {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #ffffff;
  }
}
