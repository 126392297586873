.parent-header-e {
  display: flex;
  justify-content: center;
  width: 100%;
}

.parent-header-e .menu-mob {
  display: block;
}

.materials-mobile {
  display: block;
}

.materials-desktop {
  display: none;
}

.parent-header-e .menu-mobiles {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  z-index: 10000;
  background: #fdfbff;
  height: 100vh;
  width: 100%;
}

.parent-header-e .menu-mobiles .headers {
  padding: 19px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #eaeaea;
}

.parent-header-e .menu-mobiles .logouts {
  padding: 19px 16px;
  display: flex;
  align-items: center;
  width: 100%;
  border-top: 1px solid #eaeaea;
  cursor: pointer;
  margin-left: 10px;
}

.parent-header-e .menu-mobiles .mained {
  display: block;
  padding: 24px 16px;
}

.parent-header-e .menu-mobiles .mained .menu-ava {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.parent-header-e .menu-mobiles .mained .menu-ava p {
  margin: 0px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}

.parent-header-e .menu-mobiles .mained .menu-ava div {
  background: #6c60e0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  width: 42px;
  border-radius: 12px;
  margin-right: 12px;
}

.parent-header-e .menu-mobiles .mained .menu-ava h5 {
  margin: 0px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.87);
}

.parent-header-e .menu-mobiles .mained .menu-status {
  display: flex;
  align-items: center;
}

.parent-header-e .menu-mobiles .mained .menu-status div {
  margin-left: 12px;
}

.parent-header-e .menu-mobiles .mained .menu-status p {
  margin: 0px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.87);
}

.parent-header-e .menu-mobiles .mained .menu-status span {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin: 0px !important;
  margin-top: 8px !important;
  color: rgba(0, 0, 0, 0.87);
}

.parent-header-e .menu-mobiles .logouts p {
  margin: 0px !important;
  margin-left: 20px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.87);
}

.parent-header-e .menu-mobiles .headers p {
  margin: 0px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #262626;
}

.event-header div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
  padding-left: 16px;
}

.event-header {
  width: 100%;
  max-width: 448px;
  padding: 12px 0;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0px;
  z-index: 1000;
  background-color: #fff;
}

.parent-event {
  display: flex;
  justify-content: center;
  width: 100%;
  background: #f2f2f2;
  margin-top: -90px;
  padding-top: 54px;
  height: auto;
  min-height: 100%;
  overflow: initial;
}

.event-container {
  max-width: 448px;
  width: 100%;
  margin-top: -24px;
  padding-top: 0px;
  background: #fcfcfc;
}

.event-logo {
  width: 123px;
  height: 41px;
}

.event-cover {
  width: 100%;
  min-height: 100%;
  height: auto;
  max-height: 244px;
  overflow: hidden;
  border-radius: 10px;
}

.zoom-schedule.soon {
  background: #999;
  color: #fff;
  border: none;
}

.zoom-schedule.opened {
  background: #7d61d6;
  color: #fff;
  border: none;
}

.zoom-schedule.ended {
  background: #fff;
  color: #999999;
  border: solid 1px #999;
}

.zoom-schedule.unavailable {
  background: #999;
  color: #fff;
  border: none;
}

.zoom-schedule.ended.unavailable {
  background: #fff;
  color: #999;
  border: solid 1px #999;
}

.recorded-materials,
.certificate {
  background: #7d61d6;
  color: #fff;
  border: none;
}

.recorded-materials.unavailable,
.certificate.unavailable {
  background: #999;
  color: #fff;
  border: solid 1px #999;
}

.parent-notif-success.aud {
  position: fixed;
  bottom: 24px;
  width: 100%;
  display: flex;
  justify-content: center;
  left: 0px;
  z-index: 1;
}

.box-notif-success {
  display: flex;
  background: #fd8c25;
  border-radius: 12px;
  align-items: center;
  width: 327px;
  height: 86px;
  padding: 26px;
}

.section-text-notif {
  margin-left: 26px;
}

.text-title-notif {
  margin: unset !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}

.text-description-notif {
  margin: unset !important;
  margin-top: 4px !important;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}

.event-container .menu {
  margin: 32px 0px;
  display: flex;
  width: 100%;
  padding: 0px 16px;
}

.event-container .menu div {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  cursor: pointer;
}

.event-container .menu div p {
  margin: 0px !important;
  margin-top: 6px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #262626;
}

.event-container .last-event {
  margin-bottom: 32px;
  padding: 0px 16px;
}

.event-container .last-event>h1 {
  margin: 0px !important;
  margin-bottom: 10px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.87);
}

.h1-prog {
  margin: 0px !important;
  margin-bottom: 16px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.87);
}

.event-container .last-event .head-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
}

.event-container .last-event .head-section div {
  background: #f9a33f;
  border-radius: 10px;
  padding: 4px 13px;
}

.event-container .last-event .head-section div p {
  margin: 0px !important;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
}

.event-container .last-event .head-section h1 {
  margin: 0px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.87);
}

.card-events {
  width: 100%;
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 12px;
}

.card-events .quiz-carded {
  display: flex;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #eaeaea;
}

.card-events .quiz-carded .imag {
  width: 88px;
  height: 88px;
  border-radius: 12px;
}

.card-events .quiz-carded div {
  margin-left: 16px;
}

.card-events .quiz-carded h5 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.87);
  margin: 0px !important;
}

.card-events .quiz-carded p {
  margin: 0px !important;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #6c60e0;
}

.card-events>img {
  width: 100%;
  height: 179px;
  border-radius: 12px 12px 0px 0px;
}

.card-events .detailes {
  padding: 8px;
  background: #f3f3f3;
}

.card-events .detailes p {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  margin: 0px !important;
  color: #999999;
  text-align: center;
}

.card-events .schedule {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 12px;
  border-bottom: 1px solid #eaeaea;
}

.card-events .schedule div {
  display: flex;
  align-items: center;
  width: 142px;
}

.card-events .schedule div p {
  margin: 0px !important;
  margin-left: 8px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.87);
}

.card-events .status {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 12px;
}

.card-events .status div {
  display: flex;
  align-items: center;
  width: 142px;
}

.card-events .status div p {
  margin: 0px !important;
  margin-left: 8px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.87);
}

.card-events .status .cta {
  width: 142px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
}

.card-events .status .cta.done {
  background: #ffffff;
  border: 1px solid #999999;
  color: #999999;
}

.card-events .status .cta.off {
  background: #999;
  border: unset;
  color: #fff;
}

.card-events .status .cta.off.offline {
  background: #6c60e0;
  border: unset;
  color: #fff;
}

.card-events .status .cta.on {
  background: #6c60e0;
  border: unset;
  color: #fff;
}
#detail-schedule .look-all {
  width: 100%;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  background: #f4f1ff;
  border: 1px solid rgba(125, 97, 214, 0.2);
  box-sizing: border-box;
  border-radius: 8px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #6c60e0;
}

.event-container .last-event .look-all {
  border: none;
  width: auto;
  background: #f5f5f5;
  border-radius: 4px;
  padding: 4px 12px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.87);
}

.event-container .want-ask {
  padding: 32px 16px;
  background: #f5f0ff;
}

.event-container .want-ask div {
  display: flex;
}

.event-container .want-ask div h4 {
  margin: 0px !important;
  margin-left: 10px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.87);
}

.event-container .want-ask button,
.button-submit-question {
  margin-top: 16px;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #6c60e0;
  border-radius: 12px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  border: unset;
}

.event-container .qna {
  padding: 32px 16px;
}

.event-container .qna>h1 {
  margin: 0px !important;
  margin-bottom: 10px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.87);
}

.event-container .qna .filter-qna {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.event-container .qna .filter-qna button {
  height: 30px;
  border-radius: 8px;
  background: #ffffff;
  border: 1px solid #eaeaea;
  padding: 8px 12px;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.87);
  margin-right: 8px;
  white-space: nowrap;
}

.event-container .qna .filter-qna button.active {
  background: #e0d7ff;
  border: unset;
  color: #6c60e0;
  font-weight: 500;
}

.last-event .filter-qna {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.last-event .filter-qna button {
  height: 30px;
  border-radius: 8px;
  background: #ffffff;
  border: 1px solid #eaeaea;
  padding: 8px 12px;
  font-family: Rubik;
  white-space: nowrap;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.87);
  margin-right: 8px;
}

.last-event .filter-qna button.active {
  background: #e0d7ff;
  border: unset;
  color: #6c60e0;
  font-weight: 500;
}

.event-container .card-qna {
  margin-bottom: 10px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 12px;
}

.event-container .card-qna .up {
  padding: 16px 12px;
  border-bottom: 1px solid #eaeaea;
}

.event-container .card-qna .up h1 {
  margin: unset !important;
  margin-bottom: 0px !important;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.87);
}

.event-container .card-qna .up h5 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #999999;
  margin: unset !important;
  margin-top: 12px !important;
}

.event-container .card-qna .down {
  padding: 16px 12px;
}

.event-container .card-qna .down .speakers {
  display: flex;
  align-items: center;
}

.event-container .card-qna .down .speakers img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-right: 12px;
}

.event-container .card-qna .down p {
  margin: 0px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 4px !important;
  color: rgba(0, 0, 0, 0.87);
}

.event-container .card-qna .down h4 {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin: unset !important;
  margin-bottom: 4px !important;
  color: rgba(0, 0, 0, 0.87);
}

.event-container .card-qna .down h5 {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin: unset !important;
  color: #999999;
}

.event-container .card-qna .down .cta-sections {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 16px;
}

.event-container .card-qna .down .cta-sections .actions {
  display: flex;
  align-items: center;
  margin-right: 12px;
  cursor: pointer;
}

.event-container .card-qna .down .cta-sections .actions img {
  margin: 0px;
}

.event-container .card-qna .down .cta-sections .actions p {
  margin: 0px !important;
  margin-left: 6px !important;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

.event-container .card-qna .down .cta-sections button {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: unset;
  width: 26px;
  padding: 4px;
  height: 26px;
}

.event-container .card-qna .down div button img {
  height: 16px;
  margin-right: 4px;
}

.event-container .card-qna .down div button p {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin: unset !important;
}

.event-container .card-qna .down div button.clock {
  background: #f5f0ff;
}

.event-container .card-qna .down div button.clock p {
  color: #7d61d6;
}

.event-container .card-qna .down div button.play {
  background: #7d61d6;
}

.event-container .card-qna .down div button.play img {
  margin-right: 2px;
}

.event-container .card-qna .down div button.play p {
  color: #fff;
}

.event-container .narsum {
  background: #fff3c6;
  padding: 32px 16px;
}

.event-container .narsum .top {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}

.event-container .narsum .top h1 {
  margin: unset !important;
  margin-left: 10px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.87);
}

.event-container .narsum .bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.event-container .narsum .bottom div {
  display: flex;
  align-items: center;
  width: 48.5%;
  height: 40px;
  background: #fffae7;
  border-radius: 12px;
  padding: 8px;
  margin-top: 10px;
}

.event-container .narsum .bottom div p {
  margin: unset !important;
  margin-left: 4px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #262626;
}

.event-container .feature {
  padding: 32px 16px;
}

.event-container .feature>h1 {
  margin: 0px !important;
  margin-bottom: 10px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.87);
}

.event-container .feature>h1~div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.event-container .feature>h1~div div {
  padding: 14px 12px;
  background: #e0d7ff;
  border-radius: 12px;
  margin-bottom: 10px;
  width: 31%;
  height: 86px;
}

.event-container .feature>h1~div div p {
  margin: unset !important;
  margin-top: 8px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #262626;
}

#material-webs {
  padding: 0px 16px;
  margin-bottom: 32px;
}

#material-webs h1 {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin: 0px !important;
  color: rgba(0, 0, 0, 0.87);
}

#material-webs .contents {
  display: flex;
  align-items: center;
  width: 100%;
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 12px;
  cursor: pointer;
  margin-top: 10px;
  justify-content: space-between;
}

#material-webs .parents-mate {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

#material-webs .contents {
  width: 48.5%;
  cursor: pointer;
}

#material-webs .contents .dv {
  display: flex;
  align-items: center;
}

#material-webs .contents .dv div {
  margin-left: 16px;
}

#material-webs .contents .dv div h4 {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin: 0px !important;
  color: rgba(0, 0, 0, 0.87);
}

#material-webs .contents .dv div p {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin: 0px !important;
  margin-top: 5px !important;
  color: #999999;
}

.lists-mobile {
  display: none;
}

.ask-question-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  top: 0px;
  position: fixed;
  z-index: 1000;
}

.ask-question-container .pop_boxes {
  width: 360px;
  display: flex;
  max-height: 620px;
  border-radius: 12px;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.05);
}

.pop_tanya .pop_boxes .fq_parent {
  margin-bottom: 0px;
}

.pop_tanya .pop_boxes .fq_select {
  margin-top: 10px;
}

.pop_tanya .pop_boxes .tx_fq_title {
  margin-top: 0px;
}

.ask-question-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
}

.ask-question-header h1 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #262626;
  margin: 0px;
  margin-left: 12px;
  width: 80%;
}

.ask-question-footer {
  padding: 16px;
  background: white;
  box-shadow: 0px -5px 30px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 12px 12px;
  border-top: 1px solid #eaeaea;
}

.ask-question-content {
  padding: 0px;
  overflow: auto;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.ask-question-content::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.ask-question-container .pop_boxes .fq_parent {
  margin-bottom: 0px;
}

.ask-question-container .pop_boxes .fq_select {
  margin-top: 10px;
}

.ask-question-container .pop_boxes .tx_fq_title {
  margin-top: 0px;
}

.q_submitted {
  height: 480px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.q_submitted div {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
  margin-top: 12px;
}

.cta-asking {
  display: none;
}

@media (max-width: 768px) {
  .event-logo {
    width: 96px;
    height: 32px;
  }
  .list-speakers > h1 {
    margin: 0px !important;
    margin-bottom: 10px !important;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.87);
  }
  .cta-asking {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 16px;
    background: #f5f5f5;
    border-radius: 8px;
    border: none;
    width: 100%;
    height: 30px;
  }

  .cta-asking p {
    margin: 0px !important;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
  }

  .parent-event {
    padding-top: 96px;
  }

  .event-cover {
    width: 100%;
    min-height: 100%;
    height: 167px;
    border: solid;
  }

  .lists-mobile {
    display: block;
  }

  .ask-question-container .pop_boxes {
    width: 100%;
    position: absolute;
    margin-bottom: 10px;
    height: 100%;
    max-height: 100%;
    border-radius: 0px;
    margin: 0px;
    justify-content: space-between;
  }

  .ask-question-footer {
    border-radius: 12px 12px 0px 0px;
  }
}

.event-container .section-ones {
  display: flex;
  margin-top: 36px;
  max-width: 480px;
  width: 100%;
  justify-content: center;
  border-radius: 6px;
  flex-wrap: wrap;
  padding: 0px 16px;
}

.parent-event .event-container .one {
  display: none;
}

.parent-event .event-container .two .two-left {
  display: none;
}

.parent-event .event-container .two .two-right .text-wantask {
  display: none;
}

.parent-event .event-container .two .tabs {
  display: none;
}

.parent-event .event-container .two .two-right .desktops {
  display: none;
}

.parent-event .event-container .two .two-right .mobiles {
  display: block;
}
/* .parent-event .event-container .last-event ~ .one ~ .two .app-bars {
  display: none;
} */
.parent-event .event-container .last-event ~ .one ~ .two .app-bars {
  position: fixed;
  top: 0px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.parent-event
  .event-container
  .last-event
  ~ .one
  ~ .two
  .app-bars
  .content-appbars {
  max-width: 1170px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.parent-event
  .event-container
  .last-event
  ~ .one
  ~ .two
  .app-bars
  .content-appbars
  .sectiones {
  display: flex;
  justify-content: center;
  width: 37%;
  margin-left: 100px;
}
.parent-event .event-container .last-event ~ .one ~ .two .app-bars .the-logos {
  width: 96px;
  display: block;
}
.parent-event
  .event-container
  .last-event
  ~ .one
  ~ .two
  .app-bars
  .parent-tabs {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.parent-event
  .event-container
  .last-event
  ~ .one
  ~ .two
  .app-bars
  .parent-tabs
  .item-tabs {
  padding: 10px;
  padding-bottom: 0px;
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  height: 64px;
}
.parent-event
  .event-container
  .last-event
  ~ .one
  ~ .two
  .app-bars
  .parent-tabs
  .item-tabs
  .head-tabs {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.parent-event
  .event-container
  .last-event
  ~ .one
  ~ .two
  .app-bars
  .parent-tabs
  .item-tabs
  p {
  margin: 0px !important;
  margin-top: 7px !important;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
}
.parent-event
  .event-container
  .last-event
  ~ .one
  ~ .two
  .app-bars
  .parent-tabs
  .item-tabs
  .tab-indicator {
  background: #fff;
  border-radius: 2px 2px 0px 0px;
  height: 2px;
  width: 100%;
}
.parent-event
  .event-container
  .last-event
  ~ .one
  ~ .two
  .app-bars
  .parent-tabs
  .item-tabs
  .active-tab-indicator {
  background: #6c60e0;
  border-radius: 2px 2px 0px 0px;
  height: 2px;
  width: 100%;
}
@media (min-width: 1000px) {

  .event-container>.section-ones,
  .event-container .menu,
  .event-container .menu~.last-event {
    display: none;
  }

  .event-container .last-event {
    padding: 0px !important;
  }

  .parent-header-e {
    display: none;
  }

  .parent-header-e .forced {
    display: flex;
  }

  .parent-event {
    background-color: #fcfcfc;
    /* height: 100vh;
    overflow-y: hidden; */
    height: auto;
    min-height: 100%;
    overflow: initial;
  }

  .parent-event .event-container {
    max-width: 1170px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 28px;
  }

  .parent-event .event-container .one {
    display: block;
    width: 31%;
    background: #ffffff;
    /* border: 1px solid #eaeaea; */
    box-sizing: border-box;
    border-radius: 12px;
    height: fit-content;
  }

  .parent-event .event-container .one .headss {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #eaeaea;
  }

  .parent-event .event-container .one .headss img {
    height: 41px;
  }

  .parent-event .event-container .one .contents {
    padding: 16px;
    padding-top: 18px;
    border-bottom: 1px solid #eaeaea;
  }

  .parent-event .event-container .one .contents .the-content {
    display: flex;
    align-items: center;
  }

  .parent-event .event-container .one .contents .the-content img {
    background: #ffffff;
    border: 1px solid #eaeaea;
    border-radius: 50%;
    width: 44px;
    height: 44px;
  }

  .parent-event .event-container .one .logins {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0px 16px;
    margin-bottom: 16px;
  }

  .parent-event .event-container .one .logins .msk {
    width: 47.5%;
    border: 1px solid #6c60e0;
    box-sizing: border-box;
    border-radius: 12px;
    background: #fff;

    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    height: 41px;
    text-align: center;
    color: #6c60e0;
  }

  .parent-event .event-container .one .logins .dft {
    width: 47.5%;
    border: unset;
    box-sizing: border-box;
    border-radius: 12px;
    background: #6c60e0;

    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    height: 41px;
    text-align: center;
    color: #fff;
  }

  .parent-event .event-container .one .cover-img {
    width: 100%;
    height: 80px;
    object-fit: cover;
    border: 1px solid #eaeaea;
    border-radius: 12px 12px 0px 0px;
    border-bottom: none;
  }

  .parent-event .event-container .one .contents h4 {
    margin: 0px !important;
    margin-left: 16px !important;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.87);
  }

  .parent-event .event-container .one .contents p {
    margin: 0px !important;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;

    color: rgba(0, 0, 0, 0.87);
  }

  .parent-event .event-container .last-event~.one~.two {
    width: 70%;
    padding-left: 32px;
  }

  .parent-event .event-container .last-event ~ .one ~ .two .app-bars .asking {
    display: flex;
    align-items: center;
    padding: 5px 12px;
    background: #f5f5f5;
    border-radius: 8px;
    border: none;
    height: fit-content;
  }

  .parent-event .event-container .last-event~.one~.two .app-bars .asking p {
    margin: 0px !important;
    margin-left: 8px !important;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: rgba(0, 0, 0, 0.87);
  }

  .parent-event .event-container .last-event~.one~.two .tabs {
    display: flex;
    padding-top: 20px;
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;
    background: #ffffff;
    border: 1px solid #eaeaea;
    box-sizing: border-box;
    border-radius: 12px;
    justify-content: space-between;
  }

  .parent-event .event-container .last-event~.one~.two .tabs .div>div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 14px;
    width: 100%;
  }

  .parent-event .event-container .last-event~.one~.two .tabs .div {
    cursor: pointer;
    width: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .parent-event .event-container .last-event~.one~.two .tabs .div .span {
    background: #fff;
    border-radius: 8px 8px 0px 0px;
    height: 6px;
    width: 100%;
    padding: 0px !important;
  }

  .parent-event .event-container .last-event~.one~.two .tabs .div.active .span {
    background: #6c60e0;
  }

  .parent-event .event-container .last-event~.one~.two .tabs .div.sche .span {
    background: #f159c4;
  }

  .parent-event .event-container .last-event~.one~.two .tabs div.active p {
    color: #6c60e0;
    font-weight: bold;
  }

  .parent-event .event-container .last-event~.one~.two .tabs div.sche p {
    color: #f159c4;
    font-weight: bold;
  }

  .parent-event .event-container .last-event~.one~.two .tabs .div.recr .span {
    background: #f9a33f;
  }

  .parent-event .event-container .last-event~.one~.two .tabs div.recr p {
    color: #f9a33f;
    font-weight: bold;
  }

  .parent-event .event-container .last-event~.one~.two .tabs .div.asks .span {
    background: #3fa985;
  }

  .parent-event .event-container .last-event~.one~.two .tabs div.asks p {
    color: #3fa985;
    font-weight: bold;
  }

  .parent-event .event-container .last-event~.one~.two .tabs .div.downs .span {
    background: #369cf7;
  }

  .parent-event .event-container .last-event~.one~.two .tabs div.downs p {
    color: #369cf7;
    font-weight: bold;
  }

  .parent-event .event-container .last-event~.one~.two .tabs .div p {
    margin: 0px !important;
    margin-left: 12px !important;

    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;

    color: #262626;
  }

  .parent-event .event-container .last-event~.one~.two .sectioner {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .parent-event .event-container .last-event~.one~.two .two-left {
    display: block;
    width: 54%;
    height: 100vh;
    padding-bottom: 114px;
    overflow-y: auto;
    scrollbar-width: none;
    box-sizing: border-box;
  }

  .parent-event .event-container .last-event~.one~.two .two-left::-webkit-scrollbar {
    display: none;
  }

  .parent-event .event-container .last-event~.one~.two .two-left::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  .parent-event .event-container .last-event~.one~.two .two-left::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
  }

  .parent-event .event-container .last-event~.one~.two .two-right {
    width: 42%;
    max-height: 80vh;
    height: 100%;
    overflow: auto;
    scrollbar-width: none;
  }

  .parent-event .event-container .last-event~.one~.two .two-right::-webkit-scrollbar {
    display: none;
  }

  .parent-event .event-container .last-event~.one~.two .two-right .text-wantask {
    margin: 0px !important;
    margin-bottom: 16px !important;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: block;
    color: rgba(0, 0, 0, 0.87);
  }

  .event-container .want-ask {
    padding: 18px 16px;
    margin-bottom: 24px;
    border-radius: 12px;
  }

  .event-container .want-ask div {
    display: block;
  }

  .event-container .want-ask div h4 {
    margin-left: 0px !important;
    margin-top: 16px !important;
  }

  .event-container .narsum {
    padding: 18px 16px;
    padding-top: 30px;
    background: #ffffff;
    border: 1px solid #eaeaea;
    box-sizing: border-box;
    border-radius: 12px;
  }

  .event-container .narsum .top {
    display: block;
  }

  .event-container .narsum .top h1 {
    margin-left: 0px !important;
    margin-top: 16px !important;
    margin-bottom: 6px !important;
  }

  .event-container .narsum .bottom img {
    display: none;
  }

  .event-container .feature {
    padding: 0px;
    margin-top: 24px;
  }

  .event-container .feature>h1~div div {
    background: #ffffff;
    border: 1px solid #eaeaea;
    box-sizing: border-box;
    border-radius: 12px;
  }

  .event-container .two-left .last-event {
    padding: 0px;
  }

  .event-container .two-left .card-events {
    margin-bottom: 16px;
  }

  .event-container .two-left .placeHolders {
    background: #ffffff;
    border: 1px solid #eaeaea;
    box-sizing: border-box;
    border-radius: 12px;
    width: 100%;
    height: 330px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }

  .event-container .two-left .placeHolders img {
    height: 84px;
    margin-bottom: 16px;
  }

  .event-container .two-left .placeHolders h4 {
    margin: 0px !important;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
  }

  .event-container .two-left .placeHolders p {
    margin: 6px 0px !important;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    max-width: 300px;
    color: rgba(0, 0, 0, 0.87);
  }

  .event-container .two-right .choose-time {
    background: #ffffff;
    border: 1px solid #eaeaea;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 16px;
    padding-bottom: 6px;
    width: 100%;
  }

  .event-container .two-right .choose-time div {
    display: flex;
    align-items: center;
    padding: 8px 10px;
    background: #ffffff;
    border: 1px solid #eaeaea;
    box-sizing: border-box;
    border-radius: 8px;
    margin-bottom: 10px;
    cursor: pointer;
  }

  .event-container .two-right .choose-time div p {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    margin: 0px !important;
    margin-left: 8px !important;
    color: rgba(0, 0, 0, 0.87);
  }

  .event-container .two-right .choose-time div.active {
    background: #e0d7ff;
    border: unset;
  }

  .event-container .two-right .choose-time div.active p {
    color: #6c60e0;
  }

  .parent-event .event-container .two .two-right .desktops {
    display: block;
  }

  .parent-event .event-container .two .two-right .mobiles {
    display: none;
  }

  .parent-event .event-container .two .two-left .card-certificate {
    background: #ffffff;
    border: 1px solid #eaeaea;
    box-sizing: border-box;
    border-radius: 12px;
    margin-bottom: 16px;
  }

  .parent-event .event-container .two .two-left .card-certificate .one {
    padding: 12px;
    border-bottom: 1px solid #eaeaea;
    width: 100% !important;
    border-radius: 12px 12px 0px 0px !important;
  }

  .parent-event .event-container .two .two-left .card-certificate .one h5 {
    margin-bottom: 8px;
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: #6c60e0;
  }

  .parent-event .event-container .two .two-left .card-certificate .one h1 {
    margin: 0px !important;
    margin-bottom: 12px !important;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.87);
  }

  .parent-event .event-container .two .two-left .card-certificate .one h1~div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .event-container {
    background: unset !important;
  }

  .parent-event .event-container .two .two-left .card-certificate .one h1~div div {
    display: flex;
    align-items: center;
    width: 48.5%;
  }

  .parent-event .event-container .two .two-left .card-certificate .one h1~div div p {
    margin: 0px !important;
    margin-left: 8px !important;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.87);
  }

  .parent-event .event-container .two .two-left .card-certificate .two {
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .parent-event .event-container .two .two-left .card-certificate .two div {
    display: flex;
    align-items: center;
    width: 48.5%;
  }

  .parent-event .event-container .two .two-left .card-certificate .two div p {
    margin: 0px !important;
    margin-left: 8px !important;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.87);
  }

  .parent-event .event-container .two .two-left .card-certificate .two button {
    width: 48.5%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    background: #6c60e0;
    border-radius: 8px;
    border: unset;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
  }

  .parent-event .event-container .parent-verif section {
    padding: 16px;
    background: #d7efff;
    border-radius: 12px 12px 0px 0px;
  }

  .parent-event .event-container .parent-verif section h1 {
    margin: 0px !important;
    margin-top: 16px !important;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.87);
  }

  .parent-event .event-container .parent-verif {
    margin-bottom: 24px;
  }

  .parent-event .event-container .parent-verif section~div {
    background: #ffffff;
    border: 1px solid #eaeaea;
    box-sizing: border-box;
    border-radius: 0px 0px 12px 12px;
  }

  .parent-event .event-container .parent-verif section~div .one {
    padding: 16px 12px;
    border-bottom: 1px solid #eaeaea;
    width: 100% !important;
    border-radius: 0px !important;
  }

  .parent-verif section~div .one div {
    display: flex;
    align-items: center;
  }

  .parent-verif section~div .one div p {
    margin: 0px !important;
    margin-left: 8px !important;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.87);
  }

  .parent-verif section~div .one h5 {
    margin: 0px !important;
    margin-left: 38px !important;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.87);
  }

  .parent-verif section~div .two {
    padding: 16px 12px;
  }

  .parent-verif section~div .two .head div {
    display: flex;
    align-items: center;
  }

  .parent-verif section~div .two .head div h5 {
    margin-left: 8px;
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: #f9a33f;
  }

  .parent-verif section~div .two .head p {
    margin: 0px !important;
    margin-left: 38px !important;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.87);
  }

  .parent-verif section~div .two .cta {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 16px;
  }

  .parent-verif section~div .two .cta .change {
    width: 48.5%;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    border-radius: 8px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.87);
  }

  .parent-verif section~div .two .cta .done {
    width: 48.5%;
    background: #ffffff;
    border: 1px solid #6c60e0;
    box-sizing: border-box;
    border-radius: 8px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #6c60e0;
  }

  .auths-box .warning {
    margin: 24px 0px;
  }

  .auths-box .warning div {
    display: flex;
    align-items: center;
  }

  .auths-box .warning h5 {
    margin-left: 8px;
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: #f9a33f;
  }

  .auths-box .warning p {
    margin: 0px !important;
    margin-left: 38px !important;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.87);
  }

  .parent-header-e .menu-mob {
    display: none;
  }

  .event-header div {
    justify-content: center;
    width: 100%;
  }

  .parent-header-e .menu-mobiles {
    display: none;
  }

  .parent-event .event-container .mained {
    display: block;
    padding: 16px;
    padding-top: 24px;
    border-top: 1px solid #eaeaea;
  }

  .parent-event .event-container .microsite-paids .prime {
    padding: 16px;
    border-bottom: 1px solid #eaeaea;
  }

  .parent-event .event-container .microsite-paids .prime h1 {
    margin: 0px !important;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.87);
  }

  .parent-event .event-container .microsite-paids .prime p {
    margin: 0px !important;
    margin-top: 16px !important;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.87);
  }

  .parent-event .event-container .microsite-paids .prime .flexed {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 24px;
  }

  .parent-event .event-container .microsite-paids .prime h5 {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #6c60e0;
    margin: 0px !important;
  }

  .parent-event .event-container .microsite-paids .prime h4 {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    margin: 0px !important;
    margin-top: 4px !important;
    color: rgba(0, 0, 0, 0.87);
  }

  .parent-event .event-container .microsite-paids .prime .flexed button {
    width: auto;
    background: #6c60e0;
    border-radius: 8px;
    border: none;
    padding: 6px 19px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
  }

  .parent-event .event-container .microsite-paids .prime .flexed button img {
    margin-right: 6px;
  }

  .parent-event .event-container .microsite-paids .bottomeds {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .parent-event .event-container .microsite-paids .bottomeds .flexed {
    display: flex;
    align-items: center;
  }

  .parent-event .event-container .microsite-paids .bottomeds .flexed p {
    margin: 0px !important;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: rgba(0, 0, 0, 0.87);
  }

  .parent-event .event-container .microsite-paids .bottomeds a {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #6c60e0;
    text-decoration: none;
  }

  .parent-event .event-container .mained h6 {
    margin: 0px;
    margin-left: 10px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: rgba(153, 153, 153, 0.87);
  }

  .parent-event .event-container .mained .menu-ava {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }

  .parent-event .event-container .mained .menu-ava p {
    margin: 0px !important;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
  }

  .parent-event .event-container .mained .menu-ava div {
    background: #6c60e0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
    width: 42px;
    border-radius: 12px;
    margin-right: 12px;
  }

  .parent-event .event-container .mained .menu-ava h5 {
    margin: 0px !important;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.87);
  }

  .parent-event .event-container .mained .menu-status {
    display: flex;
    align-items: center;
  }

  .parent-event .event-container .mained .menu-status div {
    margin-left: 12px;
  }

  .parent-event .event-container .mained .menu-status p {
    margin: 0px !important;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.87);
  }

  .parent-event .event-container .mained .menu-status span {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    margin: 0px !important;
    margin-top: 2px !important;
    color: rgba(0, 0, 0, 0.87);
  }

  .parent-event .event-container .logouts p {
    margin: 0px !important;
    margin-left: 16px !important;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.87);
  }

  .parent-event .event-container .logouts {
    padding: 16px;
    display: flex;
    align-items: center;
    width: 100%;
    border-top: 1px solid #eaeaea;
    cursor: pointer;
  }

  .materials-mobile {
    display: none !important;
  }

  .materials-desktop {
    display: block !important;
  }

  #material-webs {
    padding: 0px;
  }
}

#detail-schedule #e_mobs {
  display: none;
  padding-top: 32px;
}

@media (max-width: 768px) {
  #detail-schedule #e_mobs {
    display: block;
  }

  #detail-schedule .event-header div {
    justify-content: center !important;
    width: 100% !important;
    padding-left: 0px !important;
  }

  #detail-schedule .parent-header-e .menu-mob {
    display: none;
  }

  #detail-schedule.profile .event-header div {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    width: 60% !important;
    padding-left: 16px !important;
  }

  #detail-schedule.profile .parent-header-e .menu-mob {
    display: block;
  }

  #detail-schedule .parent-event .event-container {
    max-width: 448px !important;
    background: #fff !important;
    width: 100% !important;
    display: block !important;
    padding: 0px !important;
  }

  #detail-schedule .event-container .e_mobs .last-event {
    padding: 32px 16px !important;
    padding-bottom: 0px !important;
  }

  #detail-schedule .parent-event {
    height: auto !important;
  }

  .parent-allevent .placeHolders {
    height: 100vh;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .parent-allevent .placeHolders h4 {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
  }

  .parent-allevent .placeHolders p {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
  }

  .event-container .section-ones .mob-covers {
    width: 100%;
    height: auto;
    background-color: #fff;
    border: 1px solid #eaeaea;
    border-radius: 8px;
  }

  .event-container .section-ones .microsite-paids .prime {
    padding: 12px;
    border-bottom: 1px solid #eaeaea;
  }

  .event-container .section-ones .microsite-paids .prime h1 {
    margin: 0px !important;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.87);
  }

  .event-container .section-ones .microsite-paids .prime p {
    margin: 0px !important;
    margin-top: 16px !important;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.87);
  }

  .event-container .section-ones .microsite-paids .prime .flexed {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 24px;
  }

  .event-container .section-ones .microsite-paids .prime h5 {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #6c60e0;
    margin: 0px !important;
  }

  .event-container .section-ones .microsite-paids .prime h4 {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    margin: 0px !important;
    margin-top: 4px !important;
    color: rgba(0, 0, 0, 0.87);
  }

  .event-container .section-ones .microsite-paids .prime .flexed button {
    width: auto;
    background: #6c60e0;
    border-radius: 8px;
    border: none;
    padding: 6px 19px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
  }

  .event-container .section-ones .microsite-paids .prime .flexed button img {
    margin-right: 6px;
  }

  .event-container .section-ones .microsite-paids .bottomeds {
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .event-container .section-ones .microsite-paids .bottomeds .flexed {
    display: flex;
    align-items: center;
  }

  .event-container .section-ones .microsite-paids .bottomeds .flexed p {
    margin: 0px !important;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: rgba(0, 0, 0, 0.87);
  }

  .event-container .section-ones .microsite-paids .bottomeds a {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #6c60e0;
    text-decoration: none;
  }
  .parent-event .event-container .last-event ~ .one ~ .two .app-bars {
    bottom: 0px;
    height: fit-content;
    top: unset;
    border: 1px solid #eaeaea;
  }
  .parent-event
    .event-container
    .last-event
    ~ .one
    ~ .two
    .app-bars
    .the-logos {
    display: none;
  }
  .parent-event
    .event-container
    .last-event
    ~ .one
    ~ .two
    .app-bars
    .content-appbars
    .sectiones {
    margin: 0px;
    width: 100%;
  }
  .parent-event .event-container .last-event ~ .one ~ .two .app-bars .asking {
    display: none;
  }
}

#other_e .card-events {
  margin-bottom: 10px !important;
}

.divs-titles {
  padding: 12px;
  border-bottom: 1px solid #eaeaea;
}

.divs-titles h1 {
  margin: 0px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.87);
}

.testi-container .card-test {
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 12px;
  margin-top: 10px;
}

.testi-container .card-test .test-parent {
  display: flex;
  align-items: center;
  padding: 16px 12px;
  border-bottom: 1px solid #eaeaea;
}

.testi-container .card-test .test-parent .ava {
  background: #f5f0ff;
  border: 1px solid #6c60e0;
  box-sizing: border-box;
  border-radius: 12px;
  height: 42px;
  width: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.testi-container .card-test .test-parent .ava span {
  margin: 0px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin-right: 10px;
  color: #6c60e0;
}

.testi-container .card-test .test-parent .ava~div h1 {
  margin: 0px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.87);
}

.testi-container .card-test .test-parent .ava~div img {
  height: 12px;
  width: 12px;
}

.testi-container .card-test .score-parent {
  padding: 16px 12px;
}

.testi-container .card-test .score-parent p {
  margin: 0px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.87);
}

.testi-header .h1 {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin: 0px !important;
  color: rgba(0, 0, 0, 0.87);
}

.testi-header .card-sche {
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 12px;
  margin-top: 10px;
  margin-bottom: 24px;
}

.testi-header .card-sche .title-parent {
  padding: 16px 12px;
  border-bottom: 1px solid #eaeaea;
}

.testi-header .card-sche .title-parent h1 {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin: 0px !important;
  color: rgba(0, 0, 0, 0.87);
}

.testi-header .card-sche .score-parent {
  padding: 16px 12px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.testi-header .card-sche .score-parent div {
  display: flex;
  align-items: center;
  width: 50%;
}

.testi-header .card-sche .score-parent div p {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin: 0px !important;
  margin-left: 8px !important;
  color: rgba(0, 0, 0, 0.87);
}

@media (min-width: 1370px) {
  .parent-event .event-container .last-event~.one~.two .two-right {
    max-height: 100%;
  }
}

.logins-mob {
  display: none;
}

@media (max-width: 768px) {
  .logins-mob {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0px 16px;
    margin-bottom: 16px;
  }

  .logins-mob .msk {
    width: 47.5%;
    border: 1px solid #6c60e0;
    box-sizing: border-box;
    border-radius: 12px;
    background: #fff;

    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    height: 41px;
    text-align: center;
    color: #6c60e0;
  }

  .logins-mob .dft {
    width: 47.5%;
    border: unset;
    box-sizing: border-box;
    border-radius: 12px;
    background: #6c60e0;

    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    height: 41px;
    text-align: center;
    color: #fff;
  }
}

.esch_titles {
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 12px;
  margin-top: 18px;
  margin-bottom: 18px;
}

.esch_titles button {
  display: flex;
  align-items: center;
  background: #f5f0ff;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 12px 0px 0px 12px;
  padding: 12px;
  cursor: pointer;
}

.esch_titles button p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin-left: 8px;
  color: #6c60e0;
}

.esch_titles div p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin-left: 16px;
  color: rgba(0, 0, 0, 0.87);
}

@media (max-width: 768px) {
  #detail-schedule .two {
    display: none !important;
  }
}

#detail-schedule .kues_parent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 12px;
  margin-top: 12px;
}

#detail-schedule .kues_parent div {
  display: flex;
  align-items: center;
}

#detail-schedule .kues_parent div p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin-left: 16px;
  color: rgba(0, 0, 0, 0.87);
}

.p-quiz {
  width: 100%;
  background: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 12px;
  padding: 24px 0px;
  padding-right: 16px;
}

.p-quiz .l-quiz {
  display: flex;
  margin-bottom: 32px;
  width: 100%;
}

.p-quiz .l-quiz span {
  background: #6c60e0;
  border-radius: 0px 4px 4px 0px;
  width: 4px;
  height: 42px;
  border-radius: 0px 12px 12px 0px;
}

.p-quiz .l-quiz .s-quiz {
  margin-left: 12px;
  width: 100%;
}

.p-quiz .l-quiz .s-quiz h1 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.87);
  margin: 0px !important;
}

.p-quiz .l-quiz .s-quiz .multi-quiz {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.87);
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding: 18px;
  cursor: pointer;
  margin-top: 16px;
  width: 100%;
}

.p-quiz .l-quiz .s-quiz .multi-quiz.non-selectable {
  border: solid 1px #dedede;
}

.p-quiz .l-quiz .s-quiz .multi-quiz.active {
  background: #f5f0ff;
  border: 1px solid #6c60e0;
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding: 18px;
  cursor: pointer;
  margin-top: 16px;
  width: 100%;
}

.p-quiz .l-quiz .s-quiz .multi-quiz span {
  padding: 6px;
  height: auto;
  border-radius: 50%;
  background: #ffffff;
  border: 2px solid #999999;
  margin-right: 14px;
}

.p-quiz .l-quiz .s-quiz .multi-quiz.active span {
  padding: 6px;
  height: auto;
  border-radius: 50%;
  background: #ffffff;
  border: 4px solid #6c60e0;
  margin-right: 14px;
}

.p-quiz .l-quiz .s-quiz .multi-quiz p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin: 0px !important;
  color: rgba(0, 0, 0, 0.87);
}

.p-quiz .l-quiz .s-quiz .multi-quiz.active p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin: 0px !important;
  color: #6c60e0;
}

.p-quiz .l-quiz .s-quiz textarea {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.87);
  border-radius: 12px;
  width: 100%;
  resize: none;
  margin-top: 16px;
  padding: 16px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

.p-quiz .l-quiz .s-quiz textarea.inactive {
  background: #f3f3f3;
  border: solid 1px #dedede;
  color: #999999;
}

.p-quiz .l-quiz .s-quiz .ones-quiz {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.87);
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding: 18px;
  cursor: pointer;
  width: 100%;
  margin-top: 16px;
}

.p-quiz .l-quiz .s-quiz .ones-quiz.active {
  background: #f5f0ff;
  border: 1px solid #6c60e0;
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding: 18px;
  cursor: pointer;
  width: 100%;
  margin-top: 16px;
}

.p-quiz .l-quiz .s-quiz .ones-quiz p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin: 0px !important;
  margin-left: 14px !important;
  color: rgba(0, 0, 0, 0.87);
}

.p-quiz .l-quiz .s-quiz .ones-quiz.active p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin: 0px !important;
  margin-left: 14px !important;
  color: #6c60e0;
}

.p-quiz .l-quiz .s-quiz .results-quiz {
  margin-top: 16px;
  background: #f4f4f4;
  border-radius: 12px;
  padding: 16px;
}

.p-quiz .l-quiz .s-quiz .results-quiz .topped {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.p-quiz .l-quiz .s-quiz .results-quiz .topped .tx-titles {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin: 0px !important;
  color: rgba(0, 0, 0, 0.87);
}

.p-quiz .l-quiz .s-quiz .results-quiz .topped .tx-indicators {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  margin: 0px !important;
  color: #6c60e0;
}

.p-quiz .l-quiz .s-quiz .results-quiz .bars-quiz {
  width: 100%;
  background: #dedede;
  border-radius: 2px;
  height: 4px;
}

.p-quiz .l-quiz .s-quiz .results-quiz .bars-quiz .sliders {
  background: #6c60e0;
  border-radius: 2px;
  height: 4px;
}

.r-quiz {
  display: flex;
  align-items: center;
  background: #6c60e0;
  border-radius: 12px;
  padding: 20px 16px;
  margin-bottom: 18px;
}

.r-quiz p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  margin-left: 12px;
}

.section-scc {
  position: fixed;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
}

.section-scc .boxes-s {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  max-width: 360px;
  width: 100%;
}

.section-scc .boxes-s .tops {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 32px 0px;
}

.section-scc .boxes-s .tops img {
  width: 80px;
  height: 80px;
  margin-bottom: 16px;
}

.section-scc .boxes-s .tops p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  margin: 0px !important;
  color: rgba(0, 0, 0, 0.87);
}

.section-scc .boxes-s .bottoms {
  padding: 16px;
  border-top: 1px solid #eaeaea;
}

.section-scc .boxes-s .bottoms button {
  width: 100%;
  padding: 12px;
  text-align: center;
  border: unset;
  background: #6c60e0;
  border-radius: 12px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;
  color: #ffffff;
}

.wrapper_speakers {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .q-mobiles {
    position: absolute;
    top: 0px;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .q-mobiles .parent-mobiles {
    background: #6c60e0;
    min-height: 100vh;
    height: 100%;
    max-width: 450px;
    width: 100%;
  }

  .q-mobiles .parent-mobiles .tops-mobiles {
    padding: 16px;
  }

  .q-mobiles .parent-mobiles .tops-mobiles p {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    margin: 0px !important;
    margin-top: 16px !important;
    color: #ffffff;
  }

  #body.quiziz {
    margin: 0px !important;
  }

  .p-quiz {
    border-radius: 16px 16px 0px 0px;
  }

  .parent-sheets {
    display: flex;
    justify-content: center;
    width: 100%;
    position: fixed;
    z-index: 1000;
    bottom: 0px;
  }

  .parent-sheets .b-sheets {
    max-width: 450px;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px -5px 30px rgba(0, 0, 0, 0.1);
    border-radius: 12px 12px 0px 0px;
    padding: 16px;
  }

  .parent-sheets .b-sheets button {
    width: 100%;
    padding: 12px;
    text-align: center;
    border: unset;
    background: #6c60e0;
    border-radius: 12px;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    cursor: pointer;
    color: #ffffff;
  }

  .section-scc {
    display: none !important;
  }

  .scc-mob {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
  }

  .scc-mob img {
    height: 60px;
    width: 60px;
    margin-bottom: 20px;
  }

  .scc-mob p {
    margin: 0px !important;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
  }
}

#stream-join-btn {
  width: 100%;
  border: none;
  display: flex;
  margin-top: 12px;
  padding: 10px 17px;
  align-items: center;
  flex-direction: row;
  border-radius: 12px;
  justify-content: space-between;

  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}

@media (max-width: 768px) {
  .parent-event .event-container .two {
    min-height: unset !important;
  }
}