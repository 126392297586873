#end-credits .box-popups {
  width: 640px;
  height: 347px;
  display: flex;
  border-radius: 12px;
}
#end-credits .image-popups {
  background: url(/assets/images/popup-subs-qna.png) center no-repeat;
  background-size: cover;
  height: 100%;
  width: 265px;
  border-radius: 12px 0px 0px 12px;
}

#end-credits .content-popups {
  background-color: #fff;
  width: 375px;
  border-radius: 0px 12px 12px 0px;
  padding-left: 24px;
  padding-bottom: 24px;
  height: 100%;
}
#end-credits .close-popups {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 12px;
  padding-bottom: 0px;
}
#end-credits .close-popups img {
  cursor: pointer;
}
#end-credits .main-popups {
  padding-right: 24px;
  margin-top: -8px;
}

#end-credits .main-popups #titlenyo {
  font-family: Rubik;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.87);
  margin: unset !important;
  margin-right: 8px !important;
}

#end-credits .main-popups .parent-item .item-one {
  padding-bottom: 12px;
  border-bottom: 1px solid #dedede;
  margin-bottom: 24px;
}
#end-credits .main-popups .parent-item .item-one .head-one {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
#end-credits .main-popups .parent-item .item-one .head-one .active-package {
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 6px;
  background: #fd8c25;
  border-radius: 4px;
}
#end-credits .main-popups .parent-item .item-one .head-one .active-package p {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  line-height: 9px;
  margin: 0px !important;
  color: #ffffff;
}
#end-credits .descone div {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
#end-credits .descone div img {
  height: 24px;
  margin-right: 8px;
}
#end-credits .descone div p {
  margin: 0px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.87);
}
#end-credits .options-popup {
  margin-top: 24px;
}
#end-credits .options-popup p {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin: unset !important;
  color: rgba(0, 0, 0, 0.87);
}
#end-credits .options-popup div {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
#end-credits .options-popup div .langganan {
  display: flex;
  align-items: center;
  border: unset;
  background: #7d61d6 !important;
  box-sizing: border-box !;
  border-radius: 12px;
  padding: 8.5px 22px;
  background: unset;
  width: auto;
  justify-content: center;
}
#end-credits .options-popup div .langganan p {
  margin: unset !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #fff;
  text-align: center;
}

#end-credits .options-popup div .facebook {
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  border-radius: 12px;
  padding: 8.5px 28px;
  background: unset;
  width: auto;
  justify-content: center;
}
#end-credits .options-popup div .facebook p {
  margin: unset !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
}

#end-credits .options-popup > button {
  width: 100%;
  padding: 12px 0px;
  text-align: center;
  background: #ffffff;
  border-radius: 12px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #7d61d6;
  border: 1px solid #7d61d6;
  margin-top: 16px;
}
#end-credits .parent-closeM {
  display: none;
}
.desktops {
  display: block;
}
.mobiles {
  display: none;
}
@media (max-width: 500px) {
  #end-credits.parent-voice-over {
    padding: 0px 16px;
    align-items: center;
  }
  #end-credits .box-popups {
    width: 100%;
    flex-direction: column;
    height: fit-content;
  }
  #end-credits .image-popups {
    width: 100%;
    height: 164px;
    background: url(/assets/images/popup-subs-qna-mob.png) center no-repeat;
    background-size: cover;
    border-radius: 12px 12px 0px 0px;
  }
  #end-credits .close-popups {
    display: none;
  }
  #end-credits .content-popups {
    width: 100%;
    height: fit-content;
    border-radius: 0px 0px 12px 12px;
    padding: 0px;
  }
  #end-credits .main-popups {
    margin-top: 0px;
    padding: 16px;
  }
  #end-credits .main-popups h1 {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.87);
  }
  #end-credits.descone {
    margin-top: 8px !important;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.87);
  }
  #end-credits .options-popup {
    margin-top: 16px;
  }
  #end-credits .options-popup p {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.87);
  }
  #end-credits .parent-closeM {
    display: flex;
    justify-content: flex-end;
    padding: 8px;
  }
  #end-credits .options-popup div .langganan {
    width: 47.5%;
    height: 41px;
  }

  #end-credits .options-popup div .facebook {
    width: 47.5%;
    height: 41px;
  }
  #end-credits .main-popups .parent-item .item-one {
    padding-bottom: 4px;
    margin-bottom: 16px;
  }

  .desktops {
    display: none !important;
  }
  .mobiles {
    display: block !important;
  }
}
