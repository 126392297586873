.parent-call {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 12px;
  padding: 16px;
}
.parent-call div {
  width: 50%;
}
.parent-call h1 {
  margin: 0px !important;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.87);
}
.parent-call h5 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  margin-top: 12px;
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.87);
}
.parent-call button {
  display: flex;
  align-items: center;
  padding: 7px 17px;
  background: linear-gradient(180deg, #20b16e 0%, #00bb66 100%);
  border-radius: 8px;
  cursor: pointer;
  border: none;
}
.parent-call button p {
  margin: 0px !important;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
}

.parent-call button img {
  margin-right: 8px;
  height: 16px;
  width: 16px;
}

/* @media (max-width: 768px) {
  .parent-call {
    border: none;
  }
} */
