#payment_success .ps_program {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
}

#payment_success .ps_program p {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.87);
}

#payment_success .ps_notes {
    margin: 16px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.87);
}