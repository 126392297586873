.parent-carousels {
  margin-top: 36px;
}
.parent-heads-carousels {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dummy-carousels-box {
  display: none;
}
.text-title-carousels {
  margin: unset !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.87);
}
.text-link-carousels {
  margin: unset !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;
  color: #7d61d6;
}
.parent-indicator-carousels {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.parent-btn-carousels {
  display: flex;
  align-items: center;
}
.parent-dots-carousels {
  display: flex;
  align-items: center;
}
.dots-carousels {
  background: rgba(125, 97, 214, 0.2);
  width: 10px;
  border-radius: 50%;
  height: 10px;
  margin-right: 12px;
  cursor: pointer;
}
.dots-carousels-active {
  background: rgba(125, 97, 214, 0.2);
  width: 10px;
  cursor: pointer;
  border-radius: 50%;
  height: 10px;
  margin-right: 12px;
  background: #7d61d6;
}
.btn-arrow-carousels {
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  height: 34px;
  width: 34px;
  display: flex;
  margin-left: 16px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.wrapper-carousels {
  display: flex;
  align-items: center;
  overflow-x: hidden;
  flex-wrap: nowrap;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
}

.wrapper-carousels::-webkit-scrollbar {
  display: none;
}
.wrapper-carousels > div {
  flex: 0 0 auto;
}

@media (max-width: 768px) {
  .wrapper-carousels {
    overflow-x: auto;
    overflow-y: hidden;
    padding-left: 16px;
  }
  .text-title-carousels {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.87);
  }
  .text-link-carousels {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #7d61d6;
  }
  .parent-indicator-carousels {
    display: none;
  }
  .parent-heads-carousels {
    padding: 0px 16px;
  }
  .dummy-carousels-box {
    flex: 0 0 auto;
    width: 1px;
    height: 16px;
    display: block;
  }
}
