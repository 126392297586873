.parent-card-narasumber {
  margin-right: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  width: 125px;
  justify-content: center;
  margin-bottom: 16px !important;
}
#narasumber-section .wrapper-carousels {
  align-items: flex-start;
}
.img-narasumber {
  width: 125px;
  height: 125px;
  border-radius: 50%;
  margin-bottom: 24px;
}
.text-title-narasumber {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  margin: unset !important;
  color: rgba(0, 0, 0, 0.87);
  height: 34px;
}
@media (max-width: 768px) {
  .parent-card-narasumber {
    margin-right: 16px;
    width: 91px;
    margin-bottom: unset !important;
  }
  .img-narasumber {
    width: 91px;
    height: 91px;
    margin-bottom: 8px;
  }
  .text-title-narasumber {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
  }
}
