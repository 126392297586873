#article-program {
  position: inherit;  
  overflow-y: none;
}
.close_button {
  display: none;
}
#article-program .img-bkg {
  width: 100%;
  height: 200px;
  background-size: cover;
  margin-bottom: 24px;
}

.bodys p {
  margin-bottom: 20px;;
}

#article-program .bodys img {
  width: 100% !important;
}

.img-bkg.mobile {
  display: none;
}

#article-program .heads .main .item-heads {
  display: flex;
  align-items: center;
  background: #f9a33f;
  border-radius: 6px;
  padding: 3px 6px;
}
#article-program .heads .main .item-heads p {
  margin: 0px !important;
  margin-left: 4px !important;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
}

#article-program .heads .main .item-heads-mains {
  display: flex;
  align-items: center;
  background: #f5f0ff;
  border-radius: 6px;
  padding: 3px 6px;
  margin-left: 10px;
}

#article-program .heads {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
#article-program .heads .main {
  display: flex;
  align-items: center;
}

#article-program .heads .main .item-heads-mains p {
  margin: 0px !important;
  margin-left: 4px !important;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #6c60e0;
}

#article-program h1 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin: 0px !important;
  margin-top: 12px !important;
  margin-bottom: 32px !important;
  color: rgba(0, 0, 0, 0.87);
}

#article-program .author-sec {
  display: none;
  align-items: center;
  margin-bottom: 32px;
}
#article-program .author-sec img {
  height: 48px;
  width: 48px;
  border-radius: 50%;
  margin-right: 16px;
}

#article-program .author-sec h5 {
  margin: 0px !important;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: rgba(0, 0, 0, 0.87);
}

#article-program .author-sec p {
  margin: 0px !important;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.87);
}
#article-program .author-sec .bodys p {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  margin: 0px !important;
  color: rgba(0, 0, 0, 0.87);
}

@media (max-width: 768px) {
  #article-program {
    /* position: fixed; */
    top: 0px;
    left: 0px;
    /* height: 110vh; */
    z-index: 1000;
    /* overflow-y: auto; */
  }
  /* #article-program div{
    position: fixed;
    bottom: 0px;
    height: 100%;
  } */
  .close_button {
    display: block;
  }
  #article-program .author-sec {
    display: block;
  }
  .img-bkg.mobile {
    display: block;
  }
}